import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PaymentReportService extends BaseService {
  getPaymentTypes() {
    let paymentTypes$ = this.http.get<any>(this.sharedService.apiUrl('fees/payment-types'))
    return paymentTypes$;
  }
  
  getPaymentUsers() {
    let users$ = this.http.get<any>(this.sharedService.apiUrl('fees/users'))
    return users$;
  }

  // getPaymentsByRevenue() {
  //   const httpOptions = {
  //     params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
  //     responseType: 'blob' as 'json'
  //   };



    getPaymentsByRevenue(filterObject): Observable<any> {
      return this.http.get(this.sharedService.apiUrl('payments/payments-by-revenue'), {
        params: new HttpParams()
          .set('filter.query', filterObject)
      }).pipe(
        map(res => {
          return res;
        })
      );
    }
    getPaymentsByRevenueAlternative(filterObject): Observable<any> {
      return this.http.get(this.sharedService.apiUrl('payments/payments-by-revenue-alternative'), {
        params: new HttpParams()
          .set('filter.query', filterObject)
      }).pipe(
        catchError(error => {
          return throwError(error);
        }), 
        map(res => {
          return res;
        })
      );
    }
  downloadReport(filterObject: any, headers: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('fees/download-report'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;
  }

  stringifyWithUTC(obj) {
    return JSON.stringify(obj, (key, value) => {
      if (obj && obj[key] instanceof Date) {
        const utcDateString = new Date(obj[key].getTime() - (obj[key].getTimezoneOffset() * 60000)).toISOString();
       return utcDateString
      }
      return value;
    });
  }
}
