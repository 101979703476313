import { CompanyDexie } from "../interfaces/companyDexie";
import Dexie from 'dexie';

export async function searchCompanies(
    queryObj: Record<string, any>, page: number,
    pageSize: number, sortColumn: string, sortOrder: string, thisCompanies): Promise<{ total: number; data: CompanyDexie[] }> {
    let collection: Dexie.Collection<CompanyDexie, number> | null = null;
    for (const [key, value] of Object.entries(queryObj)) {
        if (value) {
            if (key === "balanceType") {
                collection = collection
                    ? collection.and((item) => value === 1 ? item.balance >= 0 : item.balance < 0)
                    : thisCompanies.filter((item) => value === 1 ? item.balance >= 0 : item.balance < 0);
            } else if (Array.isArray(value)) {
                collection = collection
                    ? collection.and((item) => value.includes(item[key]))
                    : thisCompanies.where(key).anyOf(value);
            }
            else {
                collection = collection
                    ? collection.and((item) => item[key]?.toLowerCase().startsWith(value.toLowerCase()))
                    : thisCompanies.where(key).startsWithIgnoreCase(value);
            }

        }
    }
    if (!collection) {
        collection = thisCompanies.toCollection();
    }

    const total = await collection.count();
    let data = await collection.toArray();
    data.sort((a, b) => {
        if (sortOrder === 'desc') {
            return b[sortColumn] > a[sortColumn] ? 1 : -1;
        } else {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
        }
    });

    const paginatedData = data.slice((page - 1) * pageSize, page * pageSize);

    return { total, data: paginatedData };
}