import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { SharedService } from "src/app/services/sharedService";
import { TranslateService } from "@ngx-translate/core";
import { YearService } from "src/app/services/year.service";

@Component({
  selector: "app-forecast-performance-search",
  templateUrl: "./search-forecast-performance.component.html",
  styleUrls: ["./search-forecast-performance.component.css"],
})
export class ForecastPerformanceSearchComponent implements OnInit {
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('stateSelect', { static: false }) stateSelect: MultiselectAutocompleteComponent;
  @ViewChild('yearSelect', { static: false }) yearSelect: MultiselectAutocompleteComponent;
  @Input() type;
  @Output() onApplySearch = new EventEmitter<any>();
  @Output() onResetSearch = new EventEmitter<void>();
  municipalityChangeSubscription: any;
  years: any
  filterData = {
    municipality: null,
  };
  states: any[]
  month = "-1";
  months = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  queryFilter = {};
  filterFields = ["municipality"];
  panelOpenState = true;

  constructor(private categoryService: CategoryService,public sharedService: SharedService, public translate: TranslateService,public yearService: YearService) {

  }
 
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length === 0) {
      delete this.queryFilter[field]
    }
  };

  selectMonth(event){
    this.month = event.value
  }

  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }

  resetFilter() {
    this.queryFilter = {}
    this.month = "-1"
    this.municipalitySelect.resetAll()
    this.stateSelect.resetAll()
    this.yearSelect.resetAll()
  }

  advancedSearch() {
    let selectedMunicipalityData = this.municipalitySelect.selectData.filter(el=> el.selected).map(el => el.item.id)
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.queryFilter['municipality']
      } else {
        if (selectedMunicipalityData.length>0)
          this.queryFilter['municipality'] = selectedMunicipalityData
        else
        this.queryFilter['municipality'] = [localStorage.getItem('municipality')]
      }
    } else {
      if (this.municipalitySelect.selectData.length === 0)
        delete this.queryFilter['municipality']
    }
    if (this.type === 'performance' && this.month!=="-1"){
      this.queryFilter['month'] = this.month;
    }
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }
  async ngOnInit() {
    this.translate.get('APPROVAL-STATES.SUBMITTED').subscribe((translatedText: string) => {
      this.states = [{id:'SUBMITTED', name: this.translate.instant("APPROVAL-STATES.SUBMITTED")}, 
        {id:'APPROVED', name: this.translate.instant("APPROVAL-STATES.APPROVED")},
        {id:'REOPENED', name: this.translate.instant("APPROVAL-STATES.REOPENED")}
      ]
  });
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
        }
        else { 
          this.municipalitySelect.resetAll()
        }
      }else{
        this.municipalitySelect.resetAll()
      }
    })

    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
          setTimeout(()=>{
            if (localStorage.getItem('municipality')) {
              if (localStorage.getItem('municipality') !== '-1') {
                this.municipalitySelect.resetAll()
                this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
              }
              else { 
                this.municipalitySelect.resetAll()
              }
            }else{
              this.municipalitySelect.resetAll()
            }
          }, 1000)
        });
    });
    if (this.type === 'forecast') {
      const fpConfig = await this.sharedService.getForecastConfig().toPromise();
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const startYear = fpConfig.startYear;
      const endYear = currentYear + (currentMonth > (fpConfig.changeMonth - 2) ? 1 : 0);
      this.years = [];
      for (let year = startYear; year <= endYear; year++) {
        this.years.push({ name: year.toString(), id: year.toString() });
      }
    }
    this.yearService.getList("year").subscribe(val => {
      this.years = val.map(elem => ({ name: elem.year, id: elem.year }))
    });
  }
}
