import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { BaseService } from "./base.service";
import { SharedService } from "./sharedService";
import { CompanyDexieService } from "./companyDexie-services/companyDexie.service";
import { CompanyDexie } from "./companyDexie-services/interfaces/companyDexie";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class TaxPayerService extends BaseService {
  sendSMS(data) {
    return this.http
      .post<any>(
        this.sharedService.apiUrl("tax-payers/sendSMS/payment"), data, httpOptions)
      .pipe(
        tap((request: any) => super.log(`added request w/ id=${request}`)),
        catchError(this.handleError<any>("add"))
      );
  }
  constructor(public http: HttpClient, public sharedService: SharedService, public dexieService: CompanyDexieService) {
    super(http, sharedService);
  }
  getTaxPayerByNiu(resourceUrl: string, niu: any, isOnline = true): Observable<any> {
    if (isOnline){
      return this.http
      .get(this.sharedService.apiUrl(resourceUrl + "/niu/" + niu))
      .pipe(
        map((res) => {
          return res;
        })
      );
    }else{
      return of(this.dexieService.getCompanyByTin(niu))
    }
    
  }
  getCurrentCompany(id): Observable<any> {
    return this.http.get(this.sharedService.apiUrl("tax-payers/niu") + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addBalance(wallet): Observable<any> {
    return this.http.post(this.sharedService.apiUrl("payments/addBalance"), wallet, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deletePayment(refnum: any): Observable<any> {
    return this.http.delete<any>(this.sharedService.apiUrl('payments/deleteAvisPayment/' + refnum), {}).pipe(
      tap((resource: any) => this.log(`resource deleted`)),
      catchError(this.handleError<any>('deleted'))
    );
  }

  getCompanyFromAtom(resourceUrl: string, id: any): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl + '/' + id)).pipe(
      map(res => {
        return res;
      })
    );
  }

  fetchCompanies() {
    return this.http.get<CompanyDexie[]>('http://localhost:3000/gov/api/offline/companies').pipe(
      map((res) => {
        return res;
      })
    );
  }

  sendRequest(compRep: any, update: boolean): Observable<any> {
    return this.http
      .post<any>(
        this.sharedService.apiUrl(
          "company-representative/" + (update ? "update" : "add")
        ),
        compRep,
        httpOptions
      )
      .pipe(
        tap((request: any) => super.log(`added request w/ id=${request}`)),
        catchError(this.handleError<any>("add"))
      );
  }
  downloadReport(filterObject: any, headers: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('filter.object', JSON.stringify(filterObject)).set('headers', JSON.stringify(headers)),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('tax-payers/download-report'), httpOptions).pipe(
      map((res: Response) => { return res }), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;
  }
}
