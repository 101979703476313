const AdminMenu =  [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    //["/declarations", 'USER-TOP-MENU.DECLARATIONS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/formcreation_foradmin525", 'USER-TOP-MENU.FORM_CREATOR'],
    ["/user_management", 'ADMIN-TOP-MENU.USER_MANAGEMENT'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/configuration", 'ADMIN-TOP-MENU.CONFIGURATION'],
    ["/admin", 'ADMIN-TOP-MENU.ADMIN']
];

const MayorTreasurerMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];

const CentralReaderMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];

const FinancialManagerAdministratorMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];
const CentralEditorMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];
const SecretaryAssistantMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];

const MenuCategories = new Map<string, string[][]>([
    ["ADMIN", AdminMenu],
    ["MAYOR_TREASURER", MayorTreasurerMenu],
    ["CENTRAL_READER", CentralReaderMenu],
    ["FINANCIAL_MANAGER_ADMINISTRATOR", FinancialManagerAdministratorMenu],
    ["CENTRAL_EDITOR", CentralEditorMenu],
    ["SECRETARY_ASSISTANT", SecretaryAssistantMenu],
 
]);

export { MenuCategories }