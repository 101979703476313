import { Platform } from "@angular/cdk/platform";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { YearDateAdapter } from "src/app/adapters/year-date-adapter";
import { SharedService } from "src/app/services/sharedService";
import { YEAR_FORMATS } from "src/app/util/data-adapters";

@Component({
  selector: "app-yearly-period-filter",
  templateUrl: "./yearly-period-filter.component.html",
  styleUrls: ["./yearly-period-filter.component.css"],
  providers: [
    { provide: DateAdapter, useClass: YearDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ],
})
export class YearlyPeriodFilterComponent implements OnInit {
  @Output() onSelectedRangeChange = new EventEmitter<any>();
  periods = ["CURRENT", "DECADE"];
  per = "DECADE";
  searchDateFrom = new Date();
  searchDateTo = new Date();
  constructor(public sharedService: SharedService) {}
  minDateFrom;
  maxDateFrom = new Date();
  minDateTo;
  maxDateTo = new Date();
  today = new Date();
  numberOfDays = 60 * 300;

  ngOnInit() {
    this.today.setHours(23);
    this.today.setMinutes(59);
    this.today.setSeconds(59);
    this.today.setMilliseconds(999);
    this.searchDateTo.setHours(23, 59, 59, 999);
    this.searchDateFrom.setDate(1);
    this.searchDateFrom.setMonth(0);
    this.searchDateFrom.setFullYear(this.today.getFullYear() - 9);
    this.searchDateFrom.setHours(0, 0, 0, 0);
    this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo, launch: true })
    this.calculateMinMaxDates(null);
    this.sharedService.resetDashboardFilterEmitter.subscribe((elem) => {
      this.per = "DECADE";
      this.setPeriod(this.per);
    });
  }

  chosenYearHandler(normalizedYear: Date, datepicker: any, picker) {
    if (picker==='FROM'){
      this.searchDateFrom = new Date()
      this.searchDateFrom.setFullYear(normalizedYear.getFullYear())
    }
    if (picker==='TO'){
      this.searchDateTo = new Date()
      this.searchDateTo.setFullYear(normalizedYear.getFullYear())
    }
    this.searchDateTo.setMonth(11)
    this.searchDateTo.setDate(31)
    this.searchDateTo.setHours(23, 59, 59, 999);
    this.searchDateFrom.setMonth(0)
    this.searchDateFrom.setDate(1)
    this.searchDateFrom.setHours(0, 0, 0, 0);
    this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo })
    this.per = null
    this.calculateMinMaxDates(picker)
    datepicker.close();
  }

  setPeriod(period) {
    this.searchDateFrom = new Date();
    this.searchDateTo = new Date();
    this.searchDateTo.setHours(23, 59, 59, 999);
    if (period === "DECADE") {
      this.searchDateFrom.setFullYear(this.searchDateFrom.getFullYear() - 9);
    }
    this.searchDateFrom.setMonth(0);
    this.searchDateFrom.setDate(1);

    this.searchDateFrom.setHours(0, 0, 0, 0);
    this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo, launch: true })
  }
  onDateChange(event: MatDatepickerInputEvent<Date>, rangeElement): void {
    this.per = null;
    const newDate = event.value;
    rangeElement === "FROM" && (this.searchDateFrom = newDate);
    rangeElement === "TO" && (this.searchDateTo = newDate);
    this.searchDateTo.setMonth(11)
    this.searchDateTo.setDate(31)
    this.searchDateTo.setHours(23, 59, 59, 999);
    this.searchDateFrom.setMonth(0)
    this.searchDateFrom.setDate(1)
    this.searchDateFrom.setHours(0, 0, 0, 0);
    this.onSelectedRangeChange.emit({
      period: this.per,
      from: this.searchDateFrom,
      to: this.searchDateTo,
    });
    this.calculateMinMaxDates(rangeElement);
  }

  calculateMinMaxDates(rangeElement) {
    if (rangeElement && rangeElement === "FROM") {
      if (
        !this.isLessThanNDays(
          this.searchDateTo,
          this.searchDateFrom,
          this.numberOfDays
        ) ||
        this.searchDateFrom.getTime() > this.searchDateTo.getTime()
      ) {
        this.searchDateTo = new Date(this.searchDateFrom.getTime());
        if (this.moreThanToday(this.searchDateFrom, this.numberOfDays))
          this.searchDateTo.setTime(this.today.getTime());
        else {
          this.searchDateTo.setDate(
            this.searchDateTo.getDate() + this.numberOfDays
          );
        }
      }
      this.maxDateTo = new Date(this.searchDateFrom.getTime());
      if (this.moreThanToday(this.searchDateFrom, this.numberOfDays)) {
        this.maxDateTo.setTime(this.today.getTime());
      } else {
        this.maxDateTo.setDate(this.maxDateTo.getDate() + this.numberOfDays);
      }
      this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo})
    }
  }
  moreThanToday(date, days) {
    let tempDate = new Date(date.getTime());
    tempDate.setDate(this.maxDateTo.getDate() + days);
    return tempDate.getTime() > this.today.getTime();
  }
  isLessThanNDays(
    date1: string | Date,
    date2: string | Date,
    days: number
  ): boolean {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDiff = Math.abs(d2.getTime() - d1.getTime());
    const diffDays = timeDiff / (1000 * 3600 * 24);
    return diffDays < days;
  }
}
