import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CategoryService } from 'src/app/services/category.service';
import { SharedService } from 'src/app/services/sharedService';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { UserService } from 'src/app/services/user.service';
import { YearService } from 'src/app/services/year.service';
import { environment } from "../../../environments/environment";
import { ProcessDataSource } from '../../services/process.datasource';
import { ProcessService } from '../../services/process.service';
import { CashPaymentDialogComponent } from '../dialogs/cash-payment-dialog/cash-payment-dialog.component';
import { FillWalletDialogComponent } from '../dialogs/fill-wallet-dialog/fill-wallet-dialog.component';
import { InformationDialogComponent } from '../dialogs/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { CompanyDexieService } from 'src/app/services/companyDexie-services/companyDexie.service';
import { customAlphabet } from 'nanoid';




@Component({
  selector: 'app-tx-declarations',
  templateUrl: './tx-declarations.component.html',
  styleUrls: ['./tx-declarations.component.css']
})
export class TxDeclarationsComponent implements OnInit, OnDestroy {
  
  displayedColumns: string[] = ['link', 'name', 'status', 'started_on', 'month', 'rec_num'];
  displayedColumnsSmall: string[] = ['link', 'name', 'status'];
  currentYear: any
  currentNIU = '';
  currentYearInt: any
  displayedYears = []
  dataSource: ProcessDataSource;
  company: any;
  isLoading = false;
  user: any;
  category: any;
  subscriptionDeleteDeclaration
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  isOnline: boolean = navigator.onLine;
  name: string;
  constructor(private processService: ProcessService, private yearService: YearService,
    private sharedService: SharedService, public userService: UserService,
    private taxPayerService: TaxPayerService, private companyDexieService: CompanyDexieService,
    private categoryService: CategoryService,
    private route: ActivatedRoute, public dialog: MatDialog, public spinner: NgxSpinnerService) {

  }
  cellClicked(element) {
  }
  addTaxPayerEmmitterSubscription: any
  addCashPaymentEmitterSubscription: any
  fillWalletEmitterSubscription: any
  initialLoad = true
  async ngOnInit() {
    this.sharedService.onlineStatus$.subscribe(status => {
      this.isOnline = status;
      if (!this.initialLoad){
        this.dataSource = new ProcessDataSource(this.processService)
        this.getTaxpayer()
      }
      this.initialLoad = false
    });
    this.user = await this.userService.getCurrentUser(this.isOnline).toPromise()
    this.route.queryParams.subscribe(params => {
      this.spinner.show()
      this.isLoading = true
      if (params['tin']) {
        this.currentNIU = params['tin']
      }
      if (params['tkn']) {
        this.currentNIU = params['tkn']
      }
      this.getTaxpayer()
    });

    this.dataSource = new ProcessDataSource(this.processService)
    this.addTaxPayerEmmitterSubscription = this.sharedService.addTaxPayerEmmitter.subscribe(val => {
      this.taxPayerService.getTaxPayerByNiu('tax-payers', this.currentNIU).subscribe(taxPayer => {
        this.spinner.hide()
        this.isLoading = false
        if (!taxPayer) {
          return
        }
        this.company = taxPayer;
      })
    })
    this.addCashPaymentEmitterSubscription = this.sharedService.updateCashPaymentEmmitter.subscribe(async val => {
      if (val) {
        let payment = {
          "paymentSystem": {
            "key": "cash",
            "name": "Espèces"
          },
          "currency": "XOF",
          "amount": val.taxName.amount,
          "paymentDate": val.date,
          "tin": this.company.tin,
          "companyName": this.company.name,
          "taxName": val.taxName.name,
          "taxNameId": val.taxName.id,
          "revenueCodeId": val.taxName.revenueCode && val.taxName.revenueCode.id,
          "revenueCode": val.taxName.revenueCode && val.taxName.revenueCode.code,
          "avis": val.taxName.avis,
          "reference_number": val.taxName.reference_number,
          "process_name": val.taxName.origName,
          "month": val.taxName.month,
          "year": val.taxName.year,
          "description": val.description
        }
        if (!this.isOnline){
          this.companyDexieService.setPayment(payment)
          payment['user'] = await this.companyDexieService.getCurrentUser()
          const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789', 12);
          const uniqueId = nanoid();
          payment['paymentReference'] = uniqueId.toUpperCase();
          const dialogRefConf = this.dialog.open(InformationDialogComponent, {
            width: '500px',
            disableClose: true,
            data: { text: "DIALOG.PAYMENT_SUCCESS" }
          });
          dialogRefConf.afterClosed().subscribe(result => {
            if (result) {
              val.DIALOG.close();
              this.sharedService.cashPaymentPrint(payment)
            }
          });
        }
        else{
          let res = await this.userService.updateResource('payments', payment).toPromise()
          if(val.taxName.avis)
          this.companyDexieService.deleteAvis(this.company.tin, val.taxName.reference_number)
          if (res) {
            if (res.error) {
              this.dialog.open(InformationDialogComponent, {
                width: '500px',
                disableClose: true,
                data: { text: "DIALOG." + res.error, error: true }
              });
            }
            else {
              if (val.sendSMS) {
                let resp = await this.taxPayerService.sendSMS({ reference_number: val.taxName.reference_number, telephone: val.telephone }).toPromise()
                this.sharedService.smsSend(resp)
              }
              else {
                const dialogRefConf = this.dialog.open(InformationDialogComponent, {
                  width: '500px',
                  disableClose: true,
                  data: { text: "DIALOG.PAYMENT_SUCCESS" }
                });
                dialogRefConf.afterClosed().subscribe(result => {
                  if (result) {
                    val.DIALOG.close();
                    this.sharedService.cashPaymentPrint(res)
                  }
                });
              }
            }
          }
        }
        
      }
    })
    this.fillWalletEmitterSubscription = this.sharedService.updateFillWalletEmmitter.subscribe(val => {
      if (val) {
        let wallet = {
          "paymentSystem": {
            "key": "cash",
            "name": "Espèces"
          },
          "currency": "XOF",
          "amount": val.amount,
          "paymentDate": val.date,
          "tin": this.company.tin,
          "companyName": this.company.name,
        }
        this.taxPayerService.addBalance(wallet).subscribe(res => {
          if (res) {
            if (res.error) {
              this.dialog.open(InformationDialogComponent, {
                width: '500px',
                disableClose: true,
                data: { text: "DIALOG." + res.error, error: true, data: res.data }
              });
            }
            else {
              const dialogRefConf = this.dialog.open(InformationDialogComponent, {
                width: '500px',
                disableClose: true,
                data: { text: "DIALOG.PAYMENT_SUCCESS" }
              });
              dialogRefConf.afterClosed().subscribe(result => {
                if (result) {
                  val.DIALOG.close();
                  wallet['user'] = res.user
                  wallet['paymentReference'] = res.paymentReference
                  this.sharedService.cashPaymentPrint(wallet)
                }
              });
            }
          }
        })
      }
    })
  }

  canDeleteDeclaration() {
    if (this.user)
      return ['ADMIN'].includes(this.user.role);
    return false;
  }

  deleteDeclaration(d) {
    if (d.rec_status === 'ONGOING' && d.last_rec) {
      const dialogRef = this.dialog.open(InformationDialogComponent, {
        width: '500px',
        disableClose: true,
        data: { text: "DIALOG.ONGOING_LAST_REC_DELETE_ERROR", error: true }
      })
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: { text: "DIALOG.DELETE_DECLARATION", emitter: null, obj: { id: d._id }, action: "CONFIRM" }
      });
      this.subscriptionDeleteDeclaration = this.sharedService.confirmDialogEmitter.subscribe(async val => {
        if (val) {
          try {
            await this.processService.deleteDeclaration(val.id).toPromise()
            this.loadProcessPage()
          } catch (error) {
            console.log('error')
          }
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        this.subscriptionDeleteDeclaration.unsubscribe()
      });
    }
  }

  ngOnDestroy(): void {
    if (this.addTaxPayerEmmitterSubscription) {
      this.addTaxPayerEmmitterSubscription.unsubscribe()
    }
    if (this.addCashPaymentEmitterSubscription) {
      this.addCashPaymentEmitterSubscription.unsubscribe()
    }
    if (this.fillWalletEmitterSubscription) {
      this.fillWalletEmitterSubscription.unsubscribe()
    }
  }

  async getTaxpayer() {
    this.company = await this.taxPayerService.getTaxPayerByNiu('tax-payers', this.currentNIU, this.isOnline).toPromise()
    this.spinner.hide()
    this.isLoading = false
    if (!this.company) {
      return
    }
    if (this.isOnline){
      this.category = await this.categoryService.getCategory('category', this.company.type.id).toPromise()
      const years = await this.yearService.getList("year").toPromise()
      years.forEach(element => {
        this.displayedYears.push(element.year)
      });
      if (years && years.length > 0) {
        this.currentYear = years[0].year
        this.currentYearInt = years[0].year * 1
      }
      setTimeout(() => {
        this.dataSource.loadProcesses(this.currentYear, '{"tin":"' + this.currentNIU + '","internal":"all"}', 'desc', 'rec_num', 0, this.paginator.pageSize);
        this.setupEventHandlers();
      }, 1000)
      if (this.route.snapshot.paramMap.get('year')) {
        this.currentYear = this.route.snapshot.paramMap.get('year')
      }
    }
  }
  getGovinLink() {
    return environment.govinLink + '/?token_from_tas=' + localStorage.getItem(environment.accessToken) + '&username=' + this.company.tin
  }

  cashPaymentDialog(): void {
    const dialogRef = this.dialog.open(CashPaymentDialogComponent, {
      width: '500px', panelClass: 'custom-dialog-container',
      data: { tin: this.company.tin }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  fillInWalletDialog(): void {
    const dialogRef = this.dialog.open(FillWalletDialogComponent, {
      width: '500px', panelClass: 'custom-dialog-container',
      data: { tin: this.company.tin }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  canGoToGovin() {
    if (this.user) {
      return !['MAYOR_TREASURER', 'CENTRAL_READER'].includes(this.user.role)
    }
    return false
  }

  // canSeeLedger() {
  //   if (this.user) {
  //     // return !['SECRETARY_ASSISTANT'].includes(this.user.role)
  //     return true
  //   }
  //   return false
  // }

  canUpdate() {
    if (this.user) {
      return !['MAYOR_TREASURER', 'CENTRAL_READER', 'SECRETARY_ASSISTANT'].includes(this.user.role)
    }
    return false
  }

  getBackgroundColor(number: number): string {
    if (!number) return "#ffffff";
    const color = this.numberToColorHsl(number);
    return color;
  }

  numberToColorHsl(number: number): string {
    const hash = this.hashNumber(number);
    let hue = (hash * 137) % 360;
    if (hue >= 270 && hue <= 330) {
      hue = (hue + 100) % 360;
    }
    const saturation = 65 + (hash % 20);
    const lightness = 75 + (hash % 10);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  hashNumber(number: number): number {
    let hash = 0;
    const str = number.toString();
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  handleSelection(event) {
    this.currentYear = event.value;
    this.dataSource.loadProcesses(this.currentYear, '{"tin":"' + this.currentNIU + '","internal":"all"}', 'desc', 'rec_num', 0, this.paginator.pageSize);
  }
  setupEventHandlers() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadProcessPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProcessPage())
      )
      .subscribe();
  }

  loadProcessPage() {
    this.dataSource.loadProcesses(
      this.currentYear,
      '{"query":"' + this.input.nativeElement.value + '" , "tin":"' + this.currentNIU + '","internal":"all"}',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }
}
