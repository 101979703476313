import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from "@angular/router";
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ProcessTypeService } from 'src/app/services/proces-type.service';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { YearService } from 'src/app/services/year.service';
import { ProcessService } from '../../services/process.service';

@Component({
  selector: 'app-tx-declarations',
  templateUrl: './declarations.component.html',
  styleUrls: ['./declarations.component.css']
})
export class DeclarationsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'key', 'year'];
  filterObject: {};
  currentYear = new Date().getFullYear().toString()
  currentNIU='';
  currentYearInt = new Date().getFullYear()
  displayedYears = []
  dataSourceProcessType: TasDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  animal: string;
  name: string;
  constructor(private processService: ProcessService, private router: Router, 
    private route: ActivatedRoute, public dialog: MatDialog, private processTypeService: ProcessTypeService, public yearService: YearService) {

  }
 
  cellClicked(element) {
  }

  ngOnInit() {

    this.yearService.getList("year").subscribe(val=>{
      val.forEach(element => {
        this.displayedYears.push(element.year)
      });
      if (val.length>0){
        this.currentYear = val[0].year
        this.dataSourceProcessType.loadData('process-types', this.currentYear, 'asc', 'name', 0, this.paginator.pageSize, this.filterObject);
      }
      
    });
    this.dataSourceProcessType = new TasDataSource(this.processTypeService)
    if (this.route.snapshot.paramMap.get('year')) {
      this.currentYear = this.route.snapshot.paramMap.get('year')
    }

    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      if (params['niu']){
        this.currentNIU = params['niu']
      }
      
    });
    
 
  }


  handleSelection(event) {
    this.currentYear = event.value;
    this.dataSourceProcessType.loadData('process-types', this.currentYear, 'asc', 'name', 0, this.paginator.pageSize, this.filterObject);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSourceProcessType.loadData('process-types', this.currentYear, 'asc', 'name', 0, this.paginator.pageSize,this.filterObject);
      
    }, 0)
 
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadProcessPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProcessPage())
      )
      .subscribe();

  }

  loadProcessPage() {
    this.dataSourceProcessType.loadData(
      'process-types',
      '{"query":"'+ this.input.nativeElement.value+'", "year:'+this.currentYear+'"}' ,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  openProcessTypeDialog(processType): void {
    if (processType)
    this.router.navigate(['declaration_reporting'], { queryParams: { id: processType._id }});
    else{
     this.router.navigate(['declaration_reporting']);
    }
  }
}




