import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SharedService } from './sharedService';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TaxNameService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }
 
  public getTaxNamesForTaxpayer(resourceUrl:string, filterObject): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl), {
      params: new HttpParams()
        .set('filter.object', JSON.stringify(filterObject))
    }).pipe(
      map(res => {
        return res['records'];
      })
    );
  }
}
