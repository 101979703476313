
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith, toArray } from 'rxjs/operators';
import { ItemData } from '../../entities/multi-select-item-data';

@Component({
  selector: 'multiselect-autocomplete',
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrls: ['./multiselect-autocomplete.component.css']
})
export class MultiselectAutocompleteComponent implements OnInit {

  @Output() result = new EventEmitter<{ key: string, data: Array<string> }>();
  @Input() multiselect = true;
  @Input() placeholder: string = 'Select Data';
  @Input() data: Array<any> = [];
  @Input() selectedData: Array<any> = [];
  @Input() key: string = '';
  @Input() showAll: boolean = false;
  @Input() disabled: boolean = false;
  allChecked = false
  selectControl = new FormControl({ disabled: false });
  singleSelected = false

  rawData: Array<ItemData> = [];
  selectData: Array<ItemData> = [];

  filteredData: Observable<Array<ItemData>>;
  filterString: string = '';

  constructor() {
    this.filteredData = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map(value => typeof value === 'string' ? value : this.filterString),
      map(filter => this.filter(filter))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && changes.disabled.previousValue !== undefined) {
      changes.disabled.previousValue ? this.selectControl.enable() : this.selectControl.disable()
    }
  }


  ngOnInit(): void {
    this.data.forEach((item: any) => {
      this.rawData.push({ item, selected: false });
    });

    if (this.selectedData){
      if (this.data.length === this.selectedData.length)
      this.allChecked = true
      this.selectedData.forEach(sd => {
        this.rawData.forEach(rd => {
          if (sd !== null && rd.item[this.key] === sd[this.key]) {
            rd.selected = true
            this.selectData.push(rd);
          }
        })
      })
    }
     

  }

  filter = (filter: string): Array<ItemData> => {
    this.filterString = filter;
    if (filter.length > 0) {
      return this.rawData.filter(option => {
        return option.item[this.key].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.rawData.slice();
    }
  };

  displayFn = (): string => '';

  optionClicked = (event: Event, data: ItemData): void => {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection = (data: ItemData): void => {
    this.filterString = ''
    data.selected = !data.selected;
    if (!this.multiselect) {
      this.selectData = []
      this.singleSelected = true;
      if (data.selected === true) {
        this.selectData.push(data);
      }

    }
    else {
      if (data.selected === true) {
        this.selectData.push(data);
      } else {
        const i = this.selectData.findIndex(value => value.item.id === data.item.id);
        this.allChecked = false
        if (i != -1)
          this.selectData.splice(i, 1);
      }
    }
    this.selectControl.setValue(this.selectData);
    this.emitAdjustedData();
  };

  emitAdjustedData = (): void => {
    const results: Array<string> = []
    this.selectData.forEach((data: ItemData) => {
      results.push(data.item);
    });
    if (this.data.length === this.selectData.length)
    this.allChecked = true
    this.result.emit({ key: this.key, data: results });
  };

  showAuto() {
    this.singleSelected = false;
  }

  setAll(checked) {
    this.resetAll()
    if (checked) {
      this.rawData.forEach(rd => {
        rd.selected = true;
        this.selectData.push(rd);
      });
    }
    this.emitAdjustedData();
  }

  removeChip = (data: ItemData): void => {
    if (!this.disabled)
      this.toggleSelection(data);
  };
  public resetAll() {
    this.selectData = [];
    this.selectedData = [];
    this.rawData = []
    this.data.forEach((item: any) => {
      this.rawData.push({ item, selected: false });
    });

    this.selectControl.setValue('')
  }

}
