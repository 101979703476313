import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { ReportService } from "src/app/services/report.service";
import { SharedService } from "src/app/services/sharedService";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { CompanyDexieService } from "src/app/services/companyDexie-services/companyDexie.service";

@Component({
  selector: "app-taxpayers-search",
  templateUrl: "./search-taxpayers.component.html",
  styleUrls: ["./search-taxpayers.component.css"],
})
export class SearchTaxpayersComponent implements OnInit, OnDestroy {
  selectedMun = []
  @ViewChild('categorySelect', { static: false }) categorySelect: MultiselectAutocompleteComponent;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('revenueCodeSelect', { static: false }) revenueCodeSelect: MultiselectAutocompleteComponent;
  filterData = {
    category: null,
    municipality: null,
    recurring: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields: any;
  municipalityChangeSubscription: any
  queryFilter = {};
  filterFields = ["category", "municipality"];
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService, public sharedService: SharedService,
    public reportService: ReportService, public companyDexieService: CompanyDexieService
  ) {
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
            if (field === 'municipality' && localStorage.getItem('municipality'))
              this.selectedMun = this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality'))
          }
        });
    });
    reportService.getReportsList("?active=true&recurring=true").subscribe(rep => {
      if (rep && rep.reports) {
        this.filterData.recurring = rep.reports.filter(elem => elem.revenueCode !== null)
          .map(elem => ({ id: elem.revenueCode[0].code, name: elem.revenueCode[0].code }))
          .filter(elem => elem.id !== undefined);
        this.filterData.recurring.sort((a, b) => a.name.localeCompare(b.name));
        this.filterData.recurring = [...this.filterData.recurring.reduce((map, obj) => {
          map.set(obj.id, obj);
          return map;
        }, new Map()).values()];
      }
    })
  }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length === 0) {
      delete this.queryFilter[field]
    }
  };
  resetFilter() {
    try {
      this.queryFilter = {}
      this.categorySelect.resetAll()
      this.municipalitySelect.resetAll()
      this.revenueCodeSelect.resetAll()
      this.onReset.emit()
    } catch (error) {

    }


  }
  advancedSearch() {
    let selData = []
    selData = this.municipalitySelect.selectData.filter(el => el.selected).map(el => el.item.id)
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.queryFilter['municipality']
      } else {
        if (selData.length > 0)
          this.queryFilter['municipality'] = selData
        else
          this.queryFilter['municipality'] = [localStorage.getItem('municipality')]
      }
    } else {
      if (this.municipalitySelect.selectData.length === 0)
        delete this.queryFilter['municipality']
    }
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }

  ngOnInit() {
    this.sharedService.onlineStatus$.subscribe(async status => {
      this.resetFilter()
      this.filterData['category'] = null
      this.filterData['municipality'] = null
      if (!status) {
        this.filterData['category'] = await this.companyDexieService.getUniqueTaxpayerTypes()
        this.filterData['municipality'] = await this.companyDexieService.getUniqueMunicipalities()
      } else {
        this.filterFields.forEach((field) => {
          this.categoryService
            .getList(field)
            .subscribe((result) => {
              if (result) {
                this.filterData[field] = result.map((fld) => ({
                  name: fld.name,
                  id: fld.id,
                }));
                if (field === 'municipality' && localStorage.getItem('municipality'))
                  this.selectedMun = this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality'))
              }
            });
        });
      }
    });
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        this.selectedMun = null;
        if (localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          this.selectedMun = this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality'))
          this.municipalitySelect.setSelectedData(this.selectedMun)
        }
        else {
          this.municipalitySelect.resetAll()
        }
      } else {
        this.municipalitySelect.resetAll()
      }
    })
  }
}
