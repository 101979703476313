import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogCommunicator } from '../dialog-communicator';
import { SharedService } from 'src/app/services/sharedService';
import { DropdownConfigurationService } from 'src/app/services/dropdownConfiguration.service';
import { CategoryService } from 'src/app/services/category.service';
import { DropdownConfiguration } from 'src/app/entities/dropdownConfiguration';

@Component({
  selector: 'app-dropdown-configuration-dialog',
  templateUrl: './dropdown-configuration-dialog.component.html',
  styleUrls: ["./dropdown-configuration-dialog.component.css"],

})
export class DropdownConfigurationDialogComponent
  extends DialogCommunicator
  implements OnInit {
  configurationDropdown = {}
  selectedDropdown
  selectedMun
  filterFields = ["municipality"];
  sItemName
  sItemKey
  sItemValue
  error
  editingField = false;
  sortDir = 0;
  filterData = {
    municipality: null,
  };
  constructor(
    public dialogRef: MatDialogRef<DropdownConfigurationDialogComponent>,
    public sharedService: SharedService,
    public dropdownConfigurationService: DropdownConfigurationService,
    public confirmationDialog: MatDialog,
    public categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) public data: { id: string, year: any, searchedMunicipality: any, selectItem: any }
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  generateNewKey(selDrop) {
    const maxNumber = selDrop.reduce((max, item) => {
      const number = parseInt(item.key.split('_')[1], 10);
      return number > max ? number : max;
    }, 0);
    return `key_${maxNumber + 1}`;
  }
  addItem() {
    this.error = null

    for (let elem in this.selectedDropdown) {
      let element = this.selectedDropdown[elem]
      if (element.find(elem => elem.key == this.sItemKey)) {
        this.error = 'DUPLICATE_DROPDOWN_CONFIGURATION_KEY'
        return
      }

      element.push({
        name: this.sItemName, key: this.generateNewKey(element), value: this.sItemValue
      })
    }

    this.resetFields();
  }
  isValidDropdown() {
    return this.configurationDropdown['name'] || this.data.selectItem
  }
  sort(direction) {
    this.sortDir = direction
    for (let elem in this.selectedDropdown) {
      this.selectedDropdown[elem].sort((a, b) => {
        if (this.sortDir === 1) {
          return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
        }
        if (this.sortDir === -1) {
          return (b.name > a.name) ? 1 : ((a.name > b.name) ? -1 : 0)
        }
      }
      )
    }

  }
  removeField(key) {
    let index = this.selectedDropdown.indexOf(this.selectedDropdown.find(elem => elem.key == key))
    this.selectedDropdown.splice(index, 1)
    this.resetFields()
  }

  updateField(reset = true) {
    let elem = this.selectedDropdown[this.data.selectItem.municipalityId].find(elem => elem.key === this.sItemKey)
    elem.value = this.sItemValue
    elem.name = this.sItemName
    reset && this.resetFields()
  }
  selectChange = (event: any) => {
    this.selectedDropdown = {};
    if (event.data && event.data.length > 0) {
      let yearDropdown = this.configurationDropdown['dropdowns'].find(elem => elem.year === this.data['year']);
      event.data.forEach(element => {
        if (yearDropdown) {
          let selectedMunicipality = yearDropdown.municipalities.find(elem => elem.id === element.id);
          if (selectedMunicipality) {
            this.selectedDropdown[element.id] = selectedMunicipality.selectItems;
          } else {
            const newMunicipality = {
              id: element.id,
              name: element.name,
              selectItems: []
            };
            yearDropdown.municipalities.push(newMunicipality);
            this.selectedDropdown[element.id] = newMunicipality.selectItems;
          }
        } else {

          const newYearDropdown = {
            year: this.data['year'],
            municipalities: [
              {
                id: element.id,
                name: element.name,
                selectItems: this.selectedDropdown[element.id]
              }
            ]
          };

          this.configurationDropdown['dropdowns'].push(newYearDropdown);
          this.selectedDropdown[element.id] = newYearDropdown.municipalities[0].selectItems;
        }
      });

    } else {
      console.warn('No municipality selected.');
    }

  };
  editOption(item) {
    this.editingField = true;
    this.sItemName = item.name;
    this.sItemValue = item.value;
  }

  private resetFields() {
    this.editingField = false;
    this.sItemName = null;
    this.sItemKey = null;
    this.sItemValue = null;
  }
  isValidSelectItem() {
    return this.sItemName
  }
  async ngOnInit() {
    this.configurationDropdown = new DropdownConfiguration()
    this.configurationDropdown["class"] = "DROPDOWN-CONFIGURATION";
    this.setEntity(this.configurationDropdown);
    if (this.data && this.data.id) {
      try {
        let val = await this.dropdownConfigurationService.getResource('dropdown-configuration', this.data.id).toPromise()
        this.configurationDropdown = val;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (this.data.id)
      this.filterFields.forEach((field) => {
        this.categoryService
          .getList(field)
          .subscribe((result) => {
            if (result) {
              this.filterData[field] = result.map((fld) => ({
                name: fld.name,
                id: fld.id,
              }));
              if (field === 'municipality') {
                if (this.data.searchedMunicipality && this.data.searchedMunicipality[0] || localStorage.getItem('municipality')) {
                  this.selectedMun = this.filterData['municipality'].filter(elem => elem.id === ((this.data.searchedMunicipality && this.data.searchedMunicipality[0]) || localStorage.getItem('municipality')))
                  this.selectedMun && this.selectChange({ data: [{ id: this.selectedMun[0].id, name: this.selectedMun[0].name }] })
                }
              }
            }
          });
      });
    if (this.data.selectItem) {
      let eventData = {
        key: 'name', data: [{ name: this.data.selectItem.municipality, id: this.data.selectItem.municipalityId }]
      }
      this.selectChange(eventData)
      this.sItemName = this.data.selectItem.name
      this.sItemKey = this.data.selectItem.key
      this.sItemValue = this.data.selectItem.value

    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  async onSave() {
    if (this.data.selectItem) {
      this.updateField(false)
    }
    // else if (this.data.id) {
    //   //this.addItem()

    // }
    if (this.selectedDropdown)
      this.sort(1)

    if (this.configurationDropdown['dropdowns'].length === 0 && !this.data.id) {
      this.configurationDropdown['dropdowns'].push({ year: this.data.year, municipalities: [] })
    }
    let res = await this.dropdownConfigurationService.updateResource('dropdown-configuration', this.configurationDropdown).toPromise()
    this.dialogRef.close(res);
  }
}
