import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { PaymentReportService } from "src/app/services/paymentReport.service";
import { ReportService } from "src/app/services/report.service";
import { RevenueCodeService } from "src/app/services/revenueCode.service";
import { SharedService } from "src/app/services/sharedService";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";

@Component({
  selector: "app-dashboard-search",
  templateUrl: "./search-dashboard.component.html",
  styleUrls: ["./search-dashboard.component.css"],
})
export class SearchDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('revenueCodeSelect', { static: false }) revenueCodeSelect: MultiselectAutocompleteComponent;
  filterData = {
    municipality: null,
    revenueCode: null,
    users: null
  };
  municipalityChangeSubscription: any
  @Output() onFilter = new EventEmitter<any>();
  @Output() onToggle = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields: any;
  queryFilter = {};
  filterFields = ["municipality"];
  periodFilterType = 'DAILY'
  revenueDashboardTypes = ['DAILY', 'MONTHLY', 'YEARLY']
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public sharedService: SharedService,
    public reportService: ReportService, public revenueCodeService: RevenueCodeService, public paymentReportService: PaymentReportService
  ) {
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));

          }
        });
    });
    revenueCodeService.getList('revenue-code').subscribe(revCodes => {
      this.filterData['revenueCode'] = revCodes.records || revCodes
      this.filterData.revenueCode.forEach(element => {
        if (element.revenueCode && element.revenueCode.length > 0) {
          element.name = `${element.revenueCode[0].code} ${element.revenueCode[0].name}`
          element.id = element.revenueCode[0].code
        }
        else {
          element.name = `${element.code} ${element.name}`
          element.id = element.code
        }
      });
      this.filterData.revenueCode = this.filterData.revenueCode.filter(e => e.code)
      this.filterData.revenueCode.sort((a, b) => Number(a.code) - Number(b.code));
    })
    this.paymentReportService.getPaymentUsers().subscribe(val => {
      this.filterData['users'] = val.map(user => ({ id: user.username.trim(), name: (user.surname ? user.surname + ' ' : '') + user.name }))
        .sort((a, b) => a.name.localeCompare(b.name));
    })
  }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length === 0) {
      delete this.queryFilter[field]
    }
  };
  setRange(range, type) {
    this.queryFilter['type'] = type
    this.queryFilter['period'] = range.period
    this.queryFilter['from'] = range.from
    this.queryFilter['to'] = range.to
    if (range['launch']) {
      setTimeout(() => { 
        if (localStorage.getItem('municipality') && localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
        } else {
          this.municipalitySelect.resetAll()
          delete this.queryFilter['municipality']
        }
      }, 1000)
    }
  }
  resetFilter() {
    this.queryFilter = {}
    this.municipalitySelect.resetAll()
    this.revenueCodeSelect.resetAll()
    this.onReset.emit()
    this.sharedService.resetDashboardFilter()

  }
  advancedSearch() {
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }

  ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality') && localStorage.getItem('municipality') !== '-1') {
        this.municipalitySelect.resetAll()
        this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
      }
      else {
        this.municipalitySelect.resetAll()
        delete this.queryFilter['municipality']
      }

      this.onFilter.emit(this.queryFilter)
    })
  }
}