import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy, Input } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { TaxPayerStatusName } from 'src/app/entities/util';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxPayerDialogComponent } from '../dialogs/tax-payer-dialog/tax-payer-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'tas-tax-payer',
  templateUrl: './tax-payers.component.html',
  styleUrls: ['./tax-payers.component.css']
})
export class TaxPayersComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dec_key;
  @Input() dec_year;
  @Input() status;

  subscriptionAddTaxpayer: any;
  subscriptionUpdateUser: any;
  subscriptionDeactivateUser: any;
  subscriptionActivateUser: any;

  displayedColumns: string[] = ['tin', 'name', 'balance', 'extraBalance','finalBalance'];
  dataSource: TasDataSource;
  statusNames = TaxPayerStatusName
  filterObject: {};
  user: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  taxPayersEndpoint = 'tax-payers';
  constructor(private taxPayerService: TaxPayerService, public spinner:NgxSpinnerService, public dialog: MatDialog, 
    public sharedService: SharedService, public router: Router,
    public userService: UserService, public translate: TranslateService,
    public route: ActivatedRoute) {

  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.taxPayerService)
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      
      this.subscriptionAddTaxpayer = this.sharedService.addTaxPayerEmmitter.subscribe(val => {
        this.loadTaxPayerData();
      })
      this.subscriptionDeactivateUser = this.sharedService.deactivateUserEmmitter.subscribe(val => {
        if (val) {
          this.taxPayerService.deactivateResource(this.taxPayersEndpoint, val).subscribe(res => {
            this.loadTaxPayerData();
          })
        }
      })

      this.subscriptionActivateUser = this.sharedService.activateUserEmmitter.subscribe(val => {
        if (val) {
          this.taxPayerService.updateResource('tax-payers', val).subscribe(res => {
            this.loadTaxPayerData();
          })
        }
      })
    })
  }

  canAddTaxpayer() {
    if (this.user) {
      return !['MAYOR_TREASURER', 'CENTRAL_READER','SECRETARY_ASSISTANT'].includes(this.user.role)
    }

    return false
  }

  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };

  filter: any;
  ngAfterViewInit() {

    setTimeout(() => {
      if (this.dec_key && this.dec_year && this.status) {
        this.filterObject['key'] = this.dec_key
        this.filterObject['year'] = this.dec_year
        this.filterObject['status'] = this.status
      }
    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
 

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTaxPayerData())
      )
      .subscribe();

  }

  loadTaxPayerData() {
    this.spinner.show()
    this.dataSource.loadData('tax-payers',
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject, this.spinner);
  }

  openTaxPayerDialog(taxPayer): void {
    const dialogRef = this.dialog.open(TaxPayerDialogComponent, {
      width: '800px', panelClass: 'custom-dialog-container',
      data: taxPayer
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  goToTaxPayer(row) {
    this.router.navigate(['tx_declarations'], { queryParams: { tin: row.tin ? row.tin : row.username } });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateUser) this.subscriptionUpdateUser.unsubscribe();
    if (this.subscriptionDeactivateUser) this.subscriptionDeactivateUser.unsubscribe();
    if (this.subscriptionActivateUser) this.subscriptionActivateUser.unsubscribe();
  }

  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  objectFilter = {};
  advancedSearch(filter) {
    if (!this.filterObject){
      this.filterObject = {}
    }
    let advF = [
      'balanceType',
      'municipality',
      'name',
      'recurring',
      'tin',
      'type'
    ];
    advF.forEach((elem) => {
      if (!filter[elem] || filter[elem].length===0) delete this.filterObject[elem];
    });
    this.filterObject = Object.assign({}, this.filterObject, filter);
    this.spinner.show();
    this.dataSource.loadData(
      "tax-payers",
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject,
      this.spinner
    );
  }
  reset() {
    this.filterObject = {};
  }
  downloadReport() {
    let headers = [
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_NIU"), key: 'tin' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_NAME"), key: 'name' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_BALANCE"), key: 'balance' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_EXTRA_BALANCE"), key: 'extraBalance' }
    ]
    this.spinner.show();
    this.taxPayerService.downloadReport(this.filterObject, headers).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }
}

