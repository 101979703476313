import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from 'src/app/entities/dialogData';
import { ForecastService } from 'src/app/services/forecast.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-submit-dialog',
  templateUrl: './submit-dialog.component.html',
  styleUrls: ['./submit-dialog.component.css']
})
export class SubmitDialogComponent implements OnInit {
  @ViewChild("fileUploadOne", {static: true}) selectedFile: any;
  public uploaderOne: FileUploader;
  fileNameOne: string;
  allowedMimeType = [".pdf", ".doc"];
  text: string;
  emitter: EventEmitter;
  obj: any;
  action: any;
  processes = []
  forecastState
  constructor(
    public dialogRef: MatDialogRef<SubmitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService, private forecastService: ForecastService, private spinner: NgxSpinnerService) {
    this.text = data['text'];
    this.obj = data['obj']
    this.action = data['action']

  }
  maxLines = 3;

  enforceMaxLines(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    const lines = textarea.value.split('\n');

    if (lines.length > this.maxLines) {
      textarea.value = lines.slice(0, this.maxLines).join('\n');
    }
  }
  async ngOnInit() {
    if (this.obj.type==='forecast')
      this.forecastState = await this.forecastService.getForecastState(this.obj.year, this.obj.municipality).toPromise()
    else
      this.forecastState = await this.forecastService.getForecastPerformanceState(this.obj.year, this.obj.month, this.obj.municipality).toPromise()
    if (!this.forecastState) this.forecastState = {}
    else{
      if (this.forecastState.filename && this.forecastState.attachment){
        this.fileNameOne = this.forecastState.filename
      }
    }

    this.uploaderOne = new FileUploader({
      url: this.sharedService.apiUrl('upload/'+this.obj.type), authToken: "Bearer " + localStorage.getItem('tas_gov_access_token'),
      allowedFileType: ["pdf", "doc"],
      maxFileSize: 50 * 1024 * 1024,
      itemAlias: this.obj.type
    });
    this.uploaderOne.onAfterAddingFile = (fileItem) => {
      this.selectedFile.nativeElement.value = "";
      if (this.uploaderOne.queue.length > 1) {
        this.uploaderOne.removeFromQueue(this.uploaderOne.queue[0]);
      }
      this.fileNameOne = (fileItem.file.name);
    }
    this.uploaderOne.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.uploaderOne.clearQueue();
      this.spinner.hide()
      if(response){
        let resp = JSON.parse(response)
        let dialogDoc = {comment:this.forecastState.comment, year:this.obj.year, attachment:resp.key, filename:this.fileNameOne}
        if (this.obj.type === 'performance') dialogDoc['month'] = this.obj.month
        this.sharedService.confirmDialogAction(dialogDoc)
        this.dialogRef.close(true);
        this.fileNameOne = null
      }
    };
  }
  removeFileUpload(){
    this.uploaderOne.clearQueue()
    this.fileNameOne = undefined
  }
  getFilename(){
    let len = this.fileNameOne.length
      return this.fileNameOne.length<45?this.fileNameOne:this.fileNameOne.substring(0,45)+'...'+this.fileNameOne.substring(len-3, len)
  }
  onCancelClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick(): void {
    this.spinner.show()
    if (this.fileNameOne && this.uploaderOne.queue.length>0){
        this.uploaderOne.uploadAll()
    }else{
      this.spinner.hide()
      let dialogDoc = {comment:this.forecastState.comment, year:this.obj.year}
      if (this.fileNameOne) {
        dialogDoc['attachment'] = this.forecastState.attachment
        dialogDoc['filename'] = this.forecastState.filename
      }
      if (this.obj.type === 'performance') dialogDoc['month'] = this.obj.month
      this.sharedService.confirmDialogAction(dialogDoc)
      this.dialogRef.close(true);
    }
  }

}