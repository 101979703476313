import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-daily-period-filter',
  templateUrl: './daily-period-filter.component.html',
  styleUrls: ['./daily-period-filter.component.css']
})
export class DailyPeriodFilterComponent implements OnInit {
  @Output() onSelectedRangeChange = new EventEmitter<any>()
  periods = ['CURRENT','WEEK', 'MONTH']
  per = 'WEEK'
  @Input() periodTab = true
  @Input() searchDateFrom
  searchDateTo = new Date()
  constructor(public sharedService: SharedService) { }
  minDateFrom
  maxDateFrom = new Date()
  minDateTo
  maxDateTo = new Date()
  today = new Date()
  numberOfDays = 59
  
  ngOnInit() {
    this.today.setHours(23)
    this.today.setMinutes(59)
    this.today.setSeconds(59)
    this.today.setMilliseconds(999)
    this.searchDateTo.setHours(23, 59, 59, 999)
    if (!this.searchDateFrom){
      this.searchDateFrom = new Date()
      this.searchDateFrom.setDate(this.searchDateTo.getDate() - 6)
    }
    this.searchDateFrom.setHours(0, 0, 0, 0)
    this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo, launch: true })
    this.calculateMinMaxDates(null)
    this.sharedService.resetDashboardFilterEmitter.subscribe(elem => { 
      this.per = 'CURRENT'
      this.setPeriod(this.per) 
    })
  }

  setPeriod(period) {
    this.searchDateFrom = new Date()
    this.searchDateTo = new Date()
    this.searchDateTo.setHours(23, 59, 59, 999)
    if (period !== 'CURRENT'){
      this.searchDateFrom.setDate(this.searchDateTo.getDate() - (period === 'WEEK' ? 6 : 29))
    }
    this.searchDateFrom.setHours(0, 0, 0, 0)
    this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo, launch: true })
  }
  onDateChange(event: MatDatepickerInputEvent<Date>, rangeElement): void {
    this.per = null
    const newDate = event.value;
    (rangeElement === 'FROM') && (this.searchDateFrom = newDate);
    (rangeElement === 'TO') && (this.searchDateTo = newDate);
    this.searchDateTo.setHours(23, 59, 59, 999)
    this.searchDateFrom.setHours(0, 0, 0, 0)
    this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo })
    this.calculateMinMaxDates(rangeElement)
  }

  calculateMinMaxDates(rangeElement) {
    if (rangeElement && rangeElement === 'FROM') {
      if (!this.isLessThanNDays(this.searchDateTo, this.searchDateFrom, this.numberOfDays) || this.searchDateFrom.getTime() > this.searchDateTo.getTime()) {
        this.searchDateTo = new Date(this.searchDateFrom.getTime());
        if (this.moreThanToday(this.searchDateFrom, this.numberOfDays))
          this.searchDateTo.setTime(this.today.getTime())
        else {
          this.searchDateTo.setDate(this.searchDateTo.getDate() + this.numberOfDays)
        }
      }
      this.maxDateTo = new Date(this.searchDateFrom.getTime());
      if (this.moreThanToday(this.searchDateFrom, this.numberOfDays)){
        this.maxDateTo.setTime(this.today.getTime())
      }
      else {
        this.maxDateTo.setDate(this.maxDateTo.getDate() + this.numberOfDays)
      }
      this.onSelectedRangeChange.emit({ period:this.per, from: this.searchDateFrom, to: this.searchDateTo })
    }
  }
  moreThanToday(date, days) {
    let tempDate = new Date(date.getTime());
    tempDate.setDate(this.maxDateTo.getDate() + days)
    return (tempDate.getTime() > this.today.getTime())
  }
  isLessThanNDays(date1: string | Date, date2: string | Date, days: number): boolean {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDiff = Math.abs(d2.getTime() - d1.getTime());
    const diffDays = timeDiff / (1000 * 3600 * 24);
    return diffDays < days;
  }

}
