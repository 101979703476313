import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
})
export class ActionButtonsComponent implements OnInit {
  @Input() stage;
  formCreation = true;
  constructor(public _location: Location) { }

  ngOnInit() {
  }
  showCleanForm() {}
  fileChange(ev) {}
  saveInProcess() {}
  downloadForm() {}
  downloadAPI() {}
  downloadPDF() {}
}
