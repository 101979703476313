import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from './sharedService';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: 'root'
})
export class ForecastService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }

  getDashboardData(endpoint: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('dashboard/'+endpoint)).pipe(
      map(res => {
        return res;
      })
    );
  }
  getForecastState(year: string, municipality: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('forecast/forecast-state/'+year+'/'+municipality)).pipe(
      map(res => {
        return res;
      })
    );
  }

  getForecastPerformanceState(year: string, month: string, municipality: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('forecast/forecast-state/'+year+'/'+month+'/'+municipality)).pipe(
      map(res => {
        return res;
      })
    );
  }

  
  submitForecast(forecast): Observable<any> {
    return this.http.post(this.sharedService.apiUrl("forecast/submit"), forecast, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }
  approveForecast(forecast): Observable<any> {
    return this.http.post(this.sharedService.apiUrl("forecast/approve"), forecast, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }
  reopenForecast(forecast): Observable<any> {
    return this.http.post(this.sharedService.apiUrl("forecast/reopen"), forecast, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }

  downloadForecast(filterObject: any, headers: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('forecast/download-forecast'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;
  }
  downloadForecastState(filterObject: any, headers: any, texts: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)).set('texts', JSON.stringify(texts)),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('forecast/download-forecast-state'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;
  }
  downloadPerformance(filterObject: any, headers: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('forecast/download-performance'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;
  }
  downloadForecastPDF(filterObject: any, headers: any): Observable<Blob> {

    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('forecast/download-forecast-pdf'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download pdf'))
    )
    return process$;

  }

  downloadPerformancePDF(filterObject: any, headers: any): Observable<Blob> {

    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('forecast/download-performance-pdf'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download pdf'))
    )
    return process$;

  }
}



