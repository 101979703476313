import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { ProfileChart } from '../ProfileChart';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent extends ProfileChart implements OnInit {
  @Input() title;
  @Input() footer;
  @Input() description;
  @Input() data;
  @Input() index;
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    fill: false
  };
  constructor(public taxpayerService: TaxPayerService) {
    super(taxpayerService);
    this.barChartType = 'bar';
  }

  ngOnInit() {
    
    this.barChartData = [{ data: [] }];
   // this.dashboardConfigs['chart'] = 'bar';
    super.loadData(this.data);
  }

}
