import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentReportService } from 'src/app/services/paymentReport.service';
import { SharedService } from 'src/app/services/sharedService';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-comparison-graph',
  templateUrl: './comparison-graph.component.html',
  styleUrls: ['./comparison-graph.component.css']
})
export class ComparisonGraphComponent implements OnInit, OnDestroy {
  subscriptionUpdateRevenueCode: any;
  barChartLabels: Label[]
  barChartData: ChartDataSets[]
  filterObject: {};
  user: any
  currentFilter = {}
  customTextsOne=[]
  customTextsTwo=[]
  customLabels = []
  searchInitialized = false;
  dOne
  dTwo
  error
  toolTip =  {
    callbacks: {
      label: (tooltipItem, data) => {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        let value = dataset.data[tooltipItem.index];
        if ([1,3].includes(tooltipItem.datasetIndex)){
          const amountDataset = data.datasets[tooltipItem.datasetIndex===1?0:2];
          let amountValue = amountDataset.data[tooltipItem.index];
          value = Number(value) + Number(amountValue)
        }
     
        return `${dataset.label}: ${value.toLocaleString('fr-FR')}`;
      }
    }
  }
  singleMunicipality
  municipalityChangeSubscription
  subscriptionLanguage
  constructor(public dialog: MatDialog, public sharedService: SharedService,
     private userService: UserService, public paymentService: PaymentReportService,
     private spinner: NgxSpinnerService, private translate: TranslateService
  ) {
  }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
    if (this.subscriptionLanguage) this.subscriptionLanguage.unsubscribe()
  }
  ngOnInit() {
    this.subscriptionLanguage = this.sharedService.getLan$().subscribe(lan => {
      setTimeout(async ()=>{
        await this.populateChart()
      },0)
    })
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') == "-1") {
          delete this.currentFilter['municipality']
        } else {
          this.currentFilter['municipality'] = [localStorage.getItem('municipality')]
        }
        this.setupBarchartDataAndLabels()
      } else {
        this.barChartLabels = null
        this.barChartData = null
        delete this.currentFilter['municipality']
      }
     
    })
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
      if (this.user) {
        if (this.user.role !== 'ADMIN' && this.user.municipalities && this.user.municipalities.length === 1) {
          this.currentFilter = {}
          this.currentFilter['municipality'] = [this.user.municipalities[0].id]
          this.singleMunicipality = this.user.municipalities[0].name
          this.setupBarchartDataAndLabels()
        }
      }
    })
  }


  getTotalRevenueAmount(elem){
    let amount = elem.amount || 0
    let totalRevenueAmount = elem.totalRevenueAmount || 0
    return totalRevenueAmount - amount >=0? totalRevenueAmount - amount:0
  }



  async setupBarchartDataAndLabels(){
    this.searchInitialized = true
    const monthTranslations = await this.translate.get('MONTHS_FR').toPromise();
    this.customLabels = []
    this.customLabels.push(monthTranslations[(this.currentFilter['month_one']+1<10?'0':'') + (this.currentFilter['month_one']+1) ] + ' ' + this.currentFilter['year_one'])
    this.customLabels.push(monthTranslations[(this.currentFilter['month_two']+1<10?'0':'') + (this.currentFilter['month_two']+1) ] + ' ' + this.currentFilter['year_two'])
    if (!this.currentFilter['municipality'] && !localStorage.getItem('municipality'))
    {
      this.barChartData = null
      this.barChartLabels = null
      return
    }
 
    if (!this.currentFilter['municipality'])
    this.currentFilter['municipality'] = [localStorage.getItem('municipality')]
    await this.fetchDataWithAttempt();
 
    
  }
  async fetchDataWithAttempt(){
    let attempt = 0;
    while(attempt < 2){
      try {
        attempt++;
        const res = await this.paymentService.getPaymentsByRevenueAlternative(this.stringifyWithUTC(this.currentFilter)).toPromise()
        if (res.error) 
          continue; 
        this.barChartData = res.barChartData
        this.barChartLabels = res.barChartLabels
        res.dataOne.sort((a, b) => {
          if (a.code.toLowerCase() < b.code.toLowerCase()) {
            return -1;
          }
          if (a.code.toLowerCase() > b.code.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        res.dataTwo.sort((a, b) => {
          if (a.code.toLowerCase() < b.code.toLowerCase()) {
            return -1;
          }
          if (a.code.toLowerCase() > b.code.toLowerCase()) {
            return 1;
          }
          return 0;
        });
  
       this.dOne =  res.dataOne.filter(elem => elem && (elem.amount>0 || elem.totalRevenueAmount>0))
      this.dTwo =  res.dataTwo.filter(elem => elem && (elem.amount>0 || elem.totalRevenueAmount>0))
        this.dOne.forEach(elem => {
          let m = this.dTwo.find(el => el.code === elem.code)
          if (!m){
            this.dTwo.push({
              _id:elem._id,
              name:elem.name,
              code:elem.code,
              amount:0,
              totalRevenueAmount:0
            })
          }
        })
        this.dTwo.forEach(elem => {
          let m = this.dOne.find(el => el.code === elem.code)
          if (!m){
            this.dOne.push({
              _id:elem._id,
              name:elem.name,
              code:elem.code,
              amount:0,
              totalRevenueAmount:0
            })
          }
        })
        this.dOne.sort((a, b) => {
          if (a.code.toLowerCase() < b.code.toLowerCase()) {
            return -1;
          }
          if (a.code.toLowerCase() > b.code.toLowerCase()) {
            return 1;
          }
          return 0;
        });
  
        this.dTwo.sort((a, b) => {
          if (a.code.toLowerCase() < b.code.toLowerCase()) {
            return -1;
          }
          if (a.code.toLowerCase() > b.code.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        await this.populateChart();
          break;
      } catch (error) {
        if(error.name !== 'TimeoutError' || attempt >= 2){
          console.error('Fetch Failed:', error);
          this.barChartData = null;
          this.barChartLabels = null;
          this.error = "LOADING"
          this.searchInitialized = false;
          break;
        }
      }
    }
  }

  private async populateChart() {
    const dashboardTranslations = await this.translate.get('DASHBOARD').toPromise();
    this.barChartLabels = this.dOne.map(elem => elem.code);
    this.barChartData = null
    this.barChartData = [
      {
        label: dashboardTranslations['GRAPH_COMPARISON_LABEL_AMOUNT'] + ' ' + (this.currentFilter['month_one'] + 1 < 10 ? '0' : '') + (this.currentFilter['month_one'] + 1) + '/' + this.currentFilter['year_one'],
        data: this.dOne.map(elem => elem.amount || 0),
        backgroundColor: '#009f60',
        stack: 'group1'
      },
      {
        label: dashboardTranslations['GRAPH_COMPARISON_LABEL_TOTAL_REVENUE_AMOUNT'] + ' ' + (this.currentFilter['month_one'] + 1 < 10 ? '0' : '') + (this.currentFilter['month_one'] + 1) + '/' + this.currentFilter['year_one'],
        data: this.dOne.map(elem => this.getTotalRevenueAmount(elem)),
        backgroundColor: '#5db5f1',
        stack: 'group1'
      },
      {
        label: dashboardTranslations['GRAPH_COMPARISON_LABEL_AMOUNT'] + ' ' + (this.currentFilter['month_two'] + 1 < 10 ? '0' : '') + (this.currentFilter['month_two'] + 1) + '/' + this.currentFilter['year_two'],
        data: this.dTwo.map(elem => elem.amount || 0),
        backgroundColor: '#009f60',
        stack: 'group2'
      },
      {
        label: dashboardTranslations['GRAPH_COMPARISON_LABEL_TOTAL_REVENUE_AMOUNT'] + ' ' + (this.currentFilter['month_two'] + 1 < 10 ? '0' : '') + (this.currentFilter['month_two'] + 1) + '/' + this.currentFilter['year_two'],
        data: this.dTwo.map(elem => this.getTotalRevenueAmount(elem)),
        backgroundColor: '#5db5f1',
        stack: 'group2'
      },
    ];
  }

  populateData(){}
  
  createPattern() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 20;
    canvas.height = 20;
    ctx.strokeStyle = 'rgba(66, 165, 245, 0.3)';
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(0, 20);
    ctx.lineTo(20, 0);
    ctx.stroke();
    ctx.moveTo(-10, 20);
    ctx.lineTo(10, 0);
    ctx.stroke();
    ctx.moveTo(10, 20);
    ctx.lineTo(30, 0);
    ctx.stroke();
    return canvas;
  }
  externalFunction() {
    this.setupBarchartDataAndLabels()
  }
  ngAfterViewInit() {
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.currentFilter['municipality']
      }
    } else {
      delete this.currentFilter['municipality']
    }

  }

  advancedSearch(val) {
    this.barChartData = null
    this.barChartLabels = null
    this.error = null;
    this.currentFilter = val
    if (!this.currentFilter['municipality'] && !localStorage.getItem('municipality'))
      delete this.currentFilter['municipality'] 
    else {
      this.currentFilter['municipality'] = this.currentFilter['municipality'] || [localStorage.getItem('municipality')]
      this.setupBarchartDataAndLabels()
    }
  }


  stringifyWithUTC(obj) {
    return JSON.stringify(obj, (key, value) => {
      if (obj && obj[key] instanceof Date) {
        const utcDateString = new Date(obj[key].getTime() - (obj[key].getTimezoneOffset() * 60000)).toISOString();
        return utcDateString
      }
      return value;
    });
  }

}
