import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TaxPayerStatusName } from 'src/app/entities/util';
import { ProcessSummaryDataSource } from 'src/app/services/process-summary.datasource';
import { ProcessService } from 'src/app/services/process.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-stat-dec-companies',
  templateUrl: './stat-dec-companies.component.html',
  styleUrls: ['./stat-dec-companies.component.css']
})
export class StatDecCompaniesComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dec_key;
  @Input() dec_year;
  @Input() status;

  subscriptionUpdateUser: any;
  subscriptionDeactivateUser: any;
  subscriptionActivateUser: any;

  displayedColumns: string[] = ['tin', 'companyName'];
  dataSourceSummary: ProcessSummaryDataSource;
  statusNames = TaxPayerStatusName
  filterObject = {};
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private processService: ProcessService, public dialog: MatDialog, public sharedService: SharedService, public router: Router) {
  }

  ngOnInit() {
    this.dataSourceSummary = new ProcessSummaryDataSource(this.processService)
    
  }

  ngAfterViewInit() {

    setTimeout(() => {
      
      
      if (this.dec_key){
        this.filterObject['key'] = this.dec_key
        this.filterObject['year'] = this.dec_year
        this.filterObject['status'] = this.status
      }
      else{
        this.filterObject['year'] = this.dec_year
        this.filterObject['status'] = this.status
      }

      this.dataSourceSummary.loadFilteredTaxPayersBySummary('', '', 'asc', 'niu', 0, this.paginator.pageSize, this.filterObject);
    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadTaxPayerData();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTaxPayerData())
      )
      .subscribe();

  }

  loadTaxPayerData() {
    this.dataSourceSummary.loadFilteredTaxPayersBySummary('',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  goToTaxPayer(row){
    this.router.navigate(['tx_declarations'], { queryParams: { niu: row.username?row.username:row.niu }});
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateUser) this.subscriptionUpdateUser.unsubscribe();
    if (this.subscriptionDeactivateUser) this.subscriptionDeactivateUser.unsubscribe();
    if (this.subscriptionActivateUser) this.subscriptionActivateUser.unsubscribe();
  }

}

