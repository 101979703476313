import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class EntityService extends BaseService{
  downloadExcel(query): Observable<Blob> {
    var qu = JSON.parse(query)
    let params = new HttpParams();

    Object.keys(qu).forEach(key => {
      if (isArray(qu[key])) {
        params = params.set(key, JSON.stringify(qu[key]));
      } else {
        params = params.set(key, qu[key]);
      }
    });
     const httpOptionsExcel = {
       headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
       responseType: 'blob' as 'json',
       params
     };
     let applications$ = this.http.get<any>(this.sharedService.apiUrl('taxpayer/excel'), httpOptionsExcel).pipe(
       map((res: Response) => res), catchError(this.sharedService.handleError<any>('download excel'))
     )
     return applications$;
 
   }

   advancedSearch(resourceUrl: string, filter: any): Observable<any> {
      return this.http.post<any>(this.sharedService.apiUrl(resourceUrl), filter, this.httpOptions).pipe(
        tap((resource: any) => this.log(`added ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('add'))
      );
  }
 
}

 
