export class ProcessType {
    public id: number;
    public name: string;
    public key: string;
    public fee: number;
    public version: string;
    public active: Boolean;  
    public external: Boolean;
    public multi: Boolean;
    public year: string;
    public stages: any[];
    public municipality: any[];
    public category: any[];
    public revenueCode: any;
  
  }  