import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PerformanceTableComponent } from './performance-table/performance-table.component';
  @Component({
    selector: "app-performance",
    templateUrl: "./performance.component.html",
    styleUrls: ["./performance.component.css"],
  })
  export class PerformanceComponent {
    performanceTableComponent = PerformanceTableComponent;
    data
    constructor(private activatedRoute: ActivatedRoute) {
    }
    async ngOnInit() {
      this.activatedRoute.queryParams.subscribe(params => {
        this.data = { currentYear: params['year'], month: params['month'], municipality: params['municipality'] }
      });
    }
 
}