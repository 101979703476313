
import { AuthService } from "../services/auth.service";


import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { SharedService } from '../services/sharedService';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public jwtHelper: JwtHelperService, public authService: AuthService, private sharedService:SharedService, private router: Router) { }
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    inflightAuthRequest = null;
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
     

        return next.handle(this.addAuthenticationToken(req, localStorage.getItem(this.sharedService.getAccessToken()))).pipe(catchError((error, caught) => {
            // if (error.status === 404) {
            //     this.router.navigateByUrl('/error/404', {replaceUrl: true});
            //     return throwError(error);
            // }
            if (error.status === 401) {
                return throwError(error);
            }
            if (error.status !== 401) {
                return throwError(error);
            }
            if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // – which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject.pipe(
                    filter(result => result !== null),
                    take(1),
                    switchMap(() => next.handle(this.addAuthenticationToken(req, null)))
                )
            }
            // else {
            //     this.refreshTokenInProgress = true;
            //     // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            //     this.refreshTokenSubject.next(null);
            //     // Call auth.refreshAccessToken(this is an Observable that will be returned)
            //     return this.authService
            //         .requestTokenObs(localStorage.getItem(this.sharedService.getAccessToken())).pipe(
            //             switchMap((token: any) => {
            //                 //When the call to refreshToken completes we reset the refreshTokenInProgress to false
            //                 // for the next time the token needs to be refreshed
            //                 this.refreshTokenInProgress = false;
            //                 this.refreshTokenSubject.next(token.usrToken);
            //                 return next.handle(this.addAuthenticationToken(req, token.usrToken));
            //             }),
            //             catchError((err: any) => {
            //                 this.refreshTokenInProgress = false;
            //                 this.authService.logout();
            //                 return Observable.throw(error);
            //             })
            //         )
            // }
        }) as any);

    }
    addAuthenticationToken(request, token) {
        if (token !== null) {
            localStorage.setItem(this.sharedService.getAccessToken(), token)
        }
        // Get access token from Local Storage
        const accessToken = token !== null ? token : localStorage.getItem(this.sharedService.getAccessToken());
        // If access token is null this means that user is not logged in
        // And we return the original request
        
        if (!accessToken) {
            return request;
        }
        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + (token !== null ? token : localStorage.getItem(this.sharedService.getAccessToken()))
            }
        });
    }

}




