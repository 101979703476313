import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EventEmitter } from "events";
import { DialogData } from "src/app/entities/dialogData";
import { RevenueCode } from "src/app/entities/revenueCode";
import { CategoryService } from "src/app/services/category.service";
import { DropdownService } from "src/app/services/dropdown.service";
import { RevenueCodeService } from "src/app/services/revenueCode.service";
import { SharedService } from "src/app/services/sharedService";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { DialogCommunicator } from "../dialog-communicator";

@Component({
  selector: "gov-revenue-code-dialog",
  templateUrl: "./revenue-code-dialog.component.html",
  styleUrls: ["./revenue-code-dialog.component.css"],
})
export class RevenueCodeDialogComponent
  extends DialogCommunicator
  implements OnInit
{
  text: string;
  emitter: EventEmitter;
  revenueCode = {};
  @ViewChild("revenueCodeSelect", { static: false })
  revenueCodeSelect: MultiselectAutocompleteComponent;
  selectedRevenue = [];
  filterData = {
    revenueCode: null,
  };
  constructor(
    public dialogRef: MatDialogRef<RevenueCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    public categoryService: CategoryService,
    public revenueCodeService: RevenueCodeService,
    public confirmationDialog: MatDialog,
    public dropdownService: DropdownService
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  ngOnInit() {
    this.categoryService.getList("revenue-code", !this.data ? null : this.data["id"] || this.data["_id"]).subscribe((result) => {
      if (result) {
        this.revenueCode = new RevenueCode();
        if (this.data) { 
          this.revenueCode = this.data;
        }  
        this.revenueCode["class"] = "REVENUE-CODE";
        this.setEntity(this.revenueCode);
        this.filterData["revenueCode"] = result.map((fld) => ({ name: fld.name, id: fld.id, code: fld.code }))
        if (this.revenueCode['parent'] && !this.isEmpty(this.revenueCode['parent'])) this.filterData["revenueCode"].push(this.revenueCode['parent'])
        this.filterData["revenueCode"].sort((a, b) => Number(a.code)-Number(b.code));

      }
    });
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  selectChange = (event: any) => {
    this.revenueCode["parent"] = event.data[0];
  };
}
