import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { RevenueCodeService } from 'src/app/services/revenueCode.service';
import { SharedService } from 'src/app/services/sharedService';
import { UserService } from 'src/app/services/user.service';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CategoryService } from 'src/app/services/category.service';
@Component({
  selector: 'app-revenue-code-table',
  templateUrl: './revenue-code-table.component.html',
  styleUrls: ['./revenue-code-table.component.css']
})
export class RevenueCodeTableComponent implements OnInit, OnDestroy {
  @Input() currentYear
  subscriptionUpdateRevenueCode: any;
  filterObject = {};
  user: any
  displayedColumns: string[] = ['code', 'name', 'forecast', 'amount', "totalRevenueAmount", "percentageOfPerformance"];
  dataSource: TasDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  currentFilter = {}
  singleMunicipality
  municipalityChangeSubscription
  loading = { inProgress: false, downloadInProgress: false, error: false }
  constructor(private revenueCodeService: RevenueCodeService, public dialog: MatDialog, public sharedService: SharedService,
    private userService: UserService, private cdr: ChangeDetectorRef, private translate: TranslateService,
    private dashboardService: DashboardService, private categoryService: CategoryService
  ) {
  }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') == "-1") {
          delete this.currentFilter['municipality']
        } else {
          this.currentFilter['municipality'] = [localStorage.getItem('municipality')]
        }
      } else {
        delete this.currentFilter['municipality']
      }
      this.loadRevenueCodeTable()
    })
    this.dataSource = new TasDataSource(this.revenueCodeService)
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
      if (this.user) {
        if (this.user.role !== 'ADMIN' && this.user.municipalities && this.user.municipalities.length === 1) {
          let fromDate = new Date()
          fromDate.setDate(1)
          fromDate.setMonth(0)
          this.currentFilter = {
            from: fromDate, to: new Date(), municipality: [this.user.municipalities[0].id], year: this.currentYear + ''
          }
          this.singleMunicipality = this.user.municipalities[0].name
          this.loadRevenueCodeTable()
        }
      }
    })

  }

  public roundValue(val: number): number {
    return Math.round(val);
  }
  externalFunction() {
    this.loadRevenueCodeTable();
  }
  ngAfterViewInit() {
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.currentFilter['municipality']
      } else {
        this.currentFilter['municipality'] = [localStorage.getItem('municipality')]
        this.loadRevenueCodeTable()
        this.cdr.detectChanges();
      }
    } else {
      delete this.currentFilter['municipality']
    }

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadRevenueCodeTable())
      )
      .subscribe();
  }

  advancedSearch(val) {
    this.currentFilter = val
    if (!this.currentFilter['municipality'] && localStorage.getItem('municipality')) {
      this.currentFilter['municipality'] = [localStorage.getItem('municipality')]
    }
    this.loadRevenueCodeTable()
  }

  loadRevenueCodeTable() {
    this.dataSource.emptyData()
    this.currentFilter['from'] = new Date(this.currentYear * 1, 0, 1)
    this.currentFilter['to'] = new Date(this.currentYear * 1, 11, 31)
    this.currentFilter['from'].setHours(0, 0, 0, 0)
    this.currentFilter['to'].setHours(23, 59, 59, 999)
    this.currentFilter['year'] = this.currentYear + ''
    if (!this.currentFilter['municipality']) {
      return;
    }
    this.loading.inProgress = true
    this.dataSource.loadData('payments/payments-by-revenue',
      this.stringifyWithUTC(this.currentFilter),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.currentFilter, this.loading);
  }
  stringifyWithUTC(obj) {
    return JSON.stringify(obj, (key, value) => {
      if (obj && obj[key] instanceof Date) {
        const utcDateString = new Date(obj[key].getTime() - (obj[key].getTimezoneOffset() * 60000)).toISOString();
        return utcDateString
      }
      return value;
    });
  }
  async downloadRevExcel() {
    let mun = await this.categoryService.getCategory('municipality', this.currentFilter['municipality']).toPromise()
    let headers = [
      { header: this.translate.instant("DASHBOARD.TABLE_COLUMN_CODE"), key: 'code' },
      { header: this.translate.instant("DASHBOARD.TABLE_COLUMN_NAME"), key: 'name' },
      { header: this.translate.instant("DASHBOARD.TABLE_COLUMN_FORECAST"), key: 'forecast' },
      { header: this.translate.instant("DASHBOARD.TABLE_COLUMN_AMOUNT"), key: 'amount' },
      { header: this.translate.instant("DASHBOARD.TABLE_COLUMN_TOTAL_REVENUE_AMOUNT"), key: 'totalRevenueAmount' },
      { header: this.translate.instant("DASHBOARD.TABLE_COLUMN_PERF_PREC_FORECAST"), key: 'percentageOfPerformance' },
      { name: this.translate.instant("DASHBOARD.MUNICIPALITY"), key: mun && mun.name || '' }
    ]
    this.loading.downloadInProgress = true
    this.dashboardService.downloadRevenueCodeExcel(this.currentFilter, headers).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }
  async downloadRevPdf() {
    this.loading.downloadInProgress = true
    let mun = await this.categoryService.getCategory('municipality', this.currentFilter['municipality']).toPromise()
    let headers = {
      title: this.translate.instant("DASHBOARD.PDF_TITLE"),
      col1: this.translate.instant("DASHBOARD.TABLE_COLUMN_CODE"),
      col2: this.translate.instant("DASHBOARD.TABLE_COLUMN_NAME"),
      col3: this.translate.instant("DASHBOARD.TABLE_COLUMN_FORECAST"),
      col4: this.translate.instant("DASHBOARD.TABLE_COLUMN_AMOUNT"),
      col5: this.translate.instant("DASHBOARD.TABLE_COLUMN_TOTAL_REVENUE_AMOUNT"),
      col6: this.translate.instant("DASHBOARD.TABLE_COLUMN_PERF_PREC_FORECAST"),
      year: this.currentFilter["year"],
      municipality: mun && mun.name || ''
    }

    this.dashboardService.downloadRevenueCodePDF(this.currentFilter, headers).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }
}
