import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AdminService } from "src/app/services/admin.service";
import { AvisService } from "src/app/services/avis.service";
import { FinancesDataSource } from "src/app/services/finances.datasource";
import { SharedService } from "src/app/services/sharedService";
import { TaxPayerService } from "src/app/services/taxPayer.service";
import { UserService } from "src/app/services/user.service";
import { YearService } from "src/app/services/year.service";
import { SendSmsDialogComponent } from "../dialogs/send-sms-dialog/send-sms-dialog.component";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-tax-ledger",
  templateUrl: "./tax-ledger.component.html",
  styleUrls: ["./tax-ledger.component.css"],
})
export class TaxLedgerComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  currentYear: any;
  @Input()
  category: any;
  @Input()
  currentNIU: any;
  currentYearInt: any;
  displayedYears = [];
  dataSource: FinancesDataSource;
  chartDataSubscription: any
  refreshDataSubscription: any
  displayedColumns: string[] = ['date', 'paymentSystem', 'paymentReference', 'rec_num', 'debit', 'credit', 'print'];
  @Input() company: any;
  avis;
  paymentDetails = false
  otpPayment = 0;
  avisMapping = {};
  finances = [];
  barChartLabels: Label[];
  barChartData: ChartDataSets[];
  filterObject: any;
  user: any;
  loading = { inProgress: true }
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  canSendSms = false
  subscriptionDeletePayment: any
  constructor(
    private yearService: YearService,
    private avisService: AvisService,
    public translate: TranslateService,
    public taxPayerService: TaxPayerService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    public userService: UserService,
    private adminService: AdminService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.category && changes.category.currentValue.avis) {
      this.displayedColumns = ['date', 'paymentSystem', 'paymentReference', 'rec_num', 'debit', 'credit', 'balance', 'print'];
    }
  }

  async ngOnInit() {
    let admin = await this.adminService.getAdmin().toPromise()
    if (admin && admin.smsConfig && admin.smsConfig.payment && admin.smsConfig.payment.enabled) {
      this.canSendSms = true
    }
    this.yearService.getList("year").subscribe((val) => {
      val.forEach((element) => {
        this.displayedYears.push(element.year);
      });
      if (val && val.length > 0) {
        this.currentYear = val[0].year;
        this.currentYearInt = val[0].year * 1;
      }

    });

    this.user = await this.userService.getCurrentUser().toPromise()

    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
    this.chartDataSubscription = this.avisService.chartDataEmitter.subscribe(val => {
      this.barChartData = val.data
      this.barChartLabels = val.labels
    })
    this.refreshDataSubscription = this.sharedService.cashPaymentPrintEmitter.subscribe(async val => {
      await this.refreshData()
    })
  }

  async refreshData(){
    this.company = await this.taxPayerService.getTaxPayerByNiu('tax-payers', this.currentNIU).toPromise()
    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
    this.loadFinancesTable()
  }

  handleSelection(event) {
    this.loading.inProgress = true
    this.currentYear = event.value;
    this.dataSource.loadFinances(this.currentYear, this.currentNIU, '', 'asc', 'date', 0, this.paginator.pageSize, this.pType, this.filterObject, this.company.extraBalance, this.loading);
  }

  canShowReprintAndSMS() {
    if (this.user) {
      return !['SECRETARY_ASSISTANT'].includes(this.user.role);
    }
    return false;
  }
  
  canDeletePayment(p) {
    if (p.avis && this.user) {
      return ['ADMIN'].includes(this.user.role);
    }
    return false;
  }

  print(p) {
    this.sharedService.cashPaymentPrint(p)
  }


  saveAndSendSMS(p): void {
    let cashPayment = { reference_number: p.reference_number, onlySms: true, telephone: this.company.telephone || this.company.additionalFields.telephone }
    const dialogRef = this.dialog.open(SendSmsDialogComponent, {
      width: "500px",
      panelClass: "custom-dialog-container",
      data: { cashPayment: cashPayment, paymentSaved: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.sent) {
        this.loadFinancesTable()
      }
    });
  }

  deletePayment(p) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.DELETE_AVIS_PAYMENT", emitter: null, obj: p, action: "CONFIRM" }
    });

    this.subscriptionDeletePayment = this.sharedService.confirmDialogEmitter.subscribe(async val => {
      if (val) {
        try {
          await this.taxPayerService.deletePayment(val.reference_number).toPromise()
          await this.refreshData()
        } catch (error) {
          console.log('error')
        }
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionDeletePayment.unsubscribe()
    });
  }
  selectedPayment
  showPayment(p){
    this.paymentDetails = !this.paymentDetails
    if (p){
      this.selectedPayment = p
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loading.inProgress = true
      this.dataSource.loadFinances(this.currentYear, this.currentNIU, '', 'asc', 'date', 0, this.paginator.pageSize, this.pType, this.filterObject, this.company.extraBalance, this.loading);
    }, 1000)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadFinancesTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadFinancesTable())
      )
      .subscribe();

  }

  ngOnDestroy(): void {
    this.chartDataSubscription && this.chartDataSubscription.unsubscribe()
    this.refreshDataSubscription && this.refreshDataSubscription.unsubscribe()
  }
  loadFinancesTable() {
    this.loading.inProgress = true
    this.dataSource.loadFinances(this.currentYear, this.currentNIU,
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.pType, this.filterObject, this.company.extraBalance, this.loading);
  }

  pType = "ALL"
  getFinances(val, type) {
    if (this.loading) delete this.loading['error']
    this.dataSource.emptyData()
    if (val.checked) {
      this.pType = this.pType === 'ALL' ? type : "ALL"
    } else {
      this.pType = this.pType !== 'ALL' ? 'ALL' : (type === 'FEES' ? "PAYMENTS" : "FEES")
    }
    this.loadFinancesTable()
  }
  getBackgroundClass(p: any): string {
    if (!p.paymentSystem || (p.paymentSystem && p.paymentSystem.name === 'Avis')) {
      return p.paid ? 'green-background' : 'red-background';
    }
    return '';
  }
  getRowClass(row: any): string {
    if (row.type === 'WALLET') {
      return 'light-green-background';
    }
    return '';
  }
}
