import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css']
})
export class ErrorpageComponent implements OnInit {

  constructor(public jwtHelper: JwtHelperService, public authService: AuthService) { }

  ngOnInit() {
    const token = this.jwtHelper.tokenGetter()
    if (token && token !== 'undefined') {
      const decodedToken = this.jwtHelper.decodeToken(token)
      if (!this.jwtHelper.isTokenExpired()) {
        this.authService.setLoginStatus(true)
      }
    }
  }

}
