import { Component, Input, OnInit } from '@angular/core';
import { ROW_TYPES } from '../row-types';

@Component({
  selector: 'rdf-row-controls',
  templateUrl: './row-controls.component.html',
  styleUrls: ['./row-controls.component.css']
})
export class RowControlsComponent implements OnInit {
  rowTypes = ROW_TYPES
  showInputs = false;
  @Input() row
  originalRow: any
  constructor() { }

  ngOnInit() {
    this.originalRow = Object.assign({}, this.row)
  }

  editingRow() {
    this.showInputs = !this.showInputs
  }
  deleteRow() {

  }
  cancelEditing() {
    for (let k in this.originalRow){
      this.row[k] = this.originalRow[k]
    }
    this.showInputs = false
  }

}
