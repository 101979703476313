import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-rows',
  templateUrl: './rows.component.html',
  styleUrls: ['./rows.component.css']
})
export class RowsComponent implements OnInit {
  @Input() block
  constructor() { }

  ngOnInit() {
  }

  moveRow(val, rowKey){
    let rows = this.block.rows
    const currentIndex = rows.findIndex((row) => row.key === rowKey);
    const [objToMove] = rows.splice(currentIndex, 1);
    rows.splice(val, 0, objToMove);
  }
}
