import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rdf-element-positioner',
  templateUrl: './element-positioner.component.html',
  styleUrls: ['./element-positioner.component.css']
})
export class ElementPositionerComponent implements OnInit {
  @Input() position
  @Input() elements
  @Output() onPositionChanged = new EventEmitter<any>()
  constructor() { 

  }

  ngOnInit() {
  }

  moveElement(){
    this.onPositionChanged.emit(this.position)
  }

}
