import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
  @Input() field
  constructor() { }

  ngOnInit() {
  }

}
