import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SharedService } from './sharedService';


@Injectable({
  providedIn: 'root'
})
export class AvisService extends BaseService {

  constructor(public http: HttpClient, public sharedService: SharedService) {
    super(http, sharedService);
  }


  getAvisList(year) {
    let avis$ = this.http.get<any>(this.sharedService.apiUrl('avis/' + year))
    return avis$;
  }

  getUnpaidAvises(tin) {
    let avis$ = this.http.get<any>(this.sharedService.apiUrl('avis/unpaid/' + tin))
    return avis$;
  }

  getAvisYears() {
    let years$ = this.http.get<any>(this.sharedService.apiUrl('avis/years'))
    return years$;
  }

  getAvisDsfs() {
    let years$ = this.http.get<any>(this.sharedService.apiUrl('avis/dsfs'))
    return years$;
  }

  getCompanyAvis(year, niu, sortDirection, sortColumn, pageIndex, pageSize, pgOrig, filter, filterObject) {
    const httpOptionsExcel = {
      headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
      params: new HttpParams().set('tin', niu).set('sortDirection', sortDirection).set('sortColumn', sortColumn).set('pageIndex', pageIndex).set('pageSize', pageSize).set('pgOrig', pgOrig).set('search', filter).set('filterObject', this.stringifyWithUTC(filterObject))
    };
    let avis$ = this.http.get<any>(this.sharedService.apiUrl('fees/year/' + year), httpOptionsExcel)
    return avis$;
  }
  getCompanyPayments(niu, type, sortDirection, sortColumn, pageIndex, pageSize, pgOrig, filter, filterObject) {
    const httpOptionsExcel = {
      headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
      params: new HttpParams().set('tin', niu).set('type', type).set('sortDirection', sortDirection).set('sortColumn', sortColumn).set('pageIndex', pageIndex).set('pageSize', pageSize).set('pgOrig', pgOrig).set('search', filter).set('filterObject', this.stringifyWithUTC(filterObject))
    };
    let payments$ = this.http.get<any>(this.sharedService.apiUrl('fees/payments'), httpOptionsExcel)
    return payments$;
  }

  getPayments(dType) {
    const httpOptionsExcel = {
      headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
      params: new HttpParams().set('dashboardFilter', this.stringifyWithUTC(dType))
    };
    let payments$ = this.http.get<any>(this.sharedService.apiUrl('fees/payments'), httpOptionsExcel)
    return payments$;
  }

  stringifyWithUTC(obj) {
    return JSON.stringify(obj, (key, value) => {
      if (obj && obj[key] instanceof Date) {
        const utcDateString = new Date(obj[key].getTime() - (obj[key].getTimezoneOffset() * 60000)).toISOString();
       return utcDateString
      }
      return value;
    });
  }

  getProcessType(id) {
    let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('process-types/' + id))
    return processTypes$;
  }

  downloadExcel(query): Observable<Blob> {
    var qu = JSON.parse(query)
    const httpOptionsExcel = {
      headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('query', qu.query).set('year', qu.year)
    };
    let avis$ = this.http.get<any>(this.sharedService.apiUrl('avis/excel'), httpOptionsExcel).pipe(
      map((res: Response) => res), catchError(this.sharedService.handleError<any>('download excel'))
    )
    return avis$;

  }

  @Output() chartDataEmitter: EventEmitter<any> = new EventEmitter();
  emitChartData(data) {
    this.chartDataEmitter.emit(data)
  }
}
