import { Platform } from "@angular/cdk/platform";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MonthDateAdapter } from "src/app/adapters/month-date-adapter";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { SharedService } from "src/app/services/sharedService";
import { MONTH_YEAR_FORMATS } from "src/app/util/data-adapters";
import { MultiselectAutocompleteComponent } from "../../../multiselect-autocomplete/multiselect-autocomplete.component";
 
@Component({
  selector: "app-comparison-search-graph",
  templateUrl: "./search-comparison-graph.component.html",
  styleUrls: ["./search-comparison-graph.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MonthDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMATS },
  ]
})
export class SearchComparisonGraphComponent implements OnInit, OnDestroy {
  @Output() onSelectedRangeChange = new EventEmitter<any>()
  searchMonthOne = new Date();
  searchMonthTwo = new Date();

  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  filterData = {
    municipality: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields : any;
  @Input() singleMunicipality : any;
  municipalityChangeSubscription: any
  queryFilter = {};
  filterFields = ["municipality"];
  panelOpenState = true;
  fromDate = new Date();
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public sharedService: SharedService
  ) {}
 
  ngOnDestroy(): void {
   if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length===0){
      delete this.queryFilter[field]
    }
  };
  resetFilter() {
    this.queryFilter = {}
    this.municipalitySelect.resetAll()
    this.onReset.emit()
  }
   advancedSearch() {
    this.queryFilter['month_one'] = this.searchMonthOne.getMonth()
    this.queryFilter['year_one'] = this.searchMonthOne.getFullYear()
    this.queryFilter['month_two'] = this.searchMonthTwo.getMonth()
    this.queryFilter['year_two'] = this.searchMonthTwo.getFullYear()
    this.onFilter.emit(this.queryFilter)
    
    if (this.queryFilter['municipality']){ 
      this.panelOpenState = false
    }
   }

   setRange(range){
    this.queryFilter['from'] = range.from
    this.queryFilter['to']= range.to
  }

  ngOnInit() {
    this.searchMonthOne.setMonth(new Date().getMonth()-3);
    this.searchMonthTwo.setMonth(new Date().getMonth()-2);
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
          this.advancedSearch()
          if (localStorage.getItem('municipality') && !this.singleMunicipality){
            setTimeout(()=>{
              if (this.municipalitySelect){
                this.municipalitySelect.resetAll()
                this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
              }
            },1000)
          }
        });
       
    });
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')){
              this.municipalitySelect.resetAll()
              this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
          }
          else{
            this.municipalitySelect.resetAll()
          }
    })
  
    this.fromDate.setDate(1)
    this.fromDate.setMonth(0)
  }

  chosenYearHandler(normalizedYear: Date, picker) {
    if (picker==='FROM'){
      this.searchMonthOne = new Date()
      this.searchMonthOne.setFullYear(normalizedYear.getFullYear())
    }
    if (picker==='TO'){
      this.searchMonthTwo = new Date()
      this.searchMonthTwo.setFullYear(normalizedYear.getFullYear())
    }
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: any, picker) {
    if (picker==='FROM'){
      this.searchMonthOne = new Date()
      this.searchMonthOne.setFullYear(normalizedMonth.getFullYear())
      this.searchMonthOne.setMonth(normalizedMonth.getMonth())
    }
    if (picker==='TO'){
      this.searchMonthTwo = new Date()
      this.searchMonthTwo.setFullYear(normalizedMonth.getFullYear())
      this.searchMonthTwo.setMonth(normalizedMonth.getMonth())
    }
    this.searchMonthTwo.setHours(23, 59, 59, 999)
   // this.searchDateTo.setDate(this.getLastDateOfMonth(normalizedMonth.getFullYear(), normalizedMonth.getMonth()+1))
    this.searchMonthOne.setDate(1)
    this.onSelectedRangeChange.emit({ from: this.searchMonthOne, to: this.searchMonthTwo })
    datepicker.close();
  }
}
