import { Component, Input, OnInit } from '@angular/core';
import { TaxPayerService } from 'src/app/services/taxPayer.service';

@Component({
  selector: 'rdf-cash-payment-print',
  templateUrl: './cash-payment-print.component.html',
  styleUrls: ['./cash-payment-print.component.css']
})
export class CashPaymentPrintComponent implements OnInit {

  constructor(private taxPayerService: TaxPayerService) { }
  @Input() receipt
  municipality
  telephone
  elementType : 'url' | 'canvas' | 'img' = 'url';
  value : string = 'RDF';
  async ngOnInit() {
    let taxPayer = await this.taxPayerService.getTaxPayerByNiu('tax-payers', this.receipt.tin).toPromise()
    this.municipality = taxPayer.municipality.name
    this.telephone = taxPayer.telephone
  }
}
