// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apihost: ['https://sl-atom-stg-tas-server.revenuedev.org/gov'],
  accessToken: 'tas_gov_access_token',
  boundary: [ -13.5,6.8,-10.5,9.9 ],
  govinLink: "https://sl-atom-stg-govin.revenuedev.org",
  landingPage: 'dashboard',
  default_lang: 'en'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
