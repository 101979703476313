import { Injectable, Output, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { User } from '../entities/user';
import { ConfigService } from './config.service';
import { CompanyRepresentative } from '../entities/companyRepresentative';
import { TaxPayer } from '../entities/taxPayer';
import { CashPayment } from "../entities/cashPayment";
import { RevenueCode } from "../entities/revenueCode";
import { TaxName } from "../entities/taxName";
import { Wallet } from "../entities/wallet";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class SharedService {
  private lan$ = new Subject<any>();
  private serverPath: string;
  constructor(public configService: ConfigService, public http: HttpClient) {
    this.serverPath = this.configService.getSavedServerPath();
  }

  apiUrl(pathToAPI: string, parameters = ''): string {
    return this.configService.getSavedServerPath()+"/api" +  '/' + pathToAPI + (parameters!==''?'/' + parameters:'');
  }

  apiUrlLogin(pathToAPI: string, parameters = ''): string {
    return this.configService.getSavedServerPath() + '/' + pathToAPI
  }


  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return Observable.throw(error.error || 'Server error');
    };
  }

  getAccessToken() {
    return environment.accessToken;
  }

  getServerStatus(resourceUrl:string): Observable<any> {
    return this.http.get(resourceUrl+'/api/ping').pipe(
      map(res => {
        return res;
      }, err => { console.log(err)})
    );
  }

  log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }

  getLan$(): Observable<any> {
    return this.lan$.asObservable();
  }

  getLan() {
    return localStorage.getItem('default_lang');
  }

  setLan(lan) {
    localStorage.setItem('default_lang', lan);
    this.lan$.next(lan);
  }


  @Output() deactivateUserEmmitter: EventEmitter<User> = new EventEmitter();

  deactivateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.deactivateUserEmmitter.emit(obj)
    }

  }

  @Output() activateUserEmmitter: EventEmitter<User> = new EventEmitter();

  @Output() activateCompRepEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() declineCompRepEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() suspendCompRepEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateTaxPayerEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() resetDashboardFilterEmitter: EventEmitter<any> = new EventEmitter();
  activateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.activateUserEmmitter.emit(obj)
    }
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      obj['status'] = 1
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }
  }

  resetDashboardFilter(){
    this.resetDashboardFilterEmitter.emit();
  }

  declineEntity(obj) {
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      obj['status'] = 2
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }
  }

  suspendEntity(obj) {
    obj['status'] = 3
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }

  }

  @Output() addTaxPayerEmmitter: EventEmitter<any> = new EventEmitter();
  addTaxpayer() {
    this.addTaxPayerEmmitter.emit()
  }


  @Output() updateUserEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateCompanyRepresentativeEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateCashPaymentEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateRevenueCodeEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateTaxNameEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateFillWalletEmmitter: EventEmitter<User> = new EventEmitter();
  updateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.updateUserEmmitter.emit(obj)
    }
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }
    if (obj instanceof RevenueCode || obj['class'] === 'REVENUE-CODE') {
      this.updateRevenueCodeEmmitter.emit(obj)
    }
    if (obj instanceof TaxName || obj['class'] === 'TAX-NAME') {
      this.updateTaxNameEmmitter.emit(obj)
    }

    if (obj instanceof TaxPayer || obj['class'] === 'TAX-PAYER') {
      this.updateTaxPayerEmmitter.emit(obj)
    }
    if (obj instanceof CashPayment || obj['class'] === 'CASH-PAYMENT') {
      this.updateCashPaymentEmmitter.emit(obj)
    }
    if (obj instanceof Wallet || obj['class'] === 'WALLET') {
      this.updateFillWalletEmmitter.emit(obj)
    }
  }

  @Output() updateVisitEmmitter: EventEmitter<any> = new EventEmitter();
  updateVisit(obj) {
    this.updateVisitEmmitter.emit(obj)
  }

  @Output() notifyStartedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyStartedVisit() {
    this.notifyStartedVisitEmmiter.emit()
  }
  @Output() notifyAcceptedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyAcceptedVisit() {
    this.notifyAcceptedVisitEmmiter.emit()
  }
  @Output() notifyRejectedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyRejectedVisit() {
    this.notifyRejectedVisitEmmiter.emit()
  }

  @Output() notifyEndedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyEndedVisit() {
    this.notifyEndedVisitEmmiter.emit()
  }


  @Output() pendingVisitNumberEmmitter: EventEmitter<boolean> = new EventEmitter();
  setPendingVisitNumber(obj) {
    this.pendingVisitNumberEmmitter.emit(obj)
  }

  @Output() visitorBlacklistEmmiter: EventEmitter<boolean> = new EventEmitter();
  setVisitorBlacklist(obj) {
    this.visitorBlacklistEmmiter.emit(obj)
  }

  @Output() notifyTableReloadEmmiter: EventEmitter<any> = new EventEmitter();
  notifyTableReload() {
    this.notifyTableReloadEmmiter.emit()
  }


  @Output() notifyTableLoadEmmiter: EventEmitter<any> = new EventEmitter();
  notifyLoadTable(obj) {
    this.notifyTableLoadEmmiter.emit(obj)
  }

  @Output() deleteBlock: EventEmitter<number> = new EventEmitter();
  updateDeleteBlock(ind) {
    this.deleteBlock.emit(ind)
  }
  @Output() renameBlock: EventEmitter<any> = new EventEmitter();
  updateRenameBlock(name, ind) {
    this.renameBlock.emit({ name: name, ind: ind })
  }
  @Output() addRow: EventEmitter<any> = new EventEmitter();
  updateAddRow(blockIndx, row) {
    this.addRow.emit({ blockIndx: blockIndx, row: row })
  }

  @Output() updateRow: EventEmitter<any> = new EventEmitter();
  updateUpdateRow(blockIndx, row) {
    this.updateRow.emit({ blockIndx: blockIndx, row: row })
  }

  @Output() moveRow: EventEmitter<any> = new EventEmitter();
  updateMoveRow(blockIndx, row, direction) {
    this.moveRow.emit({ blockIndx: blockIndx, row: row, direction: direction })
  }

  @Output() addColumn: EventEmitter<any> = new EventEmitter();
  updateAddColumn(blockIndx, column) {
    this.addColumn.emit({ blockIndx: blockIndx, column: column })
  }

  @Output() deleteColumnEmitter: EventEmitter<any> = new EventEmitter();
  deleteColumn(blockIndx, column) {
    this.deleteColumnEmitter.emit({ blockIndx: blockIndx, column: column })
  }

  @Output() updateColumnEmmitter: EventEmitter<any> = new EventEmitter();
  updateColumn(blockIndx, column, colIndex) {
    this.updateColumnEmmitter.emit({ blockIndx: blockIndx, column: column, colIndex: colIndex })
  }

  @Output() deleteRowEmitter: EventEmitter<any> = new EventEmitter();
  deleteRow(blockIndx, row) {
    this.deleteRowEmitter.emit({ blockIndx: blockIndx, row: row })
  }
  @Output() updateAvisBlockEmitter: EventEmitter<any> = new EventEmitter();
  updateAvisBlock(avis, ind) {
    this.updateAvisBlockEmitter.emit({ avis: avis, ind: ind })
  }

  @Output() deleteFormEmmiter: EventEmitter<any> = new EventEmitter();
  deleteForm(obj) {
    this.deleteFormEmmiter.emit(obj)
  }

  @Output() formulaKeyEmmiter: EventEmitter<any> = new EventEmitter();

  emitFormulaKey(obj) {
    this.formulaKeyEmmiter.emit(obj)
  }

  @Output() confirmDialogEmitter: EventEmitter<boolean> = new EventEmitter();
  confirmDialogAction(obj) {
    this.confirmDialogEmitter.emit(obj)
  }

  @Output() conditionBlock: EventEmitter<any> = new EventEmitter();
  setBlockCondition(condition, ind) {
    this.conditionBlock.emit({ condition, ind })
  }

  @Output() updateColTypeEmitter: EventEmitter<any> = new EventEmitter();
  updateColType(blockIndex, columnType) {
    this.updateColTypeEmitter.emit({ blockIndex, columnType })
  }

  @Output() updateSumTotalsEmitter: EventEmitter<any> = new EventEmitter();
  updateSumTotals(blockIndex, sumTotals) {
    this.updateSumTotalsEmitter.emit({ blockIndex, sumTotals })
  }
  @Output() cashPaymentPrintEmitter: EventEmitter<any> = new EventEmitter();
  cashPaymentPrint(payment) {
    this.cashPaymentPrintEmitter.emit(payment)
  }

}
