import { UserStatus } from './states/userStatus';

export class User {
  public id: number;
  public name: string;
  public username: string;
  public surname: string;
  public password: string;
  public email: string;
  public status: UserStatus;
  public municipalities: any[];
  public role : string;
  
  constructor() {
 
  }

}  