import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SharedService } from './sharedService';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class OtpService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }


  getOtpsByReference(ref_num): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('payments/')+ref_num).pipe(
      map(res => {
        return res;
      })
    );
  }
}



