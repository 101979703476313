import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { map } from "rxjs/operators";
import { YearService } from "src/app/services/year.service";

@Component({
  selector: "rdf-yeared-tab",
  templateUrl: "./yeared-tab.component.html",
  styleUrls: ["./yeared-tab.component.css"],
})
export class YearedTabComponent implements OnInit {
  @Input() component: any;
  @Input() componentData: any;
  @Input() startYear: any;
  @Input() endYear: any;
  @ViewChild("dynamicComponentContainer", {
    read: ViewContainerRef,
    static: true,
  })
  container: ViewContainerRef;
  displayedYears = [];
  currentYear = new Date().getFullYear();
  componentRef;
  constructor(
    private yearService: YearService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}
  async ngOnInit() {
    let years = [];
    if (this.startYear && this.endYear) {
      for (let i = this.endYear; i >= this.startYear; i--) {
        years.push(i);
      }
    } else {
      years = await this.yearService
        .getList("year")
        .pipe(
          map((yearsList) => {
            return yearsList.map((year) => year.year * 1);
          })
        )
        .toPromise();
    }
    years.forEach((element) => {
      this.displayedYears.push(element);
    });
    if (years && years.length > 0) {
      this.currentYear = years[0];
    }
    if (this.component) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(
        this.component
      );
      this.container.clear();
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance["currentYear"] = this.currentYear;
      if (this.componentData){
        for (let dt in this.componentData){
          this.componentData['currentYear'] && (this.componentRef.instance[dt] = this.componentData[dt])
        }
        if (this.componentData['currentYear']){
          this.currentYear = this.componentData['currentYear']*1
        }
      }
    }
  }
  handleSelection(event) {
    this.currentYear = event.value;
    this.componentRef.instance["currentYear"] = this.currentYear;
    this.componentRef.instance.externalFunction();
  }
}
