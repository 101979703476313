import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { RevenueCodeService } from "src/app/services/revenueCode.service";
import { SharedService } from "src/app/services/sharedService";
import { TasDataSource } from "src/app/services/tasGovTable.datasource";
import { RevenueCodeDialogComponent } from "../dialogs/revenue-code-dialog/revenue-code-dialog.component";

@Component({
  selector: "tas-gov-revenue-code",
  templateUrl: "./revenue-code.component.html",
  styleUrls: ["./revenue-code.component.css"],
})
export class RevenueCodeComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptionUpdateRevenueCode: any;
  filterObject: {};
  displayedColumns: string[] = ["code", "name", "description", "parent"];
  dataSource: TasDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("input_search", { static: false }) input: ElementRef;
  loading = { inProgress : false, error: false, downloadInProgress: false  }
  constructor(
    private revenueCodeService: RevenueCodeService,
    public dialog: MatDialog,
    public sharedService: SharedService
  ) {}
  resetLoading = () => {
    this.loading.inProgress = true
    this.loading.error = false
    this.dataSource.emptyData()
  }
  ngOnInit() {
    this.dataSource = new TasDataSource(this.revenueCodeService);
    this.subscriptionUpdateRevenueCode =
      this.sharedService.updateRevenueCodeEmmitter.subscribe((val) => {
        if (val) {
          this.revenueCodeService
            .updateResource("revenue-code", val)
            .subscribe((res) => {
              this.loadRevenueCodeTable();
            });
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.loadData(
        "revenue-code",
        "",
        "asc",
        "name",
        0,
        this.paginator.pageSize,
        this.filterObject,
        this.loading
      );
    }, 0);

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadRevenueCodeTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadRevenueCodeTable()))
      .subscribe();
  }

  loadRevenueCodeTable() {
    this.resetLoading()
    this.dataSource.loadData(
      "revenue-code",
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject,
      this.loading
    );
  }

  async openRevenueCodeDialog(revenueCode) {
    let elem = revenueCode;
    if (revenueCode) {
      try {
        elem = await this.revenueCodeService
          .getResource("revenue-code", revenueCode.id || revenueCode._id)
          .toPromise();
      } catch (error) {
        console.error("Error:", error);
      }
    }
    const dialogRef = this.dialog.open(RevenueCodeDialogComponent, {
      width: "600px",
      panelClass: "custom-dialog-container",
      data: elem,
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  ngOnDestroy() {
    if (this.subscriptionUpdateRevenueCode)
      this.subscriptionUpdateRevenueCode.unsubscribe();
  }
}
