import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  text: string;
  emitter: EventEmitter;
  obj: any;
  action: any;
  processes = []

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService) {
    this.text = data['text'];
    this.obj = data['obj']
    this.action = data['action']
  }

  ngOnInit() {

  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick(): void {
    if (['DEACTIVATE','ACTIVATE'].includes(this.action)){
      this.dialogRef.close(true);
    }
    else if (this.action === 'DELETE_PROCESS') {
      this.sharedService.deleteForm(this.obj)
      this.dialogRef.close(true);
    }
    else if (this.action=="CONFIRM"){
      this.sharedService.confirmDialogAction(this.obj)
      this.dialogRef.close(false);
    }
    else {
      this.sharedService.updateEntity(this.obj)
      this.dialogRef.close(true);
    }

  }

}