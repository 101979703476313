import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog, MatPaginator, MatSort } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AvisService } from "src/app/services/avis.service";
import { FinancesDataSource } from "src/app/services/finances.datasource";
import { SharedService } from "src/app/services/sharedService";
import { TaxPayerService } from "src/app/services/taxPayer.service";
import { YearService } from "src/app/services/year.service";

@Component({
  selector: "app-tax-ledger",
  templateUrl: "./tax-ledger.component.html",
  styleUrls: ["./tax-ledger.component.css"],
})
export class TaxLedgerComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  currentYear: any;
  @Input()
  category: any;
  @Input()
  currentNIU: any;
  currentYearInt: any;
  displayedYears = [];
  dataSource: FinancesDataSource;
  chartDataSubscription: any
  refreshDataSubscription: any
  displayedColumns: string[] = ['date', 'paymentSystem', 'paymentReference', 'debit', 'credit', 'print'];
  @Input() company: any;
  avis;
  otpPayment = 0;
  avisMapping = {};
  finances = [];
  barChartLabels: Label[];
  barChartData: ChartDataSets[];
  filterObject: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  
  constructor(
    private yearService: YearService,
    private avisService: AvisService,
    public translate: TranslateService,
    public taxPayerService: TaxPayerService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.category && changes.category.currentValue.avis) {
        this.displayedColumns = ['date', 'paymentSystem', 'paymentReference', 'debit', 'credit',  'balance', 'print'];
    }
  }

  ngOnInit() { 
    this.yearService.getList("year").subscribe((val) => {
      val.forEach((element) => {
        this.displayedYears.push(element.year);
      });
      if (val && val.length > 0) {
        this.currentYear = val[0].year;
        this.currentYearInt = val[0].year * 1;
      }

    });
    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
    this.chartDataSubscription = this.avisService.chartDataEmitter.subscribe(val => {
      this.barChartData = val.data
      this.barChartLabels = val.labels
    })
    this.refreshDataSubscription = this.sharedService.cashPaymentPrintEmitter.subscribe(val => {
      this.taxPayerService.getTaxPayerByNiu('tax-payers', this.currentNIU).subscribe(taxPayer => {
        this.company = taxPayer
        this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
        this.loadFinancesTable()
      })

    })
  }

  handleSelection(event) {
    this.currentYear = event.value;
    this.dataSource.loadFinances(this.currentYear, this.currentNIU, '', 'asc', 'date', 0, this.paginator.pageSize, this.pType, this.filterObject, this.company.extraBalance);
  }


  print(p) {
    this.sharedService.cashPaymentPrint(p)
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.loadFinances(this.currentYear, this.currentNIU, '', 'asc', 'date', 0, this.paginator.pageSize, this.pType, this.filterObject, this.company.extraBalance);
    }, 1000)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
           this.loadFinancesTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
       tap(() => this.loadFinancesTable())
    )
      .subscribe();

  }
  ngOnDestroy(): void {
    this.chartDataSubscription && this.chartDataSubscription.unsubscribe()
    this.refreshDataSubscription && this.refreshDataSubscription.unsubscribe()
  }
  loadFinancesTable() {
    this.dataSource.loadFinances(this.currentYear, this.currentNIU, 
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,this.pType, this.filterObject, this.company.extraBalance);
  }
 
  pType = "ALL"
  getFinances(val, type){
    if (val.checked){
      this.pType = this.pType==='ALL' ? type : "ALL"
    }else{
     this.pType = this.pType!=='ALL' ? 'ALL' : (type==='FEES' ? "PAYMENTS":"FEES")
    }
    this.loadFinancesTable()
  }
  getBackgroundClass(p: any): string {
    if (!p.paymentSystem ||  (p.paymentSystem && p.paymentSystem.name === 'Avis')) {
      return p.paid ? 'green-background' : 'red-background';
    }
    return '';
  }
  getRowClass(row: any): string {
    if (row.type === 'WALLET') {
      return 'light-green-background';
    }
    return '';
  }
}
