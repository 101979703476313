export const environment = {
  production: true,
  apihost: ['https://sl-atom-stg-tas-server.revenuedev.org/gov'],
  extApihost: ['https://sl-atom-stg-tas.revenuedev.org/ext'],
  accessToken: 'tas_gov_access_token',
  boundary: [ -13.3186960220337,6.90220165252686,-10.250563621521, 10.0158452987671 ],
  govinLink: "https://sl-atom-stg-govin.revenuedev.org",
  landingPage: 'dashboard',
  default_lang: 'en',
  synchronizationInterval: 5
};
