import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { switchMap } from 'rxjs/operators';
import { Role } from 'src/app/entities/role';
import { RoleName } from 'src/app/entities/util';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { SharedService } from 'src/app/services/sharedService';
import { Errors } from 'src/app/util/errors';
import { environment } from 'src/environments/environment';
import { MenuCategories } from '../top-menu/menuItems';
import { CompanyDexieService } from 'src/app/services/companyDexie-services/companyDexie.service';

@Component({
  selector: 'tas-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string;
  public password: string;
  public error: string;
  roleNames = RoleName
  notifParam: any;
  initialRoute = 'dashboard'
  constructor(public sharedService: SharedService, public companyDexieService: CompanyDexieService, public authService: AuthService, public configService: ConfigService, public jwtHelper: JwtHelperService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.setServerPath()
    localStorage.removeItem('municipalityBbox')
    localStorage.removeItem('municipalityName')
    localStorage.removeItem('municipality')
  }

  setServerPath() {
    return new Promise(resolve => {
    this.sharedService.getServerStatus(environment.apihost[0]).subscribe((resp) => {
      this.initializeSettings(environment.apihost[0]).then(resolve)
    }, err => {
      this.initializeSettings(environment.apihost[1]).then(resolve)
    })
  })
  }

  initializeSettings(path) {
    return new Promise(resolve => {
      if (!localStorage.getItem("serverPath")) {
        localStorage.setItem("serverPath", path);
      }
      const token = this.jwtHelper.tokenGetter()
      if (token && token !== 'undefined') {
        const decodedToken = this.jwtHelper.decodeToken(token);
        var routePath = MenuCategories.get(decodedToken.role)[0][0]
        this.router.navigate([routePath]);
      }
      this.route.queryParamMap.subscribe(q => {
        this.notifParam = q['params'].notifications
      });
      resolve({});
    })
  }

  login(username, password) {
    this.authService.login(username.value, password.value).pipe(
      switchMap(result => {
        this.authService.setLoginStatus(result);
        return this.authService.getUserRole$();
      })
    ).subscribe(async role => {
      await this.companyDexieService.addSynchinfo(true)
      if (role === this.roleNames.get(Role.ADMIN)) {
        this.router.navigate([this.initialRoute]);
      } else {
        const queryParams = this.notifParam ? { notifications: true } : undefined;
        this.router.navigate([this.initialRoute], { queryParams });
      }
    },
    err => {
      if (err.status === 0) {
        this.error = Errors.CONNECTION_FAILED;
      }
      if (err.status === 401) {
        if(err.error && err.error.message === "PASSWORD_EXPIRED" ){
          const queryParams = { vercode:err.error.vercode, renew: true }
          this.router.navigate(['password_restore'],  {queryParams})
          return;
        }
        this.error = Errors.UNAUTHORIZED;
      }
    });
  }
  


}
