import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { CompanyDexie } from './interfaces/companyDexie';
import { TaxNameDexie } from './interfaces/taxNameDexie';
import { UserDexie } from './interfaces/userDexie';
import { CategoryDexie } from './interfaces/categoryDexie';
import { SynchronizationInfoDexie } from './interfaces/synchronizationInfoDexie';
import * as _dexieUtilities from "./dexie-utilities";
@Injectable({
  providedIn: 'root',
})
export class CompanyDexieService extends Dexie {

  companies: Dexie.Table<CompanyDexie, number>;
  taxNames: Dexie.Table<TaxNameDexie, number>;
  users: Dexie.Table<UserDexie, number>;
  categories: Dexie.Table<CategoryDexie, number>;
  synchronizationInfo: Dexie.Table<SynchronizationInfoDexie, number>;

  constructor() {
    super('tasdb');

    this.version(1).stores({
      companies: 'id, name, tin, telephone, municipality, municipalityName, type, typeName, balance, extraBalance, finalBalance',
      taxNames: 'id, amount, name, revenueCode, municipality, category',
      users: 'id, username',
      categories: 'id, name',
      synchronizationInfo: '++id, synchronizationDate'
    });

    this.companies = this.table('companies');
    this.taxNames = this.table('taxNames');
    this.users = this.table('users');
    this.categories = this.table('categories');
    this.synchronizationInfo = this.table('synchronizationInfo');

    this.handleDbReopen();
  }

  async addCompanies(companies: CompanyDexie[]): Promise<void> {
    await _dexieUtilities.addCompanies(companies, this.companies)
  }

  async addTaxNames(taxNames: TaxNameDexie[]): Promise<void> {
    await _dexieUtilities.addTaxNames(taxNames, this.taxNames)
  }

  async addUsers(users: UserDexie[]): Promise<void> {
    await _dexieUtilities.addUsers(users, this.users)
  }

  async addCategories(categories: CategoryDexie[]): Promise<void> {
    await _dexieUtilities.addCategories(categories, this.categories)
  }

  async addSynchinfo(synchFlag = false): Promise<void> {
    if (!this.isOpen()) {
      await this.open();
    }
    await _dexieUtilities.addSynchinfo(this.synchronizationInfo, synchFlag)
  }

  async getAllPayments(): Promise<any[]> {
    return await _dexieUtilities.getAllPayments(this.companies)
  }

  async getUniqueMunicipalities(): Promise<{ municipality: string; municipalityName: string }[]> {
    return await _dexieUtilities.getUniqueMunicipalities(this.companies)
  }

  async getUniqueTaxpayerTypes(): Promise<{ type: string; typeName: string }[]> {
    return await _dexieUtilities.getUniqueTaxpayerTypes(this.companies)
  }

  async searchCompanies(queryObj: Record<string, any>, page: number,
    pageSize: number, sortColumn: string, sortOrder: string): Promise<{ total: number; data: CompanyDexie[] }> {
    return await _dexieUtilities.searchCompanies(queryObj, page, pageSize, sortColumn, sortOrder, this.companies)
  }

  async getCompanyByTin(tin: string): Promise<CompanyDexie | undefined> {
    return await _dexieUtilities.getCompanyByTin(tin, this.companies)
  }

  async getCurrentUser(): Promise<UserDexie | undefined> {
    return this.users.toCollection().first();
  }
  async getCategoryById(id: string): Promise<CategoryDexie | undefined> {
    return this.categories.where('id').equals(id).first();
  }

  async getLatestSynchronization() {
    return await _dexieUtilities.getLatestSynchronization(this.synchronizationInfo)
  }

  async getTaxNamesByMunicipalityAndType(munId: string, catId: string): Promise<TaxNameDexie[]> {
    return await _dexieUtilities.getTaxNamesByMunicipalityAndType(munId, catId, this.taxNames)
  }

  async setPayment(payment: { paymentSystem: { key: string; name: string; }; currency: string; amount: any; paymentDate: any; tin: any; companyName: any; taxName: any; taxNameId: any; revenueCodeId: any; revenueCode: any; avis: any; reference_number: any; process_name: any; month: any; year: any; description: any; }) {
    await _dexieUtilities.addSynchinfo(this.synchronizationInfo, true)
    await _dexieUtilities.setPayment(payment, this.companies)
  }

  async deleteAvis(tin: string, reference_number: any) {
    await _dexieUtilities.deleteAvis(tin, reference_number, this.companies)
  }

  async clearDatabase() {
    await _dexieUtilities.clearData(this.companies)
    await _dexieUtilities.clearData(this.taxNames)
    await _dexieUtilities.clearData(this.users)
    await _dexieUtilities.clearData(this.categories)
    await _dexieUtilities.clearData(this.synchronizationInfo)
  }
  
  async databaseExists(dbName: string): Promise<boolean> {
    const dbList = await Dexie.getDatabaseNames();
    return dbList.includes(dbName);
  }

  async isDatabaseAvailable(): Promise<boolean> {
    const dbExists = await this.databaseExists(this.name);
    return dbExists && this.isOpen();
  }

  async handleDbReopen() {
    if (!this.isOpen()) {
      try {
        await this.open();
      } catch (error) {
        console.error('Dexie: Failed to reopen database:', error);
      }
    }
  }
  

}
