import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-approval",
  templateUrl: "./approval.component.html",
  styleUrls: ["./approval.component.css"],
})
export class ApprovalComponent implements OnInit {
  selectedTabIndex = 0
  onTabChange(index) {
    this.selectedTabIndex = index;
  }
  get currentType() {
    return this.selectedTabIndex === 0 ? 'forecast' : 'performance';
  }
  constructor() {
 
  }

  ngOnInit() {
 
  }
}
