import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/sharedService';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { ConditionalsComponent } from './components/form-creation/conditionals/conditionals.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DeadlineSelectorComponent } from './components/deadline-selector/deadline-selector.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import localeFr from '@angular/common/locales/fr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from "ng2-file-upload";
import { ChartsModule, ThemeService } from 'ng2-charts';
import { DynamicDropdownComponent } from './components/form-creation/dynamic-dropdown/dynamic-dropdown.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSidenavModule, MatListModule, MatButtonModule,
  MatPaginatorModule,
  MatCardModule,
  MatTableModule,
  MatSortModule,
  MatDialogModule,
  MatMenuModule,
  MatPaginatorIntl,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTabsModule,
  MatSelectModule,
  MatChipsModule,
  MatOptionModule,
  MatRadioModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatButtonToggleModule,
  MatAutocompleteModule,
  MAT_DATE_LOCALE
} from '@angular/material';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfigService } from './services/config.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './util/auth.interceptor';
import { UserComponent } from './components/user/user.component';
import { RevenueCodeComponent } from './components/revenue-code/revenue-code.component';
import { TaxNameComponent } from './components/tax-name/tax-name.component';
import { CashPaymentPrintComponent } from './components/cash-payment-print/cash-payment-print.component';
import { UserService } from './services/user.service';
import { PaymentReportService } from './services/paymentReport.service';
import { CompanyRepresentativeService } from './services/companyRepresentative.service';
import { UserDialogComponent } from './components/dialogs/user-dialog/user-dialog.component';
import { RevenueCodeDialogComponent } from './components/dialogs/revenue-code-dialog/revenue-code-dialog.component';
import { TaxNameDialogComponent } from './components/dialogs/tax-name-dialog/tax-name-dialog.component';
import { CashPaymentDialogComponent } from './components/dialogs/cash-payment-dialog/cash-payment-dialog.component';
import { FillWalletDialogComponent } from './components/dialogs/fill-wallet-dialog/fill-wallet-dialog.component';
import { TaxPayersComponent } from './components/tax-payers/tax-payers.component';
import { CompanyProfileComponent } from './components/company-profile/company-profile.component';
import { RecurringReportsComponent } from './components/recurring-reports/recurring-reports.component';
// import { TxDeclarationsComponent } from './components/tx-declarations-old/tx-declarations.component';
// import { MatPaginatorIntlTas } from './components/tx-declarations-old/MatPaginatorIntlTas';
import { MultiselectAutocompleteComponent } from './components/multiselect-autocomplete/multiselect-autocomplete.component';
import { TxDeclarationsComponent } from './components/tx-declarations/tx-declarations.component';
import { MatPaginatorIntlTas } from './components/tx-declarations/MatPaginatorIntlTas';

import { ProcessTypeService } from './services/proces-type.service';
import { ProcessViewComponent } from './components/process/process-view/process-view.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReportService } from './services/report.service';
import { ProcessStepComponent } from './components/process/process-step/process-step.component';
import { MultiStepFormComponent } from './components/multi-step-form/multi-step-form.component';
import { TasTableComponent } from './components/tas-table/tas-table.component';
import { ThousandSeparatorDirective } from './directives/thousandSeparator.directive';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { OnlyNumber } from './directives/onlyNumberDirective';
import { CdkTableModule } from '@angular/cdk/table';
import { ToastrModule } from 'ngx-toastr';
import { FormCreatorComponent } from './components/form-creation/form-creator/form-creator.component';
import { ProcessTypeComponent } from './components/form-creation/process-type/process-type.component';
import { ProcessAddEditComponent } from './components/form-creation/process-type/process-add-edit/process-add-edit.component';
import { ColumnUtilComponent } from './components/form-creation/column-util/column-util.component';
import { RowUtilComponent } from './components/form-creation/row-util/row-util.component';
import { DeclarationsComponent } from './components/declarations/declarations.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { PaymentConfigurationComponent } from './components/configuration/payment-configuration/payment-configuration.component';
import { ReportingComponent } from './components/declarations/reporting/reporting.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TaxDivisionComponent } from './components/tax-division/tax-division.component';
import { TaxDivisionService } from './services/taxDivision.service';
import { StatDetailsComponent } from './components/statistics/stat-details/stat-details.component';
import { StatDecCompaniesComponent } from './components/statistics/stat-dec-companies/stat-dec-companies.component';
import { CompanyRepresentativeComponent } from './components/company-representative/company-representative.component';
import { CompanyRepresentativeDialogComponent } from './components/dialogs/company-representative-dialog/company-representative-dialog.component';
import { TaxPayerDialogComponent } from './components/dialogs/tax-payer-dialog/tax-payer-dialog.component';
import { HomepageAdminComponent } from './components/homepage-admin/homepage-admin.component';
import { AdminService } from './services/admin.service';
import { YearService } from './services/year.service';
import { TaxLedgerComponent } from './components/tax-ledger/tax-ledger.component';
import { WalletDepositsComponent } from './components/wallet-deposits/wallet-deposits.component';
import { AvisService } from './services/avis.service';
import { OtpService } from './services/otp.service';
import { BarChartNativeComponent } from './components/charts/bar-chart-native/bar-chart-native.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { LineChartNativeComponent } from './components/charts/line-chart-native/line-chart-native.component';
import { PieChartNativeComponent } from './components/charts/pie-chart-native/pie-chart-native.component';
import { MultiAxisChartComponent } from './components/charts/multi-axis-chart/multi-axis-chart.component';
import { PaymentReportComponent } from './components/payment-report/payment-report.component';
import { CategoryService } from './services/category.service';
import { DropdownService } from './services/dropdown.service';
import { MainComponent } from './components/form-builder/main/main.component';
import { FormMainPropertiesComponent } from './components/form-builder/form-main-properties/form-main-properties.component';
import { FormViewerComponent } from './components/form-builder/form-viewer/form-viewer.component';
import { ActionButtonsComponent } from './components/form-builder/action-buttons/action-buttons.component';
import { BlockComponent } from './components/form-builder/block/block.component';
import { BlockControlsComponent } from './components/form-builder/block/block-controls/block-controls.component';
import { BlockViewComponent } from './components/form-builder/block/block-view/block-view.component';
import { MapPreviewComponent} from "./components/map/map-preview.component";
import { MapService } from "./components/map/map.service";
import { MapComponent } from "./components/map/map.component";
import { RegistrationsMapComponent } from "./components/registrations-map/registrations-map.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { RowsComponent } from './components/form-builder/rows/rows.component';
import { ColumnsComponent } from './components/form-builder/columns/columns.component';
import { RowComponent } from './components/form-builder/rows/row/row.component';
import { TextInputComponent } from './components/form-builder/form-elements/text-input/text-input.component';
import { NumberInputComponent } from './components/form-builder/form-elements/number-input/number-input.component';
import { DateInputComponent } from './components/form-builder/form-elements/date-input/date-input.component';
import { CheckboxComponent } from './components/form-builder/form-elements/checkbox/checkbox.component';
import { DropdownComponent } from './components/form-builder/form-elements/dropdown/dropdown.component';
import { OptionComponent } from './components/form-builder/form-elements/option/option.component';
import { RowControlsComponent } from './components/form-builder/rows/row-controls/row-controls.component';
import { ElementPositionerComponent } from './components/form-builder/various/element-positioner/element-positioner.component';
import { FormFilterComponent } from './components/form-creation/form-filter/form-filter.component';
import { CustomCalculationEmitterComponent } from './components/form-creation/custom-calculation-emitter/custom-calculation-emitter.component';
import { RevenueCodeService } from './services/revenueCode.service';
import { TaxNameService } from './services/taxName.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PeriodFilterComponent } from './components/dashboard/period-filter/period-filter.component';
import { InformationDialogComponent } from './components/dialogs/information-dialog/information-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EntityService } from './services/entity.service';
import { SearchTaxpayersComponent } from './components/tax-payers/search/search-taxpayers.component';
import { SearchTaxnamesComponent } from './components/tax-name/search/search-taxnames.component';
import { SearchDashboardComponent } from './components/dashboard/search/search-dashboard.component';
import { SearchStatisticsComponent } from './components/statistics/search/search-taxpayers.component';
import { PasswordRestoreComponent } from './components/password-restore/password-restore.component';
import { SearchUsersComponent } from './components/user/search/search-users.component';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import { HelpComponent } from './components/help/help.component';
import { SearchWalletComponent } from './components/wallet-deposits/search/search-wallet.component';
registerLocaleData(localeFr, 'fr');
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('tas_gov_access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PeriodFilterComponent,
    DashboardComponent,
    ErrorpageComponent,
    TopMenuComponent,
    ConfirmationDialogComponent,
    InformationDialogComponent,
    UserComponent,
    MultiselectAutocompleteComponent,
    UserDialogComponent,
    RevenueCodeDialogComponent,
    TaxNameDialogComponent,
    CashPaymentDialogComponent,
    FillWalletDialogComponent,
    TaxPayersComponent,
    TxDeclarationsComponent,
    ProcessViewComponent,
    PasswordRestoreComponent,
    TaxNameComponent,
    SearchUsersComponent,
    TaxNameDialogComponent,
    ProcessStepComponent,
    ConfigurationComponent,
    PaymentConfigurationComponent,
    CompanyProfileComponent,
    RecurringReportsComponent,
    MultiStepFormComponent,
    TasTableComponent,
    ThousandSeparatorDirective,
    ThousandSeparatorPipe,
    UtcDatePipe,
    CashPaymentPrintComponent,
    OnlyNumber,
    DynamicDropdownComponent,
    FormCreatorComponent,
    DeadlineSelectorComponent,
    ProcessTypeComponent,
    ProcessAddEditComponent,
    ColumnUtilComponent,
    RowUtilComponent,
    DeclarationsComponent,
    ConditionalsComponent,
    StatisticsComponent,
    RevenueCodeComponent,
    TaxNameComponent,
    CustomCalculationEmitterComponent,
    ReportingComponent,
    SettingsComponent,
    RegistrationsMapComponent,
    TaxDivisionComponent,
    StatDetailsComponent,
    StatDecCompaniesComponent,
    CompanyRepresentativeComponent,
    CompanyRepresentativeDialogComponent,
    TaxPayerDialogComponent,
    HomepageAdminComponent,
    TaxLedgerComponent,
    BarChartNativeComponent,
    BarChartComponent,
    LineChartNativeComponent,
    PieChartNativeComponent,
    MultiAxisChartComponent,
    PaymentReportComponent,
    MainComponent,
    FormMainPropertiesComponent,
    FormViewerComponent,
    ActionButtonsComponent,
    BlockComponent,
    BlockControlsComponent,
    BlockViewComponent,
    MapComponent,
    MapPreviewComponent,
    WalletDepositsComponent,
    RowsComponent,
    ColumnsComponent,
    RowComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
    CheckboxComponent,
    DropdownComponent,
    OptionComponent,
    SearchTaxpayersComponent,
    SearchTaxnamesComponent,
    SearchDashboardComponent,
    RowControlsComponent,
    ElementPositionerComponent,
    FormFilterComponent,
    SearchStatisticsComponent,
    HelpComponent,
    SearchWalletComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    BrowserModule,
    AppRoutingModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ChartsModule,
    FileUploadModule,
    LeafletModule,
    LeafletDrawModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatFormFieldModule,
    DragDropModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatOptionModule,
    MatRadioModule,
    MatSlideToggleModule,
    ToastrModule.forRoot(),
    MatDatepickerModule,
    MatSortModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    HttpClientModule,
    NgxSpinnerModule,
    CdkTableModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    })
  ],
  providers: [AuthService, SharedService, ConfigService, AuthGuard, AvisService, OtpService, ThemeService, UserService, PaymentReportService, CompanyRepresentativeService, ProcessTypeService, ProcessTypeService,
    ReportService, TaxDivisionService,AdminService,YearService, CategoryService, DropdownService, MapService, RevenueCodeService, TaxNameService, EntityService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTas }, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, ThousandSeparatorPipe, DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [  UserDialogComponent, FillWalletDialogComponent, CashPaymentDialogComponent, CompanyRepresentativeDialogComponent, TaxPayerDialogComponent,RevenueCodeDialogComponent, TaxNameDialogComponent,
    ConfirmationDialogComponent, InformationDialogComponent]
})
export class AppModule { }
