import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent implements OnInit {
  @Input() field
  constructor() { }

  ngOnInit() {
  }

}
