import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { UserService } from 'src/app/services/user.service';
import { DialogCommunicator } from '../dialog-communicator';
import { CashPayment } from 'src/app/entities/cashPayment';
import { TaxNameService } from 'src/app/services/taxName.service';
import { map } from 'rxjs/operators';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { CategoryService } from 'src/app/services/category.service';
import { AvisService } from 'src/app/services/avis.service';

@Component({
  selector: 'gov-cash-payment-dialog',
  templateUrl: './cash-payment-dialog.component.html',
  styleUrls: ['./cash-payment-dialog.component.css']
})
export class CashPaymentDialogComponent extends DialogCommunicator implements OnInit {

  text: string
  emitter: EventEmitter
  cashPayment: any
  currentUser: any
  taxNames: any[]

  constructor(
    public dialogRef: MatDialogRef<CashPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public taxNameService: TaxNameService, public taxPayerService: TaxPayerService,
    public categoryService: CategoryService, public avisService: AvisService,
    public userService: UserService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.setConfirmationDialog(this.confirmationDialog)
  }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      this.cashPayment = new CashPayment()
      this.cashPayment.amount = 100
      this.cashPayment.date = new Date()
      this.cashPayment['class'] = 'CASH-PAYMENT'
      this.setEntity(this.cashPayment);
      this.currentUser = user
      this.taxPayerService.getTaxPayerByNiu('tax-payers', this.data['tin']).subscribe(comp => {
        if (comp && comp.type && comp.type.id) {
          this.categoryService.getCategory('category', comp.type.id).subscribe(res => {
            if (res.avis)
              this.avisService.getUnpaidAvises(this.data['tin']).subscribe(avises => {
                this.taxNames = []
                avises.forEach(elem => {
                  this.taxNames.push({
                    amount: elem.total,
                    name: this.convertDate(elem.submission_date) + ' ' + elem.total.toLocaleString() + 'XOF ' + (elem.processName || ''),
                    id: elem.id,
                    avis: true,
                    reference_number: elem.reference_number
                  })
                })
              })
            else {
              this.taxNameService.getTaxNamesForTaxpayer('tax-name', this.data).pipe(
                map(val => val.map(item => ({ amount: item.amount, name: item.name, id: item.id, revenueCode: item.revenueCode.length > 0 ? item.revenueCode[0] : null })))).subscribe((taxNames) => {
                  this.taxNames = taxNames;
                });
            }
          })
        }
      })
    })
  }

  convertDate = (dateStr) => {
    if (isNaN(Date.parse(dateStr))) {
      return ''
    }
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  selectChangeTaxNames = (event: any) => {
    this.cashPayment['taxName'] = event.data[0];
  };

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option === value;
    return false;
  }
}
