const AdminMenu =  [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/forecast", 'ADMIN-TOP-MENU.FORECAST'],
    ["/performance", 'ADMIN-TOP-MENU.PERFORMANCE'],
    ["/approval", 'ADMIN-TOP-MENU.APPROVAL'],
    //["/declarations", 'USER-TOP-MENU.DECLARATIONS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    // ["/formcreation_foradmin525", 'USER-TOP-MENU.FORM_CREATOR'],
    // ["/user_management", 'ADMIN-TOP-MENU.USER_MANAGEMENT'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/configuration", 'ADMIN-TOP-MENU.CONFIGURATION']
    // ["/admin", 'ADMIN-TOP-MENU.ADMIN']
  
];

const MayorTreasurerMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/forecast", 'ADMIN-TOP-MENU.FORECAST'],
    ["/performance", 'ADMIN-TOP-MENU.PERFORMANCE'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];

const CentralReaderMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/forecast", 'ADMIN-TOP-MENU.FORECAST'],
    ["/performance", 'ADMIN-TOP-MENU.PERFORMANCE'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];

const FinancialManagerAdministratorMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/forecast", 'ADMIN-TOP-MENU.FORECAST'],
    ["/performance", 'ADMIN-TOP-MENU.PERFORMANCE'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];
const CentralEditorMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS'],
    ["/forecast", 'ADMIN-TOP-MENU.FORECAST'],
    ["/performance", 'ADMIN-TOP-MENU.PERFORMANCE'],
    ["/approval", 'ADMIN-TOP-MENU.APPROVAL'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP'],
    ["/help", 'HELP_PAGE.TITLE']
];
const SecretaryAssistantMenu = [
    ["/dashboard", 'ADMIN-TOP-MENU.DASHBOARD', false],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS', true],
    ["/payment_report", 'USER-TOP-MENU.PAYMENT_REPORTS', false],
    ["/forecast", 'ADMIN-TOP-MENU.FORECAST', false],
    ["/performance", 'ADMIN-TOP-MENU.PERFORMANCE', false],
    ["/registrations_map", 'ADMIN-TOP-MENU.REGISTRATIONS_MAP', false],
    ["/help", 'HELP_PAGE.TITLE', false]
];

const MenuCategories = new Map<string, (string|boolean)[][]>([
    ["ADMIN", AdminMenu],
    ["MAYOR_TREASURER", MayorTreasurerMenu],
    ["CENTRAL_READER", CentralReaderMenu],
    ["FINANCIAL_MANAGER_ADMINISTRATOR", FinancialManagerAdministratorMenu],
    ["CENTRAL_EDITOR", CentralEditorMenu],
    ["SECRETARY_ASSISTANT", SecretaryAssistantMenu],
 
]);

export { MenuCategories };
