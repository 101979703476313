import { Component, Input, OnInit } from "@angular/core";
import { KeyGenerator } from 'src/app/util/keyGenerator';
@Component({
  selector: "rdf-form-viewer",
  templateUrl: "./form-viewer.component.html",
  styleUrls: ["./form-viewer.component.css"],
})
export class FormViewerComponent implements OnInit {
  @Input() stage;
  blockName;
  keyGenerator: KeyGenerator;
  constructor() {}

  ngOnInit() {
    this.keyGenerator = new KeyGenerator();
  }
  addBlock() {
    if (!this.stage.blocks) this.stage.blocks = [];
    this.stage.blocks.push({ name: this.blockName, key:this.keyGenerator.generateKey(this.stage,'block') });
    this.blockName = undefined;
  }
  
}
