import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-form-editor-configuration",
  templateUrl: "./form-editor-configuration.component.html",
  styleUrls: ["./form-editor-configuration.component.css"],
})
export class FormEditorConfigurationComponent {
  constructor(private router: Router) { }

  onCardClick(card) {
    this.router.navigate([`/${card}`]);
  }
}
