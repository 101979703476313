import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from './sharedService';


@Injectable()
export class AuthService {
  private username$ = new BehaviorSubject<string>(undefined);
  private userRole$ = new BehaviorSubject<string>(undefined);
  accessToken: string
  constructor(private http: HttpClient, private sharedService: SharedService, private decoder: JwtHelperService) {  this.accessToken = sharedService.getAccessToken() }
 
  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{ token: string }>(this.sharedService.apiUrlLogin('api/auth'), { username: username, password: password }, {headers:{'Content-Type': 'application/json'}})
      .pipe(
        map(result => {
          if (result['statusCode'] === 200) {
            localStorage.setItem(this.accessToken, result.token);
            var decodedToken = this.getDecodedToken()
            this.setUsername(username)
            this.setUserRole$(decodedToken['role'])
            return true;
          }
        }), catchError(
          
          (err) => throwError(err)
          
          )
      );
  }



  logout() {
    localStorage.removeItem(this.accessToken)
    localStorage.removeItem('username')
    localStorage.removeItem('userrole')
    this.setLoginStatus(false)
  }


  @Output() loggedIn: EventEmitter<boolean> = new EventEmitter();
  setLoginStatus(obj) {
    this.loggedIn.emit(obj)
  }

  setUsername(obj) {
    this.username$.next(obj);
  }

  setUserRole$(obj) {
    this.userRole$.next(obj);
  }

  getUsername$(): Observable<any> {
    return this.username$.asObservable();
  }

  getUserRole$(): Observable<any> {
    return this.userRole$.asObservable();
  }

  getDecodedToken(){
    return this.decoder.decodeToken(this.decoder.tokenGetter())
  }

  getUserByVerCode(verCode): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('auth/user/' + verCode)).pipe(
      map(res => {
        return res;
      })
    )
  }
  sendResetLink(email): Observable<boolean> {
    return this.http.post<{ token: string }>(this.sharedService.apiUrl('auth/reset'), { email: email }, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        map(result => {
          return true;
        }), catchError(
          (err) => throwError(err)
        )
      );
  }

  updatePassword(resource: any): Observable<any> {
      return this.http.put<any>(this.sharedService.apiUrl('auth/user/password'), 
      JSON.stringify(resource), { headers: { 'Content-Type': 'application/json' }}).pipe(
        map(result => {
          return result;
        }), catchError(
          (err) => throwError(err)
        )
      );
  }

  verifyPasswordValidity(ptv): Observable<any> {
    return this.http.put<any>(this.sharedService.apiUrl('auth/user/verify-password'), 
    JSON.stringify({ptv}), { headers: { 'Content-Type': 'application/json' }}).pipe(
      map(result => {
        return result;
      }), catchError(
        (err) => throwError(err)
      )
    );
  
}


}
