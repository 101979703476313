import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { MatPaginator, MatSort, MatDialog } from "@angular/material";
import { tap } from "rxjs/operators";
import { merge } from "rxjs";
import { UserStatusName } from "src/app/entities/util";
import { TasDataSource } from "src/app/services/tasGovTable.datasource";
import { UserService } from "src/app/services/user.service";
import { UserDialogComponent } from "../dialogs/user-dialog/user-dialog.component";
import { SharedService } from "src/app/services/sharedService";
import { UserStatus } from "src/app/entities/states/userStatus";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "tas-gov-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptionUpdateUser: any;
  subscriptionDeactivateUser: any;
  subscriptionActivateUser: any;
  filterObject: {};
  displayedColumns: string[] = [
    "username",
    "name",
    "surname",
    "role",
    "email",
    "status",
  ];
  dataSource: TasDataSource;
  statusNames = UserStatusName;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public spinner:NgxSpinnerService
  ) {}

  ngOnInit() {
    this.dataSource = new TasDataSource(this.userService);
    this.subscriptionUpdateUser =
      this.sharedService.updateUserEmmitter.subscribe((val) => {
        if (val) {
          this.userService.updateResource("user", val).subscribe((res) => {
            this.loadUserTable();
          });
        }
      });
    this.subscriptionDeactivateUser =
      this.sharedService.deactivateUserEmmitter.subscribe((val) => {
        if (val) {
          val.status = UserStatus.SUSPENDED;
          this.userService.deactivateResource("user", val).subscribe((res) => {
            this.loadUserTable();
          });
        }
      });

    this.subscriptionActivateUser =
      this.sharedService.activateUserEmmitter.subscribe((val) => {
        if (val) {
          this.userService.activateResource("user", val).subscribe((res) => {
            this.loadUserTable();
          });
        }
      });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadUserTable()))
      .subscribe();
  }

  loadUserTable() {
    this.spinner.show()
    this.dataSource.loadData(
      'user',
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject, 
      this.spinner
    );
  }

  openUserDialog(user): void {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: "600px",
      panelClass: "custom-dialog-container",
      data: user,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateUser) this.subscriptionUpdateUser.unsubscribe();
    if (this.subscriptionDeactivateUser)
      this.subscriptionDeactivateUser.unsubscribe();
    if (this.subscriptionActivateUser)
      this.subscriptionActivateUser.unsubscribe();
  }

  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  advancedSearch(filter) {
    if (!this.filterObject){
      this.filterObject = {}
    }
    let advF = [
      "name",
      "surname"
    ];
    advF.forEach((elem) => {
      if (!filter[elem] || filter[elem].trim().length===0) {
        delete filter[elem];
      }
    });
    this.filterObject = Object.assign({}, filter);
    this.spinner.show();
    this.dataSource.loadData(
      "user",
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      (Object.keys(this.filterObject).length === 0 ? null : this.filterObject),
      this.spinner
    );
  }

  reset() {
    this.filterObject = {};
    this.advancedSearch({});
  }
}
