import { Component, OnInit } from '@angular/core';
import { RevenueCodeTableComponent } from './revenue-code-table/revenue-code-table.component';

@Component({
  selector: 'app-revenue-code-dashboard',
  templateUrl: './revenue-code-dashboard.component.html',
  styleUrls: ['./revenue-code-dashboard.component.css']
})
export class RevenueCodeDashboardComponent implements OnInit {
  revenueCodeTableComponent = RevenueCodeTableComponent;

  constructor() {
  }

  ngOnInit() {


  }

}
