import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TaxPayerStatusName } from 'src/app/entities/util';
import { SharedService } from 'src/app/services/sharedService';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { UserService } from 'src/app/services/user.service';
import { TaxPayerDialogComponent } from '../dialogs/tax-payer-dialog/tax-payer-dialog.component';
import { CompanyDexieService } from 'src/app/services/companyDexie-services/companyDexie.service';
import { TaxNameService } from 'src/app/services/taxName.service';
import { CategoryService } from 'src/app/services/category.service';
import { SynchronizeService } from 'src/app/services/synchronize.service';

@Component({
  selector: 'tas-tax-payer',
  templateUrl: './tax-payers.component.html',
  styleUrls: ['./tax-payers.component.css']
})
export class TaxPayersComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dec_key;
  @Input() dec_year;
  @Input() status;

  subscriptionAddTaxpayer: any;
  subscriptionUpdateUser: any;
  subscriptionDeactivateUser: any;
  subscriptionActivateUser: any;

  displayedColumns: string[] = ['tin', 'name', 'balance', 'extraBalance', 'finalBalance', 'telephone'];
  dataSource: TasDataSource;
  statusNames = TaxPayerStatusName
  filterObject: {};
  user: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  taxPayersEndpoint = 'tax-payers';
  municipalityChangeSubscription: any
  loading = { inProgress: false, downloadInProgress: false }
  constructor(private taxPayerService: TaxPayerService, public dialog: MatDialog,
    public taxNameService: TaxNameService,
    public sharedService: SharedService, public router: Router,
    public userService: UserService, public translate: TranslateService,
    public companyDexieService: CompanyDexieService,
    public synchronizeService: SynchronizeService,
    public categoryService: CategoryService,
    public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.taxPayerService, this.companyDexieService)

    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (!this.filterObject) {
        this.filterObject = {}
      }
      this.reset()
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') == "-1") {
          delete this.filterObject['municipality']
        } else {
          this.filterObject['municipality'] = [localStorage.getItem('municipality')]
        }
      } else {
        delete this.filterObject['municipality']
      }
      this.loadTaxPayerData()
    })
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      this.subscriptionAddTaxpayer = this.sharedService.addTaxPayerEmmitter.subscribe(val => {
        this.loadTaxPayerData();
      })
      this.subscriptionDeactivateUser = this.sharedService.deactivateUserEmmitter.subscribe(val => {
        if (val) {
          this.taxPayerService.deactivateResource(this.taxPayersEndpoint, val).subscribe(res => {
            this.loadTaxPayerData();
          })
        }
      })

      this.subscriptionActivateUser = this.sharedService.activateUserEmmitter.subscribe(val => {
        if (val) {
          this.taxPayerService.updateResource('tax-payers', val).subscribe(res => {
            this.loadTaxPayerData();
          })
        }
      })
    })
  }

  canAddTaxpayer() {
    if (this.user) {
      return !['MAYOR_TREASURER', 'CENTRAL_READER', 'SECRETARY_ASSISTANT'].includes(this.user.role)
    }

    return false
  }

  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };

  filter: any;
  ngAfterViewInit() {

    setTimeout(() => {
      if (this.dec_key && this.dec_year && this.status) {
        this.filterObject['key'] = this.dec_key
        this.filterObject['year'] = this.dec_year
        this.filterObject['status'] = this.status
      }
      if (!this.filterObject) {
        this.filterObject = {}
      }

    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);


    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTaxPayerData())
      )
      .subscribe();

  }

  loadTaxPayerData() {
    this.dataSource.emptyData()
    this.loading.inProgress = true
    this.dataSource.loadData('tax-payers',
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject, this.loading);
  }

  openTaxPayerDialog(taxPayer): void {
    const dialogRef = this.dialog.open(TaxPayerDialogComponent, {
      width: '800px', panelClass: 'custom-dialog-container',
      data: taxPayer
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  goToTaxPayer(row) {
    this.router.navigate(['tx_declarations'], { queryParams: { tin: row.tin ? row.tin : row.username } });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateUser) this.subscriptionUpdateUser.unsubscribe();
    if (this.subscriptionDeactivateUser) this.subscriptionDeactivateUser.unsubscribe();
    if (this.subscriptionActivateUser) this.subscriptionActivateUser.unsubscribe();
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }

  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }


  shouldDisplayValue(value) {
    return value !== 0;
  }

  objectFilter = {};

  // async fetchDataForDexie() {
  //   let payments = await this.companyDexieService.getAllPayments()
  //   for (let payment of payments){
  //     let res = await this.synchronizeService.remoteSynchronize(payment).toPromise()
  //   }
  //   await this.synchronizeService.localSynchronize()    
  // }

  advancedSearch(filter) {
    if (!this.filterObject) {
      this.filterObject = {}
    }
    let advF = [
      'balanceType',
      'municipality',
      'name',
      'revenueCode',
      'tin',
      'type',
      'telephone'
    ];
    advF.forEach((elem) => {
      if (!filter[elem] || filter[elem].length === 0) delete this.filterObject[elem];
    });
    this.filterObject = Object.assign({}, this.filterObject, filter);
    this.dataSource.emptyData()
    this.loading.inProgress = true
    this.dataSource.loadData(
      "tax-payers",
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject,
      this.loading
    );
  }
  reset() {
    this.filterObject = {};
  }
  downloadReport() {
    let headers = [
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_NIU"), key: 'tin' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_NAME"), key: 'name' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_BALANCE"), key: 'balance' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_EXTRA_BALANCE"), key: 'extraBalance' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_FINAL_BALANCE"), key: 'finalBalance' },
      { header: this.translate.instant("TAX-PAYER.TABLE_COLUMN_PHONE_NUMBER"), key: 'telephone' }
    ]
    this.loading.downloadInProgress = true
    this.taxPayerService.downloadReport(this.filterObject, headers).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }
}

