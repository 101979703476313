import {Component, Input} from "@angular/core";
import {MapService} from './map.service';

@Component({
  selector: "map-preview",
  template: `
    <div style="margin: 15px 0px;">
        <div>
          Current Location LatLon: {{locationLatLng}}
        </div>
        <div>
            Selected Building Id: {{selectedBuildingDetails | json}}
        </div>
        <div>
            Selected Feature geoJSON: {{geoJSON | json}}
        </div>
        <label>BBOX</label>
        <input [(ngModel)]="bbox" style="width: 550px">
        <br/>
        search by ID
        <input [(ngModel)]="buildingId_" style="width: 550px">
        <button (click)="searchById()">find</button>
        <div>
            <input [(ngModel)]="streetId" style="width: 150px">
            <input [(ngModel)]="houseNumber" style="width: 50px">
            <button (click)="addMarker()">Add Marker</button>
        </div>
        <br/>
        <app-map *ngIf="!buildingId" [height]="'70vh'" [registered]="true" [findMyLocation]="true" [bbox]="bbox" (selectedBuildingDetails)="selectedBuildingDetails = $event"
                 (myLocationLatLng)="locationLatLng =$event" [addMarker]="true" (selectedFeatureGeoJSON)="geoJSON = $event"></app-map>
        <app-map *ngIf="buildingId" [height]="'70vh'" [registered]="true" [addMarker]="true" [findMyLocation]="true" [bbox]="bbox" (selectedBuildingDetails)="selectedBuildingDetails = $event"
                 (myLocationLatLng)="locationLatLng =$event" [buildingId]="buildingId" (selectedFeatureGeoJSON)="geoJSON = $event"></app-map>
    </div>
  `
})
export class MapPreviewComponent {
  buildingId_
  buildingId
  selectedBuildingDetails
  bbox
  locationLatLng
  geoJSON

  streetId
  houseNumber  = "33a"
  addedFeatureId

  constructor(private mapService: MapService) {
  }

  searchById() {
    this.buildingId = null
    setTimeout(()=>{
      this.buildingId = this.buildingId_
    }, 100)
  }

  addMarker(){
    let body = {
      streetId: +this.streetId,
      houseNumber: this.houseNumber,
      geoJson: this.geoJSON
    }
    if (!this.addedFeatureId)
      this.mapService.addBuilding$(body).subscribe( building => {
        alert('Added building ' + building.id)
        this.addedFeatureId = building.id
        console.log(body, building)
      })
    else
      this.mapService.updateBuilding$(this.addedFeatureId, body).subscribe( building => {
        alert('Updated building ' + building.id)
        this.addedFeatureId = building.id
        console.log(body, building)
      })

  }
}
