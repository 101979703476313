import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from './sharedService';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
 
export class AdminService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }

  getAdminHomepage(): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('admin/homepage')).pipe(
      map(res => {
        return res;
      })
    );
  }

  downloadManualFile(name: any): Observable<Blob> {
    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('file', name)
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('admin/manual'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download file'))
    )
    return process$;

  }

  deleteManualFile (): Observable<Blob> {
    const httpOptions = {
       
    };
    let deleteFile$ = this.http.delete<any>(this.sharedService.apiUrl('admin/manual'), httpOptions)
    return deleteFile$;

  }


 


}



