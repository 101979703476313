import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/services/sharedService";
import { ForecastTableComponent } from './forecast-table/forecast-table.component';
  @Component({
    selector: "app-forecast",
    templateUrl: "./forecast.component.html",
    styleUrls: ["./forecast.component.css"],
  })
  export class ForecastComponent implements OnInit {
    forecastTableComponent = ForecastTableComponent;
    startYear
    endYear
    year
    municipality
    data
    constructor(public sharedService: SharedService,  private activatedRoute: ActivatedRoute, ) {
    }
    async ngOnInit() {
      this.activatedRoute.queryParams.subscribe(params => {
        this.year = params['year'];
        this.municipality = params['municipality']
        this.data = { currentYear: this.year, municipality: this.municipality }
      });
      let fpConfig = await this.sharedService.getForecastConfig().toPromise()
      this.startYear = fpConfig.startYear
      this.endYear =  new Date().getFullYear() + (new Date().getMonth()>(fpConfig.changeMonth-2)?1:0)
    }

 
}