import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '../form-creator/column';


@Component({
  selector: 'rdf-formcreation-column-util',
  templateUrl: './column-util.component.html',
  styleUrls: ['./column-util.component.css']
})
export class ColumnUtilComponent implements OnInit {
  @Output() onColumnCreate = new EventEmitter<any>();
  @Output() onColumnCancel = new EventEmitter<any>();
  @Output() onColumnUpdate = new EventEmitter<any>();
  @Output() onColumnDelete = new EventEmitter<any>();
  columnBold = false;
  @Input() column: Column;
  currentColumnKey: any
  @Input() editingState = false;
  columnTypes = ["string", "number", "date", "static text", "checkbox", "option"]
  constructor() { }

  ngOnInit() {
    if (this.column === undefined)
      this.column = new Column();
    this.currentColumnKey = this.column.key
  }


  addColumn() {
    this.column.key = this.currentColumnKey;
    this.onColumnCreate.emit(this.column)
  }

  updateColumn(){
    this.column.key = this.currentColumnKey;
    this.onColumnUpdate.emit({ column: this.column })
  }

  deleteColumn(){
    this.onColumnDelete.emit({ column: this.column })
  }

  cancelEditing(){
    this.onColumnCancel.emit()
  }

}
