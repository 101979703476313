import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-sms-section-editor",
  templateUrl: "./sms-section-editor.component.html",
  styleUrls: ["./sms-section-editor.component.css"],
})
export class SmsSectionEditorComponent {
  @Input() type;
  @Input() data;
  replacedSms = ''
  @Output() onSave = new EventEmitter<any>();
  maxLines = 3;
  constructor() {}

  saveSmsConfig() {
    let smsConfig = {};
    this.data.data.sms = this.data.data.sms.replace(/\s{2,}/g, ' ').trim()
    smsConfig[this.type] = {
      sms: this.data.data.sms,
      enabled: this.data.data.enabled,
    };
    this.onSave.emit(smsConfig);
  }

  appendText(text) {
    if (this.data && this.data.data.sms) {
      if (this.data.data.sms.includes(text)){
        this.data.data.sms = this.data.data.sms.replace(text,'')
      }else
      this.data.data.sms += " " + text;
    } else {
      this.data.data.sms = text;
    }
    this.data.data.sms = this.data.data.sms.replace(/\s{2,}/g, ' ').trim()
  }
  replaceSms() {
    this.replacedSms=''
    if (this.data && this.data.data && this.data.data.sms)
      this.replacedSms = this.data.data.sms
        .replaceAll("$MUNICIPALITY", "AFFERY")
        .replaceAll("$PROCESS_NAME", "Taxe communale de l'entreprenant (TCE)")
        .replaceAll("$REFERENCE_NUMBER", "0000522624")
        .replaceAll("$AMOUNT", "5000000")
        .replaceAll("$CURRENCY", "XOF")
        .replaceAll(
          "$TAS_URL_CODE",
          " https://ivory-coast-tas.revenuedev.org/pr/zG8lsWpjnamPChUMDsWQ "
        ).replace(/\s{2,}/g, ' ').trim();

    return this.replacedSms;
  }
  amountOfSMSs(len) {
    return Math.ceil(len / 160);
  }
}
