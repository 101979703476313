import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/services/dropdown.service';
import { MultiselectAutocompleteComponent } from '../../multiselect-autocomplete/multiselect-autocomplete.component';
import { CategoryService } from 'src/app/services/category.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'rdf-form-type-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.css']
})
export class FormFilterComponent implements OnInit, OnDestroy {
  panelOpenState = true
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('categorySelect', { static: false }) catSel: MultiselectAutocompleteComponent;
  municipalities: any[]
  categories: any[]
  searchName
  searchYear
  searchRevenueCode
  searchMunicipalities
  searchCategories
  @Output() onSearch = new EventEmitter<any>()
  municipalityChangeSubscription: any
  constructor(public dropdownService: DropdownService, public categoryService: CategoryService, public sharedService: SharedService) { }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          this.municipalitySelect.setSelectedData(this.municipalities.filter(elem => elem.id === localStorage.getItem('municipality')))
        }
        else { 
          this.municipalitySelect.resetAll()
        }
      }else{
        this.municipalitySelect.resetAll()
      }
    })
    this.dropdownService.getList('municipality').subscribe((municipalities) => {
      this.municipalities = municipalities;
      setTimeout(()=>{
          if (localStorage.getItem('municipality')) {
            if (localStorage.getItem('municipality') !== '-1') {
              this.municipalitySelect.resetAll()
              this.municipalitySelect.setSelectedData(this.municipalities.filter(elem => elem.id === localStorage.getItem('municipality')))
            }
            else { 
              this.municipalitySelect.resetAll()
            }
          }else{
            this.municipalitySelect.resetAll()
          }
      }, 1000)
    });
    this.categoryService.getList("category").subscribe((result) => {
      this.categories = result.map(elem => { return { name: elem.name, id: elem.id, tinCode: elem.tinCode, taxPayerType: elem.taxPayerType } });;
    });
  }

  advancedSearch(){
    let selData = []
    selData = this.municipalitySelect.selectData.filter(el=> el.selected).map(el => el.item.id)
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        this.searchMunicipalities = null
      } else {
        if (selData.length>0)
          this.searchMunicipalities = selData
        else
        this.searchMunicipalities = [localStorage.getItem('municipality')]
      }
    } else {
      if (this.municipalitySelect.selectData.length === 0)
        this.searchMunicipalities = null
    }
    this.onSearch.emit({
      name:this.searchName, year:this.searchYear, revenueCode:this.searchRevenueCode, 
      municipality:this.searchMunicipalities && this.searchMunicipalities.length>0 ? this.searchMunicipalities:null,
      category:this.searchCategories && this.searchCategories.length>0 ? this.searchCategories:null
    })
  }
  resetFilter(){
    this.searchName = null
    this.searchYear = null
    this.searchRevenueCode = null
    this.searchMunicipalities = null
    this.searchCategories = null
    this.municipalitySelect.resetAll()
    this.catSel.resetAll()
    this.onSearch.emit(null)
  }

  selectChange = (event: any, field) => {
    switch (field) {
      case 'MUNICIPALITY':
        this.searchMunicipalities = event.data.map(el => el.id)
        break;
      case 'CATEGORY':
        this.searchCategories = event.data.map(el => el.id)
        break;
      default:
        break;
    }
  };
 
}
