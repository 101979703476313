import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { switchMap } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { Process } from '../../../entities/process';
import { ProcessService } from '../../../services/process.service';
@Component({
  selector: 'app-process-view',
  templateUrl: './process-view.component.html',
  styleUrls: ['./process-view.component.css']
})
export class ProcessViewComponent implements OnInit {
  hasMapping = false;
  currentProcessId: number;
  // stages coming from the process structure
  stages = [];
  process: Process;
  missingStages = []
 
  fileName: string;
  fileNamePDF: string;
  excelType: any
  currentYear = new Date().getFullYear().toString()

  
  constructor(public processService: ProcessService, public reportService: ReportService, private router: Router, private route: ActivatedRoute,
    private spinner: NgxSpinnerService) {

  }

   
  ngOnInit() {
    this.process = new Process();
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      return this.processService.getProcess(params.get('id'))
    }))
      .subscribe(process => {

        this.processService.getProcessMapping(process['key']).subscribe(result=>{
          this.hasMapping=(result!==null);
          this.populatePages(process)
        })


      }, err => { console.log(err) });
  }

  populatePages(process) {
    this.process = process;
    this.currentProcessId = this.process.id;
    this.reportService.getPages(process['key'])
      .subscribe(
        data => {
          this.stages = data.stages;
          var stgs = this.stages.filter(elem => elem.required === true).map(a => a.key);
          var x = this.process['stages'].map(a => a.key);

        },
        err => {
          console.log(err);
        }
      );
  }
  downloadGeneratedFile(type){
    this.spinner.show();
    this.processService.downloadGeneratedFile(this.process.id,type).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }


  list() {
    this.router.navigate(['home']);
  }

  downloadPdf(name) {
    this.spinner.show();
    this.processService.downloadPDF(name, this.process.id).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }

  downloadExcel(name) {
    this.spinner.show();
    this.processService.downloadExcel(name, this.process.id, this.process.year).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }

  downloadFileFromDocFlow(name, ext) {
    this.spinner.show();
    this.processService.downloadFileFromDocflow(name).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.' + ext;
        saveAs(file, filename);
      });
  }

  getColorByStatus(stageKey) {
    var stageInQuestion = this.process['stages'].find(element => element.key === stageKey)
    if (stageInQuestion === undefined) {
      return '#e3e8e5';
    }
    else {
      if (stageInQuestion.status === 'processing') {
        return '#757ce8'; //violet
      }
      return '#18a028';
    }
  }

  getTextColorByStatus(stageKey) {
    var stageInQuestion = this.process['stages'].find(element => element.key === stageKey)
    if (stageInQuestion === undefined) {
      return '#000501';
    }
    else {
      return '#f7fbf8';
    }

  }

}

 