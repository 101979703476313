import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SharedService } from './sharedService';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class ProcessTypeService extends BaseService{
    deleteFile (prcId,file): Observable<Blob> {
        const httpOptions = {
          params: new HttpParams().set('id', prcId).set('file', file)
        };
        let deleteFile$ = this.http.delete<any>(this.sharedService.apiUrl('process-types/file'), httpOptions)
        return deleteFile$;
    
      }

    constructor(public http: HttpClient, public sharedService: SharedService) { 
        super(http, sharedService);
    }


    getProcessTypes(year) {
        let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('process-types/'+year))
        return processTypes$;
    }
    getAllProcessTypes() {
        let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('process-types/prt'))
        return processTypes$;
    }

    getProcessType(id) {
        let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('process-types/'+id))
        return processTypes$;
    }

    
}
