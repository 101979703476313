import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartConfiguration, ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-pie-chart-native',
  templateUrl: './pie-chart-native.component.html',
  styleUrls: ['./pie-chart-native.component.css']
})
export class PieChartNativeComponent implements OnInit {

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
      },
    }
  };
  @Input() pieChartLabels: Label[]
  public pieChartType: ChartType = 'pie'
  public pieChartLegend = true
  public pieChartPlugins = []
  @Input() pieChartData: ChartDataSets[]
  @Input() title = ''
  @Output() onSelectedValue = new EventEmitter<any>()
  @Input() cdiFilter = false
  @Input() yearFilter = false
  @Input() dsfFilter = false
  @Input() yearsFrom = false
  currentTaxDivision
  currentYear
  emitValue = {
    cdi: '0',
    year: (new Date()).getFullYear(),
    dsf: '0'
  }
  constructor() { }

  ngOnInit() {
  }

  selectonChanged(value){
    this.emitValue.cdi = value
    this.onSelectedValue.emit(this.emitValue)
  }
  yearSelectonChanged(value){
    this.emitValue.year = value
    this.onSelectedValue.emit(this.emitValue)
  }

  dsfSelectonChanged(value){
    this.emitValue.dsf = value
    this.onSelectedValue.emit(this.emitValue)
  }

}
