import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { ReportService } from "src/app/services/report.service";
import { SharedService } from "src/app/services/sharedService";
import { UserService } from "src/app/services/user.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";

@Component({
  selector: "app-sms-logs-search",
  templateUrl: "./search-sms-logs.component.html",
  styleUrls: ["./search-sms-logs.component.css"],
})
export class SearchSmsLogsComponent implements OnInit, OnDestroy {
  userroles = [ 'ADMIN', 'MAYOR_TREASURER', 'CENTRAL_READER','FINANCIAL_MANAGER_ADMINISTRATOR',
    'CENTRAL_EDITOR', 'SECRETARY_ASSISTANT'
  ];
  @ViewChild('userSelect', { static: false }) userSelect: MultiselectAutocompleteComponent;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('smsTypeSelect', { static: false }) smsTypeSelect: MultiselectAutocompleteComponent;
  @ViewChild('smsStatus', { static: false }) smsStatus: MultiselectAutocompleteComponent;
  @ViewChild('recurringSelect', { static: false }) recurringSelect: MultiselectAutocompleteComponent;
  filterData = {
    category: null,
    municipality: null,
    recurring: null,
    users: null,
    smsType: null,
    status: null
  };
  today = new Date()
  minDateFrom
  maxDateFrom = new Date()
  minDateTo
  maxDateTo = new Date()
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields : any;
  queryFilter = {};
  filterFields = ["municipality", "user"];
  panelOpenState = true;
  municipalityChangeSubscription: any
  constructor(public categoryService: CategoryService, public entityService: EntityService, private translate: TranslateService,
    public reportService: ReportService, public userService: UserService, public sharedService: SharedService
  ) {
    this.filterData['smsType'] = [{id:'avis', name:this.translate.instant("SMS-LOG.AVIS")},{id:'payment', name:this.translate.instant("SMS-LOG.PAYMENT")}]
    this.filterData['status'] = [{id:'SENT', name:this.translate.instant("SMS-LOG.STATUS_SENT")},{id:'PENDING', name:this.translate.instant("SMS-LOG.STATUS_PENDING")}]
    
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
            if (field === 'municipality'){
              setTimeout(()=>{
                if (localStorage.getItem('municipality')) {
                  if (localStorage.getItem('municipality') !== '-1') {
                    this.municipalitySelect.resetAll()
                    this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
                  }
                  else { 
                    this.municipalitySelect.resetAll()
                  }
                }else{
                  this.municipalitySelect.resetAll()
                }
              }, 1000)
            }
          }
        });
    });
    reportService.getReportsList("?active=true&recurring=true").subscribe(rep => {
      if (rep && rep.reports){
        this.filterData.recurring = rep.reports.map(elem => ({id:elem._id, name: elem.name + ' ' + elem.year}))
      }
    })
    this.userService.getUsernames().subscribe(val => {
      this.filterData.users = val.map(user => ({ id: user.username.trim(), name: user.username }))
      .sort((a, b) => a.name.localeCompare(b.name));
    })
  }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length===0){
      delete this.queryFilter[field]
    }
  };
  resetFilter() {
    this.queryFilter = {}
    this.userSelect.resetAll()
    this.smsTypeSelect.resetAll()
    this.smsStatus.resetAll()
    this.municipalitySelect.resetAll()

    this.onReset.emit()
    
  }
  advancedSearch() {
    let selData = []
    selData = this.municipalitySelect.selectData.filter(el=> el.selected).map(el => el.item.id)
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.queryFilter['municipality']
      } else {
        if (selData.length>0)
          this.queryFilter['municipality'] = selData
        else
        this.queryFilter['municipality'] = [localStorage.getItem('municipality')]
      }
    } else {
      if (this.municipalitySelect.selectData.length === 0)
        delete this.queryFilter['municipality']
    }
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }

  ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
        }
        else { 
          this.municipalitySelect.resetAll()
        }
      }else{
        this.municipalitySelect.resetAll()
      }
    })
  }
}
