import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { RevenueCodeService } from 'src/app/services/revenueCode.service';
import { RevenueCode } from 'src/app/entities/revenueCode';
import { DialogCommunicator } from '../dialog-communicator';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'gov-revenue-code-dialog',
  templateUrl: './revenue-code-dialog.component.html',
  styleUrls: ['./revenue-code-dialog.component.css']
})
export class RevenueCodeDialogComponent extends DialogCommunicator implements OnInit {

  text: string;
  emitter: EventEmitter;
  revenueCode = {}

  constructor(
    public dialogRef: MatDialogRef<RevenueCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService, 
    public revenueCodeService: RevenueCodeService, public confirmationDialog: MatDialog, public dropdownService: DropdownService) {
    super(dialogRef, sharedService)
    this.setConfirmationDialog(this.confirmationDialog)
  }

  ngOnInit() {
    this.revenueCode = new RevenueCode()
          if (this.data) {
        this.revenueCodeService.getResource('revenue-code', this.data['id'] || this.data['_id']).subscribe(val => {
          this.revenueCode = val
          this.revenueCode['class'] = 'REVENUE-CODE'
          this.setEntity(this.revenueCode);
        }
        )
      } else {
        this.setEntity(this.revenueCode);
      }
  }

}
