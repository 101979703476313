import { UserStatus } from './states/userStatus';
import { CompanyRepresentativeStatus } from './states/companyRepresentativeStatus';
import { Role } from './role';
import { TaxPayerStatus } from './states/taxPayerStatus';

const UserStatusName = new Map<number, string>([
  [UserStatus.ACTIVE, 'ACTIVE'],
  [UserStatus.SUSPENDED, 'SUSPENDED']
]);

const CompanyRepresentativesStatusName = new Map<number, string>([
  [CompanyRepresentativeStatus.REQUESTED, 'REQUESTED'],
  [CompanyRepresentativeStatus.ACTIVE, 'ACTIVE'],
  [CompanyRepresentativeStatus.DECLINED, 'DECLINED'],
  [CompanyRepresentativeStatus.SUSPENDED, 'SUSPENDED']
]);

const RoleName = new Map<number, string>([
  [Role.ADMIN, 'ADMIN'],
  [Role.MAYOR_TREASURER, 'MAYOR TREASURER'],
  [Role.CENTRAL_READER, 'CENTRAL READER'],
  [Role.FINANCIAL_MANAGER_ADMINISTRATOR, 'FINANCIAL MANAGER ADMINISTRATOR'],
  [Role.CENTRAL_EDITOR, 'CENTRAL EDITOR'],
  [Role.SECRETARY_ASSISTANT, 'SECRETARY ASSISTANT'],
]);

const TaxPayerStatusName = new Map<number, string>([
  [TaxPayerStatus.ACTIVE, 'ACTIVE'],
  [TaxPayerStatus.SUSPENDED, 'SUSPENDED']
]);

export { UserStatusName, RoleName, TaxPayerStatusName, CompanyRepresentativesStatusName }
