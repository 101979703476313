import { Injectable, Optional, Inject } from '@angular/core';
import { NativeDateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class YearDateAdapter extends NativeDateAdapter {
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform
  ) {
    super(matDateLocale, platform);
  }

  parse(value: any): Date | null {
    const year = Number(value);
    return isNaN(year) ? null : new Date(year, 0, 1);
  }

  format(date: Date, displayFormat: Object): string {
    return date.getFullYear().toString();
  }
}
