import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../entities/role';
import { AuthService } from '../services/auth.service';
import { SharedService } from '../services/sharedService';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {


    constructor(private router: Router, public jwtHelper: JwtHelperService, public authService: AuthService, public sharedService: SharedService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var token = this.jwtHelper.tokenGetter()
        if (token && token !== 'undefined') {
            const decodedToken = this.jwtHelper.decodeToken(token)
            if (next.data.roles && next.data.roles.indexOf(Role[decodedToken.role]) === -1) {
                this.router.navigateByUrl('/error/403', { replaceUrl: true });
                return false;
            }
            else {
                if (this.jwtHelper.isTokenExpired()) {
                    this.authService.setLoginStatus(false)
                   this.router.navigate(['login']);
                    return false;
                } else {
                   
                    this.authService.setLoginStatus(true)
                    return true;
                }
            }

        }
    
        this.authService.setLoginStatus(false)
        if (next.queryParams['notifications'])
            this.router.navigate(['login'], { queryParams: { notifications: true } });
        else {
            this.router.navigate(['login']);
        }
        return false;
    }

}