  import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CategoryService } from 'src/app/services/category.service';
import { PaymentReportService } from 'src/app/services/paymentReport.service';
import { SharedService } from 'src/app/services/sharedService';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { UserService } from 'src/app/services/user.service';
import { MultiselectAutocompleteComponent } from '../../multiselect-autocomplete/multiselect-autocomplete.component';
  
  @Component({
    selector: 'app-wallet-payment-report',
    templateUrl: './wallet-payment-report.component.html',
    styleUrls: ['./wallet-payment-report.component.css']
  })
  export class WalletPaymentReportComponent implements OnInit, AfterViewInit, OnDestroy {
    municipalityChangeSubscription: any
    filterObject: {};
    displayedColumns: string[] = ['paymentDate', 'tin', 'companyName', 'paymentReference', 'municipality', 'paymentSystem', 'amount'];
    @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
    @ViewChild('userSelect', { static: false }) userSelect: MultiselectAutocompleteComponent;
    dataSource: TasDataSource;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    searchTin
    searchCompanyName
    searchDateFrom = new Date()
    searchDateTo = new Date()
    paymentTypes : []
    users: []
    user: any;
    panelOpenState = true
    filterData = {
      municipality: null
    };
    searchPaymentUser: any
    searchMunicipality: any
    searchPaymentType = ['wallet']
    filterFields = ["municipality"];
    loading = { inProgress : false, error: false, downloadInProgress: false  }
    constructor(private paymentReportService: PaymentReportService, public dialog: MatDialog, public sharedService: SharedService,
      public translate: TranslateService, public userService: UserService, public categoryService: CategoryService) {
        this.filterFields.forEach((field) => {
          this.categoryService
            .getList(field)
            .subscribe((result) => {
              if (result) {
                this.filterData[field] = result.map((fld) => ({
                  name: fld.name,
                  id: fld.id,
                }));
              }
              setTimeout(()=>{
                if (localStorage.getItem('municipality')) {
                  if (localStorage.getItem('municipality') !== '-1') {
                    this.municipalitySelect.resetAll()
                    this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
                  }
                  else { 
                    this.municipalitySelect.resetAll()
                  }
                }else{
                  this.municipalitySelect.resetAll()
                }
              }, 1000)
            });
        });
    }
    ngOnDestroy(): void {
      if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
    }
    ngOnInit() {
      this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
        if (localStorage.getItem('municipality')) {
          if (localStorage.getItem('municipality') !== '-1') {
            this.municipalitySelect.resetAll()
            this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
          }
          else { 
            this.municipalitySelect.resetAll()
          }
        }else{
          this.municipalitySelect.resetAll()
        }
      })
      this.searchDateFrom.setHours(0, 0, 0, 0)
      this.searchDateTo.setHours(23, 59, 59, 999)
      this.dataSource = new TasDataSource(this.paymentReportService)
      
      this.userService.getCurrentUser().subscribe(user => {
        this.user = user 
      })
      this.paymentReportService.getPaymentUsers().subscribe(val => {
        this.users = val.map(user => ({ id: user.username.trim(), name: (user.surname? user.surname+ ' ': '') + user.name }))
        .sort((a, b) => a.name.localeCompare(b.name));
      })
    }
  
    objectComparisonFunctionId = function (option, value): boolean {
      let sss = value ? option.key === value.key : false
      return sss;
    };
    getTotal() {
      return this.dataSource.getTotalAmount()
    }
    ngAfterViewInit() {
  
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadPaymentReportTable())).subscribe();
  
    }
    resetLoading = () => {
      this.loading.inProgress = true
      this.loading.error = false
      this.dataSource.emptyData()
    }
    loadPaymentReportTable() {
      this.resetLoading()
      this.dataSource.loadData('fees/payments',
        null,
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize, this.filterObject, this.loading);
    }
  
    showRegisteredBy() {
      if (this.user) {
        return !['SECRETARY_ASSISTANT'].includes(this.user.role)
      }
  
      return true
    }
  
    selectChange = (event: any, field) => {
      if (field === 'municipality'){
        this.searchMunicipality = event.data.map(elem => elem.id)
        if (this.searchMunicipality.length===0){
         this.searchMunicipality = null
       }
      }
      if (field === 'users'){
        this.searchPaymentUser = event.data.map(elem => elem.id)
        if (this.searchPaymentUser.length===0){
         this.searchPaymentUser = null
       }
      }
    };
  
    advancedSearch() {
      if (this.searchDateTo) this.searchDateTo.setHours(23, 59, 59, 999)
      this.filterObject = {}
      !this.isEmpty(this.searchTin) && (this.filterObject['tin'] = this.searchTin);
      !this.isEmpty(this.searchCompanyName) && (this.filterObject['searchCompanyName'] = this.searchCompanyName);
      !this.isEmpty(this.searchDateFrom) && (this.filterObject['searchDateFrom'] = this.searchDateFrom);
      !this.isEmpty(this.searchDateTo) && (this.filterObject['searchDateTo'] = this.searchDateTo);
      this.searchPaymentType && (this.filterObject['searchPaymentType'] = this.searchPaymentType);
      this.searchPaymentUser && (this.filterObject['searchPaymentUser'] = this.searchPaymentUser);
      this.searchMunicipality && (this.filterObject['searchMunicipality'] = this.searchMunicipality);
      let selData = []
      selData = this.municipalitySelect.selectData.filter(el=> el.selected).map(el => el.item.id)
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') == "-1") {
          delete this.filterObject['searchMunicipality']
        } else {
          if (selData.length>0)
            this.filterObject['searchMunicipality'] = selData
          else
          this.filterObject['searchMunicipality'] = [localStorage.getItem('municipality')]
        }
      } else {
        if (this.municipalitySelect.selectData.length === 0)
          delete this.filterObject['searchMunicipality']
      }
      this.resetLoading()
      this.dataSource.loadData('fees/payments', '', 'desc', 'paymentDate', 0, this.paginator.pageSize, this.filterObject, this.loading);
      this.panelOpenState = false
    }
  
    resetFilter() {
      this.filterObject = {}
      this.searchTin = null
      this.searchCompanyName = null
      this.searchDateFrom.setHours(0, 0, 0, 0)
      this.searchDateTo.setHours(23, 59, 59, 999)
      this.searchPaymentUser = null
      this.searchMunicipality = null
      this.municipalitySelect.resetAll()
      this.userSelect.resetAll()
      this.dataSource.emptyData()
    }
    isEmpty(val) {
      return !val
    }
    downloadReport() {
      let headers = [
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_DATE"), key: 'date' },
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_TIME"), key: 'time' },
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_TIN"), key: 'tin' },
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_COMPANY_NAME"), key: 'companyName' },
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_REFERENCE"), key: 'paymentReference' },
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_MUNICIPALITY"), key: 'municipality' },
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_SYSTEM"), key: 'paymentSystem' },
        { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_AMOUNT"), key: 'amount' }]
      this.loading.downloadInProgress = true
      this.paymentReportService.downloadReport(this.filterObject, headers).subscribe
        (data => {
          this.loading.downloadInProgress = false
          const blob = data;
          const file = new Blob([blob], {});
          const filename = 'document_' + Date.now() + '.xlsx';
          saveAs(file, filename);
        });
    }
  }
  