import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.css']
})
export class ColumnsComponent implements OnInit {
  @Input() columns
  constructor() { }

  ngOnInit() {
  }

}
