import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './services/sharedService';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'tas-gov-web';
  isLoggedIn = false;
  lang: string;
  cashPaymentPrintEmitter: any;
  receipt
  currentYear = (new Date()).getFullYear()
  constructor(public translate: TranslateService, private router: Router, public sharedService: SharedService, public authService: AuthService) {
    translate.setDefaultLang(environment.default_lang || 'fr');
    this.setLang(localStorage.getItem("default_lang") ? localStorage.getItem("default_lang") : (environment.default_lang || 'fr'))
    authService.loggedIn.subscribe(val => {
      this.isLoggedIn = val;
    })
  }
  ngOnInit() {
    this.authService.loggedIn.subscribe(val => {
      this.isLoggedIn = val;
    })
    this.cashPaymentPrintEmitter = this.sharedService.cashPaymentPrintEmitter.subscribe(val => {
      this.receipt = val
      setTimeout(() => {
        window.print()
      }, 500)
    })
  }

  setLang(lang) {
    this.sharedService.setLan(lang)
    this.lang = lang
    this.translate.use(lang);
  }

}
