import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { ReportService } from "src/app/services/report.service";

@Component({
  selector: "app-wallet-search",
  templateUrl: "./search-wallet.component.html",
  styleUrls: ["./search-wallet.component.css"],
})
export class SearchWalletComponent implements OnInit {

  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields: any;
  queryFilter = {};
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public reportService: ReportService
  ) {

  }

  resetFilter() {
    this.queryFilter = {}
    this.onReset.emit()
  }

  advancedSearch() {
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }

  ngOnInit() { }
}
