import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { ReportService } from "src/app/services/report.service";
import { YearService } from "src/app/services/year.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-statistics-search",
  templateUrl: "./search-statistics.component.html",
  styleUrls: ["./search-statistics.component.css"],
})
export class SearchStatisticsComponent implements OnInit {
  @ViewChild('categorySelect', { static: false }) categorySelect: MultiselectAutocompleteComponent;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('recurringSelect', { static: false }) recurringSelect: MultiselectAutocompleteComponent;
  @ViewChild('statusSelect', { static: false }) statusSelect: MultiselectAutocompleteComponent;
  @ViewChild('yearSelect', { static: false }) yearSelect: MultiselectAutocompleteComponent;


  statuses: any
  years: any
  filterData = {
    category: null,
    municipality: null,
    report: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields: any;
  queryFilter = {};
  filterFields = ["municipality"];
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public reportService: ReportService, public yearService: YearService, public translate: TranslateService
  ) {

    this.translate.get('DECLARATIONS.SUBMITTED').subscribe((translated: string) => {
      this.statuses = [
        { name: this.translate.instant("DECLARATIONS.SUBMITTED"), id: 'Submitted' },
        { name: this.translate.instant("DECLARATIONS.INPROGRESS"), id: 'In Progress (Draft)' },
        { name: this.translate.instant("DECLARATIONS.INPROGRESS_AUTO"), id: 'In Progress_auto' }
      ]
    });

    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
        });
    });
    this.yearService.getList("year").subscribe(val => {
      this.years = val.map(elem => ({ name: elem.year, id: elem.year }))
    });
    reportService.getReportsList("?active=true").subscribe(rep => {
      if (rep && rep.reports) {
        this.filterData.report = rep.reports.map(elem => ({ id: elem.key, name: elem.name + ' ' + elem.year }))
      }
    })
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length === 0) {
      delete this.queryFilter[field]
    }
  };

  resetFilter() {
    this.queryFilter = {}
    this.municipalitySelect.resetAll()
    this.recurringSelect.resetAll()
    this.statusSelect.resetAll()
    this.yearSelect.resetAll()
  }

  advancedSearch() {
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }

  ngOnInit() {

  }
}
