import { Component, OnInit } from "@angular/core";
import { saveAs } from "file-saver";
import { NgxSpinnerService } from "ngx-spinner";
import { AdminService } from "src/app/services/admin.service";

@Component({
  selector: "app-help.component",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.css"],
})
export class HelpComponent implements OnInit {
  homepage = {};

  constructor(
    private adminService: AdminService,
    private spinner: NgxSpinnerService
  ) {}

  helpPageItem = {
    title: "",
    text: "",
    manual: "",
  };

  ngOnInit() {
    this.adminService.getAdmin().subscribe((val) => {
      if (val !== null) {
        this.helpPageItem = val.homepage;
      } else {
        console.error("Failed to load homepage");
      }
    });
  }

  downloadManualFile(name) {
    this.spinner.show();
    this.adminService.downloadManualFile(name).subscribe((data) => {
      this.downloadPrc(data, name);
    });
  }
  
  downloadPrc(data, name) {
    this.spinner.hide();
    const blob = data;
    const file = new Blob([blob], {});
    const filename = name;
    saveAs(file, filename);
  }
}
