import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";
import { AvisService } from "src/app/services/avis.service";
import { FinancesDataSource } from "src/app/services/finances.datasource";
import { SharedService } from "src/app/services/sharedService";
import { TaxPayerService } from "src/app/services/taxPayer.service";
import { YearService } from "src/app/services/year.service";

@Component({
  selector: "app-wallet-deposits",
  templateUrl: "./wallet-deposits.component.html",
  styleUrls: ["./wallet-deposits.component.css"],
})
export class WalletDepositsComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  currentYear: any;
  @Input()
  category: any;
  @Input()
  currentNIU: any;
  currentYearInt: any;
  displayedYears = [];
  dataSource: FinancesDataSource;
  refreshDataSubscription: any
  displayedColumns: string[] = ['date', 'paymentSystem', 'paymentReference', 'debit', 'credit', 'print'];
  @Input() company: any;
  avis;
  otpPayment = 0;
  avisMapping = {};
  finances = [];
  filterObject: any;
  loading = { inProgress : true}
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  constructor(
    private yearService: YearService,
    private avisService: AvisService,
    public translate: TranslateService,
    public taxPayerService: TaxPayerService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.category && changes.category.currentValue.avis) {
        this.displayedColumns = ['date', 'paymentSystem', 'paymentReference', 'debit',  'balance', 'print'];
    }
  }

  ngOnInit() { 
    this.yearService.getList("year").subscribe((val) => {
      val.forEach((element) => {
        this.displayedYears.push(element.year);
      });
      if (val && val.length > 0) {
        this.currentYear = val[0].year;
        this.currentYearInt = val[0].year * 1;
      }

    });
    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'WALLET');
    this.refreshDataSubscription = this.sharedService.cashPaymentPrintEmitter.subscribe(val => {
      this.taxPayerService.getTaxPayerByNiu('tax-payers', this.currentNIU).subscribe(taxPayer => {
        this.company = taxPayer
        this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'WALLET');
        this.loadFinancesTable()
      })

    })
  }

  handleSelection(event) {
    this.loading.inProgress = true
    this.currentYear = event.value;
    this.dataSource.loadFinances(this.currentYear, this.currentNIU, '', 'asc', 'date', 0, this.paginator.pageSize, this.pType, this.filterObject, this.company.extraBalance, this.loading);
  }


  print(p) {
    this.sharedService.cashPaymentPrint(p)
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.loading.inProgress = true
      this.dataSource.loadFinances(this.currentYear, this.currentNIU, '', 'asc', 'date', 0, this.paginator.pageSize, this.pType, this.filterObject, this.company.extraBalance, this.loading);
    }, 1000)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
 

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
       tap(() => this.loadFinancesTable())
    )
      .subscribe();

  }
  ngOnDestroy(): void {
    this.refreshDataSubscription && this.refreshDataSubscription.unsubscribe()
  }
  loadFinancesTable() {
    this.loading.inProgress = true
    this.dataSource.loadFinances(this.currentYear, this.currentNIU, 
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,this.pType,this.filterObject, this.company.extraBalance, this.loading);
  }
  advancedSearch(filter){
    this.filterObject = filter
    this.loadFinancesTable()
  }
 
  pType = "ALL"
  getFinances(val, type){
    if (val.checked){
      this.pType = this.pType==='ALL' ? type : "ALL"
    }else{
     this.pType = this.pType!=='ALL' ? 'ALL' : (type==='FEES' ? "PAYMENTS":"FEES")
    }
    this.loadFinancesTable()
  }

}
