import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { ReportService } from "src/app/services/report.service";
import { SharedService } from "src/app/services/sharedService";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";

@Component({
  selector: "app-taxnames-search",
  templateUrl: "./search-taxnames.component.html",
  styleUrls: ["./search-taxnames.component.css"],
})
export class SearchTaxnamesComponent implements OnInit, OnDestroy {
  @ViewChild('categorySelect', { static: false }) categorySelect: MultiselectAutocompleteComponent;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  filterData = {
    category: null,
    municipality: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields : any;
  municipalityChangeSubscription: any;
  queryFilter = {};
  filterFields = ["category", "municipality"];
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public reportService: ReportService, public sharedService: SharedService
  ) {
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
            setTimeout(()=>{
              if (field === 'municipality'){
                if (localStorage.getItem('municipality')) {
                  if (localStorage.getItem('municipality') !== '-1') {
                    this.municipalitySelect.resetAll()
                    this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
                  }
                  else { 
                    this.municipalitySelect.resetAll()
                  }
                }else{
                  this.municipalitySelect.resetAll()
                }
              }
            }, 1000)
          }
        });
    });
  }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length===0){
      delete this.queryFilter[field]
    }
  };
  resetFilter() {
    this.queryFilter = {}
    this.categorySelect.resetAll()
    this.municipalitySelect.resetAll()
    this.onReset.emit()
  }
   advancedSearch() {
    let selData = []
    selData = this.municipalitySelect.selectData.filter(el=> el.selected).map(el => el.item.id)
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.queryFilter['municipality']
      } else {
        if (selData.length>0)
          this.queryFilter['municipality'] = selData
        else
        this.queryFilter['municipality'] = [localStorage.getItem('municipality')]
      }
    } else {
      if (this.municipalitySelect.selectData.length === 0)
        delete this.queryFilter['municipality']
    }
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
   }

  ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
        }
        else { 
          this.municipalitySelect.resetAll()
        }
      }else{
        this.municipalitySelect.resetAll()
      }
    })
  }
}
