import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ForecastService } from "src/app/services/forecast.service";
import { TasDataSource } from "src/app/services/tasGovTable.datasource";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { CategoryService } from "src/app/services/category.service";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";
import { ForecastDialogComponent } from "../../dialogs/forecast-dialog/forecast-dialog.component";
import { SharedService } from "src/app/services/sharedService";
import { UserService } from "src/app/services/user.service";
import { ConfirmationDialogComponent } from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from 'file-saver';
import { SubmitDialogComponent } from "../../dialogs/submit-dialog/submit-dialog.component";
import { ProcessService } from "src/app/services/process.service";
@Component({
  selector: "app-forecast-table",
  templateUrl: "./forecast-table.component.html",
  styleUrls: ["./forecast-table.component.css"],
})
export class ForecastTableComponent implements OnInit, AfterViewInit, OnDestroy {
  singleMunicipality
  dataSource: TasDataSource;
  displayedColumns: string[] = ["revenueCode", "revenueCodeName", "forecast.forecast", "fees"];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  filterObject = {};
  @Input() currentYear = new Date().getFullYear().toString();
  @Input() municipality;
  @ViewChild("municipalitySelect", { static: false })
  municipalitySelect: MultiselectAutocompleteComponent;
  filterData = { municipality: null };
  forecastEmitterSubscription: any
  municipalityChangeSubscription: any
  deleteFileEmmitter: EventEmitter<boolean> = new EventEmitter();
  subscriptionConfirm: any
  subscriptionSubmit: any
  subscriptionApprove: any
  subscriptionReopen: any
  currentUser
  forecastState
  selectedData = []
  loading = { inProgress: false, downloadInProgress: false, downloadAttachmentInProgress: false }
  constructor(
    private forecastService: ForecastService,
    public categoryService: CategoryService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    public processService: ProcessService
  ) { }
  ngOnDestroy(): void {
    if (this.forecastEmitterSubscription) this.forecastEmitterSubscription.unsubscribe()
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }

  async ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(async val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') == "-1") {
          delete this.filterObject['municipality']
          this.dataSource.clearTotalSums()
        } else {
          this.filterObject['municipality'] = [localStorage.getItem('municipality')]
          this.municipalitySelect.resetAll()
          this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
        }
      } else {
        this.municipalitySelect.resetAll()
        delete this.filterObject['municipality']
        this.dataSource.clearTotalSums()
        this.forecastState = null
      }
      this.loadTable()
    })
    this.dataSource = new TasDataSource(this.forecastService);
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user
      if (this.currentUser) {
        if (this.currentUser.role !== 'ADMIN' && this.currentUser.municipalities && this.currentUser.municipalities.length === 1) {
          this.singleMunicipality = this.currentUser.municipalities[0].name
          this.filterObject['municipality'] = [this.currentUser.municipalities[0].id]
          this.loadTable()
        }
      }

      this.categoryService.getList("municipality").subscribe((result) => {
        if (result) {
          this.filterData["municipality"] = result.map((fld) => ({
            name: fld.name,
            id: fld.id,
          }));
          if (this.municipality) {
            let fm = this.filterData["municipality"].find(elem => elem.id === this.municipality)
            if (fm) {
              this.selectedData.push(fm)
              this.filterObject["municipality"] = [this.municipality]
              this.loadTable()
            }

          }
          else{
            if (localStorage.getItem('municipality')) {
              if (localStorage.getItem('municipality') == "-1") {
                delete this.filterObject['municipality']
                this.dataSource.clearTotalSums()
              } else {
                this.filterObject['municipality'] = [localStorage.getItem('municipality')]
                if (!this.singleMunicipality) {
                  setTimeout(() => {
                    this.municipalitySelect.resetAll()
                    this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
                  }, 1000)
                }
              }
            } else {
              if (this.municipalitySelect)
                setTimeout(() => {
                  this.municipalitySelect.resetAll()
                }, 1000)
              delete this.filterObject['municipality']
              this.dataSource.clearTotalSums()
            }
          }
        
        }
      });
      if (this.canEditForecast()) {
        this.displayedColumns.push('action')
      }
      this.forecastEmitterSubscription = this.sharedService.updateForecastEmmitter.subscribe(val => {
        this.forecastService.updateResource('forecast', val).subscribe(res => {
          this.loadTable()
        })
      })
    })
  }

  canEditForecast() {
    let val = false
    if (this.currentUser) {
      val = (this.currentUser.role === 'FINANCIAL_MANAGER_ADMINISTRATOR' && (!this.forecastState || this.forecastState.state === 'REOPENED'))
    }
    if (!val) {
      this.displayedColumns = this.displayedColumns.filter(col => col !== 'action')
    }
    return val;
  }

  canApproveForecast() {
    if (this.currentUser && this.forecastState) {
      return this.currentUser.role === 'CENTRAL_EDITOR' && this.forecastState.state === 'SUBMITTED'
    }
  }

  canReopenForecast() {
    if (this.currentUser && this.forecastState) {
      return this.currentUser.role === 'CENTRAL_EDITOR' && this.forecastState.state === 'SUBMITTED'
    }
  }

  externalFunction() {
    this.dataSource.emptyData()
    this.loadTable();
  }
  async loadTable() {
    this.filterObject["year"] = this.currentYear + '';
    if (!this.filterObject["municipality"]) this.dataSource.emptyData();
    else {
      this.loading.inProgress = true
      this.dataSource.emptyData()
      this.forecastState = await this.forecastService.getForecastState(this.currentYear, this.filterObject['municipality'][0]).toPromise()
      if ((!this.forecastState || this.forecastState.state === 'REOPENED') && !this.displayedColumns.includes('action')) {
        this.displayedColumns.push('action')
      }
      this.dataSource.loadData(
        "forecast/dialoglist",
        "",
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.filterObject,
        this.loading
      );


    }
  }

  downloadFile(fileObj) {
    this.loading.downloadAttachmentInProgress = true
    this.processService.downloadFileFromDocflow(fileObj.attachment).subscribe
      (data => {
        this.loading.downloadAttachmentInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = fileObj.filename;
        saveAs(file, filename);
      });
  }
  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTable())
      ).subscribe();
  }
  getTotalSum(key) {
    if (this.dataSource)
      return this.dataSource.getTotalSum(key)
  }
  selectChange = (event: any, field) => {
    this.filterObject[field] = event.data.map((elem) => elem.id);
    if (this.filterObject[field].length === 0) {
      delete this.filterObject[field];
      this.dataSource.clearTotalSums()
    }
    this.loadTable();
  };
  async openForecastDialog(forecast) {
    if (!forecast) forecast = { year: this.currentYear + '', municipalityId: this.filterObject['municipality'][0] }
    if (forecast.forecast) forecast.forecast = await this.forecastService.getResource('forecast', forecast.forecast.id).toPromise()
    this.dialog.open(ForecastDialogComponent, {
      width: '780px', panelClass: 'custom-dialog-container',
      data: forecast
    })
  }

  async openSubmitDialog() {
    const dialogRef = this.dialog.open(SubmitDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.SUBMIT_FORECAST", emitter: null, obj: { year: this.currentYear, type: 'forecast', municipality: this.filterObject['municipality'][0] }, action: "CONFIRM" }
    });

    this.subscriptionSubmit = this.sharedService.confirmDialogEmitter.subscribe(async val => {
      if (val) {
        this.spinner.show()
        await this.forecastService.submitForecast({ type: 'forecast', comment: val.comment, attachment: val.attachment, filename: val.filename, year: this.currentYear + '', municipality: this.filterObject['municipality'][0] }).toPromise()
        this.forecastState = await this.forecastService.getForecastState(this.currentYear, this.filterObject['municipality'][0]).toPromise()
        this.spinner.hide()
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionSubmit.unsubscribe()
    });
  }

  async openApproveDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.APPROVE_FORECAST", emitter: null, obj: this.currentYear, action: "CONFIRM" }
    });

    this.subscriptionApprove = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) {
        this.spinner.show()
        this.forecastService.approveForecast({ type: 'forecast', year: this.currentYear + '', municipality: this.filterObject['municipality'][0] }).subscribe(async res => {
          this.forecastState = await this.forecastService.getForecastState(this.currentYear, this.filterObject['municipality'][0]).toPromise()
          this.spinner.hide()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionApprove.unsubscribe()
    });
  }
  getFeeAmount(fee){
    let amount = isNaN(fee.amount) ? 0 : fee.amount * 1
    if (fee.type==='AMOUNT')
      return amount.toLocaleString('fr-FR')
    return amount+'%'
  }
  async openReopenForecastDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.REOPEN_FORECAST", emitter: null, obj: this.currentYear, action: "CONFIRM" }
    });

    this.subscriptionReopen = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) {
        this.spinner.show()
        this.forecastService.reopenForecast({ type: 'forecast', year: this.currentYear + '', municipality: this.filterObject['municipality'][0] }).subscribe(async res => {
          this.forecastState = await this.forecastService.getForecastState(this.currentYear, this.filterObject['municipality'][0]).toPromise()
          this.spinner.hide()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionReopen.unsubscribe()
    });
  }


  openForecastDeleteDialog(elem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.PROCESS_DELETE_FORECAST", emitter: null, obj: elem, action: "CONFIRM" }
    });

    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) {
        this.spinner.show()
        this.forecastService.deleteResource('forecast', { id: elem.forecast.id }).subscribe(res => {
          this.loadTable()
          this.spinner.hide()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionConfirm.unsubscribe()
    });
  }

  downloadForecast() {
    let headers = [
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_REVENUE-CODE"), key: 'code' },
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_REVENUE-CODE-NAME"), key: 'name' },
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_FORECAST"), key: 'forecast' },
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_ADJUSTMENT"), key: 'adjustment' },
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_EMISSION"), key: 'emission' },
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_AMOUNT"), key: 'amount' },
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_PERCENTAGE_OF_FORECAST"), key: 'percentageOfForecast' },
      { header: this.translate.instant("FORECAST.TABLE_COLUMN_MUNICIPALITY"), key: 'municipality' }
    ]
    this.loading.downloadInProgress = true
    this.forecastService.downloadForecast(this.filterObject, headers).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }
  downloadForecastPdf() {
    this.loading.downloadInProgress = true
    let headers = {
      title: this.translate.instant("FORECAST.PDF_TITLE"),
      col1: this.translate.instant("FORECAST.PDF_TABLE_COL1"),
      col2: this.translate.instant("FORECAST.PDF_TABLE_COL2"),
      col3: this.translate.instant("FORECAST.PDF_TABLE_COL3"),
      col4: this.translate.instant("FORECAST.PDF_TABLE_COL4"),
      year: this.filterObject["year"],
      municipality: this.filterData['municipality'].find(elem => elem.id === this.filterObject['municipality'][0]).name
    }

    this.forecastService.downloadForecastPDF(this.filterObject, headers).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }
}
