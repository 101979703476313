import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/sharedService';
import { TaxDivisionService } from 'src/app/services/taxDivision.service';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-tax-division',
  templateUrl: './tax-division.component.html',
  styleUrls: ['./tax-division.component.css']
})
export class TaxDivisionComponent implements OnInit {
  subscriptionUpdateTaxDivision: any;
  subscriptionDeactivateTaxDivision: any;
  subscriptionActivateTaxDivision: any;
  filterObject: {};
  displayedColumns: string[] = ['name'];
  dataSource: TasDataSource;
 
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private taxDivisionService: TaxDivisionService, public dialog: MatDialog, public sharedService: SharedService, public router: Router) { }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.taxDivisionService)
    
    this.subscriptionDeactivateTaxDivision = this.sharedService.deactivateUserEmmitter.subscribe(val => {
      if (val) {
        this.taxDivisionService.deactivateResource('tax-divisions', val).subscribe(res => {
          this.loadTaxDivisionsData();
        })
      }
    })

    this.subscriptionActivateTaxDivision = this.sharedService.activateUserEmmitter.subscribe(val => {
      if (val) {
        this.taxDivisionService.activateResource('tax-divisions', val).subscribe(res => {
          this.loadTaxDivisionsData();
        })
      }
    })
  }

  
  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('tax-divisions', '', 'asc', 'abrev', 0, this.paginator.pageSize, this.filterObject);
    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadTaxDivisionsData();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTaxDivisionsData())
      )
      .subscribe();

  }

  loadTaxDivisionsData() {
    this.dataSource.loadData('tax-divisions',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  // openUserDialog(user): void {
  //   const dialogRef = this.dialog.open(UserDialogComponent, {
  //     width: '600px', panelClass: 'custom-dialog-container',
  //     data: user
  //   });

  //   dialogRef.afterClosed().subscribe(result => {

  //   });
  // }

  goToTaxDivision(row){
    this.router.navigate(['tax_payers'], { queryParams: { 'abrev': row._id }});
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateTaxDivision) this.subscriptionUpdateTaxDivision.unsubscribe();
    if (this.subscriptionDeactivateTaxDivision) this.subscriptionDeactivateTaxDivision.unsubscribe();
    if (this.subscriptionActivateTaxDivision) this.subscriptionActivateTaxDivision.unsubscribe();
  }


}
