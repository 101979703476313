import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SharedService } from './sharedService';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }

  getDashboardData(endpoint: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('dashboard/'+endpoint)).pipe(
      catchError(error => {
        return throwError(error);
      }), 
      map(res => {
        return res;
      })
    );
  }


  downloadRevenueCodeExcel(filterObject: any, headers: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('dashboard/download-revenue-code-excel'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;
  }

  downloadRevenueCodePDF(filterObject: any, headers: any): Observable<Blob> {
    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('filter.object', this.stringifyWithUTC(filterObject)).set('headers', JSON.stringify(headers)),
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('dashboard/download-revenue-code-pdf'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download pdf'))
    )
    return process$;

  }

}



