import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/services/dropdown.service';
import { MultiselectAutocompleteComponent } from '../../multiselect-autocomplete/multiselect-autocomplete.component';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'rdf-form-type-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.css']
})
export class FormFilterComponent implements OnInit {
  panelOpenState = true
  @ViewChild('municipalitySelect', { static: false }) munSel: MultiselectAutocompleteComponent;
  @ViewChild('categorySelect', { static: false }) catSel: MultiselectAutocompleteComponent;
  municipalities: any[]
  categories: any[]
  searchName
  searchYear
  searchRevenueCode
  searchMunicipalities
  searchCategories
  @Output() onSearch = new EventEmitter<any>()
  constructor(public dropdownService: DropdownService, public categoryService: CategoryService) { }

  ngOnInit() {
    this.dropdownService.getList('municipality').subscribe((municipalities) => {
      this.municipalities = municipalities;
    });
    this.categoryService.getList("category").subscribe((result) => {
      this.categories = result.map(elem => { return { name: elem.name, id: elem.id, tinCode: elem.tinCode, taxPayerType: elem.taxPayerType } });;
    });
  }

  advancedSearch(){
    this.onSearch.emit({
      name:this.searchName, year:this.searchYear, revenueCode:this.searchRevenueCode, 
      municipality:this.searchMunicipalities && this.searchMunicipalities.length>0 ? this.searchMunicipalities:null,
      category:this.searchCategories && this.searchCategories.length>0 ? this.searchCategories:null
    })
  }
  resetFilter(){
    this.searchName = null
    this.searchYear = null
    this.searchRevenueCode = null
    this.searchMunicipalities = null
    this.searchCategories = null
    this.munSel.resetAll()
    this.catSel.resetAll()
    this.onSearch.emit(null)
  }

  selectChange = (event: any, field) => {
    switch (field) {
      case 'MUNICIPALITY':
        this.searchMunicipalities = event.data.map(el => el.id)
        break;
      case 'CATEGORY':
        this.searchCategories = event.data.map(el => el.id)
        break;
      default:
        break;
    }
  };
 
}
