import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserStatusName } from 'src/app/entities/util';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';

import { Router } from '@angular/router';
import { ProcessTypeService } from 'src/app/services/proces-type.service';
import { SharedService } from 'src/app/services/sharedService';
import { YearService } from 'src/app/services/year.service';

@Component({
  selector: 'tas-gov-process',
  templateUrl: './process-type.component.html',
  styleUrls: ['./process-type.component.css']
})
export class ProcessTypeComponent implements OnInit, AfterViewInit {
 
  filterObject: {};
  displayedColumns: string[] = ['name', 'key', 'year', 'type', 'active', 'version'];
  dataSource: TasDataSource;
  statusNames = UserStatusName
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  formYears = ["2017","2018","2019","2020"]
  newYear: number;
  errorAddYear: string;
  loading = { inProgress : false, error: false }
  constructor(private processTypeService: ProcessTypeService, public dialog: MatDialog, public sharedService: SharedService, 
    public yearService:YearService, public router: Router) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.processTypeService)
    this.getYears()
    
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.loadProcessTypeTable()

    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // fromEvent(this.input.nativeElement, 'keyup')
    //   .pipe(
    //     debounceTime(1000),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadProcessTypeTable();
    //     })
    //   )
    //   .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProcessTypeTable())
      )
      .subscribe();

  }
  resetLoading = () => {
    this.loading.inProgress = true
    this.loading.error = false
    this.dataSource.emptyData()
  }

  getForms(value){
    if (value){
      this.filterObject = {}
      for (let k in value){
        if (value[k]){
          this.filterObject[k] = value[k]
        }
      }
      this.loadProcessTypeTable()
    }
    else{
      this.resetLoading()
      this.dataSource.loadData('process-types', '{}', 'asc', 'name', 0, this.paginator.pageSize, null, this.loading);
    }
  }

  loadProcessTypeTable() {
    this.resetLoading()
    this.dataSource.loadData('process-types',
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject, this.loading);
  }

   openProcessTypeDialog(processType): void {
     if (processType){
      this.router.navigate(['process_type_add_edit'], { queryParams: { id: processType._id }});
     }
     else{
      this.router.navigate(['process_type_add_edit']);
     }
   }

 addYear(){
  var findYear = this.formYears.find(elem => elem['year'] == this.newYear)
  if (findYear==undefined){
    var year = {"year":this.newYear+"", "isActive":true, "visible":true}
    this.yearService.updateResource("year/add",year).subscribe(val=>{
      this.getYears()
      this.newYear=null
    })
  }
  else{
    this.errorAddYear = "YEAR_EXISTS"
  }
  
 }
 setActive(val,year){
  var yearVal = {"id":year, "isActive":val.checked}
  this.yearService.patchResource("year",yearVal).subscribe(val=>{
   
  })
 }
 setVisible(val,year){
  var yearVal = {"id":year, "visible":val.checked}
  this.yearService.patchResource("year",yearVal).subscribe(val=>{
   
  })
 }
 removeYear(id){
  var yearVal = {"id":id}
  this.yearService.deleteResource("year",yearVal).subscribe(val=>{
    this.getYears()
  })
 }

 getYears(){
  this.yearService.getList("year").subscribe(val=>{
    this.formYears = val
  });
 }

}

