import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { SharedService } from "src/app/services/sharedService";
import { MultiselectAutocompleteComponent } from "../../../multiselect-autocomplete/multiselect-autocomplete.component";

@Component({
  selector: "app-revenue-code-amounts-search-graph",
  templateUrl: "./search-revenue-code-amounts-graph.component.html",
  styleUrls: ["./search-revenue-code-amounts-graph.component.css"],
})
export class SearchRevenueCodeAmountsGraphComponent implements OnInit, OnDestroy {
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  filterData = {
    municipality: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields : any;
  @Input() singleMunicipality : any;
  municipalityChangeSubscription: any
  queryFilter = {};
  filterFields = ["municipality"];
  panelOpenState = true;
  fromDate = new Date();
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public sharedService: SharedService
  ) {}
 
  ngOnDestroy(): void {
   if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length===0){
      delete this.queryFilter[field]
    }
  };
  resetFilter() {
    this.queryFilter = {}
    this.municipalitySelect.resetAll()
    this.onReset.emit()
  }
   advancedSearch() {
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
   }

   setRange(range){
    this.queryFilter['from'] = range.from
    this.queryFilter['to']= range.to
  }

  ngOnInit() {
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
          if (localStorage.getItem('municipality') && !this.singleMunicipality){
            setTimeout(()=>{
              if (this.municipalitySelect){
                this.municipalitySelect.resetAll()
                this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
              }
            },1000)
          }
        });
       
    });
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')){
              this.municipalitySelect.resetAll()
              this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
          }
          else{
            this.municipalitySelect.resetAll()
          }
    })
  
    this.fromDate.setDate(1)
    this.fromDate.setMonth(0)
  }
}
