export class KeyGenerator {
  generateKey(stage, type) {
    switch (type) {
        case 'block':
            let counter = 1
            while (stage.blocks.find(elem => elem.key===type+'_'+counter)){
                counter++;
            }
            return type+'_'+counter;
        default:
            break;
    }
  }
}
