import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";
import { MatSort } from "@angular/material/sort";
import { TasDataSource } from "src/app/services/tasGovTable.datasource";
import { MultiselectAutocompleteComponent } from "../../../multiselect-autocomplete/multiselect-autocomplete.component";
import { CategoryService } from "src/app/services/category.service";
import { TranslateService } from "@ngx-translate/core";
import { ProcessService } from "src/app/services/process.service";
import { SharedService } from "src/app/services/sharedService";
import { DropdownConfigurationService } from "src/app/services/dropdownConfiguration.service";
import { MatDialog } from "@angular/material/dialog";
import { DropdownConfigurationDialogComponent } from "src/app/components/dialogs/dropdown-configuration-dialog/dropdown-configuration-dialog.component";
import { Router } from "@angular/router";
import { ConfirmationDialogComponent } from "src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-dropdown-configuration-table",
  templateUrl: "./dropdown-configuration-table.component.html",
  styleUrls: ["./dropdown-configuration-table.component.css"],
})
export class DropdownConfigurationTableComponent implements OnInit, OnDestroy {
  singleMunicipality
  dataSource: TasDataSource;
  displayedColumns: string[] = ["name", "action"];
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  filterObject = {};
  @Input() currentYear = new Date().getFullYear().toString();
  @Input() municipality;
  @Input() extraFields: any;
  @Output() onApplySearch = new EventEmitter<any>();
  @Output() onResetSearch = new EventEmitter<void>();
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  filterData = { municipality: null };
  filterFields = ["municipality"];
  municipalityChangeSubscription: any
  selectedData = []
  loading = { inProgress: false }
  panelOpenState = true;
  queryFilter = {};
  subscriptionConfirm: any
  constructor(
    public categoryService: CategoryService,
    public translate: TranslateService,
    public processService: ProcessService,
    public sharedService: SharedService,
    public dropdownConfigurationService: DropdownConfigurationService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTable())
      ).subscribe();
  }

  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length === 0) {
      delete this.queryFilter[field]
    }
  };

  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }

  resetFilter() {
    this.queryFilter = {}
    this.municipalitySelect.resetAll()
  }
  openEditDialog(row) {
    if (row)
      this.router.navigate(['dropdown_configuration_edit/' + row._id], { queryParams: { year: this.currentYear } });
    else {
      const dialogRef = this.dialog.open(DropdownConfigurationDialogComponent, {
        width: '800px', panelClass: 'custom-dialog-container',
        data: { id: row ? row._id : null, year: this.currentYear + '', searchedMunicipality: this.filterObject['municipality'] }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['dropdown_configuration_edit/' + result.id], { queryParams: { year: this.currentYear } });
        }
      });
    }
  }

  onDelete(elem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.DELETE_DROPDOWN_CONFIGURATION", emitter: null, obj: elem, action: "CONFIRM" }
    });

    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) {
        this.dropdownConfigurationService.deleteResource('dropdown-configuration', { id: elem._id }).subscribe(res => {
          this.loadTable()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionConfirm.unsubscribe()
    });
  }

  advancedSearch() {
    let selectedMunicipalityData = this.municipalitySelect.selectData.filter(el => el.selected).map(el => el.item.id)
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.filterObject['municipality']
      } else {
        if (selectedMunicipalityData.length > 0)
          this.filterObject['municipality'] = selectedMunicipalityData
        else
          this.filterObject['municipality'] = [localStorage.getItem('municipality')]
      }
    } else {
      if (this.municipalitySelect.selectData.length === 0)
        delete this.filterObject['municipality']
      else
        this.filterObject['municipality'] = selectedMunicipalityData
    }
    this.filterObject['name'] = this.queryFilter['name']
    this.panelOpenState = false
    this.loadTable()
  }
  async ngOnInit() {
    this.filterObject['year'] = this.currentYear + ''
    this.dataSource = new TasDataSource(this.dropdownConfigurationService);
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          let selectedMun = this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality'))
          if (selectedMun.length > 0) {
            this.filterObject['municipality'] = [selectedMun[0].id]
            this.municipalitySelect.setSelectedData(selectedMun)
          }
        }
        else {
          this.municipalitySelect.resetAll()
        }
      } else {
        this.municipalitySelect.resetAll()
      }
      this.loadTable()
    })
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
          setTimeout(() => {
            if (localStorage.getItem('municipality')) {
              if (localStorage.getItem('municipality') !== '-1') {
                this.municipalitySelect.resetAll()
                let selectedMun = this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality'))
                if (selectedMun.length > 0) {
                  this.filterObject['municipality'] = [selectedMun[0].id]
                  this.municipalitySelect.setSelectedData(selectedMun)
                }
              }
              else {
                this.municipalitySelect.resetAll()
              }
            } else {
              this.municipalitySelect.resetAll()
            }
            this.loadTable()
          }, 500)
        });
    });
  }
  externalFunction() {
    this.dataSource.emptyData()
    this.filterObject['year'] = this.currentYear + ''
    this.panelOpenState = true
    this.loadTable()
  }
  async loadTable() {
    this.dataSource.loadData(
      "dropdown-configuration",
      "",
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject,
      this.loading
    );
  }
}
