import { getCompanyByTin } from "./getCompanyByTin";

export async function setPayment(
    payment: { paymentSystem: { key: string; name: string; }; currency: string; amount: any; paymentDate: any; tin: any; companyName: any; taxName: any; taxNameId: any; revenueCodeId: any; revenueCode: any; avis: any; reference_number: any; process_name: any; month: any; year: any; description: any; }, thisCompanies) {
    const company = await getCompanyByTin(payment.tin, thisCompanies)
    if (!company.payments) {
      company.payments = [];
    }
    payment['municipalityId'] = company.municipality
    company.payments.push(payment);
    if (company.avises) {
      company.avises = company.avises.filter(avis => avis.reference_number !== payment.reference_number);
    }
    await thisCompanies.update(company.id, company);
}