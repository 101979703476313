import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyDexieService } from 'src/app/services/companyDexie-services/companyDexie.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'rdf-offline-payments-table',
  templateUrl: './offline-payments-table.component.html',
  styleUrls: ['./offline-payments-table.component.css']
})
export class OfflinePaymentsTableComponent implements OnInit, OnDestroy {
  @Input() tin
  displayedColumns: string[] = ['date', 'paymentSystem', 'paymentReference', 'amount'];
  loading = { inProgress: true }
  refreshDataSubscription: any
  constructor(private companyDexieService: CompanyDexieService, private sharedService: SharedService) { }
  dataSource
  async ngOnInit() {
    let comp = await this.companyDexieService.getCompanyByTin(this.tin)
    this.dataSource = new MatTableDataSource<any>(comp.payments || []);
    this.refreshDataSubscription = this.sharedService.cashPaymentPrintEmitter.subscribe(async val => {
      let comp = await this.companyDexieService.getCompanyByTin(this.tin)
      this.dataSource.data = comp.payments || []
    })
  }

  ngOnDestroy(): void {
    this.refreshDataSubscription && this.refreshDataSubscription.unsubscribe()
  }

}
