
export class TaxName {
  public id: number;
  public name: string;
  public revenueCode: any[];
  public municipality: any[];
  public amount: number;
  
  constructor() {
 
  }

}  