import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from "ngx-spinner";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";
import { CategoryService } from "src/app/services/category.service";
import { ForecastService } from "src/app/services/forecast.service";
import { ProcessService } from "src/app/services/process.service";
import { SharedService } from "src/app/services/sharedService";
import { TasDataSource } from "src/app/services/tasGovTable.datasource";
import { UserService } from "src/app/services/user.service";
import { ConfirmationDialogComponent } from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import { ForecastDialogComponent } from "../../dialogs/forecast-dialog/forecast-dialog.component";
import { SubmitDialogComponent } from "../../dialogs/submit-dialog/submit-dialog.component";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
@Component({
  selector: "app-performance-table",
  templateUrl: "./performance-table.component.html",
  styleUrls: ["./performance-table.component.css"],
})
export class PerformanceTableComponent implements OnInit, AfterViewInit, OnDestroy {
  singleMunicipality
  dataSource: TasDataSource;
  displayedColumns: string[] = ["revenueCode", "forecast", "prevMonth", "currentMonth", "total", "percentage", "payment"];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  filterObject = {};
  @Input() currentYear = new Date().getFullYear().toString();
  @Input() municipality;
  @Input() month = "0";
  @ViewChild("municipalitySelect", { static: false })
  municipalitySelect: MultiselectAutocompleteComponent;
  filterData = { municipality: null };
  forecastEmitterSubscription: any
  municipalityChangeSubscription: any
  currentUser
  day = 1;
  forecastState
  subscriptionSubmit
  subscriptionApprove
  subscriptionReopen
  initialLoad = true
  months = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
  ];
  selectedData = []
  loading = { inProgress : false, error: false, downloadInProgress: false, downloadAttachmentInProgress: false }
  constructor(
    private forecastService: ForecastService,
    public categoryService: CategoryService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private processService: ProcessService,
    public translate: TranslateService
  ) { }
  ngOnDestroy(): void {
    if (this.forecastEmitterSubscription) this.forecastEmitterSubscription.unsubscribe()
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
 
  ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')){
        if (localStorage.getItem('municipality')=="-1"){
          delete this.filterObject['municipality']
          this.dataSource.clearTotalSums()
        }else{
          this.filterObject['municipality'] = [localStorage.getItem('municipality')]
          if (this.municipalitySelect){
            this.municipalitySelect.resetAll()
            this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
          }
        }
      }else{
        if (this.municipalitySelect)
          this.municipalitySelect.resetAll()
        delete this.filterObject['municipality']
        this.dataSource.clearTotalSums()
        this.forecastState = null
      }
      this.loadTable()
    })
    this.dataSource = new TasDataSource(this.forecastService);  
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user
        if (this.currentUser){
          if (this.currentUser.role !== 'ADMIN' && this.currentUser.municipalities && this.currentUser.municipalities.length === 1){
            this.singleMunicipality = this.currentUser.municipalities[0].name
            this.filterObject['municipality'] = [this.currentUser.municipalities[0].id]
            this.loadTable()
          }    
        }
      this.categoryService.getList("municipality").subscribe((result) => {
        if (result) {
          this.filterData["municipality"] = result.map((fld) => ({
            name: fld.name,
            id: fld.id,
          }));
        }
        if (this.municipality) {
          let fm = this.filterData["municipality"].find(elem => elem.id === this.municipality)
          if (fm) {
            this.selectedData.push(fm)
            this.filterObject["municipality"] = [this.municipality]
          }
        }else{
          if (!this.singleMunicipality) {
            setTimeout(() => {
              if (this.municipalitySelect){
                this.municipalitySelect.resetAll()
                this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
              }
            }, 1000)
          }
        }
        
      });
      if (this.canEditForecast()){
        this.displayedColumns.push('action')
      }
      this.forecastEmitterSubscription = this.sharedService.updateForecastEmmitter.subscribe(val => {
        val.id = val.id.id
        this.forecastService.updateResource('forecast', val).subscribe(res => {
          this.loadTable()
        })
      })
    })
  }

  canEditForecast() {
    let val = false
    if (this.currentUser) {
      val = (this.currentUser.role === 'FINANCIAL_MANAGER_ADMINISTRATOR' && (!this.forecastState || this.forecastState.state === 'REOPENED'))
    }
    if (!val){
      this.displayedColumns = this.displayedColumns.filter(col=> col!=='action')
    } 
    return val;
  }

  canApproveForecast() {
    if (this.currentUser && this.forecastState) {
      return this.currentUser.role === 'CENTRAL_EDITOR' && this.forecastState.state === 'SUBMITTED'
    }
  }

  canReopenForecast() {
    if (this.currentUser && this.forecastState) {
      return this.currentUser.role === 'CENTRAL_EDITOR' && this.forecastState.state === 'SUBMITTED'
    }
  }


  externalFunction() {
    this.dataSource.emptyData()
    this.loadTable();
  }
  async loadTable() {
    
    this.filterObject["year"] = this.currentYear+'';
    this.filterObject["month"] = this.month;
    if (!this.filterObject["municipality"]) this.dataSource.emptyData();
    else {
      this.forecastState = await this.forecastService.getForecastPerformanceState(this.currentYear, this.month, this.filterObject['municipality'][0]).toPromise()
      if ((!this.forecastState || this.forecastState.state === 'REOPENED') && !this.displayedColumns.includes('action')){
        this.displayedColumns.push('action')
    }
      this.dataSource.loadData(
        "forecast",
        "",
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.filterObject, 
        this.loading
      );
    }
  }
  ngAfterViewInit() {
    if (localStorage.getItem('municipality')){
      if (localStorage.getItem('municipality')=="-1"){
        delete this.filterObject['municipality']
        this.dataSource.clearTotalSums()
      }else{
        this.filterObject['municipality'] = [localStorage.getItem('municipality')]
        this.loadTable()
        if (this.municipalitySelect)
        setTimeout(() => {
          if (this.municipalitySelect){
            this.municipalitySelect.resetAll()
            this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
          }
        }, 1000)
      }
    }else{
      if (this.municipalitySelect)
      setTimeout(() => {
        if (this.municipalitySelect)
        this.municipalitySelect.resetAll()
      }, 1000)
      delete this.filterObject['municipality']
      this.dataSource.clearTotalSums()
    }


    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTable())
      ).subscribe();
  }
  getTotalSum(key){
    if (this.dataSource)
    return this.dataSource.getTotalSum(key)
  }
  selectChange = (event: any, field) => {
    
    this.filterObject[field] = event.data.map((elem) => elem.id);
    if (this.filterObject[field].length === 0) {
      delete this.filterObject[field];
      this.dataSource.clearTotalSums()
      this.forecastState = null
    }
    if (!this.initialLoad)
    this.loadTable();
    this.initialLoad = false
  };
  async openForecastDialog(forecastRow) {
    let months = {}
    months[this.month] = 0
    let forecast = forecastRow? JSON.parse(JSON.stringify(forecastRow)) : { year : this.currentYear,municipalityId : this.filterObject['municipality'][0], months, performance:true }
    //if (!forecast) forecast = { year : this.currentYear,municipalityId : this.filterObject['municipality'][0], months, performance:true }
    if (forecast.revenueCode) forecast.forecast = await this.forecastService.getResource('forecast', forecast.revenueCode.id).toPromise()
     
    forecast['performance'] = true
    forecast['month'] = this.month
     if (!forecast.forecast['months']){
      forecast.forecast['months'] = months
    }
    forecast.revenueCode = forecast.revenueCode.revenueCode
    this.dialog.open(ForecastDialogComponent, {
      width: '780px', panelClass: 'custom-dialog-container',
      data: forecast
    })
  }

  async openSubmitDialog() {
    const dialogRef = this.dialog.open(SubmitDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.SUBMIT_PERFORMANCE", emitter: null, obj: { year: this.currentYear, month:this.month+'', type:'performance', municipality: this.filterObject['municipality'][0]}, action: "CONFIRM" }
    });

    this.subscriptionSubmit = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) {
       this.spinner.show()
        this.forecastService.submitForecast({ type: 'performance', comment:val.comment, attachment:val.attachment, filename:val.filename, year: this.currentYear + '', month:this.month, municipality:this.filterObject['municipality'][0] }).subscribe(async res => {
          this.forecastState = await this.forecastService.getForecastPerformanceState(this.currentYear, this.month, this.filterObject['municipality'][0]).toPromise()
          this.spinner.hide()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionSubmit.unsubscribe()
    });
  }

  async openApproveDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.APPROVE_PERFORMANCE", emitter: null, obj: this.currentYear, action: "CONFIRM" }
    });

    this.subscriptionApprove = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) { 
       this.spinner.show()
        this.forecastService.approveForecast({ type: 'performance', year: this.currentYear + '', month:this.month,  municipality:this.filterObject['municipality'][0] }).subscribe(async res => {
          this.forecastState = await this.forecastService.getForecastPerformanceState(this.currentYear, this.month, this.filterObject['municipality'][0]).toPromise()
          this.spinner.hide()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionApprove.unsubscribe()
    });
  }
  resetLoading = () => {
    this.loading.inProgress = true
    this.loading.error = false
    this.dataSource.emptyData()
  }
  async openReopenForecastDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.REOPEN_PERFORMANCE", emitter: null, obj: this.currentYear, action: "CONFIRM" }
    });

    this.subscriptionReopen = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) { 
       this.spinner.show()
        this.forecastService.reopenForecast({ type: 'performance', year: this.currentYear + '',  month:this.month, municipality:this.filterObject['municipality'][0] }).subscribe(async res => {
          this.forecastState = await this.forecastService.getForecastPerformanceState(this.currentYear, this.month, this.filterObject['municipality'][0]).toPromise()
          this.spinner.hide()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionReopen.unsubscribe()
    });
  }

  deleteFileEmmitter: EventEmitter<boolean> = new EventEmitter();
  subscriptionConfirm: any
  openForecastDeleteDialog(elem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.PROCESS_DELETE_FORECAST", emitter: null, obj: elem, action: "CONFIRM" }
    });
    
    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) {
        this.spinner.show()
        this.forecastService.deleteResource('forecast', {id: elem.forecast.id}).subscribe(res => {
          this.loadTable()
          this.spinner.hide()
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionConfirm.unsubscribe()
    });
  }
  selectMonth(val) {
    this.loadTable()
  }
  downloadPerformance() {
    let headers = [
      { header: this.translate.instant("PERFORMANCE.TABLE_COLUMN_REVENUE-CODE"), key: 'code' },
      { header: this.translate.instant("PERFORMANCE.TABLE_COLUMN_REVENUE-CODE-NAME"), key: 'name' },
      { header: this.translate.instant("PERFORMANCE.TABLE_COLUMN_AMOUNT"), key: 'amount' },
      { header: this.translate.instant("PERFORMANCE.TABLE_COLUMN_TOTAL_REVENUE_AMOUNT"), key: 'totalRevenueAmount' },
      { header: this.translate.instant("PERFORMANCE.TABLE_COLUMN_MUNICIPALITY"), key: 'municipality' }
    ]
    this.loading.downloadInProgress = true
    this.forecastService.downloadPerformance(this.filterObject, headers).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }
  downloadPerformancePdf() {
    this.loading.downloadInProgress = true
    let headers = {

      title: this.translate.instant("PERFORMANCE.PDF_PERFORMANCE_TITLE"),
      col1: this.translate.instant("PERFORMANCE.PDF_TABLE_REVENUE_CODE"),
      col2: this.translate.instant("PERFORMANCE.PDF_TABLE_BUDGET"),
      col3: this.translate.instant("PERFORMANCE.PDF_TABLE_PREV_MONTH"),
      col4: this.translate.instant("PERFORMANCE.PDF_TABLE_CUR_MONTH"),
      col5: this.translate.instant("PERFORMANCE.PDF_TABLE_YEAR_TOTAL"),
      col6: this.translate.instant("PERFORMANCE.PDF_TABLE_PERCENTAGE"),
      monthTitle: this.translate.instant("PERFORMANCE.PDF_MONTH_TITLE"),
      year: this.filterObject["year"],
      month: this.translate.instant("PERFORMANCE."+this.filterObject["month"]),
      municipality: this.filterData['municipality'].find(elem => elem.id === this.filterObject['municipality'][0]).name
    }

    this.forecastService.downloadPerformancePDF(this.filterObject, headers).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }
  getReportMonth(month) {
    let m = month * 1 + 1
    return m<10?'0'+m:m+''
  }
  downloadFile(fileObj) {
    this.loading.downloadAttachmentInProgress = true
    this.processService.downloadFileFromDocflow(fileObj.attachment).subscribe
      (data => {
        this.loading.downloadAttachmentInProgress = false
        const blob = data;
        const file = new Blob([blob], {});
        const filename = fileObj.filename;
        saveAs(file, filename);
      });
  }
}
