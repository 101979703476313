import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EventEmitter } from "events";
import { DialogData } from "src/app/entities/dialogData";
import { UserStatus } from "src/app/entities/states/userStatus";
import { User } from "src/app/entities/user";
import { UserStatusName } from "src/app/entities/util";
import { DropdownService } from "src/app/services/dropdown.service";
import { SharedService } from "src/app/services/sharedService";
import { UserService } from "src/app/services/user.service";
import { DialogCommunicator } from "../dialog-communicator";
import { InformationDialogComponent } from "../information-dialog/information-dialog.component";

@Component({
  selector: "gov-user-dialog",
  templateUrl: "./user-dialog.component.html",
  styleUrls: ["./user-dialog.component.css"],
})
export class UserDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  user: any;
  statusNames = UserStatusName;
  userStatus = UserStatus;
  branches = [];
  usernames = [];
  userroles = [
    "ADMIN",
    "MAYOR_TREASURER",
    "CENTRAL_READER",
    "FINANCIAL_MANAGER_ADMINISTRATOR",
    "CENTRAL_EDITOR",
    "SECRETARY_ASSISTANT",
  ];
  repeatPassword = "";
  municipalities: any[];
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    public userService: UserService,
    public confirmationDialog: MatDialog,
    public dropdownService: DropdownService
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  async ngOnInit() {
    this.user = new User();
    
    if (this.data) {
      this.user = await this.userService.getResource("user", this.data["id"]).toPromise()
      this.user["class"] = "USER";
      this.setEntity(this.user);
    } else {
      this.user.status = UserStatus.ACTIVE;
      this.setEntity(this.user);
    }

    try {
      this.municipalities = await this.dropdownService.getList("municipality").toPromise()
    } catch (error) {
      console.log(error)
    }
 
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value) return option === value;
    return false;
  };
  selectChange = (event: any) => {
    this.user["municipalities"] = event.data;
  };

  needForMunicipalityByRole(role) {
    return !["ADMIN", "CENTRAL_READER", "CENTRAL_EDITOR"].includes(role);
  }

  async saveUser(id) {
    let res = await this.userService.getUserByEmail(this.user.email).toPromise()
    if (!res) {
      this.onUpdateClick(id ? "SAVE" : "UPDATE", "UPDATE_USER");
    } else {
      if (!id || (id && res.id !== id)) {
        this.dialog.open(InformationDialogComponent, {
          width: "500px",
          disableClose: true,
          data: { text: "DIALOG.EMAIL_ALREADY_EXISTS" },
        });
      } else {
        this.onUpdateClick(id ? "SAVE" : "UPDATE", "UPDATE_USER");
      }
    }
  }
}
