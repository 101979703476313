export class Forecast {
    public id: number;
    public forecast: number;
    public revenueCodeId: any;
    public revenueCodeName: string;
    public revenueCode: string;
    public municipalityId: string;
    public year: string;
    public fees = []
    constructor() {
   
    }
  
  }  