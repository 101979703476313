import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ExternalService } from 'src/app/services/eternal.service';
import { SharedService } from 'src/app/services/sharedService';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'rdf-external-avis',
  templateUrl: './external-avis.component.html',
  styleUrls: ['./external-avis.component.css']
})
export class ExternalAvisComponent implements OnInit {

  constructor(public translate: TranslateService, public sharedService: SharedService, private route: ActivatedRoute, private externalService: ExternalService) { }
  avis
  elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string = 'RDF';
  error = false
  reference
  async ngOnInit() {
    if (this.route.snapshot.paramMap.get('reference')) {
      await this.setServerPath()
      this.reference = this.route.snapshot.paramMap.get('reference')
      this.avis = await this.externalService.getAvis(this.reference).toPromise()
      if (!this.avis) {
        this.error = true
      }
    }
  }
  setServerPath() {
    return new Promise(resolve => {
      this.sharedService.getServerStatus(environment.apihost[0]).subscribe((resp) => {
        this.initializeSettings(environment.apihost[0]).then(resolve)
      }, err => {
        this.initializeSettings(environment.apihost[1]).then(resolve)
      })
    })
  }

  initializeSettings(path) {
    return new Promise(resolve => {
      if (!localStorage.getItem("serverPath")) {
        localStorage.setItem("serverPath", path);
      }
      resolve({});
    })
  }

  downloadAvisPdf() {
    this.externalService.downloadAvisPdf(this.avis.id).subscribe
      (data => {
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }
}
