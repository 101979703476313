import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './sharedService';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class TaxDivisionService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }

}



