import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/services/admin.service";

@Component({
  selector: "app-sms-configuration",
  templateUrl: "./sms-configuration.component.html",
  styleUrls: ["./sms-configuration.component.css"],
})
export class SmsConfigurationComponent implements OnInit {
  applicationsSmsConfigurations;
  admin;
  loading = {
    balance: false,
    error: false,
  };
  constructor(
    private adminSevice: AdminService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private router: Router 
  ) {}

  async ngOnInit() {
    this.admin = await this.adminSevice.getAdmin().toPromise();
    this.applicationsSmsConfigurations = {
      payment: {
        legend: [
          "MUNICIPALITY",
          "REFERENCE_NUMBER",
          "PROCESS_NAME",
          "AMOUNT",
          "CURRENCY",
          "TAS_URL_CODE",
        ],
        data: {},
      },
      avis: {
        legend: [
          "MUNICIPALITY",
          "REFERENCE_NUMBER",
          "PROCESS_NAME",
          "AMOUNT",
          "CURRENCY",
          "TAS_URL_CODE",
        ],
        data: {},
      },
    };
    if (this.admin && this.admin.smsConfig) {
      if (this.admin.smsConfig.payment) {
        this.applicationsSmsConfigurations.payment["data"] =
          this.admin.smsConfig.payment;
      }
      if (this.admin.smsConfig.avis) {
        this.applicationsSmsConfigurations.avis["data"] =
          this.admin.smsConfig.avis;
      }
    }
  }

  async saveConfig(config) {
    await this.adminSevice.addAdmin("admin/smsConfig", config).toPromise();
    this.admin = await this.adminSevice.getAdmin().toPromise();
    this.toastr.success(
      this.translateService.instant("SETTINGS.SUCCESS"),
      this.translateService.instant("SETTINGS.SMS_SETTINGS_SAVED"),
      { toastClass: "ngx-toastr border-red", timeOut: 3000 }
    );
  }
  balance;

  async getBalance(){
    this.loading.balance = true
    this.loading.error = false
    this.balance = null
      let attempt = 0;
      while (attempt < 2) {
        try {
          attempt++;
          let res = await this.adminSevice.getSmsBalance().toPromise()
          if (res.error) {
            continue; 
          } else {
            this.balance = res
            break; 
          }
        } catch (error) {
          if (error.status === 401){
            this.router.navigate(['home'])
          }
          if (error.name !== 'TimeoutError' || attempt >= 2) {
            console.error('Fetch failed:', error);
            this.loading.error = true
            this.balance = null
            break;
          }
        }
      }
      if (attempt === 2 && this.balance === undefined) {
        this.balance = null
        this.loading.error = true
      }
      this.loading.balance = false
    }
  // async getBalance() {

  //   let resp = await this.adminSevice.getSmsBalance().toPromise()
  //   this.loading.balance = false
  //   if (resp['error']){
  //     resp = await this.adminSevice.getSmsBalance().toPromise()
  //     if (resp['error']){
  //       this.balance = null
  //       this.loading.error = true
  //     }else{
  //       this.balance = resp
  //     }
  //   }else{
  //     this.balance = resp
  //   }
  // }
}
