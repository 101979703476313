import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { ReportService } from "src/app/services/report.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-users-search",
  templateUrl: "./search-users.component.html",
  styleUrls: ["./search-users.component.css"],
})
export class SearchUsersComponent implements OnInit {
  userroles = [ 'ADMIN', 'MAYOR_TREASURER', 'CENTRAL_READER','FINANCIAL_MANAGER_ADMINISTRATOR',
    'CENTRAL_EDITOR', 'SECRETARY_ASSISTANT'
  ];
  @ViewChild('userSelect', { static: false }) userSelect: MultiselectAutocompleteComponent;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('recurringSelect', { static: false }) recurringSelect: MultiselectAutocompleteComponent;
  filterData = {
    category: null,
    municipality: null,
    recurring: null,
    users: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields : any;
  queryFilter = {};
  filterFields = ["category", "municipality", "user"];
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public reportService: ReportService, public userService: UserService
  ) {
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
        });
    });
    reportService.getReportsList("?active=true&recurring=true").subscribe(rep => {
      if (rep && rep.reports){
        this.filterData.recurring = rep.reports.map(elem => ({id:elem._id, name: elem.name + ' ' + elem.year}))
      }
    })
    this.userService.getUsernames().subscribe(val => {
      this.filterData.users = val.map(user => ({ id: user.username.trim(), name: user.username }))
      .sort((a, b) => a.name.localeCompare(b.name));
    })
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length===0){
      delete this.queryFilter[field]
    }
  };
  resetFilter() {
    this.queryFilter = {}
    this.userSelect.resetAll()
    this.municipalitySelect.resetAll()
    this.onReset.emit()
    
  }
  advancedSearch() {
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }

  ngOnInit() {}
}
