import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChange, SimpleChanges, ViewChildren } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThousandSeparatorPipe } from 'src/app/pipes/thousand-separator.pipe';
import { ProcessService } from 'src/app/services/process.service';
import { SharedService } from 'src/app/services/sharedService';
import { Column } from '../form-creation/form-creator/column';
@Component({
  selector: 'tas-table',
  templateUrl: './tas-table.component.html',
  styleUrls: ['./tas-table.component.css'],

})
export class TasTableComponent implements OnInit {
  @ViewChildren('blockName') blocks: QueryList<ElementRef>
  //form editing section
  rowTypes = ["string", "number", "date", "checkbox"]
  styles = ["input","group", "text"]


  @Input() block;
  @Input() processId;
  @Input() processKey;
  @Input() stageKey;
  @Input() companyTin;
  @Input() stageData;
  @Input() globals = {}
  @Input() processYear: any
  
  @Output() onSelectedValue = new EventEmitter<any>();
  @Output() onRowKeyValue = new EventEmitter<any>();
  @Input() stage: any;
  @Input() selectedBlk: any;
  @Input() emittables = []
  @Input()
  formCreation: boolean;
  @Input()
  username;
  curlyBrackets = '{{'
  dataSourceOld = [];
  dataSources = [];
  originalData = [];
  categoryTableData: any;
  categoryTableColumns = [];
  originalDisplayedColumns = null;
  inputValues = {};
  attachments = {};
  backingData = [];
  requiredFields = [];
  stageOutput: any;
  globalsOutput: any;
  multiColumn: boolean;
  editMode = false;
  thisYear = new Date().getFullYear();
  mCategories = []
  multiColumns = []
  categoryHeader = ""
  data = {
    'thisYear': this.thisYear,
    'previousYear': this.thisYear - 1,
    'previousYearProcesses': {
      'dsf': {}
    },
    'processes': {
      'dsf': this.inputValues
    }
  };
  columnNames = {
    'name': 'Name',
    'key': 'Value'
  };

  columnStyles = {
  };

  columnNames2 = {
    'name': 'Name',
    'key': 'Value'
  };
  header_created = false;

  backingDataCounter = 0;

  clr = 1
  constructor(private thousandSeparatorPipe: ThousandSeparatorPipe, private sharedSevice: SharedService,
    public translateService: TranslateService, public processService: ProcessService,  private spinner: NgxSpinnerService) {

  }
  keytab(event) {
    if (event.key === "Enter") {
      // let element = event; // get the sibling element
      // if (element == null) {

      //   return;
      // } // check if its null

      // else {
      //   element.next(':input').focus()
      // }
    }
  }

  getColor() {
    if (this.clr === 1) {
      this.clr = 0
      return "#e6e6e6"
    }
    this.clr = 1
    return "lightgrey"
  }

  allConditionals = []
  totalFee = 0;
  processStageStructure() {
    for (const block of this.stage.blocks) {
      this.allConditionals = this.allConditionals.concat(block.rows.filter(elem=>['option','dropdown','checkbox'].includes(elem.type)))
    }
    for (const block of this.stage.blocks) {
      var dataSource1 = [];
      this.originalData = [];
      var displayedColumns = ['name'];
      var upperHeaderColumns = [];
      if (block.columns === undefined || block.columns.length===0) {
        for (const row of block.rows) {
        
          if (row.type==='total fee' && !this.formCreation){
            this.processService.getAvis(this.companyTin, this.processId).subscribe(res=> {
              this.totalFee = res
            })
          }
          let incRow = { name: row.name, key: row.key, default: row.default, enabled: row.default, style: row.style, type: row.type, required: row.required, global: row.global, avis: row.avis,  hasAttachment: row.hasAttachment||row.requiredAttachment, selectItems: row.selectItems||[], condition:row.condition, configDropdown:row.configDropdown}
          row.customValueEmitter && (incRow['customValueEmitter'] = row.customValueEmitter)
          this.originalData.push(incRow);
          if (this.stageData !== undefined) {
            var v = this.stageData.rows.find(cols => cols[row.key] !== undefined);
            if (v !== undefined) {
              this.inputValues[row.key] = v[row.key];
              this.stageOutput.rows.push({ [row.key]: v[row.key] });
              if (row.required !== undefined && row.required === true)
                this.requiredFields.splice(this.requiredFields.indexOf(row), 1)
            }
          }
          if (row.required !== undefined && row.required === true) {
            if (this.inputValues[row.key] === undefined || this.isEmptyOrSpaces(this.inputValues[row.key])) {
              this.requiredFields.push(row.key);
            }
          }
        }
        this.multiColumns[block.key] = false;
      } else {
        for (const row of block.rows) {
          const sourceRow = { name: row.name, key: row.key, default: row.default, enabled: row.default, mapping: row.mapping, style: row.style, bold: row.bold };
          this.inputValues[row.key] = {};
          if (this.stageData !== undefined) {
            var v = this.stageData.rows.find(cols => cols['key'] === row.key);
            if (v !== undefined) {
              for (const value of v.values) {
                var objKey = Object.keys(value)[0];
                this.inputValues[row.key][objKey] = value[objKey];
                var vxs = this.stageOutput.rows.find(cols => cols['key'] === row.key);
                if (vxs !== undefined) {
                  vxs.values.push({ [objKey]: value[objKey] });
                } else {
                  this.stageOutput.rows.push({ 'key': row.key, 'values': [{ [objKey]: value[objKey] }] });
                }
              }
            }
          }
          //if (row.values!==undefined)
          for (const value of row.values) {
            if (sourceRow['required'] === undefined) {
              sourceRow['required'] = [];
            }
            sourceRow['required'][value.column] = value.required
            if (value.required !== undefined && value.required === true) {
              if (this.inputValues[row.key][value.column] === undefined || this.isEmptyOrSpaces(this.inputValues[row.key][value.column])) {
                this.requiredFields.push(row.key + "." + value.column);
              }
            }


            if (sourceRow['types'] === undefined) {
              sourceRow['types'] = [];
            }
            sourceRow['types'][value.column] = value.type

            if (sourceRow['fontWeight'] === undefined) {
              sourceRow['fontWeight'] = [];
            }
            sourceRow['fontWeight'][value.column] = value.bold ? 'bold' : value.fontWeight

            if (value.formula === undefined){
              sourceRow[value.column] = value.value;
            }
              
            else {
              sourceRow[value.column] = value.formula
              sourceRow['style'] = 'bold'
            }
          }
      
          this.originalData.push(sourceRow);
        }
        for (const column of block.columns) {
          displayedColumns.push(column.key);
          this.columnNames[column.key] = column.name;
          this.columnStyles[column.key] = column.bold ? '800' : 'normal'
        }
        if (block.columnGroups !== undefined) {
          var grpCounter = 0;
          for (const column of block.columnGroups) {
            upperHeaderColumns[grpCounter] = column.name
            grpCounter++;
          }
        }
        this.multiColumns[block.key] = true;
      }
      dataSource1 = this.originalData.filter(elem => {
        return elem.enabled === true;
      });
      if (block.colType === "2" && this.stageData) {
        for (let x = 0; x < this.stageData.rows.length; x++) {
          let r = this.stageData.rows[x];
          if (r.key) {
            let spl = r.key.split("#####");
            if (spl.length > 1 && block.key === spl[0]) {
              this.stageOutput.rows.push({key: r.key, values: r.values})
              let result = r.values.reduce((obj, item) => {
                for (let key in item) {
                  obj[key] = item[key];
                }
                return obj;
              }, {});
              this.inputValues[r.key] = result;
              let cloneDatasource = Object.assign([], dataSource1);
              let m = cloneDatasource[cloneDatasource.length - 1];
              let newRow = Object.assign({}, m);
              newRow.key = r.key;
              cloneDatasource.push(newRow);
              dataSource1 = cloneDatasource;
            }
          }
        }
        
      }
      if (!block.colType){
        if ((!block.columns || block.columns.length>0) && block.rows.length>0){
          block.colType = '0'
        }
      }
      let conditionals =  this.allConditionals.filter(elem=> !block.rows.filter(l=>['checkbox','option','dropdown'].includes(l.type)).find(el=>elem.key===el.key))
      this.dataSources.push({ 'datasource': dataSource1, rows:block.rows, columns:block.columns, 'displayedColumns': displayedColumns, 'multiColumn': this.multiColumns[block.key], 'blockName': block.name, 'columnGroups': block.columnGroups, 'upperHeaderColumns': upperHeaderColumns, avisBlock: block.avisBlock, condition: block.condition, conditionals, colType: block.colType, sumTotals: block.sumTotals});
    }
    this.stageOutput['status'] = this.requiredFields.length === 0 ? "done" : "processing"
    this.stageOutput['name'] = this.stage.name
    // if (this.stageOutput['globals'] === undefined)
    this.stageOutput['globals'] = this.globals ? this.globals : {}
    this.onSelectedValue.emit(this.stageOutput);
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      if (this.blocks.toArray()[this.selectedBlk]) this.blocks.toArray()[this.selectedBlk].nativeElement.focus();
    },0);  
  }
  onColumnTypeChange(ev, selectedBlk){
    this.sharedSevice.updateColType(selectedBlk, ev.value)
  }
  setSumTotals(ev, selectedBlk){
    this.sharedSevice.updateSumTotals(selectedBlk, ev.checked)
  }
  setCondition(event, dtInd){
    this.sharedSevice.setBlockCondition(event, dtInd)
  }

  getSum(col,dtInd){
    let currentDatasource = this.dataSources[dtInd].datasource[0]
    if (!currentDatasource || currentDatasource['types'][col]!=='number')
    return ''
    let sum = 0;
    this.stageOutput.rows.forEach(r => {
      r.values.forEach(val => {
        if (val[col]){
          sum +=val[col]*1
        }
      })
    })
    return sum
  }

  processPreviousYearJSON(data) {
    for (const stage of data.stages) {
      for (const row of stage.rows) {
        if (row.key === undefined) continue
        for (const value of row.values) {
          this.data.previousYearProcesses.dsf[row.key] = {}
          this.data.previousYearProcesses.dsf[row.key][Object.keys(value)[0]] = value[Object.keys(value)[0]];
        }
      }
    }
  }

  ngOnInit() {

    if (this.processId) {
      this.processService.getAttachments(this.processId, this.stageKey).subscribe
        (attachments => {
       
          this.attachments = {};
          attachments.forEach(att => {
            for (var key in att[this.stageKey]) {
              this.attachments[key] = att[this.stageKey][key]
            }
          })
          this.initialize()
        });
    } else {
      this.initialize()
    }

   
 
  }

  initialize() {
    this.stageOutput = {
      'key': this.stageKey,
      'rows': []
    };
      this.processStageStructure();
      //this.onSelectedValue.emit(this.stageOutput);
  }

  renderMustache(template, index, row, col) {
    if (!template) return
    if (template.indexOf('{{') === -1) return index === 0 ? "" : template
    const string = template.replace(
      /{{\s?([^{}\s]*)\s?}}/g,
      (substring, parsedKey) => {
        const replacer = parsedKey.split('.').reduce((o, i) => {
          if (o !== undefined) {
            return (o[i] && o[i].value) || o[i]
          }
        },
          this.inputValues)
        return typeof replacer !== 'undefined' && replacer !== '' && !isNaN(replacer) ? replacer : 0;
      }
    );
    if (string === template || string.indexOf('{{') !== -1) {
      return null;
    } else {
      try {

        var res = eval(string);
        if (isNaN(res)) {
          return res;
        }
        else {
          this.inputValues[row['key']][col] = res

          return res;
        }

      } catch (e) {
        return this.translateService.instant('PROCESS.SUBMITED_FILE_REPORT')
        // return 'Incorrect value in input field.'; 
      }
    }
  }
  a = 0;
  getToggleColor() {
    if (this.a === 0) {
      this.a = 1;
      return "#6699ff"

    }
    this.a = 0;
    return "white"
  }
  arrangeRequiredField(required, rowKey) {
    if (this.requiredFields.find(elem => elem === rowKey) !== undefined) {
      this.requiredFields.splice(this.requiredFields.indexOf(rowKey), 1)
    }
    else if (required && this.isEmptyOrSpaces(this.getEventValue(event))) {
      this.requiredFields.push(rowKey)
    }
  }

  onChange(rowElem, col = null, multiColumn, event) {
    let row = rowElem['key']
    let required = col !== null ? (rowElem['required'][col] !== undefined ? rowElem['required'][col] : false) : (rowElem['required'] !== undefined ? rowElem['required'] : false)
    let pushed = false;
    let pushedGlobal = false;
    if (!multiColumn) {

      this.arrangeRequiredField(required, row)
      for (const stageRow of this.stageOutput.rows) {
        if (stageRow[row] !== undefined) {
          stageRow[row] = this.getEventValue(event);
          pushed = true;
          break;
        }
      }
      if (!pushed) {
        this.stageOutput.rows.push({
          [row]: this.getEventValue(event)
        });
      }

      if (rowElem['global']) {
        // if (this.stageOutput.globals === undefined) {
        //   this.stageOutput['globals'] = []
        // }
        for (const stageRow of this.stageOutput.globals) {
          if (stageRow[row] !== undefined) {
            stageRow[row] = this.getEventValue(event);
            pushedGlobal = true;
            break;
          }
        }
        if (!pushedGlobal) {
          // this.stageOutput.globals.push({
          //   [row]: this.getEventValue(event)
          // });

          this.stageOutput.globals[row] = this.getEventValue(event)

        }
      }
    } else {

      this.arrangeRequiredField(required, row + '.' + col)
      for (const stageRow of this.stageOutput.rows) {
        if (stageRow['key'] === row) {
          for (const stageValue of stageRow.values) {
            if (stageValue[col] !== undefined) {
              stageValue[col] = this.getEventValue(event);
              pushed = true;
            }
          }
          if (!pushed) {
            if (event.source instanceof MatRadioButton) {
              stageRow.values = []
              stageRow.values.push({
                [col]: this.getEventValue(event)
              });
            } else {
              stageRow.values.push({
                [col]: this.getEventValue(event)
              });
            }
            pushed = true;
          }
        }

      }
      if (!pushed) {
        if (event.source instanceof MatRadioButton) {
          this.stageOutput.rows.push({
            'key': row,
            'values': [{
              [col]: this.getEventValue(event)
            }]
          });
        } else {
          this.stageOutput.rows.push({
            'key': row,
            'values': [{
              [col]: this.getEventValue(event)
            }]
          });
        }
      }
    }
    this.stageOutput['status'] = this.requiredFields.length === 0 ? "done" : "processing"
    this.stageOutput['name'] = this.stage.name
    this.onSelectedValue.emit(this.stageOutput);


  }

  // edit() {
  //   if (!this.editMode) {
  //     this.editMode = true;
  //     this.dataSourceOld = this.originalData;
  //     this.originalDisplayedColumns = this.displayedColumns;
  //     this.displayedColumns = ['default', 'name'];
  //   } else {
  //     this.editMode = false;
  //     this.dataSourceOld = this.originalData.filter(elem => {
  //       return elem.enabled === true;
  //     });
  //     this.displayedColumns = this.originalDisplayedColumns;
  //     this.originalDisplayedColumns = null;
  //   }
  // }

  downloadFileFromDocFlow(fileObj) {
    this.spinner.show();
    this.processService.downloadFileFromDocflow(fileObj.file).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = fileObj.filename;
        saveAs(file, filename);
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    const stage: SimpleChange = changes.stage;
  }
  isEmptyOrSpaces(str) {
    const stringValue = str.toString();
    return stringValue === null || stringValue.match(/^ *$/) !== null;
  }

  checkboxClick(event, key) {
    const rowToBeChanged = this.originalData.find(elem => {
      return elem.key === key;
    });
    rowToBeChanged.enabled = event.checked;
    if (event.checked === false) {
      if (!this.multiColumn) {
        for (const row of this.stageOutput.rows) {
          if (row[key] !== undefined) {
            delete row[key];
          }
        }
      } else {
        for (let i = 0; i < this.stageOutput.rows.length; i++) {
          if (this.stageOutput.rows[i].key === key) {
            delete this.stageOutput.rows[i];
          }
        }
      }
      Object.keys(this.inputValues[key]).forEach(col => {
        this.inputValues[key][col] = 0;
      });
    }
  }

  onAmountChange(amount: string, name) {
  }

  getEventValue(event) {
    return event.source instanceof MatCheckbox ? event.checked :
      event.source instanceof MatRadioButton ? event.value :
        event.target !== undefined ? event.target.value : event
  }
  printKey(key, col) {
    this.onRowKeyValue.emit("{{" + key + "." + col + "}}");
    this.sharedSevice.emitFormulaKey("{{" + key + "." + col + "}}")
  }


  deleteBlock(blockIndx) {
    this.sharedSevice.updateDeleteBlock(blockIndx)
  }
  renameBlock(name, blockIndx) {
    this.sharedSevice.updateRenameBlock(name, blockIndx)
  }

  addRow(row, dtIndx) {
    this.sharedSevice.updateAddRow(dtIndx, row)
  }

  updateRow(row, dtIndx) {
    this.sharedSevice.updateUpdateRow(dtIndx, row)
    this.cancelRow()
  }

  deleteRow(row, dtIndx) {
    this.sharedSevice.deleteRow(dtIndx, row)
    this.cancelRow()
  }

  cancelRow() {
    this.showEditBtn = true;
    this.editingRowKey = undefined
  }

  editingRowKey: any
  showEditBtn = true;

  moveRow(rowKey, dtIndx, direction) {
    this.sharedSevice.updateMoveRow(dtIndx, rowKey, direction)
  }


  editingRow(rowKey, dtIndx) {
    this.editingRowKey = rowKey;
    //var row = this.stage.blocks[dtIndx].find(elem => elem['key'] === rowKey)
    //this.sharedSevice.updateEditRow(dtIndx, rowKey)
    this.showEditBtn = false;
  }
  editingColumnKey: any
  editingColumnIndex: any
  editingColumn(columnKey, dtIndx, index) {
    this.editingColumnIndex = index
      this.editingColumnKey = columnKey;
     this.showEditBtn = false;
  }

  cancelColumn() {
    this.showEditBtn = true;
    this.editingColumnKey = undefined
    this.editingColumnIndex = undefined
  }

  addColumn(col, dtIndx) {
    this.sharedSevice.updateAddColumn(dtIndx, col)
  }

  deleteColumn(col, dtIndx) {
    this.sharedSevice.deleteColumn(dtIndx, col)
  }

  updateColumn(col, dtIndx) {
    this.sharedSevice.updateColumn(dtIndx, col.column, this.editingColumnIndex)
  }

  getColumn(columns, col){
    var colF = columns.find(elem=>elem.key===col);
    return colF!==undefined?colF:new Column()
  }
  
  setAvisBlock(ev, blockIndx) {
    this.sharedSevice.updateAvisBlock(ev.checked, blockIndx)
  }
}
