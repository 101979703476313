import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { RevenueCodeService } from 'src/app/services/revenueCode.service';
import { RevenueCodeDialogComponent } from '../dialogs/revenue-code-dialog/revenue-code-dialog.component';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'tas-gov-revenue-code',
  templateUrl: './revenue-code.component.html',
  styleUrls: ['./revenue-code.component.css']
})
export class RevenueCodeComponent implements OnInit, AfterViewInit, OnDestroy {


  subscriptionUpdateRevenueCode: any;
  filterObject: {};
  displayedColumns: string[] = ['code', 'name'];
  dataSource: TasDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private revenueCodeService: RevenueCodeService, public dialog: MatDialog, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.revenueCodeService)
    this.subscriptionUpdateRevenueCode = this.sharedService.updateRevenueCodeEmmitter.subscribe(val => {
      if (val) {
        this.revenueCodeService.updateResource('revenue-code', val).subscribe(res => {
          this.loadRevenueCodeTable();
        })
      }
    })
 
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('revenue-code', '', 'asc', 'name', 0, this.paginator.pageSize, this.filterObject);

    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadRevenueCodeTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadRevenueCodeTable())
      )
      .subscribe();

  }

  loadRevenueCodeTable() {
    this.dataSource.loadData('revenue-code',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  openRevenueCodeDialog(revenueCode): void {
    const dialogRef = this.dialog.open(RevenueCodeDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: revenueCode
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateRevenueCode) this.subscriptionUpdateRevenueCode.unsubscribe();
  }

}

