import { CompanyRepresentativeStatus } from './states/companyRepresentativeStatus';

export class CompanyRepresentative {
  public id: number;
  public name: string;
  public username: string;
  public surname: string;
  public email: string;
  public status: CompanyRepresentativeStatus;
  public address : string;
  public telephone : string;
  
  constructor() {
 
  }

}  