import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rdf-block-controls',
  templateUrl: './block-controls.component.html',
  styleUrls: ['./block-controls.component.css']
})
export class BlockControlsComponent implements OnInit {
  @Input() block;
  @Input() stage;
  @Input() blockPosition
  @Output() onDeleteBlock = new EventEmitter()
  @Output() onRenameBlock = new EventEmitter()
  @Output() onMoveBlock = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }

  deleteBlock() {
     this.stage.blocks = this.stage.blocks.filter(elem => elem.key !== this.block.key)
   }
 
   moveBlock(val) {
     this.blockPosition = val
     let blocks = this.stage.blocks
     const currentIndex = blocks.findIndex((block) => block.key === this.block.key);
     const [objToMove] = blocks.splice(currentIndex, 1);
     blocks.splice(this.blockPosition, 0, objToMove);
   }
}
