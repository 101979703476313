import { TaxPayerStatus } from './states/taxPayerStatus';

export class TaxPayer {
  public id: number;
  public compaanyName: string;
  public tin: string;
  public status: TaxPayerStatus;
  public address: string;
  public email:string;
  public telephone:string;
  
  constructor() {
 
  }

}  