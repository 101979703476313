import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  private readonly TIMEOUT = 120000;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      timeout(this.TIMEOUT),
      catchError(error => {
        if (error.name === 'TimeoutError') {
          this.showErrorMessage('Request timed out.');
        }
        return throwError(error);
      })
    );
  }

  showErrorMessage(message: string) {
  //  alert(message);
  }
}
