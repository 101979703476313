import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/services/sharedService';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { TaxNameService } from 'src/app/services/taxName.service';
import { TaxNameDialogComponent } from '../dialogs/tax-name-dialog/tax-name-dialog.component';

@Component({
  selector: 'tas-gov-tax-name',
  templateUrl: './tax-name.component.html',
  styleUrls: ['./tax-name.component.css']
})
export class TaxNameComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptionUpdateTaxName: any;
  filterObject: {};
  displayedColumns: string[] = ['name', 'revenueCode', 'municipalities', 'categories', 'amount'];
  dataSource: TasDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  loading = { inProgress : false, downloadInProgress: false, error: false }
  constructor(private taxNameService: TaxNameService, public dialog: MatDialog, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.taxNameService)
    this.subscriptionUpdateTaxName = this.sharedService.updateTaxNameEmmitter.subscribe(val => {
      if (val) {
        this.taxNameService.updateResource('tax-name', val).subscribe(res => {
          this.loadTaxNameTable();
        })
      }
    })
 
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadTaxNameTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTaxNameTable())
      )
      .subscribe();

  }
  resetLoading = () => {
    this.loading.inProgress = true
    this.loading.error = false
    this.dataSource.emptyData()
  }
  advancedSearch(filter){
    if (!this.filterObject){
      this.filterObject = {}
    }
    let advF = [
      'municipality',
      'type'
    ];
    advF.forEach((elem) => {
      if (!filter[elem] || filter[elem].length===0) delete this.filterObject[elem];
    });
    this.filterObject = Object.assign({}, this.filterObject, filter);
    this.resetLoading()
    this.dataSource.loadData(
      "tax-name",
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject,
      this.loading
    );
  }
  loadTaxNameTable() {
    this.resetLoading()
    this.dataSource.loadData('tax-name',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject, this.loading);
  }

  openTaxNameDialog(taxName): void {
    const dialogRef = this.dialog.open(TaxNameDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: taxName
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateTaxName) this.subscriptionUpdateTaxName.unsubscribe();
  }

}

