import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogData } from "src/app/entities/dialogData";
import { DropdownService } from "src/app/services/dropdown.service";
import { SharedService } from "src/app/services/sharedService";
import { UserService } from "src/app/services/user.service";
import { DialogCommunicator } from "../dialog-communicator";

@Component({
  selector: "gov-sms-log-dialog",
  templateUrl: "./sms-log-dialog.component.html",
  styleUrls: ["./sms-log-dialog.component.css"],
})
export class SmsLogDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  smsLog: any;
  repeatPassword = "";
  municipalities: any[];
  smsType = 'pr'
  errorMsg
  constructor(
    public dialogRef: MatDialogRef<SmsLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    public userService: UserService,
    public confirmationDialog: MatDialog,
    public dropdownService: DropdownService
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  async ngOnInit() {
    if (this.data) {
      this.smsLog = await this.userService.getResource("sms-log", this.data["id"]).toPromise()     
      if (this.smsLog && this.smsLog.smsType){
        if (this.smsLog.error && this.smsLog.status === 'PENDING'){
          this.errorMsg = JSON.stringify(this.smsLog.error, null, 2);
        }
        switch (this.smsLog.smsType) {
          case 'avis':
              this.smsType = 'av'
            break;
          default:
            break;
        }
      }
    }  
  }
}
