import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ExternalService } from 'src/app/services/eternal.service';
import { SharedService } from 'src/app/services/sharedService';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'rdf-external-receipt',
  templateUrl: './external-receipt.component.html',
  styleUrls: ['./external-receipt.component.css']
})
export class ExternalReceiptComponent implements OnInit {

  constructor(public translate: TranslateService, public sharedService: SharedService, private route: ActivatedRoute, private externalService: ExternalService) { }
  receipt
  elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string = 'RDF';
  error = false
  reference
  async ngOnInit() {
    if (this.route.snapshot.paramMap.get('reference')) {
      await this.setServerPath()
      this.reference = this.route.snapshot.paramMap.get('reference')
      this.receipt = await this.externalService.getPaymentReceipt(this.reference).toPromise()
      if (!this.receipt) {
        this.error = true
      }
    }
  }
  setServerPath() {
    return new Promise(resolve => {
      this.sharedService.getServerStatus(environment.apihost[0]).subscribe((resp) => {
        this.initializeSettings(environment.apihost[0]).then(resolve)
      }, err => {
        this.initializeSettings(environment.apihost[1]).then(resolve)
      })
    })
  }
  initializeSettings(path) {
    return new Promise(resolve => {
      if (!localStorage.getItem("serverPath")) {
        localStorage.setItem("serverPath", path);
      }
      resolve({});
    })
  }
  downloadReceiptPdf() {
    let headers = {
      title: this.translate.instant("RECEIPT.ELECTRONIC_PAYMENT"),
      company: this.translate.instant("RECEIPT.COMPANY_NAME"),
      tin: this.translate.instant("RECEIPT.TIN"),
      monthYear: this.translate.instant("RECEIPT.MONTH_YEAR"),
      referenceNumber: this.translate.instant("RECEIPT.REFERENCE_NUMBER"),
      processName: this.translate.instant("RECEIPT.PROCESS_NAME"),
      telephone: this.translate.instant("RECEIPT.TELEPHONE"),
      taxName: this.translate.instant("RECEIPT.TAX_NAME"),
      amount: this.translate.instant("RECEIPT.AMOUNT"),
      collector: this.translate.instant("RECEIPT.COLLECTOR"),
      serialNumber: this.translate.instant("RECEIPT.RECEIPT_CODE"),
      thanksText: this.translate.instant("RECEIPT.THANKS"),
      dateTime: this.translate.instant("RECEIPT.DATE"),
    }

    this.externalService.downloadReceiptPdf(this.reference, headers).subscribe
      (data => {
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }
}
