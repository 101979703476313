import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'rdf-custom-calculation-emitter',
  templateUrl: './custom-calculation-emitter.component.html',
  styleUrls: ['./custom-calculation-emitter.component.css']
})
export class CustomCalculationEmitterComponent implements OnInit {
  @Input()
  selectables
  selectItems = []
  @Input()
  row
  @Output() onSelectedValue = new EventEmitter<any>()
  emittables = []
  stageName: any
  stageKey: any
  emitter: any
  constructor() { }

  ngOnInit() {
    if (this.row.customValueEmitter){
      this.stageKey = this.row.customValueEmitter.stageKey
      this.stageName = this.row.customValueEmitter.stage
      if (this.stageKey){
        this.emittables = this.selectables.find(elem=>elem.key===this.stageKey).emittables.map(el=>{ return {name:el.name, key:el.key} })
      }
      this.emitter = this.row.customValueEmitter.key
    }
  }
  changeSelection(ev){
   let st = this.selectables.find(elem=>elem.key===this.stageKey)
   let emtbl = st.emittables.find(elem => elem.key === ev.value)
    this.onSelectedValue.emit({key:emtbl.key, stage:this.stageName, stageKey:this.stageKey, name:emtbl.name})
  }
  changeStageSelection(ev){
    let st = this.selectables.find(elem=>elem.key===ev.value)
    this.stageName = st.name
    this.stageKey = st.key
    this.emittables = st.emittables.map(el=>{ return {name:el.name, key:el.key} })
    
  }
  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.key === value.key;
    return false;
  }
 
}