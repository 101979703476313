import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { ReportService } from "src/app/services/report.service";
import { SharedService } from "src/app/services/sharedService";
import { YearService } from "src/app/services/year.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
@Component({
  selector: "app-statistics-search",
  templateUrl: "./search-statistics.component.html",
  styleUrls: ["./search-statistics.component.css"],
})
export class SearchStatisticsComponent implements OnInit, OnDestroy {
  @ViewChild('categorySelect', { static: false }) categorySelect: MultiselectAutocompleteComponent;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('revenueCodeSelect', { static: false }) revenueCodeSelect: MultiselectAutocompleteComponent;
  @ViewChild('statusSelect', { static: false }) statusSelect: MultiselectAutocompleteComponent;
  @ViewChild('yearSelect', { static: false }) yearSelect: MultiselectAutocompleteComponent;
  municipalityChangeSubscription: any;
  @Input() internal;
  statuses: any
  years: any
  filterData = {
    category: null,
    municipality: null,
    report: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields: any;
  queryFilter = {};
  filterFields = ["municipality"];
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService, public sharedService: SharedService,
    public reportService: ReportService, public yearService: YearService, public translate: TranslateService
  ) {

 
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length === 0) {
      delete this.queryFilter[field]
    }
  };

  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
  }
  resetFilter() {
    this.queryFilter = {}
    this.municipalitySelect.resetAll()
    this.revenueCodeSelect.resetAll()
    this.statusSelect.resetAll()
    this.yearSelect.resetAll()
  }

  advancedSearch() {
    let selData = []
    selData = this.municipalitySelect.selectData.filter(el=> el.selected).map(el => el.item.id)
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.queryFilter['municipality']
      } else {
        if (selData.length>0)
          this.queryFilter['municipality'] = selData
        else
        this.queryFilter['municipality'] = [localStorage.getItem('municipality')]
      }
    } else {
      if (this.municipalitySelect.selectData.length === 0)
        delete this.queryFilter['municipality']
    }
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
  }

  ngOnInit() {
    this.translate.get('DECLARATIONS.SUBMITTED').subscribe((translated: string) => {
      this.statuses = [
        { name: this.translate.instant("DECLARATIONS.SUBMITTED"), id: 'Submitted' },
        { name: this.translate.instant("DECLARATIONS.INPROGRESS"), id: 'In Progress (Draft)' },
        { name: this.translate.instant("DECLARATIONS.INPROGRESS_AUTO"), id: 'In Progress_auto' }
      ]
    });
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') !== '-1') {
          this.municipalitySelect.resetAll()
          this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
        }
        else { 
          this.municipalitySelect.resetAll()
        }
      }else{
        this.municipalitySelect.resetAll()
      }
    })

    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
          setTimeout(()=>{
            if (localStorage.getItem('municipality')) {
              if (localStorage.getItem('municipality') !== '-1') {
                this.municipalitySelect.resetAll()
                this.municipalitySelect.setSelectedData(this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality')))
              }
              else { 
                this.municipalitySelect.resetAll()
              }
            }else{
              this.municipalitySelect.resetAll()
            }
          }, 1000)
        });
    });
    this.yearService.getList("year").subscribe(val => {
      this.years = val.map(elem => ({ name: elem.year, id: elem.year }))
    });
    this.reportService.getReportsList("?active=true&internal="+this.internal).subscribe(rep => {
      if (rep && rep.reports) {
        this.filterData.report = rep.reports.filter(elem=>elem.revenueCode).map(elem => ({ id: elem.revenueCode[0].code, name: elem.revenueCode[0].code })).filter(elem => elem.id !== undefined)
        this.filterData.report.sort((a, b) => a.name.localeCompare(b.name));
        this.filterData.report = [...this.filterData.report.reduce((map, obj) => {
          map.set(obj.id, obj);
          return map;
        }, new Map()).values()];
      }
    })
  }
}
