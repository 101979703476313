import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SharedService } from './sharedService';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ExternalService extends BaseService{

  downloadReceiptPdf(ref_num, headers): Observable<Blob> {
    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('ref_un_id', ref_num).set('headers', JSON.stringify(headers)),
    };
    let process$ = this.http.get<any>(this.sharedService.externalApiUrl('payments/pdf'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download pdf'))
    )
    return process$;
  }

  downloadAvisPdf(id: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/avisfile'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;

  }

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }


  getPaymentReceipt(ref_num): Observable<any> {
    return this.http.get(this.sharedService.externalApiUrl('payments?ref_un_id='+ref_num)).pipe(
      map(res => {
        return res;
      })
    );
  }
  getAvis(ref_num): Observable<any> {
    return this.http.get(this.sharedService.externalApiUrl('avis?ref_un_id='+ref_num)).pipe(
      map(res => {
        return res;
      })
    );
  }
}



