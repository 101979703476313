import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rdf-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.css']
})
export class PaymentViewComponent implements OnInit {
  @Output() onhidePayment = new EventEmitter<any>();
  @Input() payment;
  constructor() { }
  fields = [
    {title:'PAYMENT-VIEW.PAYMENT_SYSTEM', value:'paymentSystem.name'},
    {title:'PAYMENT-VIEW.CURRENCY', value:'currency'},
    {title:'PAYMENT-VIEW.AMOUNT', value:'amount'},
    {title:'PAYMENT-VIEW.PAYMENT_DATE', value:'paymentDate', type:'date'},
    {title:'PAYMENT-VIEW.TIN', value:'tin'},
    {title:'PAYMENT-VIEW.COMPANY_NAME', value:'companyName'},
    {title:'PAYMENT-VIEW.TAX_NAME', value:'taxName'},
    {title:'PAYMENT-VIEW.REVENUE_CODE', value:'revenueCode'},
    {title:'PAYMENT-VIEW.REFERENCE_NUMBER', value:'reference_number'},
    {title:'PAYMENT-VIEW.PROCESS_NAME', value:'process_name'},
    {title:'PAYMENT-VIEW.MONTH', value:'month'},
    {title:'PAYMENT-VIEW.YEAR', value:'year'},
    {title:'PAYMENT-VIEW.PAYMENT_REFERENCE', value:'paymentReference'},
    {title:'PAYMENT-VIEW.URL_IDENTIFIER', value:'urlident'},
    {title:'PAYMENT-VIEW.USER_USERNAME', value:'user.username'},
    {title:'PAYMENT-VIEW.USER_NAME', value:'user.name'},
    {title:'PAYMENT-VIEW.USER_SURNAME', value:'user.surname'}
    ]
  ngOnInit(): void {
  }

  back(){
    this.onhidePayment.emit()
  }

  getNestedValue(obj, path) {
    return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : undefined, obj);

}

}
 
 