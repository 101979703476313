 

export class CashPayment {
  public id: number;
  public amount: number;
  public date: Date;
  public description: string;
 
  
  constructor() {
 
  }

}  