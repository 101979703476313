import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ChartsModule, ThemeService } from 'ng2-charts';
import { FileUploadModule } from "ng2-file-upload";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CashPaymentPrintComponent } from './components/cash-payment-print/cash-payment-print.component';
import { CompanyProfileComponent } from './components/company-profile/company-profile.component';
import { RevenueCodeTableComponent } from './components/dashboard/revenue-code-dashboard/revenue-code-table/revenue-code-table.component';
import { RevenueCodeGraphComponent } from './components/dashboard/revenue-code-graph/revenue-code-graph.component';
import { DeadlineSelectorComponent } from './components/deadline-selector/deadline-selector.component';
import { CashPaymentDialogComponent } from './components/dialogs/cash-payment-dialog/cash-payment-dialog.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { FillWalletDialogComponent } from './components/dialogs/fill-wallet-dialog/fill-wallet-dialog.component';
import { RevenueCodeDialogComponent } from './components/dialogs/revenue-code-dialog/revenue-code-dialog.component';
import { SmsLogDialogComponent } from './components/dialogs/sms-log-dialog/sms-log-dialog.component';
import { SubmitDialogComponent } from './components/dialogs/submit-dialog/submit-dialog.component';
import { TaxNameDialogComponent } from './components/dialogs/tax-name-dialog/tax-name-dialog.component';
import { UserDialogComponent } from './components/dialogs/user-dialog/user-dialog.component';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { ForecastTableComponent } from './components/forecast/forecast-table/forecast-table.component';
import { ForecastComponent } from './components/forecast/forecast.component';
import { ConditionalsComponent } from './components/form-creation/conditionals/conditionals.component';
import { DynamicDropdownComponent } from './components/form-creation/dynamic-dropdown/dynamic-dropdown.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { PerformanceTableComponent } from './components/performance/performance-table/performance-table.component';
import { PerformanceComponent } from './components/performance/performance.component';
import { RecurringReportsComponent } from './components/recurring-reports/recurring-reports.component';
import { RevenueCodeComponent } from './components/revenue-code/revenue-code.component';
import { SearchSmsLogsComponent } from './components/sms-log/search/search-sms-logs.component';
import { SmsLogComponent } from './components/sms-log/sms-log.component';
import { TaxNameComponent } from './components/tax-name/tax-name.component';
import { TaxPayersComponent } from './components/tax-payers/tax-payers.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { UserComponent } from './components/user/user.component';
import { YearedTabComponent } from './components/yeared-tab/yeared-tab.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { CompanyRepresentativeService } from './services/companyRepresentative.service';
import { ConfigService } from './services/config.service';
import { PaymentReportService } from './services/paymentReport.service';
import { SharedService } from './services/sharedService';
import { UserService } from './services/user.service';
import { AuthInterceptor } from './util/auth.interceptor';
import { TimeoutInterceptor } from './util/timeout.interceptor';
// import { TxDeclarationsComponent } from './components/tx-declarations-old/tx-declarations.component';
// import { MatPaginatorIntlTas } from './components/tx-declarations-old/MatPaginatorIntlTas';
import { MultiselectAutocompleteComponent } from './components/multiselect-autocomplete/multiselect-autocomplete.component';
import { MatPaginatorIntlTas } from './components/tx-declarations/MatPaginatorIntlTas';
import { TxDeclarationsComponent } from './components/tx-declarations/tx-declarations.component';

import { CdkTableModule } from '@angular/cdk/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ApprovalComponent } from './components/approval/approval.component';
import { ForecastPerformanceComponent } from './components/approval/forecast-performance/forecast-performance.component';
import { ForecastPerformanceSearchComponent } from './components/approval/search/search-forecast-performance.component';
import { BarChartNativeComponent } from './components/charts/bar-chart-native/bar-chart-native.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { GroupedHorizontalBarChartNativeComponent } from './components/charts/grouped-horizontal-bar-chart-native/grouped-horizontal-bar-chart-native.component';
import { LineChartNativeComponent } from './components/charts/line-chart-native/line-chart-native.component';
import { MultiAxisChartComponent } from './components/charts/multi-axis-chart/multi-axis-chart.component';
import { PieChartNativeComponent } from './components/charts/pie-chart-native/pie-chart-native.component';
import { CompanyRepresentativeComponent } from './components/company-representative/company-representative.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { PaymentConfigurationComponent } from './components/configuration/payment-configuration/payment-configuration.component';
import { SmsConfigurationComponent } from './components/configuration/sms-configuration/sms-configuration.component';
import { SmsSectionEditorComponent } from './components/configuration/sms-configuration/sms-section-editor/sms-section-editor.component';
import { DailyPeriodFilterComponent } from './components/dashboard/daily-period-filter/daily-period-filter.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InfoCardComponent } from './components/dashboard/info-card/info-card.component';
import { MainDashboardComponent } from './components/dashboard/main-dashboard/main-dashboard.component';
import { ComparisonGraphComponent } from './components/dashboard/monthly-comparison-graph/comparison-graph/comparison-graph.component';
import { MonthlyComparisonGraphComponent } from './components/dashboard/monthly-comparison-graph/monthly-comparison-graph.component';
import { SearchComparisonGraphComponent } from './components/dashboard/monthly-comparison-graph/search/search-comparison-graph.component';
import { MonthlyPeriodFilterComponent } from './components/dashboard/monthly-period-filter/monthly-period-filter.component';
import { RevenueCodeDashboardComponent } from './components/dashboard/revenue-code-dashboard/revenue-code-dashboard.component';
import { SearchRevenueCodeAmountsComponent } from './components/dashboard/revenue-code-dashboard/search/search-revenue-code-amounts.component';
import { RvGraphComponent } from './components/dashboard/revenue-code-graph/rv-graph/rv-graph.component';
import { SearchRevenueCodeAmountsGraphComponent } from './components/dashboard/revenue-code-graph/search/search-revenue-code-amounts-graph.component';
import { SearchDashboardComponent } from './components/dashboard/search/search-dashboard.component';
import { YearlyPeriodFilterComponent } from './components/dashboard/yearly-period-filter/yearly-period-filter.component';
import { DeclarationsComponent } from './components/declarations/declarations.component';
import { ReportingComponent } from './components/declarations/reporting/reporting.component';
import { CompanyRepresentativeDialogComponent } from './components/dialogs/company-representative-dialog/company-representative-dialog.component';
import { ForecastDialogComponent } from './components/dialogs/forecast-dialog/forecast-dialog.component';
import { InformationDialogComponent } from './components/dialogs/information-dialog/information-dialog.component';
import { SendSmsDialogComponent } from './components/dialogs/send-sms-dialog/send-sms-dialog.component';
import { TaxPayerDialogComponent } from './components/dialogs/tax-payer-dialog/tax-payer-dialog.component';
import { ExternalAvisComponent } from './components/external-avis/external-avis.component';
import { ExternalReceiptComponent } from './components/external-receipt/external-receipt.component';
import { ActionButtonsComponent } from './components/form-builder/action-buttons/action-buttons.component';
import { BlockControlsComponent } from './components/form-builder/block/block-controls/block-controls.component';
import { BlockViewComponent } from './components/form-builder/block/block-view/block-view.component';
import { BlockComponent } from './components/form-builder/block/block.component';
import { ColumnsComponent } from './components/form-builder/columns/columns.component';
import { CheckboxComponent } from './components/form-builder/form-elements/checkbox/checkbox.component';
import { DateInputComponent } from './components/form-builder/form-elements/date-input/date-input.component';
import { DropdownComponent } from './components/form-builder/form-elements/dropdown/dropdown.component';
import { NumberInputComponent } from './components/form-builder/form-elements/number-input/number-input.component';
import { OptionComponent } from './components/form-builder/form-elements/option/option.component';
import { TextInputComponent } from './components/form-builder/form-elements/text-input/text-input.component';
import { FormMainPropertiesComponent } from './components/form-builder/form-main-properties/form-main-properties.component';
import { FormViewerComponent } from './components/form-builder/form-viewer/form-viewer.component';
import { MainComponent } from './components/form-builder/main/main.component';
import { RowControlsComponent } from './components/form-builder/rows/row-controls/row-controls.component';
import { RowComponent } from './components/form-builder/rows/row/row.component';
import { RowsComponent } from './components/form-builder/rows/rows.component';
import { ElementPositionerComponent } from './components/form-builder/various/element-positioner/element-positioner.component';
import { ColumnUtilComponent } from './components/form-creation/column-util/column-util.component';
import { CustomCalculationEmitterComponent } from './components/form-creation/custom-calculation-emitter/custom-calculation-emitter.component';
import { FormCreatorComponent } from './components/form-creation/form-creator/form-creator.component';
import { FormFilterComponent } from './components/form-creation/form-filter/form-filter.component';
import { ProcessAddEditComponent } from './components/form-creation/process-type/process-add-edit/process-add-edit.component';
import { ProcessTypeComponent } from './components/form-creation/process-type/process-type.component';
import { RowUtilComponent } from './components/form-creation/row-util/row-util.component';
import { HelpComponent } from './components/help/help.component';
import { HomepageAdminComponent } from './components/homepage-admin/homepage-admin.component';
import { MapPreviewComponent } from "./components/map/map-preview.component";
import { MapComponent } from "./components/map/map.component";
import { MapService } from "./components/map/map.service";
import { MultiStepFormComponent } from './components/multi-step-form/multi-step-form.component';
import { PasswordRestoreComponent } from './components/password-restore/password-restore.component';
import { PaymentReportComponent } from './components/payment-report/payment-report.component';
import { WalletPaymentReportComponent } from './components/payment-report/wallet-payment-report/wallet-payment-report.component';
import { ProcessStepComponent } from './components/process/process-step/process-step.component';
import { ProcessViewComponent } from './components/process/process-view/process-view.component';
import { RegistrationsMapComponent } from "./components/registrations-map/registrations-map.component";
import { SettingsComponent } from './components/settings/settings.component';
import { SearchStatisticsComponent } from './components/statistics/search/search-statistics.component';
import { StatDecCompaniesComponent } from './components/statistics/stat-dec-companies/stat-dec-companies.component';
import { StatDetailsComponent } from './components/statistics/stat-details/stat-details.component';
import { StatTableInternalComponent } from './components/statistics/stat-table-internal/stat-table-internal.component';
import { StatTableComponent } from './components/statistics/stat-table/stat-table.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { TasTableComponent } from './components/tas-table/tas-table.component';
import { TaxDivisionComponent } from './components/tax-division/tax-division.component';
import { TaxLedgerComponent } from './components/tax-ledger/tax-ledger.component';
import { SearchTaxnamesComponent } from './components/tax-name/search/search-taxnames.component';
import { SearchTaxpayersComponent } from './components/tax-payers/search/search-taxpayers.component';
import { SearchUsersComponent } from './components/user/search/search-users.component';
import { SearchWalletComponent } from './components/wallet-deposits/search/search-wallet.component';
import { WalletDepositsComponent } from './components/wallet-deposits/wallet-deposits.component';
import { OnlyNumber } from './directives/onlyNumberDirective';
import { ThousandSeparatorDirective } from './directives/thousandSeparator.directive';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { AdminService } from './services/admin.service';
import { AvisService } from './services/avis.service';
import { CategoryService } from './services/category.service';
import { DashboardService } from './services/dashboard.service';
import { DropdownService } from './services/dropdown.service';
import { EntityService } from './services/entity.service';
import { ExternalService } from './services/eternal.service';
import { ForecastService } from './services/forecast.service';
import { OtpService } from './services/otp.service';
import { ProcessTypeService } from './services/proces-type.service';
import { ReportService } from './services/report.service';
import { RevenueCodeService } from './services/revenueCode.service';
import { TaxDivisionService } from './services/taxDivision.service';
import { TaxNameService } from './services/taxName.service';
import { YearService } from './services/year.service';
import { FormEditorConfigurationComponent } from './components/configuration/form-editor-configuration/form-editor-configuration.component';
import { DropdownConfigurationComponent } from './components/configuration/dropdown-configuration/dropdown-configuration.component';
import { DropdownConfigurationTableComponent } from './components/configuration/dropdown-configuration/dropdown-configuration-table/dropdown-configuration-table.component';
import { DropdownConfigurationService } from './services/dropdownConfiguration.service';
import { DropdownConfigurationDialogComponent } from './components/dialogs/dropdown-configuration-dialog/dropdown-configuration-dialog.component';
import { DropdownConfigurationEditComponent } from './components/configuration/dropdown-configuration/dropdown-configuration-edit/dropdown-configuration-edit.component';
import { CompanyDexieService } from './services/companyDexie-services/companyDexie.service';
import { SynchronizeService } from './services/synchronize.service';
import { PaymentViewComponent } from './components/payment-view/payment-view.component';
import { OfflinePaymentsTableComponent } from './components/offline-payments-table/offline-payments-table.component';
registerLocaleData(localeFr, 'fr');
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('tas_gov_access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DailyPeriodFilterComponent,
    MonthlyPeriodFilterComponent,
    YearlyPeriodFilterComponent,
    DashboardComponent,
    MainDashboardComponent,
    RevenueCodeDashboardComponent,
    RevenueCodeGraphComponent,
    ErrorpageComponent,
    TopMenuComponent,
    YearedTabComponent,
    PerformanceComponent,
    PerformanceTableComponent,
    ConfirmationDialogComponent,
    SubmitDialogComponent,
    ForecastDialogComponent,
    InformationDialogComponent,
    UserComponent,
    MultiselectAutocompleteComponent,
    UserDialogComponent,
    RevenueCodeDialogComponent,
    DropdownConfigurationDialogComponent,
    TaxNameDialogComponent,
    RevenueCodeTableComponent,
    CashPaymentDialogComponent,
    FillWalletDialogComponent,
    TaxPayersComponent,
    SmsLogDialogComponent,
    TxDeclarationsComponent,
    ProcessViewComponent,
    PasswordRestoreComponent,
    TaxNameComponent,
    SearchUsersComponent,
    TaxNameDialogComponent,
    ProcessStepComponent,
    ConfigurationComponent,
    DropdownConfigurationTableComponent,
    PaymentConfigurationComponent,
    CompanyProfileComponent,
    RecurringReportsComponent,
    ExternalAvisComponent,
    MultiStepFormComponent,
    TasTableComponent,
    ThousandSeparatorDirective,
    ThousandSeparatorPipe,
    UtcDatePipe,
    CashPaymentPrintComponent,
    ExternalReceiptComponent,
    SmsSectionEditorComponent,
    OnlyNumber,
    DynamicDropdownComponent,
    FormCreatorComponent,
    DeadlineSelectorComponent,
    ProcessTypeComponent,
    RvGraphComponent,
    ProcessAddEditComponent,
    ColumnUtilComponent,
    RowUtilComponent,
    DeclarationsComponent,
    ConditionalsComponent,
    SmsLogComponent,
    SearchSmsLogsComponent,
    StatisticsComponent,
    RevenueCodeComponent,
    DropdownConfigurationEditComponent,
    TaxNameComponent,
    CustomCalculationEmitterComponent,
    MonthlyComparisonGraphComponent,
    ComparisonGraphComponent,
    SearchComparisonGraphComponent,
    ReportingComponent,
    SettingsComponent,
    RegistrationsMapComponent,
    TaxDivisionComponent,
    ForecastComponent,
    StatDetailsComponent,
    StatDecCompaniesComponent,
    ForecastTableComponent,
    CompanyRepresentativeComponent,
    CompanyRepresentativeDialogComponent,
    SendSmsDialogComponent,
    TaxPayerDialogComponent,
    HomepageAdminComponent,
    InfoCardComponent,
    TaxLedgerComponent,
    FormEditorConfigurationComponent,
    DropdownConfigurationComponent,
    BarChartNativeComponent,
    GroupedHorizontalBarChartNativeComponent,
    BarChartComponent,
    LineChartNativeComponent,
    PieChartNativeComponent,
    MultiAxisChartComponent,
    PaymentReportComponent,
    MainComponent,
    FormMainPropertiesComponent,
    FormViewerComponent,
    ActionButtonsComponent,
    BlockComponent,
    BlockControlsComponent,
    BlockViewComponent,
    MapComponent,
    MapPreviewComponent,
    WalletDepositsComponent,
    RowsComponent,
    ColumnsComponent,
    RowComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
    CheckboxComponent,
    DropdownComponent,
    OptionComponent,
    SearchTaxpayersComponent,
    SearchTaxnamesComponent,
    SearchDashboardComponent,
    SearchRevenueCodeAmountsComponent,
    SearchRevenueCodeAmountsGraphComponent,
    RowControlsComponent,
    ElementPositionerComponent,
    FormFilterComponent,
    SmsConfigurationComponent,
    SearchStatisticsComponent,
    HelpComponent,
    SearchWalletComponent,
    StatTableComponent,
    StatTableInternalComponent,
    WalletPaymentReportComponent,
    ForecastPerformanceComponent,
    ForecastPerformanceSearchComponent,
    ApprovalComponent,
    PaymentViewComponent,
    OfflinePaymentsTableComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    BrowserModule,
    AppRoutingModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ChartsModule,
    FileUploadModule,
    LeafletModule,
    LeafletDrawModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatFormFieldModule,
    DragDropModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatOptionModule,
    MatRadioModule,
    MatSlideToggleModule,
    ToastrModule.forRoot(),
    MatDatepickerModule,
    MatSortModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    HttpClientModule,
    NgxSpinnerModule,
    CdkTableModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    })
  ],
  providers: [
    AdminService, AuthService, AvisService, CategoryService, CompanyRepresentativeService, ConfigService, DashboardService, DropdownConfigurationService,
    DropdownService, EntityService, ForecastService, MapService, OtpService, PaymentReportService, ProcessTypeService, ReportService, 
    AdminService, AuthService, AvisService, CategoryService, CompanyRepresentativeService, ConfigService, DashboardService, CompanyDexieService,
    DropdownService, EntityService, ForecastService, MapService, OtpService, PaymentReportService, ProcessTypeService, ReportService, SynchronizeService,
    RevenueCodeService, SharedService, TaxDivisionService, TaxNameService, ThemeService, UserService, YearService, AuthGuard, ExternalService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTas }, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }, ThousandSeparatorPipe, DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [  UserDialogComponent, FillWalletDialogComponent, ForecastDialogComponent, CashPaymentDialogComponent, CompanyRepresentativeDialogComponent, SendSmsDialogComponent, TaxPayerDialogComponent,RevenueCodeDialogComponent, TaxNameDialogComponent, ForecastTableComponent, PerformanceTableComponent, SmsLogDialogComponent,
    SubmitDialogComponent, ConfirmationDialogComponent, InformationDialogComponent, RevenueCodeTableComponent, RvGraphComponent]
})
export class AppModule { }
