import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-block-view',
  templateUrl: './block-view.component.html',
  styleUrls: ['./block-view.component.css']
})
export class BlockViewComponent implements OnInit {
  @Input() block;
  
  constructor() { }

  ngOnInit() {
  }

}
