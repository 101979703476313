import { Component, OnInit, Inject } from '@angular/core';
import { EventEmitter } from 'events';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/entities/dialogData';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.css']
})
export class InformationDialogComponent implements OnInit {
  text: string;
  emitter: EventEmitter;
  obj: any;
  action: any;
  processes = []
  error = false;

  constructor(
    public dialogRef: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService) {
    this.text = data['text'];
    this.obj = data['obj']
    this.action = data['action']
    this.error = data['error']
  }

  ngOnInit() {

  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick(): void {
      this.dialogRef.close(true);
  }

}