import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { DropdownConfigurationService } from "src/app/services/dropdownConfiguration.service";
import { TableRow } from "./table-row.model";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DropdownConfigurationDialogComponent } from "src/app/components/dialogs/dropdown-configuration-dialog/dropdown-configuration-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MultiselectAutocompleteComponent } from "src/app/components/multiselect-autocomplete/multiselect-autocomplete.component";
import { CategoryService } from "src/app/services/category.service";
import { ConfirmationDialogComponent } from "src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component";
import { SharedService } from "src/app/services/sharedService";
@Component({
  selector: "app-dropdown-configuration-edit",
  templateUrl: "./dropdown-configuration-edit.component.html",
  styleUrls: ["./dropdown-configuration-edit.component.css"],
})
export class DropdownConfigurationEditComponent implements OnInit {
  constructor(private route: ActivatedRoute, private dropdownConfigurationService: DropdownConfigurationService,
    public dialog: MatDialog, private categoryService: CategoryService, private sharedService: SharedService
  ) { }
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns = ['municipality', 'name', 'value', 'action']
  dataSource: MatTableDataSource<TableRow>;
  year
  currentDropdown
  dropdownConfiguration
  selItemKeys = ['name', 'key', 'value']
  tableData
  panelOpenState = true;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('nameField') nameField!: ElementRef<HTMLInputElement>;
  filterData = { municipality: null };
  filterFields = ["municipality"];
  filterObject = {};
  editname = false;
  dropdownId
  drName
  queryFilter = {};
  subscriptionConfirm
  ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      this.year = params['year'];
      if (this.year && this.route.snapshot.paramMap.get('id')) {
        this.dropdownId = this.route.snapshot.paramMap.get('id')
        try {
          await this.fetchdata()
          this.filterFields.forEach((field) => {
            this.categoryService
              .getList(field)
              .subscribe((result) => {
                if (result) {
                  this.filterData[field] = result.map((fld) => ({
                    name: fld.name,
                    id: fld.id,
                  }));
                }
                setTimeout(() => {
                  if (localStorage.getItem('municipality')) {
                    if (localStorage.getItem('municipality') !== '-1') {
                      this.municipalitySelect.resetAll()
                      let selectedMun = this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality'))
                      if (selectedMun.length > 0) {
                        this.filterObject['municipality'] = [selectedMun[0].id]
                        this.municipalitySelect.setSelectedData(selectedMun)
                      }
                    }
                    else {
                      this.municipalitySelect.resetAll()
                    }
                  } else {
                    this.municipalitySelect.resetAll()
                  }
                }, 1000)
              });
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    });
  }
 onDelete(elem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.DELETE_DROPDOWN_CONFIGURATION_SELECT_ITEM", emitter: null, obj: elem, action: "CONFIRM" }
    });

    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(async val => {
      if (val) {
        let selectedDropdown = this.currentDropdown.municipalities.find(elem=>elem.id===val.municipalityId)
        let index = selectedDropdown.selectItems.indexOf(selectedDropdown.selectItems.find(elem => elem.key == val.key))
        selectedDropdown.selectItems.splice(index, 1)
        await this.dropdownConfigurationService.updateResource('dropdown-configuration', this.dropdownConfiguration).toPromise()
        this.fetchdata()
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionConfirm.unsubscribe()
    });
  }

  async fetchdata(){
    this.dropdownConfiguration = await this.dropdownConfigurationService.getResource('dropdown-configuration', this.dropdownId).toPromise()
    if (this.dropdownConfiguration.dropdowns) {
      this.currentDropdown = this.dropdownConfiguration.dropdowns.find(elem => elem.year === this.year)
      if (this.currentDropdown) {
        const flattenedData = this.flattenData(this.currentDropdown);
        this.dataSource = new MatTableDataSource(flattenedData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  async advancedSearch() {
    await this.fetchdata()
    if (this.queryFilter['municipality']) {
      let munIds = this.currentDropdown.municipalities.map(elem => elem.id)
      const matchingRecords = munIds.filter(itemA =>
        this.queryFilter['municipality'].some(itemB => itemA === itemB)
      );
      this.currentDropdown.municipalities = this.currentDropdown.municipalities.filter(elem => matchingRecords.includes(elem.id))
      const flattenedData = this.flattenData(this.currentDropdown);
      this.dataSource = new MatTableDataSource(flattenedData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } 
  }

  resetFilter(){
    this.municipalitySelect.resetAll()
    this.queryFilter['municipality'] = null
    this.advancedSearch()
  }

  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length === 0) {
      delete this.queryFilter[field]
    }
  };
  openEditDialog(row) {
    const dialogRef = this.dialog.open(DropdownConfigurationDialogComponent, {
      width: '800px', panelClass: 'custom-dialog-container',
      data: { id: this.dropdownId, year: this.year + '', selectItem: row }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.advancedSearch()
      }
    });
  }

  getItemKey(i) {
    return this.selItemKeys[i]
  }

  async saveDropdown() {
    await this.dropdownConfigurationService.updateResource('dropdown-configuration', this.dropdownConfiguration).toPromise()
    this.editname = !this.editname
  }

  editDropdownName() {
    this.editname = !this.editname
    this.drName = this.dropdownConfiguration.name
    setTimeout(() => {
      this.nameField.nativeElement.focus();
    })

  }

  cancelEdit() {
    this.dropdownConfiguration.name = this.drName
    this.editname = !this.editname
  }


  flattenData(data: any): TableRow[] {
    const rows: TableRow[] = [];
    data.municipalities.forEach((municipality: any) => {
      const municipalityName = municipality.name;
      const municipalityId = municipality.id;
      municipality.selectItems.forEach((item: any) => {
        rows.push({
          municipality: municipalityName,
          municipalityId: municipalityId,
          name: item.name,
          key: item.key,
          value: item.value,
        });
      });
    });
    return rows;
  }

}
