import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { ReportService } from "src/app/services/report.service";

@Component({
  selector: "app-taxnames-search",
  templateUrl: "./search-taxnames.component.html",
  styleUrls: ["./search-taxnames.component.css"],
})
export class SearchTaxnamesComponent implements OnInit {
  @ViewChild('categorySelect', { static: false }) categorySelect: MultiselectAutocompleteComponent;
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  filterData = {
    category: null,
    municipality: null,
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields : any;
  queryFilter = {};
  filterFields = ["category", "municipality"];
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public reportService: ReportService
  ) {
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
        });
    });
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length===0){
      delete this.queryFilter[field]
    }
  };
  resetFilter() {
    this.queryFilter = {}
    this.categorySelect.resetAll()
    this.municipalitySelect.resetAll()
    this.onReset.emit()
  }
   advancedSearch() {
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
   }

  ngOnInit() {}
}
