import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EventEmitter } from "events";
import { DialogData } from "src/app/entities/dialogData";
import { SharedService } from "src/app/services/sharedService";
import { TaxPayerService } from "src/app/services/taxPayer.service";
import { DialogCommunicator } from "../dialog-communicator";

@Component({
  selector: "gov-send-sms-dialog",
  templateUrl: "./send-sms-dialog.component.html",
  styleUrls: ["./send-sms-dialog.component.css"],
})
export class SendSmsDialogComponent
  extends DialogCommunicator
  implements OnInit, OnDestroy
{
  savePayment = true;
  error: string
  text: string;
  emitter: EventEmitter;
  cashPayment: any;
  currentUser: any;
  taxNames: any[];
  smsSendSubsciption: any;
  comp: any;
  MOBILE_NUMBER_LENGTH = 10
  validNumber = true;
  constructor(
    public dialogRef: MatDialogRef<SendSmsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    public taxPayerService: TaxPayerService,
    public confirmationDialog: MatDialog
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  ngOnInit() {
    this.savePayment = this.data['paymentSaved']
    this.cashPayment = this.data["cashPayment"];
    this.cashPayment && this.cashPayment.telephone &&  (this.cashPayment.telephone = this.cashPayment.telephone.replace(/\s+/g, ''))
    this.setEntity(this.cashPayment);
    let phoneNumber = this.cashPayment && this.cashPayment.telephone;
    if (phoneNumber) {
      if (phoneNumber.startsWith('+2250')){
        this.cashPayment.telephone = phoneNumber.substring(
              4,
              phoneNumber.length
            );
      }
      if (phoneNumber.startsWith('2250')){
        this.cashPayment.telephone = phoneNumber.substring(
              3,
              phoneNumber.length
            );
      }
    }
    this.smsSendSubsciption = this.sharedService.smsSendEmitter.subscribe(
      (val) => {
        if (val && !val.error) {
          this.smsSent = true;
        }else{
          this.error = "ERRORS.SMS_SEND_ERROR"
        }
      }
    );
  }

  checkValidity(phoneNumber) {
    if (!phoneNumber)
      return false
    let phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength === this.MOBILE_NUMBER_LENGTH && phoneNumber.startsWith("0")) {
      return true;
    } else {
      return false;
    }
  }
  sendingSms = false;
  smsSent = false;
  async saveAndSendSMS() {
    this.sendingSms = true;
    if (this.cashPayment.onlySms){
      let resp = await this.taxPayerService.sendSMS({reference_number: this.cashPayment.reference_number, telephone: this.cashPayment.telephone}).toPromise()
      this.sharedService.smsSend(resp)
    }
    else{
      this.onUpdateClick("SAVE", "UPDATE_CASH_PAYMENT");
    }
  }

  ngOnDestroy() {
    if (this.smsSendSubsciption) this.smsSendSubsciption.unsubscribe();
  }
}

