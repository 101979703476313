import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit {
  @Input() block;
  @Input() stage;
  @Input() blockPosition
  constructor() { }

  ngOnInit() {
  }

}
