export const MONTH_YEAR_FORMATS = {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  
  export const YEAR_FORMATS = {
    parse: {
      dateInput: 'YYYY',
    },
    display: {
      dateInput: 'YYYY',
      yearLabel: 'YYYY',
      dateA11yLabel: 'YYYY',
      monthYearA11yLabel: 'YYYY',
    },
  };