import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Role } from 'src/app/entities/role';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  notifParam: any;
  constructor(public router: Router, public authService: AuthService, public route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(q => {
      this.notifParam = q.get('notifications');
    });
    const queryParams = this.notifParam ? { notifications: true } : null;
    this.router.navigate([environment.landingPage], { queryParams });
  }
}
