import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { UserComponent } from './components/user/user.component';
import { TaxPayersComponent } from './components/tax-payers/tax-payers.component';
import { TxDeclarationsComponent } from './components/tx-declarations/tx-declarations.component';
import { ProcessViewComponent } from './components/process/process-view/process-view.component';
import { ProcessStepComponent } from './components/process/process-step/process-step.component';
import { FormCreatorComponent } from './components/form-creation/form-creator/form-creator.component';
import { ProcessTypeComponent } from './components/form-creation/process-type/process-type.component';
import { ProcessAddEditComponent } from './components/form-creation/process-type/process-add-edit/process-add-edit.component';
import { DeclarationsComponent } from './components/declarations/declarations.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ReportingComponent } from './components/declarations/reporting/reporting.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TaxDivisionComponent } from './components/tax-division/tax-division.component';
import { StatDetailsComponent } from './components/statistics/stat-details/stat-details.component';

import { HomepageAdminComponent } from './components/homepage-admin/homepage-admin.component';
import { HelpComponent } from './components/help/help.component';
import { PaymentReportComponent } from './components/payment-report/payment-report.component';
import { MainComponent } from './components/form-builder/main/main.component';
import { MapPreviewComponent } from "./components/map/map-preview.component";
import { Role } from './entities/role';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { PaymentConfigurationComponent } from './components/configuration/payment-configuration/payment-configuration.component';
import { RegistrationsMapComponent } from './components/registrations-map/registrations-map.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PasswordRestoreComponent } from './components/password-restore/password-restore.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'password_restore', component: PasswordRestoreComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]} },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]} },
  { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]} },
  { path: 'payment_configuration', component: PaymentConfigurationComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]} },
  { path: 'user_management', component: UserComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]} },
  { path: 'tax_payers', component: TaxPayersComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]},  },
  { path: 'tx_declarations', component: TxDeclarationsComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]} },
  { path: 'process/:id/:step', component: ProcessStepComponent,  canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]} },
  { path: 'process/:id', component: ProcessViewComponent,  canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]}},
  { path: 'declarations', component: DeclarationsComponent,  canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'declaration_reporting', component: ReportingComponent,  canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'payment_report', component: PaymentReportComponent,  canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]}},
  { path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]}},
  { path: 'stat_details', component: StatDetailsComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]}},
  { path: 'edit_form', component: FormCreatorComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'edit_form/:year/:reportId/:stageId', component: FormCreatorComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'edit_form_old/:reportId/:stageKey', component: MainComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'formcreation_foradmin525', component: ProcessTypeComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'process_type_add_edit', component: ProcessAddEditComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'registrations_map', component: RegistrationsMapComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]}},
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'tax_division', component: TaxDivisionComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'admin', component: HomepageAdminComponent, canActivate: [AuthGuard], data: { roles: [Role.ADMIN]}},
  { path: 'help', component: HelpComponent,canActivate: [AuthGuard], data: { roles: [Role.MAYOR_TREASURER, Role.CENTRAL_READER, Role.FINANCIAL_MANAGER_ADMINISTRATOR, Role.CENTRAL_EDITOR, Role.SECRETARY_ASSISTANT]} },
  { path: 'tas-map', component: MapPreviewComponent},
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'error/:error', component: ErrorpageComponent},
  { path: '404', component: DashboardComponent },
  { path: '**', redirectTo: '/404' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
