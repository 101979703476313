import { Component, OnInit } from '@angular/core';
import { RvGraphComponent } from './rv-graph/rv-graph.component';

@Component({
  selector: 'app-revenue-code-graph',
  templateUrl: './revenue-code-graph.component.html',
  styleUrls: ['./revenue-code-graph.component.css']
})
export class RevenueCodeGraphComponent implements OnInit {
  rvGraphComponent = RvGraphComponent;

  constructor() {
  }

  ngOnInit() {


  }

}
