export async function getUniqueTaxpayerTypes(thisCompanies): Promise<{ type: string; typeName: string }[]> {
    const allRecords = await thisCompanies.toArray();
    const uniqueTaxpayerTypes = new Map();
    allRecords.forEach(({ type, typeName }) => {
        const key = `${type}-${typeName}`;
        if (!uniqueTaxpayerTypes.has(key)) {
            uniqueTaxpayerTypes.set(key, { id: type, name: typeName });
        }
    });
    return Array.from(uniqueTaxpayerTypes.values());
}
