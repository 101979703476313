import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: any, format: string = 'dd-MM-yyyy HH:mm'): any {
    if (!value) return value;
    let utcDate = new Date(value);
    let utcString = utcDate.toISOString();
    return this.datePipe.transform(utcString, format, 'UTC');
  }
}
