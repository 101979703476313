import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ProcessDataSource } from 'src/app/services/process.datasource';
import { ProcessService } from 'src/app/services/process.service';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-recurring-reports',
  templateUrl: './recurring-reports.component.html',
  styleUrls: ['./recurring-reports.component.css']
})
export class RecurringReportsComponent implements OnInit {
  displayedColumns: string[] = ['open','name', 'key', 'type', 'rec_num', 'status', 'action'];
  
  @Input() company: any;
  dataSource: ProcessDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  constructor(public dialog: MatDialog, private processService: ProcessService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.dataSource = new ProcessDataSource(this.processService)
    setTimeout(() => {
      this.dataSource.loadRecurringProcesses('{"tin":"' + this.company.tin + '"}', 'asc', 'name', 0, this.paginator.pageSize);
      this.setupEventHandlers();
    }, 1000)
  }

  setupEventHandlers() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadRecurringProcessPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadRecurringProcessPage())
      )
      .subscribe();
  }
  toggleRecursion(element, status){
    element.rec_status = status
    this.processService.toggleRecursion(element, status).subscribe(val => {
      this.loadRecurringProcessPage() 
    })
 
  }
  loadRecurringProcessPage() {
    this.dataSource.loadRecurringProcesses(
      '{"query":"' + this.input.nativeElement.value + '" , "tin":"' + this.company.tin + '"}',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }
  removeRecursionEmmitter: EventEmitter<boolean> = new EventEmitter(); 
  subscriptionConfirm: any 
  openRemoveRecursionDialog(element) { 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
      width: '500px', 
      data: { text: "DIALOG.REMOVE_RECURSION", emitter: this.removeRecursionEmmitter, obj: element, action:"CONFIRM" } 
    }); 
    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(val => { 
      if (val) { 
        this.processService.toggleRecursion(val, 'REMOVED').subscribe(val => {
          this.loadRecurringProcessPage() 
        })
      } 
    }) 
 
    dialogRef.afterClosed().subscribe(result => { 
    }); 
  } 
  
}
