import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  stage = {}
  constructor(private route: ActivatedRoute, private reportService: ReportService) { 
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      return this.reportService.getReportPageNew(params.get('reportId'), params.get('stageKey'))
    })).subscribe(stagePage => {
      this.stage = stagePage
    })
  }

  ngOnInit() {
  }

  printStage(){
  }

}
