import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { EntityService } from "src/app/services/entity.service";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { ReportService } from "src/app/services/report.service";
import { TaxNameService } from "src/app/services/taxName.service";
import { SharedService } from "src/app/services/sharedService";
import { PaymentReportService } from "src/app/services/paymentReport.service";

@Component({
  selector: "app-dashboard-search",
  templateUrl: "./search-dashboard.component.html",
  styleUrls: ["./search-dashboard.component.css"],
})
export class SearchDashboardComponent implements OnInit {
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('taxNameSelect', { static: false }) taxNameSelect: MultiselectAutocompleteComponent;
  filterData = {
    municipality: null,
    taxName: null,
    users: null
  };
  @Output() onFilter = new EventEmitter<any>();
  @Output() onToggle = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() extraFields : any;
  queryFilter = {};
  filterFields = ["municipality"];
  panelOpenState = true;
  constructor(public categoryService: CategoryService, public entityService: EntityService,
    public sharedService: SharedService,
    public reportService: ReportService, public taxNameService: TaxNameService,public paymentReportService: PaymentReportService
  ) {
    this.filterFields.forEach((field) => {
      this.categoryService
        .getList(field)
        .subscribe((result) => {
          if (result) {
            this.filterData[field] = result.map((fld) => ({
              name: fld.name,
              id: fld.id,
            }));
          }
        });
    });
    taxNameService.getList('tax-name').subscribe(txNames => {
      this.filterData['taxName'] = txNames.records || txNames
      this.filterData.taxName.forEach(element => {
          if(element.revenueCode && element.revenueCode.length > 0){
            (element.name = element.revenueCode[0].code + ' - ' + element.name)
          }
      });
    })
    this.paymentReportService.getPaymentUsers().subscribe(val => {
      this.filterData['users'] = val.map(user => ({ id: user.username.trim(), name: (user.surname? user.surname+ ' ': '') + user.name }))
      .sort((a, b) => a.name.localeCompare(b.name));
    })
  }
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  selectChange = (event: any, field) => {
    this.queryFilter[field] = event.data.map(elem => elem.id)
    if (this.queryFilter[field].length===0){
      delete this.queryFilter[field]
    }
  };
  setRange(range){
    this.queryFilter['from'] = range.from
    this.queryFilter['to']= range.to
    if (range['launch']){
      this.onFilter.emit(this.queryFilter)
    }
  }
  resetFilter() {
    this.queryFilter = {}
    this.municipalitySelect.resetAll()
    this.taxNameSelect.resetAll()
    this.onReset.emit()
    this.sharedService.resetDashboardFilter()

  }
   advancedSearch() {
    this.onFilter.emit(this.queryFilter)
    this.panelOpenState = false
   }
   loadPeriod(per){
    this.onToggle.emit(per)
   }


  ngOnInit() {}
}
