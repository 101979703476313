import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvisService } from 'src/app/services/avis.service';
import { CategoryService } from 'src/app/services/category.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FinancesDataSource } from 'src/app/services/finances.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { Card } from '../card-utilities/card.model';
import { CARDS } from '../card-utilities/cards.data';
import * as _dashboardUtilities from "../dashboard-utilities";

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.css']
})
export class MainDashboardComponent implements OnInit, OnDestroy {
  barChartLabels: Label[]
  barChartData: ChartDataSets[]
  dataSource: FinancesDataSource
  chartDataSubscription: any
  municipalityChangeSubscription: any
  subscriptionLanguage: any
  searchMunicipality: any
  searchRevenueCode: any
  searchInitialized = false;
  filterFields = ["municipality"]
  filterData = { municipality: null, revenueCode:null }
  cards: Card[] = CARDS;
  error
  globalSearchVal
  chartData
  langChange = false

  constructor(private dashboardService: DashboardService, private avisService: AvisService, private sharedService: SharedService,
    private categoryService: CategoryService, private translate: TranslateService, private spinner: NgxSpinnerService) {
      this.filterFields.forEach((field) => {
        this.categoryService.getList(field).subscribe((result) => {
            (result) && (this.filterData[field] = result.map((fld) => ({ name: fld.name, id: fld.id})))
          });
      });
  }

  async ngOnInit() {
    this.subscriptionLanguage = this.sharedService.getLan$().subscribe(lan => {
      this.langChange = true
      setTimeout(async ()=>{
        this.langChange = false
        if (this.chartData)
        await this.populateGraph();
      }, 0)
    })
    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
    let municipality = localStorage.getItem('municipality')
    await this.populateDashboard(municipality)
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(async val => {
      await this.populateDashboard(val && val.id)
    })
  }

  async populateDashboard(municipality){
    for (let elem of this.cards) {
      const url = elem.uriPath + (municipality ? `?municipality=${municipality}` : '');
      let attempt = 0;
      while (attempt < 2) {
        try {
          attempt++;
          const res = await this.dashboardService.getDashboardData(url).toPromise();
          if (res.error) {
            continue; 
          } else {
            elem.data = res.data; 
            break; 
          }
        } catch (error) {
          if (error.name !== 'TimeoutError' || attempt >= 2) {
            console.error('Fetch failed:', error);
            elem.data = 0; 
            break;
          }
        }
      }
      if (attempt === 2 && elem.data === undefined) {
        elem.data = 0; 
      }
    }
  }
  async populateGraph(){
      let attempt = 0;
      while (attempt < 2) {
        try {
          attempt++;
          this.chartData = await _dashboardUtilities.loadChart(this.globalSearchVal, this.dataSource, this.translate, this.chartData ? this.chartData.data : null)
          this.barChartData = this.chartData.barChartData
          this.barChartLabels = this.chartData.barChatLabels
          break; 
        } catch (error) {
          if (attempt >= 2) {
            console.error('Fetch failed:', error);
            this.barChartData = null
            this.barChartLabels = null
            this.searchInitialized = false
            this.error = 'LOADING'
            break;
          }
        }
      }
  }

  selectChange = (event: any, field) => {
    if (field === 'municipality'){
      this.searchMunicipality = event.data.map(elem => elem.id)
      (this.searchMunicipality.length===0) && (this.searchMunicipality = null)
    }
    if (field === 'revenueCode'){
      this.searchRevenueCode = event.data.map(elem => elem.id)
      (this.searchRevenueCode.length===0) && (this.searchRevenueCode = null)
    }
  };
  async advancedSearch(val){
    this.error = null
    this.searchInitialized = true
    this.barChartData = null
    this.barChartLabels = null
    this.globalSearchVal = val
    this.chartData = null
    this.populateGraph()
    // try{
    //   let res = await _dashboardUtilities.loadChart(val, this.dataSource, this.translate)
    //   this.barChartData = res.barChartData
    //   this.barChartLabels = res.barChatLabels
    // }
    // catch(error){
    //     console.log(error.message)
    // }
  
    
    // _dashboardUtilities.loadChart(val, this.dataSource, this.translate).then(res => {
    //   this.barChartData = res.barChartData
    //   this.barChartLabels = res.barChatLabels
    // }).catch(error => {
    //   this.searchInitialized = false
    //   console.log(error)
    // })
  }

  ngOnDestroy(): void {
    this.municipalityChangeSubscription && this.municipalityChangeSubscription.unsubscribe()
    this.subscriptionLanguage && this.subscriptionLanguage.unsubscribe()
    this.chartDataSubscription && this.chartDataSubscription.unsubscribe()
  }
}

