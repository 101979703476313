import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/entities/role';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { SharedService } from 'src/app/services/sharedService';
import { UserService } from 'src/app/services/user.service';
import { MenuCategories } from './menuItems';
import { CompanyDexieService } from 'src/app/services/companyDexie-services/companyDexie.service';
import { SynchronizeService } from 'src/app/services/synchronize.service';
import { RoleName } from 'src/app/entities/util';

@Component({
  selector: 'tas-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  selectedMun = []
  title: string = "TAS-GOV";
  lang: string;
  username: string;
  userrole: Role;
  initials: string;
  menuItems: (string | boolean)[][];
  currentUser
  filterData = { municipality: null };
  singleMunicipality
  munFull = 'COMMON-TOP-MENU.SEARCH-MUNICIPALITY'
  munEmpty = ''
  isOnline: boolean = true;
  constructor(
    public auth: AuthService,
    public jwtHelper: JwtHelperService,
    public sharedService: SharedService,
    public translate: TranslateService,
    public router: Router,
    public userService: UserService,
    public categoryService: CategoryService,
    private breakpointObserver: BreakpointObserver,
    private companyDexieService: CompanyDexieService,
    private synchronizeService: SynchronizeService,
  ) {
    this.initializeUserData();
  }


  initializeUserData() {
    const token = this.jwtHelper.tokenGetter();
    if (token && token !== 'undefined') {
      const decodedToken = this.jwtHelper.decodeToken(token);

      this.lang = localStorage.getItem("default_lang") || 'fr';
      this.username = decodedToken.username;
      this.initials = decodedToken.initials;
      this.userrole = Role[decodedToken.role + ''];
      this.menuItems = MenuCategories.get(Role[this.userrole]);
    }
  }
  isSmallScreen(): boolean {
    return this.breakpointObserver.isMatched('(min-width: 420px)');
  }

  isLargeScreen(): boolean {
    return this.breakpointObserver.isMatched('(min-width: 1450px)');
  }
  setLang(lang) {
    this.sharedService.setLan(lang);
    this.lang = lang;
    this.translate.use(lang);
  }

  logout() {
    this.username = undefined;
    this.auth.logout();
    this.router.navigate(['login']);
    localStorage.removeItem('municipality')
    localStorage.removeItem('municipalityName')
    localStorage.removeItem('municipalityBbox')
  }
  selectChange = (event: any) => {
    let municipality
    if (event.data.length > 0) {
      municipality = event.data[0]
      localStorage.setItem('municipality', event.data[0].id)
      localStorage.setItem('municipalityBbox', event.data[0].bbox)
      localStorage.setItem('municipalityName', event.data[0].name)
    }
    else {
      localStorage.removeItem('municipalityBbox')
      localStorage.removeItem('municipalityName')
      localStorage.removeItem('municipality')
    }
    this.sharedService.changeMunicipality(municipality)
  };
  async ngOnInit() {
    this.sharedService.onlineStatus$.subscribe(async status => {
      this.isOnline = status;
      if (this.isOnline) {
        const user = await this.userService.getCurrentUser().toPromise()
        const dbExists = await this.companyDexieService.databaseExists('tasdb')
        if (this.isOnline && (user?.role === 'SECRETARY_ASSISTANT' || dbExists)) {

          let synchDate = await this.companyDexieService.getLatestSynchronization()
          if (dbExists && user?.role !== 'SECRETARY_ASSISTANT' && synchDate===undefined){
            return
          }
          if (!synchDate?.synchronizationDate || synchDate?.synchFlag || this.sharedService.shouldSynchronize(synchDate.synchronizationDate)) {
            let payments = await this.companyDexieService.getAllPayments()
            for (let payment of payments) {
              await this.synchronizeService.remoteSynchronize(payment).toPromise()
            }
            if (user?.role !== 'SECRETARY_ASSISTANT') {
              await this.companyDexieService.clearDatabase()
            }
            else {
              await this.synchronizeService.localSynchronize(user)
            }
          }
        }
      }
    });
    this.currentUser = await this.userService.getCurrentUser().toPromise()
    if (this.currentUser) {
      if (this.currentUser.role !== 'ADMIN' && this.currentUser.municipalities && this.currentUser.municipalities.length === 1) {
        this.singleMunicipality = this.currentUser.municipalities[0].name
        localStorage.setItem('municipality', this.currentUser.municipalities[0].id)
        localStorage.setItem('municipalityName', this.currentUser.municipalities[0].name)
      }
    }
    const municipalityList = await this.categoryService.getList("municipality").toPromise()
    if (municipalityList) {
      this.filterData["municipality"] = municipalityList.map((fld) => ({
        name: fld.name,
        id: fld.id,
        bbox: fld.bbox
      }));
      this.filterData["municipality"].unshift({ name: 'ALL', id: -1 })
    }
    if (localStorage.getItem('municipality') && localStorage.getItem('municipalityName')) {
      this.selectedMun = this.filterData['municipality'].filter(elem => elem.id === localStorage.getItem('municipality'))
      if (!localStorage.getItem('municipalityBbox') && this.selectedMun.length > 0) {
        const municipalityBbox = await this.categoryService.getCategory('municipality', this.selectedMun[0]['id']).toPromise()
        if (municipalityBbox) {
          this.selectedMun[0].bbox = municipalityBbox.bbox
          localStorage.setItem('municipalityBbox', municipalityBbox.bbox)
        }
      }
    }
  }
  getPlaceHolder() {
    if (localStorage.getItem('municipality') && localStorage.getItem('municipalityName')) {
      return this.munEmpty
    }
    return this.munFull
  }
}
