import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { TaxDivisionService } from 'src/app/services/taxDivision.service';

@Component({
  selector: 'app-line-chart-native',
  templateUrl: './line-chart-native.component.html',
  styleUrls: ['./line-chart-native.component.css']
})
export class LineChartNativeComponent implements OnInit {

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  @Input()
  lineChartLabels: Label[];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartPlugins = [];
  @Input()
  lineChartData: ChartDataSets[];
  @Output() onSelectedValue = new EventEmitter<any>();
  currentTaxDivision = "0"
  @Input()
  cdiFilter = false
  @Input()
  title = '';
  data: []
  @Input() yearsFrom
  @Input() editable = true
  @Input() downloadable = false

  constructor() { }

  ngOnInit() {
    if (!this.editable) {
      this.lineChartOptions['legend'] = {
        onClick: (e) => {
          e.stopPropagation()
        }
      }
    }
  }

  selectonChanged(value) {
    this.onSelectedValue.emit(value)
  }
  @ViewChild('chart', { static: false }) chart: ElementRef;
  printChart() {
    var canvasDataUrl = this.chart.nativeElement.toDataURL()
      .replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
    var link = document.createElement('a');
    link.setAttribute('href', canvasDataUrl);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', this.title + ".png");
    if (document.createEvent) {
      var evtObj = document.createEvent('MouseEvents');
      evtObj.initEvent('click', true, true);
      link.dispatchEvent(evtObj);
    } else if (link.click) {
      link.click();
    }
  }

}
