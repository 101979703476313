import { Component, OnInit, Inject } from "@angular/core";
import { SharedService } from "src/app/services/sharedService";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { EventEmitter } from "events";
import { DialogData } from "src/app/entities/dialogData";
import { UserStatusName, replaceValues } from "src/app/entities/util";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/entities/user";
import { UserStatus } from "src/app/entities/states/userStatus";
import { DialogCommunicator } from "../dialog-communicator";
import { TaxPayerService } from "src/app/services/taxPayer.service";
import { TaxPayer } from "src/app/entities/taxPayer";
import { CategoryService } from "src/app/services/category.service";
import { DropdownService } from "src/app/services/dropdown.service";
import { objectInterface } from 'src/app/interfaces/objectinterface';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "gov-tax-payer-dialog",
  templateUrl: "./tax-payer-dialog.component.html",
  styleUrls: ["./tax-payer-dialog.component.css"],
})
export class TaxPayerDialogComponent
  extends DialogCommunicator
  implements OnInit {
  text: string;
  emitter: EventEmitter;
  company: any;
  statusNames = UserStatusName;
  userStatus = UserStatus;
  branches = [];
  usernames = [];

  repeatPassword = "";
  companyRepresentative: any;
  companyType: any;
  companyTypes = [];
  mandatoryFields = [];

  defaultWorkspace: any;
  cityHasMunicipality = true;
  cityHasDistrict = false;
  addableDropdowns: objectInterface = {};
  currentWorkspace: any;
  reasonType: any;
  taxPayerTypeCategory: any;
  applicationDocument: any;
  sendingRequest = false;
  dropdowns = {
    'sector dropdown': [],
    'branch dropdown': [],
    'activity dropdown': [],
    'activity detail dropdown': [],
    'region dropdown': [],
    'department dropdown': [],
    'city dropdown': [],
    'municipality dropdown': [],
    'district dropdown': [],
  };
  currentUser: any
  constructor(
    public dialogRef: MatDialogRef<TaxPayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    public taxPayerService: TaxPayerService,
    public categoryService: CategoryService,
    public dropdownService: DropdownService,
    public confirmationDialog: MatDialog,
    public toastr: ToastrService,
    public translateService: TranslateService,
    public spinner: NgxSpinnerService,
    public userService: UserService
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  // isChangable(key) {
  //   if (!this.taxPayerTypeCategory && this.currentWorkspace) {
  //     this.taxPayerTypeCategory = this.companyTypes.find(
  //       (elem) =>
  //         elem.name === this.currentWorkspace.type.name &&
  //         elem.taxPayerType === this.currentWorkspace.type.taxPayerType
  //     );
  //   }
  //   if (this.taxPayerTypeCategory) {
  //     let elem = this.taxPayerTypeCategory.additionalFields?.find(
  //       (elem) => elem.key === key
  //     );
  //     if (elem && elem.changeable) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }


  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user=>{
      this.currentUser = user
      this.company = new TaxPayer();
      this.categoryService.getList("category").subscribe((result) => {
        this.companyTypes = result;
      });
      this.dropdownService.getList('sector').subscribe((sectors) => {
        this.dropdowns['sector dropdown'] = sectors;
      });
      this.dropdownService.getList('region').subscribe((regions) => {
        this.dropdowns['region dropdown'] = regions;
      });
      this.dropdownService.getList('department').subscribe((departments) => {
        this.dropdowns['department dropdown'] = departments;
      });
      this.dropdownService.getList('city').subscribe((cities) => {
        this.dropdowns['city dropdown'] = cities;
      });
  
      this.dropdownService.getList('municipality').subscribe((municipalities) => {
        this.dropdowns['municipality dropdown'] = municipalities;
      });
  
  
      if (this.data) {
        this.taxPayerService
          .getCompanyFromAtom("company-representative", this.data["tin"])
          .subscribe((val) => {
            if (val.data && val.data.length > 0) {
              this.companyRepresentative = val.data[0];
              if (this.companyRepresentative.region) {
                this.rundropdowns('region')
              }
              if (this.companyRepresentative.department) {
                this.rundropdowns('department')
              }
              if (this.companyRepresentative.city) {
                this.rundropdowns('city')
              }
              if (this.companyRepresentative.municipality) {
                this.rundropdowns('municipality')
              }
              if (this.companyRepresentative.district) {
                this.rundropdowns('district')
              }
              this.companyRepresentative["class"] = "TAX-PAYER";
              this.setEntity(this.companyRepresentative);
              this.companyType = this.companyRepresentative.type
            }
          });
      } else {
        this.setEntity(this.company);
      }
    })

  }

  rundropdowns(item) {
    if (this.companyRepresentative[item]) {
      this.updateCorrespondingDropdown({ value: { id: this.companyRepresentative[item].id } }, item, false)
    }
  }

  public objectComparisonFunction = function (option, value): boolean {
    let sss = value ? option === value : false
    return sss;
  };
  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.id === value.id : false
    return sss;
  };
  resetFields() {
    if (!this.companyRepresentative) this.companyRepresentative = {};
    this.companyRepresentative.type = this.companyType;
    this.companyRepresentative.company = null;
    this.companyRepresentative.additionalFields = {};

    this.setMandatories();
    var addables = this.companyRepresentative.type.additionalFields.filter(
      (elem) => elem.type === "addable"
    );
    addables.forEach((addable) => {
      this.addAddable(addable);
    });
  }
  setMandatories() {
    this.mandatoryFields = this.companyRepresentative.type.additionalFields
      .filter((elem) => {
        return elem.required && !["header", "sub-header"].includes(elem.type);
      })
      .map((elem) => elem.key);
  }
  addAddable(addable) {
    if (!this.companyRepresentative.additionalFields[addable.key])
      this.companyRepresentative.additionalFields[addable.key] = {
        type: 'addable',
        fields: [],
      };
    addable.fields.forEach((f) => {
      let fArr = [];
      f.forEach((fl) => {
        fArr.push({ key: fl.key, type: fl.type, name: fl.name });
      });
      this.companyRepresentative.additionalFields[addable.key].fields.push(
        fArr
      );
    });
  }
  removeAddable(index, key) {
    this.companyRepresentative.additionalFields[key].fields.splice(index, 1);
  }
  getKeys(obj) {
    return Object.keys(obj);
  }
  getItemName(item) {
    if (item) {
      return item.name || item;
    }
  }

  getName(name) {
    return name[localStorage.getItem('default_lang')];
  }
  showDropdown(key, index, type) {
    if (['municipality dropdown', 'district dropdown'].includes(type)) {
      if (
        !this.addableDropdowns[key] ||
        !this.addableDropdowns[key][index] ||
        !this.addableDropdowns[key][index][type] ||
        this.addableDropdowns[key][index][type].length === 0
      ) {
        return false;
      }
    }
    return true;
  }
  isDropdownType(type: string) {
    return [
      'custom dropdown',
      'sector dropdown',
      'postal codes dropdown',
      'region dropdown',
      'department dropdown',
      'city dropdown',
      'municipality dropdown',
      'district dropdown',
      'branch dropdown',
      'activity dropdown',
      'activity detail dropdown',
    ].includes(type);
  }

  updateAddableSelections(event, key, index, type) {
    switch (type) {
      case 'region dropdown':
        {
          if (!this.addableDropdowns[key]) {
            this.addableDropdowns[key] = [];
          }
          this.addableDropdowns[key].push({ 'department dropdown': [] });
          this.dropdownService
            .getLocations('dictionary/region/' + event.value.id + '/department')
            .subscribe((departments) => {
              this.addableDropdowns[key][index]['department dropdown'] =
                departments;
              this.addableDropdowns[key][index]['city dropdown'] = [];
              this.addableDropdowns[key][index]['municipality dropdown'] = [];
              this.addableDropdowns[key][index]['district dropdown'] = [];
            });
        }
        break;

      case 'department dropdown':
        this.dropdownService
          .getLocations('dictionary/department/' + event.value.id + '/city')
          .subscribe((cities) => {
            this.addableDropdowns[key][index]['city dropdown'] = cities;
            this.dropdownService
            .getLocations('dictionary/department/' + event.value.id + '/municipality')
            .subscribe((municipalities) => {
              this.addableDropdowns[key][index]['municipality dropdown'] = municipalities;
              this.addableDropdowns[key][index]['district dropdown'] = [];
            })


          });
        break;
      case 'city dropdown':
        if (event.value.municipality)
          this.dropdownService
            .getLocations('dictionary/city/' + event.value.id + '/municipality')
            .subscribe((municipalities) => {
              this.addableDropdowns[key][index]['municipality dropdown'] =
                municipalities;
              this.addableDropdowns[key][index]['district dropdown'] = [];
            });
        else if (event.value.district)
          this.dropdownService
            .getLocations('dictionary/city/' + event.value.id + '/district')
            .subscribe((districts) => {
              this.addableDropdowns[key][index]['district dropdown'] =
                districts;
              this.addableDropdowns[key][index]['municipality dropdown'] = [];
            });
        break;
      case 'sector dropdown':
        {
          if (!this.addableDropdowns[key]) {
            this.addableDropdowns[key] = [];
          }

          this.addableDropdowns[key].push({ 'branch dropdown': [] });


          this.dropdownService
            .getLocations('dictionary/sector/' + event.value.id + '/branch')
            .subscribe((branches) => {
              this.addableDropdowns[key][index]['branch dropdown'] = branches;
              this.addableDropdowns[key][index]['activity dropdown'] = [];
              this.addableDropdowns[key][index]['activity detail dropdown'] =
                [];
            });
        }
        break;

      case 'branch dropdown':
        this.dropdownService
          .getLocations('dictionary/branch/' + event.value.id + '/activity')
          .subscribe((activities) => {
            this.addableDropdowns[key][index]['activity dropdown'] = activities;
            this.addableDropdowns[key][index]['activity detail dropdown'] = [];
          });
        break;
      case 'activity dropdown':
        this.dropdownService
          .getLocations(
            'dictionary/activity/' + event.value.id + '/activityDetail'
          )
          .subscribe((activityDetails) => {
            this.addableDropdowns[key][index]['activity detail dropdown'] =
              activityDetails;
          });

        break;
      default:
        return;
    }
  }
  getList(key, index, type) {
    switch (type) {
      case 'custom dropdown':
        return [];
      case 'region dropdown':
        return this.dropdowns['region dropdown'];
      case 'sector dropdown':
        return this.dropdowns['sector dropdown'];
      case 'department dropdown':
      case 'city dropdown':
      case 'district dropdown':
      case 'municipality dropdown':
      case 'branch dropdown':
      case 'activity dropdown':
      case 'activity detail dropdown':
        return this.getAddableDropdown(key, index, type);
      default:
        return [];
    }
  }
  getAddableDropdown(key, index, type) {
    if (this.addableDropdowns[key] && this.addableDropdowns[key][index]) {
      return this.addableDropdowns[key][index][type];
    } else {
      return [];
    }
  }
  updateCorrespondingDropdown(drdown, key, reset = false) {
    if (key === 'region')
      this.dropdownService
        .getLocations('dictionary/region/' + drdown.value.id + '/department')
        .subscribe((departments) => {
          if (reset) {
            departments && (this.dropdowns['department dropdown'] = departments);
            this.dropdowns['city dropdown'] = [];
            this.dropdowns['municipality dropdown'] = [];
            this.companyRepresentative.additionalFields['department'] = null;
            this.companyRepresentative.additionalFields['city'] = null;
            this.companyRepresentative.additionalFields['municipality'] = null;
            this.companyRepresentative.additionalFields['district'] = null;
          }
        });
    if (key === 'department')
      this.dropdownService
        .getLocations('dictionary/department/' + drdown.value.id + '/city')
        .subscribe((cities) => {
          cities && (this.dropdowns['city dropdown'] = cities);
          this.dropdownService
          .getLocations('dictionary/department/' + drdown.value.id + '/municipality')
          .subscribe((municipalities) => {
            municipalities &&  (this.dropdowns['municipality dropdown'] = municipalities);
          })
          if (reset) {
            this.companyRepresentative.additionalFields['city'] = null;
            this.companyRepresentative.additionalFields['municipality'] = null;
            this.companyRepresentative.additionalFields['district'] = null;
          }
        });
    if (key === 'city') {
      if (drdown.value.municipality)
        this.dropdownService
          .getLocations('dictionary/city/' + drdown.value.id + '/municipality')
          .subscribe((municipalities) => {
            municipalities && (this.dropdowns['municipality dropdown'] = municipalities);
            
            this.cityHasMunicipality = true;
            this.cityHasDistrict = false;
            if (!this.mandatoryFields.includes('municipality')) {
              this.mandatoryFields.push('municipality');
            }
            if (reset) {
              this.dropdowns['district dropdown'] = [];
              this.companyRepresentative.additionalFields['municipality'] = null;
              this.companyRepresentative.additionalFields['district'] = null;
            }
            this.mandatoryFields = this.mandatoryFields.filter(
              (elem) => elem !== 'district'
            );
          });
      else if (drdown.value.district)
        this.dropdownService
          .getLocations('dictionary/city/' + drdown.value.id + '/district')
          .subscribe((districts) => {
            
            districts && (this.dropdowns['district dropdown'] = districts);
            this.cityHasMunicipality = false;
            this.cityHasDistrict = true;
            if (reset) {
              this.dropdowns['municipality dropdown'] = [];
              this.companyRepresentative.additionalFields['municipality'] = null;
              this.companyRepresentative.additionalFields['district'] = null;
            }
            if (!this.mandatoryFields.includes('district')) {
              this.mandatoryFields.push('district');
            }
            this.mandatoryFields = this.mandatoryFields.filter(
              (elem) => elem !== 'municipality'
            );
          });
    }
    if (key === 'sector')
      this.dropdownService
        .getLocations('dictionary/sector/' + drdown.value.id + '/branch')
        .subscribe((branches) => {
          this.dropdowns['branch dropdown'] = branches;
          this.dropdowns['activity dropdown'] = [];
          this.dropdowns['activity detail dropdown'] = [];
          this.companyRepresentative.additionalFields['branch'] = null;
          this.companyRepresentative.additionalFields['activity'] = null;
          this.companyRepresentative.additionalFields['activityDetail'] = null;
        });

    if (key === 'branch')
      this.dropdownService
        .getLocations('dictionary/branch/' + drdown.value.id + '/activity')
        .subscribe((activities) => {
          this.dropdowns['activity dropdown'] = activities;
          this.dropdowns['activity detail dropdown'] = [];
          this.companyRepresentative.additionalFields['activity'] = null;
          this.companyRepresentative.additionalFields['activityDetail'] = null;
        });

    if (key === 'activity')
      this.dropdownService
        .getLocations(
          'dictionary/activity/' + drdown.value.id + '/activityDetail'
        )
        .subscribe((activityDetails) => {
          this.dropdowns['activity detail dropdown'] = activityDetails;
          this.companyRepresentative.additionalFields['activityDetail'] = null;
        });
  }


  missingMandatories() {
    if (!this.companyRepresentative || !this.companyRepresentative.type) {
      return true;
    } else {
      if (this.mandatoryFields.length === 0) {
        return false;
      } else {
        let missingMandatory = false;
        for (let i = 0; i < this.mandatoryFields.length; i++) {
          const mandKey = this.mandatoryFields[i];
          if (
            !this.companyRepresentative.additionalFields[mandKey] ||
            (this.companyRepresentative.additionalFields[mandKey] &&
              (null ||
                this.companyRepresentative.additionalFields[mandKey]
                  .toString()
                  .match(/^ *$/) !== null))
          ) {
            if (
              ['district', 'municipality'].includes(mandKey) &&
              ['district', 'municipality'].some(
                (elem) => this.companyRepresentative.additionalFields[elem]
              )
            ) {
              missingMandatory = false;
            } else {
              return true;
            }
          }
        }
        return missingMandatory;
      }
    }
  }

  tempRep: any;
  

  sendRequest() {
    this.sendingRequest = true;
    this.companyRepresentative.ownerCreation = 0
    this.tempRep = JSON.stringify(this.companyRepresentative);
    this.clearPayload();
    this.spinner.show();
    this.taxPayerService
      .sendRequest(
        this.companyRepresentative,  this.companyRepresentative.id?true:false
      )
      .subscribe((val) => {
        this.sendingRequest = false;
        if (val.tin) {
          var valTin = val.tin;
          this.sharedService.addTaxpayer()
          this.dialogRef.close(true);
          this.spinner.hide();
        } else {
          this.companyRepresentative = JSON.parse(this.tempRep);
          this.spinner.hide();
          var text = this.translateService.instant(
            'TIN-REQUEST-MSGS.' + val.reasonType
          );
          if (val.duplicateValues) {
            var elem = null;
            for (var k in val.duplicateValues) {
              var elem =
                this.companyRepresentative.type.additionalFields.find(
                  (el) => el.key === k
                );
            }
          }
          if (elem) {
            text = this.translateService
              .instant('TIN-REQUEST-MSGS.' + val.reasonType)
              .replace(
                'XXX',
                elem['name'][localStorage.getItem('default_lang')] +
                ' - ' +
                val.duplicateValues[elem.key] +
                ' - '
              )
              .replace('YYY', val.duplicateTin);
          }

          this.reasonType = val.reasonType;
          this.toastr.error(text, `ERROR!!!`, {
            positionClass: 'toast-center-center',
            timeOut: 10000,
          });
        }
      });
  }

  clearPayload() {
    if (this.companyRepresentative.additionalFields) {
      if (this.companyRepresentative.additionalFields.region) {
        delete this.companyRepresentative.additionalFields.region.department;
      }
      if (this.companyRepresentative.additionalFields.department) {
        delete this.companyRepresentative.additionalFields.department.city;
      }
      if (this.companyRepresentative.additionalFields.city) {
        delete this.companyRepresentative.additionalFields.city.district;
        delete this.companyRepresentative.additionalFields.city.municipality;
      }

      if (this.companyRepresentative.additionalFields.sector) {
        delete this.companyRepresentative.additionalFields.sector.branch;
      }
      if (this.companyRepresentative.additionalFields.branch) {
        delete this.companyRepresentative.additionalFields.branch.activity;
      }
      if (this.companyRepresentative.additionalFields.activity) {
        delete this.companyRepresentative.additionalFields.activity
          .activityDetail;
      }

      this.companyRepresentative.type.additionalFields.forEach((elem) => {
        if (elem.type === 'addable') {
          if (this.companyRepresentative.additionalFields[elem.key]) {
            delete this.companyRepresentative.additionalFields[elem.key]
              .changeable;
            delete this.companyRepresentative.additionalFields[elem.key]
              .columnStyle;
            delete this.companyRepresentative.additionalFields[elem.key]
              .duplicate;
            delete this.companyRepresentative.additionalFields[elem.key]
              .nonFinalized;
            delete this.companyRepresentative.additionalFields[elem.key]
              .required;
            this.companyRepresentative.additionalFields[
              elem.key
            ].fields.forEach((elem) => {
              elem.forEach((f) => {
                if (f.value) this.deleteDropdownsInData(f, f.value);
              });
            });
          }
        }
        this.deleteDropdownsInData(
          elem,
          this.companyRepresentative.additionalFields[elem.key]
        );
      });

      delete this.companyRepresentative.type.additionalFields;
    }

  }
  deleteDropdownsInData(elem, additionalField) {
    if (additionalField) {
      if (elem.key !== 'region' && elem.type === 'region dropdown') {
        delete additionalField.department;
      }
      if (elem.key !== 'department' && elem.type === 'department dropdown') {
        delete additionalField.city;
      }
      if (elem.key !== 'city' && elem.type === 'city dropdown') {
        delete additionalField.municipality;
        delete additionalField.district;
      }
      if (elem.key !== 'sector' && elem.type === 'sector dropdown') {
        delete additionalField.branch;
      }
      if (elem.key !== 'branch' && elem.type === 'branch dropdown') {
        delete additionalField.activity;
      }
      if (elem.key !== 'activity' && elem.type === 'activity dropdown') {
        delete additionalField.activityDetail;
      }
    }
  }
}
