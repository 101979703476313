import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EventEmitter } from "events";
import { CashPayment } from "src/app/entities/cashPayment";
import { DialogData } from "src/app/entities/dialogData";
import { AdminService } from "src/app/services/admin.service";
import { AvisService } from "src/app/services/avis.service";
import { CategoryService } from "src/app/services/category.service";
import { ProcessService } from "src/app/services/process.service";
import { SharedService } from "src/app/services/sharedService";
import { TaxNameService } from "src/app/services/taxName.service";
import { TaxPayerService } from "src/app/services/taxPayer.service";
import { UserService } from "src/app/services/user.service";
import { DialogCommunicator } from "../dialog-communicator";
import { SendSmsDialogComponent } from "../send-sms-dialog/send-sms-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { CompanyDexieService } from "src/app/services/companyDexie-services/companyDexie.service";
@Component({
  selector: "gov-cash-payment-dialog",
  templateUrl: "./cash-payment-dialog.component.html",
  styleUrls: ["./cash-payment-dialog.component.css"],
})
export class CashPaymentDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  cashPayment: any;
  currentUser: any;
  taxNames: any[];
  comp: any;
  controlsVisible = true;
  canSendSms = false;
  isOnline = true;
  constructor(
    public dialogRef: MatDialogRef<CashPaymentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService, public taxNameService: TaxNameService,
    public taxPayerService: TaxPayerService, public categoryService: CategoryService,
    public avisService: AvisService, public processService: ProcessService,
    public userService: UserService, public confirmationDialog: MatDialog,
    public adminService: AdminService, public companyDexieService: CompanyDexieService, public translate: TranslateService
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }
  async ngOnInit() {
    this.sharedService.onlineStatus$.subscribe(async status => {
      this.isOnline = status
    })
    this.setCashPayment();
    this.currentUser = await this.getUser()
    this.comp = await this.getcompany()
    this.isOnline ? await this.onlineInit() : await this.offlineInit()
  }

  async onlineInit() {
    let admin = await this.adminService.getAdmin().toPromise()
    if (admin?.smsConfig?.payment?.enabled) {
      this.canSendSms = true
    }
    if (this.comp?.type?.id) {
      let res = await this.categoryService.getCategory("category", this.comp.type.id).toPromise();
      if (res.avis) {
        await this.avisPaymentsSetup();
      } else {
        this.revenueCodeBasedPaymentsSetup(false);
      }
    }
  }

  async offlineInit() {
    if (this.comp?.type) {
      let res = await this.companyDexieService.getCategoryById(this.comp.type)
      if (res.avis) {
        await this.avisPaymentsSetup();
      } else {
        this.revenueCodeBasedPaymentsSetup();
      }
    }
  }

  async getUser() {
    return this.isOnline ? await this.userService.getCurrentUser().toPromise() : await this.companyDexieService.getCurrentUser()
  }

  async getcompany() {
    return this.isOnline ? await this.taxPayerService.getTaxPayerByNiu("tax-payers", this.data["tin"]).toPromise() :
      await this.companyDexieService.getCompanyByTin(this.data['tin'])
  }

  async revenueCodeBasedPaymentsSetup(offline = true) {
    if (offline) {
      let comp = await this.companyDexieService.getCompanyByTin(this.data['tin'])
      this.taxNames = await this.companyDexieService.getTaxNamesByMunicipalityAndType(comp.municipality, comp.type)
    }
    else {
      this.taxNames = await this.taxNameService.getTaxNamesForTaxpayer("tax-name", this.data).toPromise()
    }
    this.taxNames = this.taxNames.map(this.transformTaxname)
  }

  transformTaxname = (elem) =>
  ({
    amount: elem.amount,
    name: elem.name,
    id: elem.id,
    revenueCode: elem.revenueCode.length > 0 ? elem.revenueCode[0] : null
  })

  private async avisPaymentsSetup() {
    let avises = this.isOnline ? await this.avisService.getUnpaidAvises(this.data["tin"]).toPromise() : this.comp.avises
    if (avises.length === 0) this.taxNames = [];
    let txNames = [];
    let index = 0;
    for (let elem of avises) {
      let proc;
      if (this.isOnline) {
        try {
          proc = await this.processService.getProcess(elem.processId).toPromise();
        } catch (error) {
          continue;
        }
      }
      else {
        proc = { name: elem.name, month: elem.month, year: elem.year }
      }
      txNames.push({
        amount: elem.total, name: this.convertDate(elem.submission_date) + " " + elem.total.toLocaleString() + "XOF " +
          (elem.processName || "") +
          (elem.rec_num ? " - " + elem.rec_num : ""),
        id: elem.id,
        avis: true,
        month: (proc && proc["month"]) || null,
        origName: (proc && proc.name) || null,
        year: (proc && proc.year) || null,
        revenueCode: {
          code: elem.revenueCode || "",
          id: elem.revenueCodeId || "",
        },
        reference_number: elem.reference_number,
      });
      if (index === avises.length - 1) {
        this.taxNames = txNames.map(txName => {
          if (txName.avis && txName.month) {
            return {
              ...txName,
              name: `${this.translate.instant("MONTHS_FR." + (txName.month < 10 ? '0' + txName.month : txName.month))}-${txName.year} ${txName.amount.toLocaleString('en-GB') + 'XOF'} ${txName.origName}`
            };
          } return txName;

        });
        this.sortedDataByYearsAndMonths(this.taxNames);
      }
      index++;
    }
    txNames.length === 0 && (this.taxNames = []);
  }

  private setCashPayment() {
    this.cashPayment = new CashPayment();
    this.cashPayment.amount = 100;
    this.cashPayment.date = new Date();
    this.cashPayment["class"] = "CASH-PAYMENT";
    this.setEntity(this.cashPayment);
  }

  sortedDataByYearsAndMonths(data) {
    data.sort((a, b) => {
      if (a.year < b.year) return -1;
      if (a.year > b.year) return 1;
      if (a.month === null && b.month !== null) return -1;
      if (a.month !== null && b.month === null) return 1;
      if (a.month < b.month) return -1;
      if (a.month > b.month) return 1;
      return 0;
    })
  }
  convertDate = (dateStr) => {
    if (isNaN(Date.parse(dateStr))) {
      return "";
    }
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };
  selectChangeTaxNames = (event: any) => {
    this.cashPayment["taxName"] = event.data[0];
  };

  saveAndSendSMS(): void {
    this.cashPayment.sendSMS = true;
    this.cashPayment.telephone = this.comp.telephone || this.comp.additionalFields.telephone;
    const dialogRef = this.dialog.open(SendSmsDialogComponent, {
      width: "500px",
      panelClass: "custom-dialog-container",
      data: { cashPayment: this.cashPayment },
    });
    this.controlsVisible = false;

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.sent) {
        this.dialogRef.close();
      } else {
        this.controlsVisible = true;
      }
    });
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value) return option === value;
    return false;
  };
}
