import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { Row } from '../form-creator/row'


@Component({
  selector: 'rdf-formcreation-row-util',
  templateUrl: './row-util.component.html',
  styleUrls: ['./row-util.component.css']
})
export class RowUtilComponent implements OnInit, OnDestroy {
  @Output() onRowCreate = new EventEmitter<any>();
  @Output() onRowUpdate = new EventEmitter<any>();
  @Output() onRowCancel = new EventEmitter<any>();
  @Input() emittables: [];
  @Input() row: Row;
  @Input() columns: [];
  @Input() rows: [];
  @Input() stage: any;
  @Input() dataSource: any;
  @Input() formula: any;
  @Input() rowkey: any;
  columnsInRows: boolean[]
  formulaKeySubscription: any
  selectableList = false;
  rowTypes = ["string", "number", "date", "checkbox", "dropdown", "multiselect", "option", "sector dropdown","branch dropdown","activity dropdown","activity detail dropdown",
  "region dropdown","department dropdown","city dropdown","municipality dropdown","district dropdown", "locality dropdown", "map"]
  rowStyles = ["input", "group", "text"]
  
  @Input() editingState = false;
  constructor(public sharedService: SharedService) { }
  ngOnDestroy(): void {
    if (this.formulaKeySubscription) {
      this.formulaKeySubscription.unsubscribe()
    }
  }
  prevRowKey: any;
  currentRowKey: any
  checkRequired = {};
  checkFormulas = {};
  staticTexts = {};
  formulas = {};
  rowStyle = "input"
  excelMappingText = "excel mapping"
  ngOnInit() {
    if (this.dataSource !== undefined) {
      this.row = this.dataSource.rows.find(elem => elem.key === this.rowkey)
    } else if (this.row === undefined) {
      this.row = new Row()
    }
    this.rowStyle = this.row.style
    this.currentRowKey = this.row.key
    this.prevRowKey = this.row.key
    this.checkRowType(this.row.type)
    if (this.columns && this.columns.length>0){
      this.excelMappingText = "excel row"
    }
    if (this.row['values']) {
      this.row['values'].forEach(element => {
        this.checkFormulas[element['column']] = element['formula'];
        this.formulas[element['column']] = element['formula'];
        this.checkRequired[element['column']] = element['required'];
        this.staticTexts[element['column']] = element['value'];
      })
    }
    this.formulaKeySubscription = this.sharedService.formulaKeyEmmiter.subscribe(val => {
      this.addKeyToFormula(val)
    })
  }

  createRow() {
    this.row.style = this.rowStyle
    this.row.key = this.currentRowKey;
    this.onRowCreate.emit(this.row)
    this.rowFormula = undefined
  }

  updateRow(rowKey) {
    this.row.style = this.rowStyle
    this.row.key = this.currentRowKey;
    if (this.row['values'] !== undefined) {
      for (var i = 0; i < this.row['values'].length; i++) {
        if (this.formulas[this.row['values'][i].column] !== undefined) {
          if (this.row['values'][i]["type"] !== undefined)
            delete this.row['values'][i]["type"];
          this.row['values'][i]["formula"] = this.formulas[this.row['values'][i].column]
        }
        if (this.checkRequired[this.row['values'][i].column] !== undefined) {
          this.row['values'][i]['required'] = this.checkRequired[this.row['values'][i].column]
        }

        if (this.staticTexts[this.row['values'][i].column] !== undefined) {
          this.row['values'][i]['value'] = this.staticTexts[this.row['values'][i].column]
        }
      }
    }
    this.onRowUpdate.emit({ row: this.row, prevRowKey: this.prevRowKey })
  }
  cancelEditing() {
    this.onRowCancel.emit()
  }

  lastColumn = ""
  calculateColumnInRowsClasses(index) {
    return {
      btn: true,
      'selected-component': this.columnsInRows[index]
    };
  }
  selectLastFormula(col) {
    this.lastColumn = col.key
  }

  rowFormula: string
  addKeyToFormula(event) {
    this.formulas[this.lastColumn] = this.formulas[this.lastColumn] === undefined ? event : this.formulas[this.lastColumn] + " " + event
    this.rowFormula = this.rowFormula === undefined ? event : this.rowFormula + " " + event
  }
  checkRowType(type) {
    this.selectableList = false;
    if (['dropdown', 'multiselect','option'].includes(type)) {
      this.selectableList = true
    }else{
      this.row['selectItems'] = null
    }
  }
  setListItems(items) {
    this.row['selectItems'] = items
  }
  setCondition(event){
    this.row['condition'] = event
  }
  setCustomValue(event){
    this.row['customValueEmitter'] = event
  }
}
