import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NgxSpinnerService } from "ngx-spinner";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";
import { SharedService } from "src/app/services/sharedService";
import { TasDataSource } from "src/app/services/tasGovTable.datasource";
import { UserService } from "src/app/services/user.service";
import { SmsLogDialogComponent } from "../dialogs/sms-log-dialog/sms-log-dialog.component";

@Component({
  selector: "tas-gov-sms-log",
  templateUrl: "./sms-log.component.html",
  styleUrls: ["./sms-log.component.css"],
})
export class SmsLogComponent implements OnInit, AfterViewInit {
  filterObject: {};

  displayedColumns: string[] = [
    "date",
    "smsType",
    "reference_number",
    "tin",
    "companyName",
    "address",
    "status"
  ];
  dataSource: TasDataSource;
  loading = { inProgress : false, downloadInProgress: false, error: false }
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public spinner:NgxSpinnerService
  ) {}

  ngOnInit() {
    this.dataSource = new TasDataSource(this.userService);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadSmsLogTable()))
      .subscribe();
  }
  resetLoading = () => {
    this.loading.inProgress = true
    this.loading.error = false
    this.dataSource.emptyData()
  }
  loadSmsLogTable() {
    this.resetLoading()
    this.dataSource.loadData(
      'sms-log',
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject, 
      this.loading
    );
  }

  openSmsLogDialog(user): void {
    const dialogRef = this.dialog.open(SmsLogDialogComponent, {
      width: "600px",
      panelClass: "custom-dialog-container",
      data: user,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  advancedSearch(filter) {
    if (!this.filterObject){
      this.filterObject = {}
    }
    let advF = [
      "name",
      "tin"
    ];
    advF.forEach((elem) => {
      if (!filter[elem] || filter[elem].trim().length===0) {
        delete filter[elem];
      }
    });
    if (filter && filter.searchDateTo){
      filter.searchDateTo.setHours(23, 59, 59, 999)
    }
    this.filterObject = Object.assign({}, filter);
    this.resetLoading()
    this.dataSource.loadData(
      "sms-log",
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      (Object.keys(this.filterObject).length === 0 ? null : this.filterObject),
      this.loading
    );
  }

  reset() {
    this.filterObject = {};
    this.advancedSearch({});
  }
}
