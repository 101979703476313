import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { SharedService } from './sharedService';
import { catchError, tap, map } from 'rxjs/operators';
import { UserStatus } from '../entities/states/userStatus';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(public http: HttpClient, public sharedService: SharedService) { }

  getList(resourceUrl:string, query=null, projection=null): Observable<any> {
    if (query)
      resourceUrl +=query
    else if (projection)
      resourceUrl +=`?filter.projection=${projection}`
    if (query && projection){
      resourceUrl +=query + `&filter.projection=${projection}`
    }
    return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
      map(res => {
        return res;
      })
    );
  }
  

  public getTableData(resourceUrl:string,
    filter = '',  sortOrder = 'asc', sortColumn = '',
    pageNumber = 0, pageSize = 10, filterObject): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl), {
      params: new HttpParams()
        .set('filter.query', encodeURIComponent(filter))
        .set('filter.object', this.stringifyWithUTC(filterObject))
        .set('sortOrder', sortOrder)
        .set('sortColumn', sortColumn.toString())
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => {
        return res;
      })
    );
  }

  stringifyWithUTC(obj) {
    return JSON.stringify(obj, (key, value) => {
      if (value instanceof Date) {
        return new Date(value.getTime() - value.getTimezoneOffset() * 60000).toISOString();
      }
      if (typeof value === 'string') {
        return encodeURIComponent(value.trim());
      }
      return value;
    });
  }

  getResource(resourceUrl:string, id:any): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl+'/'+id)).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateResource(resourceUrl: string, resource: any): Observable<any> {
    if (resource.id) {
      return this.http.put<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), resource, httpOptions).pipe(
        tap((resource: any) => this.log(`updated ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('update'))
      );
    }
    else {
      return this.http.post<any>(this.sharedService.apiUrl(resourceUrl), resource, httpOptions).pipe(
        tap((resource: any) => this.log(`added ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('add'))
      );
    }
  }

  
  deleteResource(resourceUrl: string, resource: any): Observable<any> {
      return this.http.delete<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), resource).pipe(
        tap((resource: any) => this.log(`resource deleted`)),
        catchError(this.handleError<any>('deleted'))
      );
  }
 

  deactivateResource(resourceUrl: string, resource: any): Observable<any> {
    // return this.http.delete<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id)).pipe(
    //   tap((resource: any) => this.log(`deactivated ${resourceUrl} w/ id=${resource.id}`)),
    //   catchError(this.handleError<any>('deactivate'))
    // );
    return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), resource, httpOptions).pipe(
      tap((resource: any) => this.log(`activated ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>('activate'))
    );
  }

  activateResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), {status:UserStatus.ACTIVE}, httpOptions).pipe(
      tap((resource: any) => this.log(`activated ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>('activate'))
    );
  }

  patchResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource.id), resource, httpOptions).pipe(
      tap((resource: any) => this.log(`activated ${resourceUrl} w/ id=${resource.id}`)),
      catchError(this.handleError<any>('activate'))
    );
  }

  changeStatus(resourceUrl: string, resource: any): Observable<any> {
    return this.http.patch<any>(this.sharedService.apiUrl(resourceUrl+'/' + resource), null, httpOptions).pipe(
      tap((resource: any) => this.log(`status change ${resourceUrl} w/ id=${resource}`)),
      catchError(this.handleError<any>('status change'))
    );
  }

  addAdmin(resourceUrl: any, homepage: any): Observable<any> {
    return this.http.post<any>(this.sharedService.apiUrl(resourceUrl), homepage, httpOptions).pipe(
        tap((resource: any) => this.log(`added ${resourceUrl} w/ id=${resource.id}`)),
        catchError(this.handleError<any>('add'))
      );
  }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}


