import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { SharedService } from 'src/app/services/sharedService';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-homepage-admin.component',
  templateUrl: './homepage-admin.component.html',
  styleUrls: ['./homepage-admin.component.css']
})
export class HomepageAdminComponent implements OnInit {
  public uploaderOne: FileUploader;
  fileNameOne: string;
  homepage = {}
  allowedMimeType = [".pdf", ".doc"];
  @ViewChild("fileUploadOne", {static: true}) selectedFile: any;
  constructor(public adminService: AdminService, private toastr: ToastrService, private translateService: TranslateService,
    private sharedService: SharedService,private spinner: NgxSpinnerService, public dialog: MatDialog){
  }
  homepageItem = {
    title:"",
    text:"",
    manual:""
  }
 

  ngOnInit() {
    this.adminService.getAdmin().subscribe(val=>{
      
      if (val!==null && val.homepage)
      this.homepageItem = val.homepage
     
      this.uploaderOne = new FileUploader({
        url: this.sharedService.apiUrl('upload/manual'), authToken: "Bearer " + localStorage.getItem('tas_gov_access_token'),
        allowedFileType: ["pdf", "doc"],
        maxFileSize: 50 * 1024 * 1024,
        itemAlias: 'manual'
      });
      this.uploaderOne.onAfterAddingFile = (fileItem) => {
        this.selectedFile.nativeElement.value = "";
        if (this.uploaderOne.queue.length > 1) {
          this.uploaderOne.removeFromQueue(this.uploaderOne.queue[0]);
        }
        this.fileNameOne = (fileItem.file.name);
      }
      this.uploaderOne.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.uploaderOne.clearQueue();
        this.fileNameOne = null
        this.adminService.getAdmin().subscribe(val=>{
          this.homepageItem = val.homepage
        })
      };
    })
  }
  async saveHomepage(){
    await this.adminService.addAdmin('admin/homepage', this.homepageItem).toPromise()
    this.toastr.success(this.translateService.instant('SETTINGS.SUCCESS'), 
      this.translateService.instant('SETTINGS.HOMEPAGE_SAVED'), 
      { toastClass: "ngx-toastr border-red", timeOut: 3000 });
  }

  downloadManualFile(name) {
    this.spinner.show();
    this.adminService.downloadManualFile(name).subscribe
      (data => {
        this.downloadPrc(data,name)
      });
  }
 
  downloadPrc(data,name){
    this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = name;
        saveAs(file, filename);
  }

  deleteFileEmmitter: EventEmitter<boolean> = new EventEmitter(); 
  subscriptionConfirm: any 
  openDeleteFileDialog(file) { 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
      width: '500px', 
      data: { text: "DIALOG.PROCESS_DELETE_FILE", emitter: this.deleteFileEmmitter, obj: file, action:"CONFIRM" } 
    }); 
    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(val => { 
      if (val) { 
        this.spinner.show() 
        this.adminService.deleteManualFile().subscribe(res=>{
          this.adminService.getAdmin().subscribe(val=>{
            this.uploaderOne.clearQueue();
            this.selectedFile.nativeElement.value = "";
            this.fileNameOne = null
            this.homepageItem = val.homepage
          })
          this.spinner.hide() 
        })
      } 
    }) 
 
    dialogRef.afterClosed().subscribe(result => { 
      this.subscriptionConfirm.unsubscribe()
    }); 
  } 
}
