import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../services/base.service';
import { SharedService } from "../../services/sharedService";

@Injectable()
export class MapService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService){
    super(http, sharedService);
  }

  getInstance$(){
    return this.http.get(
      this.sharedService.apiUrl('map/instance'))
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

  getRegistered$(bbox){
    return this.http.get(
      this.sharedService.apiUrl('map/feature/registered', '?bbox='+ bbox))
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

  getNearestBuildings$(latlng){
    return this.http.get(
      this.sharedService.apiUrl('map/feature/nearest', '?latlng='+ latlng))
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

  getFeatureList$(bbox){
    return this.http.get(
      this.sharedService.apiUrl('map/feature', '?bbox='+ bbox))
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

  getFeatureById$(id){
    return this.http.get(
      this.sharedService.apiUrl('map/feature/' + id, ''))
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

  getTinsByBuildingId$(id):Observable<any>{
    return this.http.get(
      this.sharedService.apiUrl('map/feature/building-tin-balance/' + id, ''))
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

  addBuilding$(body): Observable<any> {
    return this.http.post<any>(this.sharedService.apiUrl('map/building'), body)
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

  updateBuilding$(id, body): Observable<any> {
    return this.http.put<any>(this.sharedService.apiUrl(`map/building/${id}`), body)
      .pipe(
        catchError(error => this.sharedService.handleError(error))
      );
  }

}
