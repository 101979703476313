import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SharedService } from "./sharedService";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(public http: HttpClient, public sharedService: SharedService) {}

  getList(resourceUrl: string, potentialParents = null): Observable<any> {
    if (potentialParents) {
      return this.http
        .get(this.sharedService.apiUrl(resourceUrl) + "/potentialParents/" + potentialParents)
        .pipe(
          map((res) => {
            return res;
          })
        );
    } else {
      return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
        map((res) => {
          return res;
        })
      );
    }
  }

  getCategory(resourceUrl: string, id: string): Observable<any> {
    return this.http
      .get(this.sharedService.apiUrl(resourceUrl) + "/" + id)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
