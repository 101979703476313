import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class MonthDateAdapter extends NativeDateAdapter {
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform
  ) {
    super(matDateLocale, platform);
  }
 
  getFirstDayOfWeek(): number {
    return 1; // Monday
  }

  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[1]);
      const month = Number(str[0]) - 1;
      return new Date(year, month, 1);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {
    const month = date.getMonth()<9?"0"+(date.getMonth() + 1):date.getMonth() + 1;
    const year = date.getFullYear();
    return `${month}/${year}`;
  }
}