import { Component, Input, OnInit } from '@angular/core';
import { TaxPayerDialogComponent } from '../dialogs/tax-payer-dialog/tax-payer-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {
  @Input() company: any;
  @Input() canUpdate = true;
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  getName(addT) {
    if (addT.name)
      return addT.name[localStorage.getItem('default_lang')] || addT.name['en'] || addT.name
    return ''
  }
  getValue(addT) {
    if (this.company.additionalFields[addT.key]) 
      return this.company.additionalFields[addT.key].name || this.company.additionalFields[addT.key]
    return ''
  }
  getCreator() {
    if (this.company['createdBy']) {
      let createdBy = this.company['createdBy']
      let name = createdBy['name'] && createdBy['name'] !== null ? createdBy['name'] : ''
      let surname = createdBy['name'] && createdBy['name'] !== null ? ' ' + createdBy['surname'] : ''
      let username = createdBy['name'] && createdBy['name'] !== null ? ' (' + createdBy['username'] + ')' : ''
      return name + surname + username
    }
    return ''
  }

  openTaxPayerDialog(taxPayer): void {
    this.dialog.open(TaxPayerDialogComponent, {
      width: '800px', panelClass: 'custom-dialog-container',
      data: taxPayer
    });
  }
}
