
export class RevenueCode {
  public id: number;
  public name: string;
  public code: string;
  
  constructor() {
 
  }

}  