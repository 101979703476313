export * from './clearData';
export * from './addCompanies';
export * from './addTaxNames';
export * from './addUsers';
export * from './addCategories';
export * from './addSynchinfo';
export * from './getAllPayments';
export * from './getUniqueMunicipalities';
export * from './getUniqueTaxpayerTypes';
export * from './searchCompanies';
export * from './getCompanyByTin';
export * from './getLatestSynchronization';
export * from './getTaxNamesByMunicipalityAndType';
export * from './setPayment';
export * from './deleteAvis';
export * from './deleteDatabase';


