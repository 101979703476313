import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import * as L from "leaflet";
import {
  CRS,
  FeatureGroup,
  LatLng,
  featureGroup
} from "leaflet";
import 'leaflet-spin';
import 'leaflet.locatecontrol';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, of } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { MapService } from "./map.service";

@Component({
  selector: 'app-map',
  templateUrl: 'map.component.html',
})
export class MapComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public height = "100vh"
  @Output() mapReady: EventEmitter<any> = new EventEmitter()
  @Input() bbox
  @Output() selectedBuildingDetails = new EventEmitter<any>()
  @Output() myLocationLatLng = new EventEmitter<any>()
  @Output() selectedFeatureGeoJSON = new EventEmitter<any>()
  @Input() buildingId
  @Input() buildingIdsList
  @Input() findMyLocation = false
  @Input() registered = false
  @Input() addMarker = false
  @Input() addPolygon = false
  @Input() addedFeatureId
  private fetchBuilding = new Subject<any>()
  clientInstance

  strToLeafletBBox(bbox) {
    if (!bbox)
      return [new LatLng(environment.boundary[1], environment.boundary[0]),
        new LatLng(environment.boundary[3], environment.boundary[2])]
    else {
      let bboxParts = bbox.split(",")
      return [new LatLng(bboxParts[0].split(" ")[1], bboxParts[0].split(" ")[0]),
        new LatLng(bboxParts[1].split(" ")[1], bboxParts[1].split(" ")[0])]
    }
  }

  countryBounds: LatLng[] = [new LatLng(environment.boundary[1], environment.boundary[0]),
    new LatLng(environment.boundary[3], environment.boundary[2])]

  baseLayerOpenLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // opacity: 0.5,
    maxZoom: 19,
    attribution: '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
  })
  buildingLayer = L.tileLayer.wms('http://mapservice.revenuedev.org/geoserver/CI/wms?maxFeatures=500', {
    tms: false,
    opacity: 0.5,
    transparent: true,
    maxZoom: 19,
    layers: 'CI:buildings_v',
    format: 'image/png',
    attribution: 'using GeoServer open geospatial information server'
  })

  crs
  baseLayers
  options
  map
  loading

  initMap = false;

  drawItems: FeatureGroup = featureGroup();

  drawOptions

  customMarker(color='blue'){
    return new (L.Icon.extend({
      options: {
        iconUrl: `assets/img/marker-icon-${color}.png`,
        shadowUrl: null,
        iconSize: [25,25],
        iconAnchor: [5, 10]
      }
    }));
  }

  constructor(private mapService: MapService,
              private spinner: NgxSpinnerService) {
    this.fetchBuilding.pipe(debounceTime(1500))
      .subscribe(() => {
        this.getFeatureList()
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes.bbox) {
        this.map.fitBounds(L.latLngBounds(this.strToLeafletBBox(this.bbox)))
      }
      if (changes.buildingId) {
        this.mapService.getFeatureById$(this.buildingId).subscribe(geoJson => {
          let singleBuilding = this.createGeoJSONLayer(geoJson, 'green', true).addTo(this.map)
          this.map.fitBounds(singleBuilding.getBounds())
        })
      }
    }
  }


  ngOnInit(): void {

    this.baseLayers = {
      'OpenStreet': this.baseLayerOpenLayer
    }

    // setTimeout(() => {
      this.options = {
        layers: [
          this.baseLayers['OpenStreet']
        ],
        zoom: 8,
        center: L.latLngBounds(this.countryBounds).getCenter(),
        useCache: true,
        crossOrigin: true,
        // maxBounds: L.latLngBounds(this.countryBounds),
        // maxBoundsViscosity: 1,
        fitBounds: L.latLngBounds(this.countryBounds),
        // crs: CRS.EPSG3857
      }

      this.drawOptions = {
        position: 'topright',
        draw: {
          polygon: false,
          polyline: false,
          circle: false,
          rectangle: false,
          marker: false,
          circlemarker: false
        },

        edit: false
      };

      if (this.addMarker){
        this.drawOptions.draw.marker = {icon: this.customMarker()}
        this.drawOptions.edit = {featureGroup: this.drawItems}
      }

      if (this.addPolygon){
        this.drawOptions.draw.polygon = true
        this.drawOptions.edit = {featureGroup: this.drawItems}
      }

      this.mapService.getInstance$().subscribe( data => {
        // @ts-ignore
        this.clientInstance = data.instance
        this.initMap = true
      })

    // }, 50)
  }

  mapControl

  coordsToLatLng(coords) {
    let latlng = CRS.EPSG3857.unproject(L.point(coords[0], coords[1]))
    return latlng
  };


  currentLocation = null
  loadBuildings = false

  onMapReady($event) {

    $event.attributionControl.setPrefix('RDF Maps')

    this.map = $event

    L.control.scale().addTo(this.map)

    this.map.on('overlayadd', (layer) => {
      if (layer.name === 'RDF Buildings'){
        this_.loadBuildings = true
        this_.fetchBuilding.next('')
      }
    })

    this.map.on('overlayremove', (layer) => {
      if (layer.name === 'RDF Buildings'){
        this_.loadBuildings = false
      }
    })

    // // @ts-ignore
    // const customButton = L.control({ position: 'topleft' });
    // customButton.onAdd = () => {
    //   const buttonDiv = L.DomUtil.create('div', 'button');
    //
    //   buttonDiv.innerHTML = `<button>Custom Button</button>`;
    //   buttonDiv.addEventListener('click', () => alert('me'))
    //   return buttonDiv;
    // };
    // customButton.addTo(this.map)


    if (this.findMyLocation)
      // @ts-ignore
      L.control.locate().addTo(this.map)

    let count = 0
    this.map.on('locationfound', (e)=> {
      count ++
      if (count < 2) {
        this.myLocationLatLng.next(e.latlng.lat + "," + e.latlng.lng)
        this.currentLocation = e.latlng.lat + "," + e.latlng.lng
        this.getFeatureList()
      }
    });

    this.map.on('locationerror', (e)=> {
      count = 0
    });

    this.map.on('locatedeactivate', (e)=>{
      this.currentLocation = null
      count = 0
    })

    // this.map.on('locateactivate', (e)=>{
    // });

    this.mapControl = L.control.layers(this.baseLayers, {}, {
      collapsed: false,
      position: "topright",
      hideSingleBase: false
    }).addTo(this.map)

    // setTimeout(
    // this.mapControl.addOverlay(this.buildingLayer, "Buildings")
    //   , 10)

    if (this.buildingId){
      this.mapService.getFeatureById$(this.buildingId).subscribe( geoJson => {
        let singleBuilding = this.createGeoJSONLayer(geoJson, "green", true).addTo(this.map)
        this.map.fitBounds(singleBuilding.getBounds())
      })
    }

    if (!this.buildingId || !this.buildingIdsList)
      this.fetchBuilding.next('')

    let this_ = this
    let bounds = this_.map.getBounds()
    let zoom2distance = {
      7 : 125,
      8: 100,
      9: 80,
      10: 40,
      11: 25,
      12: 15,
      13: 12,
      14: 10,
      15: 5,
      16: 3,
      17: 1,
      18: 0.5,
      19: 0.2
    }
    this.map.on('dragend', function() {
      let newBounds = this_.map.getBounds()
      let dis = this_.calculateDistance(newBounds.getNorthEast(), bounds.getNorthEast())
      let zoom = this.getZoom()
      setTimeout(()=> {
          if (dis > zoom2distance[zoom] &&  zoom > 6) {
            bounds = newBounds
            this_.fetchBuilding.next('')
          }
        },
        50)
    });
    this.map.on('zoomend', function() {
      bounds = this_.map.getBounds()
      this_.fetchBuilding.next('')
    });

    // this.map.on('click', function(e){
    //   this_.addMarker(e)
    // });

    this.map.on("draw:created", function (e) {
      if (this_.marker)
        this_.drawItems.removeLayer(this_.marker)
      this_.marker = e.layer
      this_.drawItems.addLayer(this_.marker)
      this_.selectedFeatureGeoJSON.emit(this_.pointGeoJSON(e.layer.getLatLng()))
    })

    this.mapReady.emit(true)

  }


  calculateDistance(lat1,lat2){
    let dis = lat1.distanceTo(lat2)
    return dis / 1000 || 0
  }

  drawUpdated(e){
    let layers = e.layers
    let this_ = this
    layers.eachLayer(layer => {
      this_.selectedFeatureGeoJSON.emit(this_.pointGeoJSON(layer.getLatLng()))
    });
  }

  drawDeleted(e){
    this.marker = null
  }

  getFeatureList(){
    // this.map.spin(true)
    this.showSpinner()
    let buildings
    if (this.currentLocation)
      buildings = this.mapService.getNearestBuildings$(this.currentLocation)
    else {
      if (this.loadBuildings)
        buildings = this.mapService.getFeatureList$(this.getCurrentBbox())
      else
        buildings =  of("")
    }

    buildings.subscribe(geoJson => {
      setTimeout(()=>{
        if (this.layer){
          this.layer.clearLayers()
        }
        if (!this.layer) {
         this.layer = this.createGeoJSONLayer(geoJson)
         this.mapControl.addOverlay(this.layer, "RDF Buildings")
        }
        this.layer.addData(geoJson)

        if (this.registered){
          this.mapService.getRegistered$(this.getCurrentBbox()).subscribe(registeredBuildings => {
            setTimeout(()=> {
              if (this.layerRegistred){
                this.layerRegistred.clearLayers()
              }
              if (!this.layerRegistred) {
                this.layerRegistred = this.createGeoJSONLayer(registeredBuildings, "green")
                this.layerRegistred.addTo(this.map)
                this.mapControl.addOverlay(this.layerRegistred, "Registered")
              }
              this.layerRegistred.addData(registeredBuildings)
              // this.map.spin(false)
              this.hideSpinner()
            }, 100)
          })
        }else{
          this.hideSpinner()
        }
      },100)
    })
  }

  clearMap() {

    // this.mapControl.removeLayer(this.buildingLayer)

    // this.mapControl.addOverlay(this.buildingLayer, "Buildings")

    // this.fitBounds()

    this.mapReady.emit(true)
  }

  fitBounds(geoJson) {
  }

  onClick(event) {
    this.getFeatureData(event)
  }

  popUp(latlng, infoHtml) {
    L.popup()
      .setLatLng(latlng)
      .setContent(infoHtml)
      .openOn(this.map)
  }

  // popUp(layer) {
  //   let data = layer.feature
  //   let infoHtml = '<div class="block"><div><b>Building:</b></div><div>' + data.properties.id + '</div>'
  //   infoHtml += '<div class="block"><div><b>Address:</b></div><div>' +
  //     (data.properties.street_id ? `${data.properties.street_name} ${data.properties.house_number}`  : 'N/A' ) +'</div>'
  //   infoHtml += '<div class="block"><div><b>Area:</b></div><div>' + data.properties.area + ' sqm</div>'
  //   infoHtml += '</div>'
  //   return infoHtml
  // }

  layer
  layerRegistred

  getFeatureData(e) {
    // if (this.layer) {
    //   this.layer.removeFrom(this.map)
    // }
    // let BBOX = this.getCurrentBbox()
    // let WIDTH = this.map.getSize().x
    // let HEIGHT = this.map.getSize().y
    // let X = Math.trunc(this.map.layerPointToContainerPoint(e.layerPoint).x)
    // let Y = Math.trunc(this.map.layerPointToContainerPoint(e.layerPoint).y)
    // this.loading = true
    // this.mapService.getFeatureInfo$(BBOX, WIDTH, HEIGHT, X, Y).subscribe(geoJson => {
    //   this.loading = false
    //   if (geoJson) {
    //     this.selectedBuildingId.emit(geoJson.id)
    //     this.layer = this.createGeoJSONLayer(geoJson).addTo(this.map)
    //   }
    // })
  }


  ngOnDestroy(): void {
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // this.fitBounds()
  }
  // prevLayer
  selectedBuildingLayer
  marker

  createGeoJSONLayer(geoJson, color = null, singleFeature = null){
    let this_ = this
    let layer = L.geoJSON(geoJson, {
      // @ts-ignore
      style: function (feature) {
          return this_.determineBuildingColor(feature, color, singleFeature)
        },
      pointToLayer: function (feature, latlng) {
        let markerColor = this_.determineBuildingColor(feature, color, singleFeature)
        return L.marker(latlng, {icon: this_.customMarker(markerColor.className)})
      }
    });
    // .bindPopup(async function (layer){

      // if (this_.registered) {
      //   await this_.mapService.getTinsByBuildingId$(layer.feature.properties.id)
      // }

      // if (this_.registered)
      //   this_.mapService.getTinsByBuildingId$(layer.feature.properties.id).subscribe(tins=>{
      //     return this_.popUp(layer)
      // })
      //   else
      //     return this_.popUp(layer)
      // }, {autoPan: false}
    // )
    //
    // this._event.latlng

    layer.on('click', function(ev) {
      let data = ev.layer.feature.properties
      if (this_.selectedBuildingLayer)
        this_.selectedBuildingLayer.removeFrom(this_.map)
      this_.selectedBuildingDetails.next(data)
      this_.selectedBuildingLayer = this_.createGeoJSONLayer(ev.layer.feature, 'blue').addTo(this_.map)
      let popupHtml =`<table class="center-style mat-table mat-h5" style="width:100%"><tbody>
            <tr class="element-row mat-row">
                <td><b>ID</b></td>
                <td>${data.id}</td>
            </tr>`
      if (data.street_id)
        popupHtml += `<tr class="element-row mat-row">
                    <td><b>Address</b></td>
                    <td>${data.street_name} ${data.house_number ? data.house_number : ''}</td>
                </tr>`
      popupHtml += `<tr class="element-row mat-row">
                <td><b>Surface</b></td>
                <td>${data.area ? data.area : 0} &#13217;</td>
            </tr>
        </tbody></table>`
        // '<div class="block"><div><b>Building:</b></div><div>' + data.id + '</div>'+
        // '<div class="block"><div><b>Address:</b></div><div>' +
        // (data.street_id ? `${data.street_name} ${data.properties.house_number}`  : 'N/A' ) +'</div>'+
        // '<div class="block"><div><b>Area:</b></div><div>' + data.area + ' sqm</div></div>'
      if (this_.registered) {
        this_.showSpinner()
        this_.mapService.getTinsByBuildingId$(data.id).subscribe(tins=> {
          if (tins && tins.length)
            popupHtml += '<hr/>'
          popupHtml += '<table class="center-style mat-table mat-h3" style="width:100%"><tbody>'

          tins.forEach((t) => {
            // Extract of Payments
            t.groupByKeyYear.forEach((paymentDetails) => {
              if (paymentDetails.finalBalance < 0)
                popupHtml += `<tr class="element-row mat-row">
                                <td class="mat-cell cdk-column-name mat-column-name">${paymentDetails.name}</td>
                                <td class="mat-cell cdk-column-name mat-column-name" style="text-align: right;">${this_.balanceField(paymentDetails.finalBalance)}</td>
                            </tr>`
            })
            popupHtml += `<tr><td colspan="2"><hr/></td></tr>
                          <tr class="element-row mat-row"><td class="mat-cell cdk-column-name mat-column-name">
                            <a style="text-decoration: none" href="/tx_declarations?tin=${t.tin}" target="_blank" rel="noopener noreferrer">
                            ${t.tin} ${t.companyName}</a>
                          </td>
                          <td class="mat-cell cdk-column-name mat-column-name" style="text-align: right;">${this_.balanceField(t.overallBalance)}</td>
                          </tr>
                          <tr><td colspan="2"><hr/></td></tr>`
          })
          popupHtml += '</tbody></table>'
          this_.hideSpinner()
          this_.popUp(ev.latlng, popupHtml)
        })
      }else {
        this_.popUp(ev.latlng, popupHtml)
      }
    });
    return layer
  }

  balanceField(balance){
    if (balance < 0){
      return `<span style="color:red;">${this.numberThousands(balance)}</span>`
    }else{
      return `<span style="color:green;">${this.numberThousands(balance)}</span>`
    }
  }

  numberThousands(value){
    return value.toLocaleString('fr', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  }

  determineBuildingColor(feature, color, singleFeature){
    let data = feature.properties
    let abstractParam =  JSON.parse(data.abstract_params)
    if (!abstractParam || !abstractParam[`${this.clientInstance}`]){
      if (data.street_id && !singleFeature)
        return {color: "#4cbb17", fillRule: "nonzero", className: 'green-s'};
       else
        return {color: color ? color : "gray", fillRule: "nonzero"};
    }else if (abstractParam[`${this.clientInstance}`] > 0)
      return {color: "#87f84e", fillRule: "nonzero", className: 'green'};
    else if (abstractParam[`${this.clientInstance}`] < 0)
      return {color: "#e11846", fillRule: "nonzero", className: 'red'};
    else
      return {color: "gray", fillRule: "nonzero"};
  }

  // markerIcon = L.icon({
  //   iconUrl: 'marker-icon.png',
  //   // iconSize: [25, 50],
  //   // iconAnchor: [0, 0],
  //   iconSize: [16,25],
  //   iconAnchor: [5, 10]
  // });

  // addMarker(e){
  //   // Add marker to map at click location; add popup window
  //   console.debug(e.latlng)
  //   // L.marker(e.latlng).addTo(this.map);
  //   L.marker(e.latlng, {icon: this.markerIcon}).addTo(this.map);
  // }

  // popUp(layer, this_) {
  //   let infoHtml = 'hello';
  // }

  getCurrentBbox(){
    // let sw = this.map.options.crs.project(this.map.getBounds().getSouthWest())
    // let ne = this.map.options.crs.project(this.map.getBounds().getNorthEast())
    // let sw = CRS.EPSG4326.project(this.map.getBounds().getSouthWest())
    // let ne = CRS.EPSG4326.project(this.map.getBounds().getNorthEast())
    let west = this.map.getBounds().getSouthWest()
    let east = this.map.getBounds().getNorthEast()
    // let boundingBox = sw.x + "," + sw.y + "," + ne.x + "," + ne.y
    let boundingBox = west.lat + "," + west.lng + "," + east.lat + "," + east.lng

    // let bounds = [[sw.x, sw.y], [ne.x, ne.y]];
    // if (this.boundingBox){
    //   this.boundingBox.removeFrom(this.map)
    // }
    // this.boundingBox = L.rectangle(this.map.getBounds(), {color: "#ff7800", weight: 1})
    // this.map.addLayer(this.boundingBox)
    return boundingBox
  }

  pointGeoJSON(latln) {
    return {
        'type': 'Point',
        'coordinates': [latln.lng, latln.lat]
    };
  }
  showSpinner(){
    this.spinner.show()
    window.dispatchEvent(new Event('resize'))
  }
  hideSpinner(){
    this.spinner.hide()
    window.dispatchEvent(new Event('resize'));
  }

}
