import Dexie from 'dexie';

export async function deleteDatabase() {
  try {
    await Dexie.delete('tasdb');
    console.log('Database deleted successfully');
  } catch (error) {
    console.error('Failed to delete database:', error);
  }
}
