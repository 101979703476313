import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessService } from '../../services/process.service';
import { ReportService } from 'src/app/services/report.service';
import {  zip } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.css']
})
export class MultiStepFormComponent implements OnInit {
  @Input()
  processKey: string;
  @Input()
  processName: string;
  @Input()
  stageName: string;
  @Input()
  processYear: string;
  @Input()
  processStages;
  @Input()
  processStageData
  @Input()
  globals
  @Input()
  currentStage: any;
  @Input()
  formCreation: boolean;
  @Input()
  columnsInRows;
  @Input()
  submitReceipt: any;
  ccData: any;
  processId;
  stages: any;
  output: any;
  emittables = []
  isFirstStage: boolean;
  isLastStage: boolean;
  username: string;
  @Output() onRowKeyValue = new EventEmitter<any>();
  width = "60%"
  completeProcess = {
    'key': '',
    'name': '',
    'year': '',
    'globals': {},
    'stages': []
  };
  @Input()
  selectedBlk: any;

  constructor(private route: ActivatedRoute, private reportService: ReportService,
    private processService: ProcessService, public router: Router, public jwtHelper: JwtHelperService) {
    this.processKey = this.route.snapshot.paramMap.get('processKey');
    this.processId = this.route.snapshot.paramMap.get('id');
  }

  processJSON(data) {
    this.completeProcess.key = data.key
    this.completeProcess.name = data.name
    this.completeProcess.year = this.processYear
    this.stages = [{ name: this.currentStage.name, key: this.currentStage.key }];
  }
 
  goBack() {
    var indexOfCurrentStage = this.ccData.stages.indexOf(this.ccData.stages.find(elem => elem.key === this.currentStage.key))
    this.generateStagePage(indexOfCurrentStage - 1)
    if (indexOfCurrentStage - 1 === 0) {
      this.isFirstStage = true;
    } else {
      this.isFirstStage = false;
    }
    window.scrollTo(0, 0)
  }


  back() {
    this.router.navigate(['process/' + this.processId]);
  }
  
  next() {
    var indexOfCurrentStage = this.ccData.stages.indexOf(this.ccData.stages.find(elem => elem.key === this.currentStage.key))
          this.isFirstStage = false;
          this.generateStagePage(indexOfCurrentStage + 1)
  }

  ngOnInit() {
    this.reportService.getPages(this.processKey)
      .subscribe(
        data => {
          this.reportService.getValueEmittersPages(this.processKey, this.processYear).subscribe(em => {
            this.emittables = em
          var indexOfCurrentStage = data.stages.indexOf(data.stages.find(elem => elem.key === this.currentStage.key))
          this.ccData = data;
          this.processJSON(data);
          if (indexOfCurrentStage === 0) {
            this.isFirstStage = true;
          } else {
            this.isFirstStage = false;
          }
          if (data.stages.indexOf(data.stages.find(elem => elem.key === this.currentStage.key)) === data.stages.length - 1) {
            this.isLastStage = true;
          } else {
            this.isLastStage = false;
          }})

        },
        err => {
          console.log(err);
        }
      );

  }

  getInputId(event) {
    this.onRowKeyValue.emit(event)
  }

  generateStagePage(indexOfCurrentStage) {
    if (this.ccData.stages.length > indexOfCurrentStage) {
      if (this.ccData.stages.length === indexOfCurrentStage + 1) {
        this.isLastStage = true;
      } else {
        this.isLastStage = false;
      }
    }
    let process$ = this.processService.getProcess(this.processId)
    let structure$ = this.reportService.getReportPage(this.processKey, this.ccData.stages[indexOfCurrentStage].key)
    let zipSubscription$ = zip(process$, structure$)
    zipSubscription$.subscribe(result => {
      let processData = result[0]
      let processStructure = result[1]
      this.currentStage = processStructure;
      this.stages = [{ name: this.currentStage.name, key: this.currentStage.key }];

      if (processData !== null) {
        if (processData['stages'].find(stage => stage !== null && stage.key === this.currentStage['key']) !== undefined) {
          this.processStageData =
            processData['stages'].find(stage => stage !== null && stage.key === processStructure.key)
        } else {
          if (this.processStageData !== undefined)
            this.processStageData.rows = []
        }
        this.globals = processData['globals'] ? processData['globals'] : {}

      } else {
        this.globals = {}
      }
    });
  }
  openInEditor() {
    this.router.navigate(['/formcreation_foradmin525', this.processKey, this.currentStage.key]);
  }

  getUserFromToken() {
    return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
  }

  changeStage(value) {
    this.currentStage = value
    this.completeProcess.globals = value['globals']
  //  delete this.currentStage['globals']
  }
}