import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { TasDataSource } from "src/app/services/tasGovTable.datasource";
import { ForecastService } from "src/app/services/forecast.service";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
@Component({
  selector: "app-forecast-performance",
  templateUrl: "./forecast-performance.component.html",
  styleUrls: ["./forecast-performance.component.css"],
})
export class ForecastPerformanceComponent implements OnInit {
  @Input() type;  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: TasDataSource;
  displayedColumns = ["year", "type", "state", "municipalityName"];
  filterObject = {};
  filterData = { municipality: [] }
  loading = { inProgress : false, downloadInProgress: false, downloadAttachmentInProgress: false, error: false }
  constructor(private forecastService: ForecastService,
  public translate: TranslateService, public router: Router
){
  this.dataSource = new TasDataSource(this.forecastService);
}

  ngOnInit() {
    this.filterObject = { type: this.type };
    if (this.type==='performance'){
      this.displayedColumns.push("month")
    }
    setTimeout(() => {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadTable()))
        .subscribe();
    }, 1000);
  }
  resetLoading = () => {
    this.loading.inProgress = true
    this.loading.error = false
    this.dataSource.emptyData()
  }
  loadTable() {
    this.resetLoading()
    this.dataSource.loadData(
      "forecast/forecast-states",
      "",
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterObject,
      this.loading
    );
  }

  advancedSearch(filter) {
    this.filterObject = {
      ...filter,
      type: this.type 
    }
    this.loadTable();
  }

  navigateToFOrP(row){
    const queryParams = { year:row.year, municipality: row.municipality}
    row.type === 'performance' && (queryParams['month'] = row.month)
    const url = this.router.createUrlTree([row.type], { queryParams }).toString();
    window.open(url, '_blank');
  }

  downloadForecastPerformance() {
    let headers = [
      { header: this.translate.instant("APPROVAL.TABLE_COLUMN_YEAR"), key: 'year' },
      { header: this.translate.instant("APPROVAL.TABLE_COLUMN_TYPE"), key: 'type' },
      { header: this.translate.instant("APPROVAL.TABLE_COLUMN_STATE"), key: 'state' },
      { header: this.translate.instant("APPROVAL.TABLE_COLUMN_MUNICIPALITY"), key: 'municipalityName' },
    ]
    if (this.type==='performance'){
      headers.push({ header: this.translate.instant("APPROVAL.TABLE_COLUMN_MONTH"), key: 'month' })
    }
    let texts = {
        forecast : this.translate.instant("APPROVAL.FORECAST"),
        performance : this.translate.instant("APPROVAL.PERFORMANCE"),
        submitted : this.translate.instant("APPROVAL-STATES.SUBMITTED"),
        approved : this.translate.instant("APPROVAL-STATES.APPROVED"),
        reopened : this.translate.instant("APPROVAL-STATES.REOPENED"),
        "0" : this.translate.instant("PERFORMANCE.0"),
        "1" : this.translate.instant("PERFORMANCE.1"),
        "2" : this.translate.instant("PERFORMANCE.2"),
        "3" : this.translate.instant("PERFORMANCE.3"),
        "4" : this.translate.instant("PERFORMANCE.4"),
        "5" : this.translate.instant("PERFORMANCE.5"),   
        "6" : this.translate.instant("PERFORMANCE.6"),
        "7" : this.translate.instant("PERFORMANCE.7"),   
        "8" : this.translate.instant("PERFORMANCE.8"),
        "9" : this.translate.instant("PERFORMANCE.9"),
        "10" : this.translate.instant("PERFORMANCE.10"),
        "11" : this.translate.instant("PERFORMANCE.11"),
    }
    this.loading.downloadInProgress = true
    this.forecastService.downloadForecastState(this.filterObject, headers, texts).subscribe
      (data => {
        this.loading.downloadInProgress = false
        const blob = data
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
    });
  }
}
