import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './sharedService';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService{
  constructor(public http: HttpClient, public sharedService: SharedService, private jwtHelper: JwtHelperService) { 
    super(http, sharedService);
  }


  getUsernames(): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('user')).pipe(
      map(res => {
        return res;
      })
    );
  }

  getCurrentUser(isOnline = true): Observable<any> {
    if (isOnline){
      return this.http.get(this.sharedService.apiUrl('user/current'))
    }
    else{
      const token = this.jwtHelper.tokenGetter()
      if (token && token !== 'undefined') {
        const decodedToken = this.jwtHelper.decodeToken(token)
        const user = { username:decodedToken.username, role: decodedToken.role }
        return of(user)
      }
    }
  }

  getUserByEmail(email: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('user/byemail/'+email))
  }

}



