import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CompanyDexieService } from "./companyDexie-services/companyDexie.service";


export class TasDataSource implements DataSource<any> {

    private processSubject = new BehaviorSubject<any[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public size = 0;

    private data = []

    private totalAmount = 0;
    private totalSums = {};

    constructor(private service: any, private companyDexieService: CompanyDexieService = null) {

    }

    async fetchDataWithAttempt(resourceUrl, filter, sortOrder, sortColumn,
        pageIndex, pageSize, filterObject, loading) {
        let attempt = 0;
        let resultData;
        while (attempt < 2) {
            try {
                attempt++;
                resultData = await this.service.getTableData(resourceUrl, filter, sortOrder, sortColumn,
                    pageIndex, pageSize, filterObject).pipe(
                        catchError((error) => {
                            return of({ error })
                        })
                    ).toPromise();
                if (resultData.error)
                    continue;
                this.data = resultData['records']
                this.processSubject.next(resultData['records'])
                this.size = resultData['total'];
                this.totalAmount = resultData['totalAmount']
                this.totalSums = resultData['totals']
                loading.inProgress = false

                break;
            } catch (error) {
                if (error.name !== 'TimeoutError' || attempt >= 2) {
                    console.error('Fetch Failed:', error);
                    loading.inProgress = false
                    break;
                }
            }
        }
        if (attempt === 2 && resultData.error) {
            if (resourceUrl==='tax-payers'){
                let comps = await this.companyDexieService.searchCompanies(filterObject, pageIndex+1, pageSize, sortColumn, sortOrder)
                this.data = comps.data
                this.processSubject.next(comps.data)
                this.size = comps.total;
                loading.inProgress = false
            }else{
                loading.inProgress = false
                loading.error = true
            }
            
        }
    }

    loadData(resourceUrl: string,
        filter: any,
        sortDirection: string,
        sortColumn: string,
        pageIndex: number,
        pageSize: number, filterObject: any, loading = null) {
        this.loadingSubject.next(true);
        try {
            this.fetchDataWithAttempt(resourceUrl, filter, sortDirection, sortColumn, pageIndex, pageSize, filterObject, loading)
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            this.loadingSubject.next(false);
        }
        // this.service.getTableData(resourceUrl, filter, sortDirection, sortColumn,
        //     pageIndex, pageSize, filterObject).pipe(
        //         catchError(() => of([])),
        //         finalize(() => { 
        //             this.loadingSubject.next(false); 
        //             if (loading) loading.inProgress = false;
        //         })
        //     )
        //     .subscribe(result => {
        //         this.data = result['records']
        //         this.processSubject.next(result['records'])
        //         this.size = result['total'];
        //         this.totalAmount = result['totalAmount']
        //         this.totalSums = result['totals']
        //     });

    }

    getData() {
        return this.data;
    }
    getTotalAmount() {
        return this.totalAmount;
    }
    getTotalSum(key) {
        return this.totalSums && this.totalSums[key] || 0
    }
    clearTotalSums() {
        this.totalSums = {}
    }
    emptyData() {
        this.processSubject.next(null)
        this.size = 0;
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.processSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.processSubject.complete();
        this.loadingSubject.complete();
    }

}