import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {ProcessService} from "./process.service";
import {catchError, finalize} from "rxjs/operators";
import { ProcessSummary } from '../entities/processSummary';



export class ProcessSummaryDataSource implements DataSource<ProcessSummary> {

    private processSubject = new BehaviorSubject<ProcessSummary[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public size = 0;

    constructor(private processService: ProcessService) {

    }

    loadProcessSummary(
                year: string, spinner = null
                 ) {

        this.loadingSubject.next(true);

        this.processService.getSummary(year).pipe(
                catchError(() => of([])),
                finalize(() => {
                    spinner && spinner.hide()
                    this.loadingSubject.next(false)
                } )
            )
            .subscribe(processSummaries => {
               
                var processSummaryArr = []
                let totalPR = new ProcessSummary();
                totalPR.name = "TOTAL"
               
                if (Array.isArray(processSummaries))
                processSummaries.forEach(prSum => 
                    { 
                        let processSummary = new ProcessSummary();
                        let tempSum = processSummaryArr.find(elem=> elem.key === prSum['_id'].key)
                        if (tempSum){
                            processSummary = tempSum
                        }else{
                            processSummary.name = prSum['_id'].name
                            processSummary.key = prSum['_id'].key
                        }
                        if (prSum['_id'].status==='Submitted'){
                            processSummary.submitted = prSum.count
                            totalPR.submitted += prSum.count
                        }
                        else{
                            processSummary.inProgress = prSum.count
                            totalPR.inProgress += prSum.count
                        }
                        processSummary.total = processSummary.submitted + processSummary.inProgress
                        if (!tempSum){
                            processSummaryArr.push(processSummary)
                        } 
                    }
                    )
                    totalPR.total = totalPR.submitted 
                    + totalPR.inProgress  
                    processSummaryArr.push(totalPR)
                this.processSubject.next(processSummaryArr)
                this.size = processSummaryArr.length;
            });

    }


    
    loadFilteredTaxPayersBySummary(
        year: string,
        filter:any,
        sortDirection:string,
        sortColumn:string,
        pageIndex:number,
        pageSize:number,
        filterObject: any) {


this.loadingSubject.next(true);

this.processService.getTaxPayersFromProcessSummary(year, filter, sortDirection, sortColumn,
    pageIndex, pageSize, filterObject).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(processes => {
        this.processSubject.next(processes['records'])
        this.size = +processes['total'];
    });

}

    connect(collectionViewer: CollectionViewer): Observable<ProcessSummary[]> {
        return this.processSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.processSubject.complete();
        this.loadingSubject.complete();
    }

}