import { Component, OnInit } from '@angular/core';
import { ComparisonGraphComponent } from './comparison-graph/comparison-graph.component';

@Component({
  selector: 'app-monthly-comparison-graph',
  templateUrl: './monthly-comparison-graph.component.html',
  styleUrls: ['./monthly-comparison-graph.component.css']
})
export class MonthlyComparisonGraphComponent implements OnInit {
  rvGraphComponent = ComparisonGraphComponent;

  constructor() {
  }

  ngOnInit() {


  }

}
