import { Component } from "@angular/core";
  
  @Component({
    selector: "app-payment-configuration",
    templateUrl: "./payment-configuration.component.html",
    styleUrls: ["./payment-configuration.component.css"],
  })
  export class PaymentConfigurationComponent {
    constructor() {}
    onCardClick(val, card){
    }
}