import { TaxPayerService } from 'src/app/services/taxPayer.service';

export class ProfileChart {

    public barChartLabels = [];
    public barChartLabelsDate = [];
    public barChartType = '';
    public barChartLegend = true;
    public data;
    public barChartData = null;

    constructor(public taxpayerService: TaxPayerService) {

    }

    loadData(data) {
        this.barChartLabels = [];
        this.barChartLabelsDate = [];
        for (var year in data){
            if (!this.barChartLabels.includes(year))
            this.barChartLabels.push(year)
            for (var x in data[year]){
                if (!this.barChartData){
                    this.barChartData.push({data:[data[year][x]], label: x, fill: false});
                }else{
                    var dd = this.barChartData.find(elem => elem.label === x)
                    if (dd){
                        dd.data.push(data[year][x])
                    }else{
                        this.barChartData.push({data:[data[year][x]], label: x, fill: false});
                    }
                }
            }
        }
    }

    async asyncForEach(array, callback) {
        for (const item of array) {
            await callback(item);
        }
    }

}
