import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { SharedService } from 'src/app/services/sharedService';
import { PaymentReportService } from 'src/app/services/paymentReport.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { RevenueCodeService } from 'src/app/services/revenueCode.service';
import { UserService } from 'src/app/services/user.service';
import { CategoryService } from 'src/app/services/category.service';
import { MultiselectAutocompleteComponent } from '../multiselect-autocomplete/multiselect-autocomplete.component';

@Component({
  selector: 'tas-gov-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.css']
})
export class PaymentReportComponent implements OnInit, AfterViewInit {


  filterObject: {};
  displayedColumns: string[] = ['paymentDate', 'tin', 'companyName', 'paymentReference', 'revenueCode', 'taxName', 'paymentSystem', 'amount'];
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('userSelect', { static: false }) userSelect: MultiselectAutocompleteComponent;
  @ViewChild('paymentTypeSelect', { static: false }) paymentTypeSelect: MultiselectAutocompleteComponent;
  dataSource: TasDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  //@ViewChild('input_search', { static: false }) input: ElementRef;
  searchTin
  searchCompanyName
  searchAmountFrom
  searchAmountTo
  searchDateFrom = new Date()
  searchDateTo = new Date()
  paymentTypes : []
  searchPaymentRevenueCode
  users: []
  user: any;
  panelOpenState = true
  revenueCodes: any[]
  filterData = {
    municipality: null
  };
  searchPaymentUser: any
  searchMunicipality: any
  searchPaymentType: any
  filterFields = ["municipality"];
  constructor(private paymentReportService: PaymentReportService, public dialog: MatDialog, public sharedService: SharedService,
    public spinner: NgxSpinnerService, public translate: TranslateService, public revenueCodeService: RevenueCodeService,
    public userService: UserService, public categoryService: CategoryService) {
      this.filterFields.forEach((field) => {
        this.categoryService
          .getList(field)
          .subscribe((result) => {
            if (result) {
              this.filterData[field] = result.map((fld) => ({
                name: fld.name,
                id: fld.id,
              }));
            }
          });
      });
  }

  ngOnInit() {
    this.searchDateFrom.setHours(0, 0, 0, 0)
    this.searchDateTo.setHours(23, 59, 59, 999)
    this.dataSource = new TasDataSource(this.paymentReportService)
    this.paymentReportService.getPaymentTypes().subscribe(val => {
      this.paymentTypes = val
    })
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user 
    })
    this.paymentReportService.getPaymentUsers().subscribe(val => {
      this.users = val.map(user => ({ id: user.username.trim(), name: (user.surname? user.surname+ ' ': '') + user.name }))
      .sort((a, b) => a.name.localeCompare(b.name));
    })
    this.revenueCodeService.getList('revenue-code').pipe(map(val => val.map(item => ({ code: item.code, name: item.name, id: item.id })))).subscribe((revenueCodes) => {
      this.revenueCodes = revenueCodes;
    });
  }

  objectComparisonFunctionId = function (option, value): boolean {
    let sss = value ? option.key === value.key : false
    return sss;
  };
  getTotal() {
    return this.dataSource.getTotalAmount()
  }
  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // fromEvent(this.input.nativeElement, 'keyup')
    //   .pipe(debounceTime(1000), distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadPaymentReportTable();
    //     })
    //   ).subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadPaymentReportTable())).subscribe();

  }

  loadPaymentReportTable() {
    this.dataSource.loadData('fees/payments',
      null,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  showRegisteredBy() {
    if (this.user) {
      return !['SECRETARY_ASSISTANT'].includes(this.user.role)
    }

    return true
  }

  selectChange = (event: any, field) => {
    if (field === 'municipality'){
      this.searchMunicipality = event.data.map(elem => elem.id)
      if (this.searchMunicipality.length===0){
       this.searchMunicipality = null
     }
    }
    if (field === 'users'){
      this.searchPaymentUser = event.data.map(elem => elem.id)
      if (this.searchPaymentUser.length===0){
       this.searchPaymentUser = null
     }
    }
    if (field === 'paymentTypes'){
      this.searchPaymentType = event.data.map(elem => elem.key)
      if (this.searchPaymentType.length===0){
       this.searchPaymentType = null
     }
    }
  };

  advancedSearch() {
    if (this.searchDateTo) this.searchDateTo.setHours(23, 59, 59, 999)
    this.filterObject = {}
    !this.isEmpty(this.searchTin) && (this.filterObject['tin'] = this.searchTin);
    !this.isEmpty(this.searchCompanyName) && (this.filterObject['searchCompanyName'] = this.searchCompanyName);
    !this.isEmpty(this.searchAmountFrom) && (this.filterObject['searchAmountFrom'] = this.searchAmountFrom);
    !this.isEmpty(this.searchAmountTo) && (this.filterObject['searchAmountTo'] = this.searchAmountTo);
    !this.isEmpty(this.searchDateFrom) && (this.filterObject['searchDateFrom'] = this.searchDateFrom);
    !this.isEmpty(this.searchDateTo) && (this.filterObject['searchDateTo'] = this.searchDateTo);
    !this.isEmpty(this.searchPaymentRevenueCode) && (this.filterObject['searchPaymentRevenueCode'] = this.searchPaymentRevenueCode);
    this.searchPaymentType && (this.filterObject['searchPaymentType'] = this.searchPaymentType);
    this.searchPaymentUser && (this.filterObject['searchPaymentUser'] = this.searchPaymentUser);
    this.searchMunicipality && (this.filterObject['searchMunicipality'] = this.searchMunicipality);
    this.dataSource.loadData('fees/payments', '', 'desc', 'paymentDate', 0, this.paginator.pageSize, this.filterObject);
    this.panelOpenState = false
  }

  resetFilter() {
    this.filterObject = {}
    this.searchTin = null
    this.searchCompanyName = null
    this.searchAmountFrom = null
    this.searchAmountTo = null
    this.searchDateFrom.setHours(0, 0, 0, 0)
    this.searchDateTo.setHours(23, 59, 59, 999)
    this.searchPaymentType = null
    this.searchPaymentUser = null
    this.searchPaymentRevenueCode = null
    this.searchMunicipality = null
    this.municipalitySelect.resetAll()
    this.paymentTypeSelect.resetAll()
    this.userSelect.resetAll()
    this.dataSource.emptyData()
  }
  isEmpty(val) {
    return !val
  }
  downloadReport() {
    let headers = [
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_DATE"), key: 'date' },
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_TIN"), key: 'tin' },
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_COMPANY_NAME"), key: 'companyName' },
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_REFERENCE"), key: 'paymentReference' },
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_REVENUE_CODE"), key: 'revenueCode' },
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_TAX_NAME"), key: 'taxName' },
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_PAYMENT_SYSTEM"), key: 'paymentSystem' },
      { header: this.translate.instant("PAYMENT-REPORT.TABLE_COLUMN_AMOUNT"), key: 'amount' }]
    this.spinner.show();
    this.paymentReportService.downloadReport(this.filterObject, headers).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }

}

