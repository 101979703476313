import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SharedService } from './sharedService';
import { CompanyDexieService } from './companyDexie-services/companyDexie.service';
import { TaxNameService } from './taxName.service';
import { CategoryService } from './category.service';
import { UserService } from './user.service';
import { TaxNameDexie } from './companyDexie-services/interfaces/taxNameDexie';
import { UserDexie } from './companyDexie-services/interfaces/userDexie';
import { CompanyDexie } from './companyDexie-services/interfaces/companyDexie';
import { CategoryDexie } from './companyDexie-services/interfaces/categoryDexie';
import { TaxPayerService } from './taxPayer.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class SynchronizeService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService, public companyDexieService: CompanyDexieService,
    public taxNameService: TaxNameService, public categoryService: CategoryService, public userService: UserService,
    public taxPayerService: TaxPayerService
  ) { 
    super(http, sharedService);
  }

  remoteSynchronize(payment): Observable<any> {
    return this.http.post(this.sharedService.apiUrl("synchronization"), payment, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }

  async localSynchronize(user){

    await this.companyDexieService.addSynchinfo()
    let taxNamesOffline = await this.taxNameService.getList('tax-name', null).toPromise()
    if (taxNamesOffline){
      let txRecords = taxNamesOffline.records || taxNamesOffline
      const taxNames: TaxNameDexie[] = txRecords.map((taxName: any) => ({
        id: taxName.id,
        amount: taxName.amount,
        name: taxName.name,
        revenueCode: taxName.revenueCode,
        category: taxName.category,
        municipality: taxName.municipality
      }));
      this.companyDexieService.addTaxNames(taxNames)
    }
   
    const dexieUsers: UserDexie[] = [{ id:user.id, username:user.username, name:user.name, surname:user.surname}]
    this.companyDexieService.addUsers(dexieUsers)

    let categoriesOffline = await this.categoryService.getList('category', null).toPromise()
    const categories: CategoryDexie[] = categoriesOffline.map((category: any) => ({
      id: category.id,
      name: category.name,
      tinCode: category.tinCode,
      avis: category.avis,
      taxPayerType: category.taxPayerType
    }));
    this.companyDexieService.addCategories(categories)


    const taxpayers = await this.taxPayerService.getList('tax-payers', null, 'offline').toPromise()
    const companies: CompanyDexie[] = taxpayers.records.map((company: any) => ({
      id: company.id,
      name: company.name,
      tin: company.tin,
      balance: company.balance,
      telephone: company.telephone,
      municipality: company.municipality,
      municipalityName: company.municipalityName,
      type: company.type,
      typeName: company.typeName,
      extraBalance: company.extraBalance,
      finalBalance: company.finalBalance,
      avises: company.avises
    }));
    this.companyDexieService.addCompanies(companies)
  }
}



