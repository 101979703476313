import { isEven } from "./isEven";
import { TranslateService } from '@ngx-translate/core';

export async function loadChart(type, dataSource, translate: TranslateService, data = null) {
    const bgColor = 'rgba(54, 162, 235, 0.9)';
    let reverse = true
    if (!data){
        try{
            data = await dataSource.loadChartData(type).toPromise();
            if (data.error){
                throw new Error(data.error)
            }
        }
        catch (error){
            throw new Error(error);
        }
    }
    else{
        reverse = false
    }
    
    
    let barChartData = [];
    let labels = [];
    let even = isEven(data.length);
    let mid = ((data.length + (!even ? 1 : 0)) / 2) - 1;
    let dss = [];

    // Fetch month abbreviations in French
    const monthTranslations = await translate.get('MONTHS_FR').toPromise();
    if (reverse){
        data = data.reverse()
    }
    data.forEach(element => {
        let labelIndex;
        let label;
        
        switch (type.type) {
            case 'DAILY':
                const dailyMonth = element._id.substring(5, 7); // "10" for October
                const dailyDay = element._id.substring(8, 10);  // "10" for day
                label = `${monthTranslations[dailyMonth]} ${dailyDay}`;
                labels.push(label);
                labelIndex = labels.indexOf(label);
                break;

            case 'MONTHLY':
                const monthlyMonth = element._id.substring(5, 7); // "10" for October
                const monthlyYear = element._id.substring(0, 4);  // "2023" for year
                label = `${monthTranslations[monthlyMonth]} ${monthlyYear}`;
                labels.push(label);
                labelIndex = labels.indexOf(label);
                break;

            case 'YEARLY':
                label = element._id.substring(0, 4); // Just the year "2023"
                labels.push(label);
                labelIndex = labels.indexOf(label);
                break;

            default:
                break;
        }

        const dataValues = Array(data.length).fill(0);
        dss[labelIndex] = element.totalAmount;
        if (even) dss.push(0);
        
        barChartData.push({
            data: dataValues,
            barPercentage: 4,
            backgroundColor: bgColor,
            hoverBackgroundColor: bgColor,
        });

        if (dataValues.length < 10) {
            barChartData.forEach(elem => {
                elem.barThickness = 40;
            });
        }
    });

    for (let m = 0; m < barChartData.length; m++) {
        barChartData[mid].data[m] = dss[m];
    }

    return { barChartData, barChatLabels: labels, data };
}
