import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.css"],
})
export class ConfigurationComponent {
  constructor(private router: Router) { }

  onCardClick(card) {
    this.router.navigate([`/${card}`]);
  }
}
