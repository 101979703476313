import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormTypes } from "src/app/util/formTypes";

@Component({
  selector: "app-deadline-selector",
  templateUrl: "./deadline-selector.component.html",
  styleUrls: ["./deadline-selector.component.css"],
})
export class DeadlineSelectorComponent implements OnInit {
  @Output() onDeadline = new EventEmitter<any>();
  @Input() type;
  @Input() deadline?: any;
  formTypes = FormTypes;
  date
  constructor() {}
  month = "JANUARY";
  day = 1;
  months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  days = [];
  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.deadline &&
      changes.deadline.currentValue &&
      changes.deadline.previousValue === undefined
    ) {
     
      if (this.deadline.day)
      this.day = this.deadline.day
      if (this.deadline.month) {
        this.month = this.months[this.deadline.month-1];
        this.changeDays({ value: this.month });
      }
      if (this.type===this.formTypes.ONETIME){
        this.date = new Date(this.deadline.year, this.deadline.month-1, this.day)
      }
    }
   
    if (changes.type && !changes.type.firstChange && (!this.deadline||changes.type.previousValue)) {
      this.setMonths(31);
      this.day = 1;
      this.month = "JANUARY";
      switch (changes.type.currentValue) {
        case this.formTypes.YEARLY:
          this.onDeadline.emit({
            deadline: {
              month: this.months.indexOf(this.month) + 1,
              day: this.day,
            },
          });
          break;
        case this.formTypes.MONTHLY:
          this.onDeadline.emit({ deadline: { day: this.day } });
          break;
        case this.formTypes.ONETIME:
          let dateObj = new Date();
          this.onDeadline.emit({ deadline: this.getOnetimeDate(dateObj) });
          break;
        default:
          break;
      }
    }
  }
  changeDays(ev) {
    let mon = ev.value;
    switch (mon) {
      case "JANUARY":
      case "MARCH":
      case "MAY":
      case "JULY":
      case "AUGUST":
      case "OCTOBER":
      case "DECEMBER":
        this.setMonths(31);
        break;

      case "APRIL":
      case "JUNE":
      case "SEPTEMBER":
      case "NOVEMBER":
        this.setMonths(30);
        break;
      case "FEBRUARY":
        this.setMonths(28);
      default:
        break;
    }
    this.onDeadline.emit({
      deadline: { month: this.months.indexOf(mon) + 1, day: this.day },
    });
  }
  emitDate(ev) {
    let deadline = {
      month: this.months.indexOf(this.month) + 1,
      day: ev.value,
    };

    if (this.type === this.formTypes.MONTHLY || this.type === this.formTypes.QUARTERLY) {
       deadline.month = null;
    }
    this.onDeadline.emit({ deadline });
  }
  setMonths(n) {
    this.days.length = 0;
    for (let d = 1; d < n + 1; d++) {
      this.days.push(d);
    }
  }
  setDate(ev) {
    let dateObj = new Date(ev.value);
    this.onDeadline.emit({ deadline: this.getOnetimeDate(dateObj) });
  }
  getOnetimeDate(dateObj) {
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    return { year, month, day };
  }
}
