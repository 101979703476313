import { Component, OnInit, ViewChild, ElementRef, Output, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, MatSort } from '@angular/material';
import { ProcessDataSource } from '../../services/process.datasource';
import { ProcessService } from '../../services/process.service';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { YearService } from 'src/app/services/year.service';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from "../../../environments/environment";
import { SharedService } from 'src/app/services/sharedService';
import { UserService } from 'src/app/services/user.service';
import { CashPaymentDialogComponent } from '../dialogs/cash-payment-dialog/cash-payment-dialog.component';
import { InformationDialogComponent } from '../dialogs/information-dialog/information-dialog.component';
import { CategoryService } from 'src/app/services/category.service';
import { FillWalletDialogComponent } from '../dialogs/fill-wallet-dialog/fill-wallet-dialog.component';
@Component({
  selector: 'app-tx-declarations',
  templateUrl: './tx-declarations.component.html',
  styleUrls: ['./tx-declarations.component.css']
})
export class TxDeclarationsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['link', 'name', 'status', 'due_date', 'started_on', 'last_modified'];
  displayedColumnsSmall: string[] = ['link', 'name', 'status'];
  currentYear: any
  currentNIU = '';
  currentYearInt: any
  displayedYears = []
  dataSource: ProcessDataSource;
  company: any;
  isLoading = false;
  user: any;
  category: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;

  name: string;
  constructor(private processService: ProcessService, private yearService: YearService,
    private sharedService: SharedService, public userService: UserService,
    private taxPayerService: TaxPayerService,
    private categoryService: CategoryService,
    private route: ActivatedRoute, public dialog: MatDialog, public spinner: NgxSpinnerService) {

  }

  cellClicked(element) {
  }
  addTaxPayerEmmitterSubscription: any
  addCashPaymentEmitterSubscription: any
  fillWalletEmitterSubscription: any
  ngOnInit() {

    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
      this.route
        .queryParams
        .subscribe(params => {
          this.spinner.show()
          this.isLoading = true
          if (params['tin']) {
            this.currentNIU = params['tin']
          }
          if (params['tkn']) {
            this.currentNIU = params['tkn']
          }
          this.getTaxpayer()
        });


      this.dataSource = new ProcessDataSource(this.processService)
      this.addTaxPayerEmmitterSubscription = this.sharedService.addTaxPayerEmmitter.subscribe(val => {
        this.taxPayerService.getTaxPayerByNiu('tax-payers', this.currentNIU).subscribe(taxPayer => {
          this.spinner.hide()
          this.isLoading = false
          if (!taxPayer) {
            return
          }
          this.company = taxPayer;
        })
      })
      this.addCashPaymentEmitterSubscription = this.sharedService.updateCashPaymentEmmitter.subscribe(val => {
        if (val) {
          let payment = {
            "paymentSystem": {
              "key": "cash",
              "name": "Espèces"
            },
            "currency": "XOF",
            "amount": val.taxName.amount,
            "paymentDate": val.date,
            "tin": this.company.tin,
            "companyName": this.company.name,
            "taxName": val.taxName.name,
            "taxNameId": val.taxName.id,
            "revenueCodeId": val.taxName.revenueCode && val.taxName.revenueCode.id,
            "revenueCode": val.taxName.revenueCode && val.taxName.revenueCode.code,
            "avis": val.taxName.avis,
            "reference_number" : val.taxName.reference_number,
            "description": val.description
          }
          this.userService.updateResource('payments', payment).subscribe(res => {
            if (res) {
              if (res.error) {
              this.dialog.open(InformationDialogComponent, {
                  width: '500px',
                  disableClose: true,
                  data: { text: "DIALOG." + res.error, error:true}
                });
              }
              else {
                const dialogRefConf = this.dialog.open(InformationDialogComponent, {
                  width: '500px',
                  disableClose: true,
                  data: { text: "DIALOG.PAYMENT_SUCCESS" }
                });
                dialogRefConf.afterClosed().subscribe(result => {
                  if (result) {
                    val.DIALOG.close();
                    this.sharedService.cashPaymentPrint(res)
                  }
                });
              }
            }
          })
        }
      })

      this.fillWalletEmitterSubscription = this.sharedService.updateFillWalletEmmitter.subscribe(val => {
        if (val) {
          let wallet = {
            "paymentSystem": {
              "key": "cash",
              "name": "Espèces"
            },
            "currency": "XOF",
            "amount": val.amount,
            "paymentDate": val.date,
            "tin": this.company.tin,
            "companyName": this.company.name,
          }
          this.taxPayerService.addBalance(wallet).subscribe(res => {
            if (res) {
              if (res.error) {
              this.dialog.open(InformationDialogComponent, {
                  width: '500px',
                  disableClose: true,
                  data: { text: "DIALOG." + res.error, error:true}
                });
              }
              else {
                const dialogRefConf = this.dialog.open(InformationDialogComponent, {
                  width: '500px',
                  disableClose: true,
                  data: { text: "DIALOG.PAYMENT_SUCCESS" }
                });
                dialogRefConf.afterClosed().subscribe(result => {
                  if (result) {
                    val.DIALOG.close();
                    wallet['user'] = res.user
                    wallet['paymentReference'] = res.paymentReference
                    this.sharedService.cashPaymentPrint(wallet)
                  }
                });
              }
            }
          })
        }
      })
    })
  }
  ngOnDestroy(): void {
    if (this.addTaxPayerEmmitterSubscription) {
      this.addTaxPayerEmmitterSubscription.unsubscribe()
    }
    if (this.addCashPaymentEmitterSubscription) {
      this.addCashPaymentEmitterSubscription.unsubscribe()
    }
    if (this.fillWalletEmitterSubscription) {
      this.fillWalletEmitterSubscription.unsubscribe()
    }
    
  }
  getTaxpayer() {
    this.taxPayerService.getTaxPayerByNiu('tax-payers', this.currentNIU).subscribe(taxPayer => {
      this.spinner.hide()
      this.isLoading = false
      if (!taxPayer) {
        return
      }
      this.categoryService.getCategory('category', taxPayer.type.id).subscribe(category => {
        this.category = category
        this.company = taxPayer;
        this.yearService.getList("year").subscribe(val => {
          val.forEach(element => {
            this.displayedYears.push(element.year)
          });
          if (val && val.length > 0) {
            this.currentYear = val[0].year
            this.currentYearInt = val[0].year * 1
          }
          setTimeout(() => {
            this.dataSource.loadProcesses(this.currentYear, '{"tin":"' + this.currentNIU + '"}', 'asc', 'name', 0, this.paginator.pageSize);
            this.setupEventHandlers();
          }, 1000)
        });
        if (this.route.snapshot.paramMap.get('year')) {
          this.currentYear = this.route.snapshot.paramMap.get('year')
        }
      })


    })
  }
  getGovinLink() {
    return environment.govinLink + '/?token_from_tas=' + localStorage.getItem(environment.accessToken) + '&username=' + this.company.tin
  }

  cashPaymentDialog(): void {
    const dialogRef = this.dialog.open(CashPaymentDialogComponent, {
      width: '500px', panelClass: 'custom-dialog-container',
      data: { tin: this.company.tin }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  fillInWalletDialog(): void {
    const dialogRef = this.dialog.open(FillWalletDialogComponent, {
      width: '500px', panelClass: 'custom-dialog-container',
      data: { tin: this.company.tin }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  canGoToGovin() {
    if (this.user) {
      return !['MAYOR_TREASURER', 'CENTRAL_READER'].includes(this.user.role)
    }
    return false
  }

  canSeeLedger() {
    if (this.user) {
      return !['SECRETARY_ASSISTANT'].includes(this.user.role)
    }
    return false
  }

  canUpdate() {
    if (this.user) {
      return !['MAYOR_TREASURER', 'CENTRAL_READER', 'SECRETARY_ASSISTANT'].includes(this.user.role)
    }
    return false
  }

  handleSelection(event) {
    this.currentYear = event.value;
    this.dataSource.loadProcesses(this.currentYear, '{"tin":"' + this.currentNIU + '"}', 'asc', 'name', 0, this.paginator.pageSize);
  }
  setupEventHandlers() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadProcessPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProcessPage())
      )
      .subscribe();
  }

  loadProcessPage() {
    this.dataSource.loadProcesses(
      this.currentYear,
      '{"query":"' + this.input.nativeElement.value + '" , "tin":"' + this.currentNIU + '"}',

      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

}




