import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EventEmitter } from "events";
import { DialogData } from "src/app/entities/dialogData";
import { Forecast } from "src/app/entities/forecast";
import { CategoryService } from "src/app/services/category.service";
import { ForecastService } from "src/app/services/forecast.service";
import { SharedService } from "src/app/services/sharedService";
import { MultiselectAutocompleteComponent } from "../../multiselect-autocomplete/multiselect-autocomplete.component";
import { DialogCommunicator } from "../dialog-communicator";

@Component({
  selector: "rdf-forecast-dialog",
  templateUrl: "./forecast-dialog.component.html",
  styleUrls: ["./forecast-dialog.component.css"],
})
export class ForecastDialogComponent
  extends DialogCommunicator
  implements OnInit {
  text: string;
  emitter: EventEmitter;
  forecast: any;
  selectedRevenue = [];
  @ViewChild("revenueCodeSelect", { static: false })
  revenueCodeSelect: MultiselectAutocompleteComponent;
  FEE_TYPES = [ 'AMOUNT', 'PERCENTAGE']
  filterData = {
    revenueCode: null,
  };
  performance = false;
  month;
  constructor(
    public dialogRef: MatDialogRef<ForecastDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    private categoryService: CategoryService,
    private confirmationDialog: MatDialog,
    private forecastService: ForecastService
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  ngOnInit() {
    this.performance = this.data['performance']
    this.month = this.data['month']
    this.categoryService.getList("revenue-code").subscribe((result) => {
      if (result) {
        let forecastFilter = { "municipality": [this.data['municipalityId']], "year": this.data['year'] }
        let existingRevenueCodes = []
        this.forecast = new Forecast();

        delete this.data['performance']
        delete this.data['month']
        for (let key in this.data) {
          if (key === 'forecast') {
            for (let fr in this.data['forecast']) {
              if (fr === "id") this.forecast["id"] = this.data['forecast'][fr];
              else
                this.forecast[fr] = this.data['forecast'][fr];
            }
          }
          else this.forecast[key] = this.data[key];
        }
       
        this.forecastService.getList('forecast/dialoglist', '?filter.object=' + encodeURIComponent(JSON.stringify(forecastFilter))).subscribe(forecasts => {
          if (forecasts && forecasts.records) {
            existingRevenueCodes = forecasts.records.map(fr => fr.revenueCode);
            this.forecast["revenueCode"] && (existingRevenueCodes = existingRevenueCodes.filter(elem => elem !== this.forecast['revenueCode']))
          }
          this.filterData["revenueCode"] = result.map((fld) => ({
            name: fld.name,
            id: fld.id,
            code: fld.code,
            altName: fld.name
          })).filter(obj => !existingRevenueCodes.includes(obj.code));
          if (this.forecast["revenueCode"])
            this.selectedRevenue =
              [
                this.filterData["revenueCode"].find(
                  (elem) => elem.code === this.forecast["revenueCode"]
                ),
              ] || [];
          this.forecast["class"] = "FORECAST";
          this.setEntity(this.forecast);
        })
      }
    });
  }
  selectChange = (event: any) => {
    let revCode = event.data[0];
    this.forecast["revenueCodeId"] = (revCode && revCode.id) || null;
    this.forecast["revenueCode"] = (revCode && revCode.code) || null;
    this.forecast["revenueCodeName"] = (revCode && revCode.name) || null;
  };

  addFee(name, description, type,amount){
    this.forecast.fees.push({name:name.value, description:description.value, type:type.value, amount:amount.value})
    name.value = null 
    type.value = null 
    amount.value = null 
  }
  removeFee(index){
    this.forecast.fees.splice(index, 1)
  }
}
