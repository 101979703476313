import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { TaxNameService } from 'src/app/services/taxName.service';
import { TaxName } from 'src/app/entities/taxName';
import { DialogCommunicator } from '../dialog-communicator';
import { DropdownService } from 'src/app/services/dropdown.service';
import { RevenueCodeService } from 'src/app/services/revenueCode.service';
import { map } from 'rxjs/operators';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'gov-tax-name-dialog',
  templateUrl: './tax-name-dialog.component.html',
  styleUrls: ['./tax-name-dialog.component.css']
})
export class TaxNameDialogComponent extends DialogCommunicator implements OnInit {

  text: string
  emitter: EventEmitter
  taxName = {}
  municipalities: any[]
  categories: any[]
  revenueCodes: any[]

  constructor(
    public dialogRef: MatDialogRef<TaxNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService, public revenueCodeService: RevenueCodeService,
    public taxNameService: TaxNameService, public confirmationDialog: MatDialog, public dropdownService: DropdownService,
    public categoryService: CategoryService) {
    super(dialogRef, sharedService)
    this.setConfirmationDialog(this.confirmationDialog)
  }

  ngOnInit() {
    this.taxName = new TaxName()
    if (this.data) {
      this.taxNameService.getResource('tax-name', this.data['_id']).subscribe(val => {
        this.taxName = val
        this.taxName['class'] = 'TAX-NAME'
        this.setEntity(this.taxName);
        this.populateDropdowns()
      }
      )
    } else {
      this.setEntity(this.taxName);
      this.populateDropdowns()
    }

  }

  populateDropdowns(){
    this.dropdownService.getList('municipality').pipe(map(val => val.map(item => ({ name: item.name, id: item.id })))).subscribe((municipalities) => {
      this.municipalities = municipalities;
    });
    this.revenueCodeService.getList('revenue-code').pipe(map(val => val.map(item => ({ code: item.code, name: item.name, id: item.id })))).subscribe((revenueCodes) => {
      this.revenueCodes = revenueCodes;
    });
    this.categoryService.getList("category").subscribe((result) => {
      this.categories = result.map(elem => { return { name: elem.name, id: elem.id, tinCode: elem.tinCode, taxPayerType: elem.taxPayerType } });;
    });
  }

  selectChangeMunicipality = (event: any) => {
    this.taxName['municipality'] = event.data;
  };
  selectChangeRevenueCode = (event: any) => {
    this.taxName['revenueCode'] = event.data;
  };
  selectChangeCategory = (event: any) => {
    this.taxName['category'] = event.data;
  };

}
