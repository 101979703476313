import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {
  CRS,
  LatLng,
  featureGroup,
  FeatureGroup
} from "leaflet";
import 'leaflet.locatecontrol'
import * as L from "leaflet";
import {environment} from "../../../environments/environment";
import {MapService} from "./map.service";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-map',
  templateUrl: 'map.component.html',
})
export class MapComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public height = "100vh"
  @Output() mapReady: EventEmitter<any> = new EventEmitter()
  @Input() bbox
  @Output() selectedBuildingDetails = new EventEmitter<any>()
  @Output() myLocationLatLng = new EventEmitter<any>()
  @Output() selectedFeatureGeoJSON = new EventEmitter<any>()
  @Input() buildingId
  @Input() buildingIdsList
  @Input() findMyLocation = false
  @Input() registered = false
  @Input() addMarker = false
  @Input() addedFeatureId
  private fetchBuilding = new Subject<any>()

  strToLeafletBBox(bbox) {
    if (!bbox)
      return [new LatLng(environment.boundary[1], environment.boundary[0]),
        new LatLng(environment.boundary[3], environment.boundary[2])]
    else {
      let bboxParts = bbox.split(",")
      return [new LatLng(bboxParts[0].split(" ")[1], bboxParts[0].split(" ")[0]),
        new LatLng(bboxParts[1].split(" ")[1], bboxParts[1].split(" ")[0])]
    }
  }

  countryBounds: LatLng[] = [new LatLng(environment.boundary[1], environment.boundary[0]),
    new LatLng(environment.boundary[3], environment.boundary[2])]

  baseLayerOpenLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // opacity: 0.5,
    maxZoom: 19,
    attribution: '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
  })
  buildingLayer = L.tileLayer.wms('http://mapservice.revenuedev.org/geoserver/CI/wms?maxFeatures=500', {
    tms: false,
    opacity: 0.5,
    transparent: true,
    maxZoom: 19,
    layers: 'CI:buildings_v',
    format: 'image/png',
    attribution: 'using GeoServer open geospatial information server'
  })

  crs
  baseLayers
  options
  map
  loading

  initMap = false;

  drawItems: FeatureGroup = featureGroup();

  customMarker = L.Icon.extend({
    options: {
      iconUrl: 'assets/img/marker-icon.png',
      shadowUrl: null,
      // iconAnchor: new L.Point(12, 12),
      // iconSize: new L.Point(24, 24),
      iconSize: [16,25],
      iconAnchor: [5, 10]
    }
  });

  drawOptions

  constructor(private mapService: MapService) {
    this.fetchBuilding.pipe(debounceTime(500))
      .subscribe(() => {
        this.getFeatureList()
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes.bbox) {
        this.map.fitBounds(L.latLngBounds(this.strToLeafletBBox(this.bbox)));
      }
      if (changes.buildingId) {
        this.mapService.getFeatureById$(this.buildingId).subscribe(geoJson => {
          let singleBuilding = this.createGeoJSONLayer(geoJson, 'green', true).addTo(this.map);
          this.map.fitBounds(singleBuilding.getBounds());
        })
      }
    }
  }


  ngOnInit(): void {

    this.baseLayers = {
      'OpenStreet': this.baseLayerOpenLayer
    }

    setTimeout(() => {
      this.options = {
        layers: [
          this.baseLayers['OpenStreet']
        ],
        zoom: 8,
        center: L.latLngBounds(this.countryBounds).getCenter(),
        useCache: true,
        crossOrigin: true,
        // maxBounds: L.latLngBounds(this.countryBounds),
        // maxBoundsViscosity: 1,
        fitBounds: L.latLngBounds(this.countryBounds),
        crs: CRS.EPSG3857
      }

      this.drawOptions = {
        position: 'topright',
        draw: {
          polygon: false,
          polyline: false,
          circle: false,
          rectangle: false,
          marker: false,
          circlemarker: false
        },

        edit: false
      };

      if (this.addMarker){
        this.drawOptions.draw.marker = {icon: new this.customMarker()}
        this.drawOptions.edit = {featureGroup: this.drawItems}
      }

      this.initMap = true
    }, 200)
  }

  mapControl

  coordsToLatLng(coords) {
    let latlng = CRS.EPSG3857.unproject(L.point(coords[0], coords[1]))
    return latlng
  };


  currentLocation = null

  onMapReady($event) {

    $event.attributionControl.setPrefix('RDF Maps')

    this.map = $event


    if (this.findMyLocation)
      // @ts-ignore
      L.control.locate().addTo(this.map)

    let count = 0
    this.map.on('locationfound', (e)=> {
      count ++
      if (count < 2) {
        this.myLocationLatLng.next(e.latlng.lat + "," + e.latlng.lng)
        this.currentLocation = e.latlng.lat + "," + e.latlng.lng
        this.getFeatureList()
      }
    });

    this.map.on('locationerror', (e)=> {
      count = 0
    });

    this.map.on('locatedeactivate', (e)=>{
      this.currentLocation = null
      count = 0
    })

    // this.map.on('locateactivate', (e)=>{
    // });

    this.mapControl = L.control.layers(this.baseLayers, {}, {
      collapsed: false,
      position: "topright",
      hideSingleBase: false
    }).addTo(this.map)

    // setTimeout(
    // this.mapControl.addOverlay(this.buildingLayer, "Buildings")
    //   , 10)

    if (this.buildingId){
      this.mapService.getFeatureById$(this.buildingId).subscribe( geoJson => {
        let singleBuilding = this.createGeoJSONLayer(geoJson, "green", true).addTo(this.map)
        this.map.fitBounds(singleBuilding.getBounds())
      })
    }

    if (!this.buildingId || !this.buildingIdsList)
      this.fetchBuilding.next('')

    let this_ = this
    this.map.on('moveend', function() {
      this_.fetchBuilding.next('')
    });

    // this.map.on('click', function(e){
    //   this_.addMarker(e)
    // });

    this.map.on("draw:created", function (e) {
      if (this_.marker)
        this_.drawItems.removeLayer(this_.marker)
      this_.marker = e.layer
      this_.drawItems.addLayer(this_.marker)
      this_.selectedFeatureGeoJSON.emit(this_.pointGeoJSON(e.layer.getLatLng()))
    })

    window.dispatchEvent(new Event('resize'));

  }

  drawUpdated(e){
    let layers = e.layers
    let this_ = this
    layers.eachLayer(layer => {
      this_.selectedFeatureGeoJSON.emit(this_.pointGeoJSON(layer.getLatLng()))
    });
  }

  drawDeleted(e){
    this.marker = null
  }

  getFeatureList(){
    let buildings
    if (this.currentLocation)
      buildings = this.mapService.getNearestBuildings$(this.currentLocation)
    else
      buildings = this.mapService.getFeatureList$(this.getCurrentBbox())

    buildings.subscribe(geoJson => {
      if (this.layer){
        this.layer.clearLayers()
      }
      if (this.layerRegistred){
        this.layerRegistred.clearLayers()
      }
      setTimeout(()=>{
        if (!this.layer) {
          this.layer = this.createGeoJSONLayer(geoJson)
          this.layer.addTo(this.map)
          this.mapControl.addOverlay(this.layer, "RDF Buildings")
        }
        this.layer.addData(geoJson)

        if (this.registered){
          this.mapService.getRegistered$(this.getCurrentBbox()).subscribe(registeredBuildings => {
            setTimeout(()=> {
              if (!this.layerRegistred) {
                this.layerRegistred = this.createGeoJSONLayer(registeredBuildings, "green")
                this.layerRegistred.addTo(this.map)
                this.mapControl.addOverlay(this.layerRegistred, "Registred")
              }
              this.layerRegistred.addData(registeredBuildings)
            }, 100)
          })
        }
      },100)
    })
  }

  clearMap() {

    // this.mapControl.removeLayer(this.buildingLayer)

    // this.mapControl.addOverlay(this.buildingLayer, "Buildings")

    // this.fitBounds()

    this.mapReady.emit(true)
  }

  fitBounds(geoJson) {
  }

  onClick(event) {
    this.getFeatureData(event)
  }

  popUp(latlng, infoHtml) {
    L.popup()
      .setLatLng(latlng)
      .setContent(infoHtml)
      .openOn(this.map)
  }

  // popUp(layer) {
  //   let data = layer.feature
  //   let infoHtml = '<div class="block"><div><b>Building:</b></div><div>' + data.properties.id + '</div>'
  //   infoHtml += '<div class="block"><div><b>Address:</b></div><div>' +
  //     (data.properties.street_id ? `${data.properties.street_name} ${data.properties.house_number}`  : 'N/A' ) +'</div>'
  //   infoHtml += '<div class="block"><div><b>Area:</b></div><div>' + data.properties.area + ' sqm</div>'
  //   infoHtml += '</div>'
  //   return infoHtml
  // }

  layer
  layerRegistred

  getFeatureData(e) {
    // if (this.layer) {
    //   this.layer.removeFrom(this.map)
    // }
    // let BBOX = this.getCurrentBbox()
    // let WIDTH = this.map.getSize().x
    // let HEIGHT = this.map.getSize().y
    // let X = Math.trunc(this.map.layerPointToContainerPoint(e.layerPoint).x)
    // let Y = Math.trunc(this.map.layerPointToContainerPoint(e.layerPoint).y)
    // this.loading = true
    // this.mapService.getFeatureInfo$(BBOX, WIDTH, HEIGHT, X, Y).subscribe(geoJson => {
    //   this.loading = false
    //   if (geoJson) {
    //     this.selectedBuildingId.emit(geoJson.id)
    //     this.layer = this.createGeoJSONLayer(geoJson).addTo(this.map)
    //   }
    // })
  }


  ngOnDestroy(): void {
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // this.fitBounds()
  }
  // prevLayer
  selectedBuildingLayer
  marker

  createGeoJSONLayer(geoJson, color = null, singleFeature = null){
    let this_ = this
    let layer = L.geoJSON(geoJson, {
      style: function (feature) {
          let data = feature.properties
          let abstractParam =  JSON.parse(data.abstract_params)
          if (!abstractParam || !abstractParam.state) {
            if (data.street_id && !singleFeature) {
              return {color: "#4cbb17", fillRule: "nonzero"};
            } else
              return {color: color ? color : "gray", fillRule: "nonzero"};
          }else if (abstractParam.state > 0)
            return {color: "#87f84e", fillRule: "nonzero"};
          else if (abstractParam.state < 0)
            return {color: "#e11846", fillRule: "nonzero"};
          else
            return {color: "gray", fillRule: "nonzero"};

        },
      pointToLayer: function (feature, latlng) {

        // return L.circleMarker(latlng, this_.geojsonMarkerOptions);
        return L.marker(latlng, {icon: new this_.customMarker()})
        // return new this_.customMarker()
      }
    });
    // .bindPopup(async function (layer){

      // if (this_.registered) {
      //   await this_.mapService.getTinsByBuildingId$(layer.feature.properties.id)
      // }

      // if (this_.registered)
      //   this_.mapService.getTinsByBuildingId$(layer.feature.properties.id).subscribe(tins=>{
      //     return this_.popUp(layer)
      // })
      //   else
      //     return this_.popUp(layer)
      // }, {autoPan: false}
    // )
    //
    // this._event.latlng

    layer.on('click', function(ev) {
      let data = ev.layer.feature.properties
      if (this_.selectedBuildingLayer)
        this_.selectedBuildingLayer.removeFrom(this_.map)
      this_.selectedBuildingDetails.next(data)
      this_.selectedBuildingLayer = this_.createGeoJSONLayer(ev.layer.feature, 'blue').addTo(this_.map)
      let popupHtml =`<table class="center-style mat-table mat-h3" style="width:100%"><tbody>
            <tr class="element-row mat-row">
                <td><b>Building</b></td>
                <td>${data.id}</td>
            </tr>
            <tr class="element-row mat-row">
                <td><b>Address</b></td>
                <td>${data.street_id ? `${data.street_name} ${data.house_number ? data.house_number : ''}` : 'N/A'}</td>
            </tr>
            <tr class="element-row mat-row">
                <td><b>Area</b></td>
                <td>${data.area ? data.area : 0} sqm</td>
            </tr>
        </tbody></table>`
        // '<div class="block"><div><b>Building:</b></div><div>' + data.id + '</div>'+
        // '<div class="block"><div><b>Address:</b></div><div>' +
        // (data.street_id ? `${data.street_name} ${data.properties.house_number}`  : 'N/A' ) +'</div>'+
        // '<div class="block"><div><b>Area:</b></div><div>' + data.area + ' sqm</div></div>'
      if (this_.registered) {
        this_.mapService.getTinsByBuildingId$(data.id).subscribe(tins=> {
          if (tins && tins.length)
            popupHtml += '<hr/>'
          popupHtml += '<table class="center-style mat-table mat-h3" style="width:100%"><tbody>'

          tins.forEach((t)=>{
            popupHtml +='<tr class="element-row mat-row">'
            if (t.balance<0)
              popupHtml += `<td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=${t.tin}" target="_blank" rel="noopener noreferrer">${t.tin} ${t.companyName}</a></td><td><span style="color:red;">${t.balance}</span></td>`
            else
              popupHtml += `<td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=${t.tin}" target="_blank" rel="noopener noreferrer">${t.tin} ${t.companyName}</a></td><td><span style="color:green">${t.balance}</span></td>`
            popupHtml +='</tr>'
          })

          // popupHtml += `<tr class="element-row mat-row"><td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=XXXX" target="_blank" rel="noopener noreferrer">N123423567445 Lidas</a></td><td><span style="color:red;">-1000</span></td></tr>`
          // popupHtml += `<tr class="element-row mat-row"><td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=XXXX" target="_blank" rel="noopener noreferrer">N123423567445 Lidas LTD</a></td><td><span style="color:green">23232</span></td></tr>`
          // popupHtml += `<tr class="element-row mat-row"><td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=XXXX" target="_blank" rel="noopener noreferrer">N123423567445 Lidas</a></td><td><span style="color:red;">-1000</span></td></tr>`
          // popupHtml += `<tr class="element-row mat-row"><td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=XXXX" target="_blank" rel="noopener noreferrer">N123423567445 Lidas LTD</a></td><td><span style="color:green">23232</span></td></tr>`
          // popupHtml += `<tr class="element-row mat-row"><td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=XXXX" target="_blank" rel="noopener noreferrer">N123423567445 Lidas</a></td><td><span style="color:red;">-1000</span></td></tr>`
          // popupHtml += `<tr class="element-row mat-row"><td class="mat-cell cdk-column-name mat-column-name"><a style="text-decoration: none" href="/tx_declarations?tin=XXXX" target="_blank" rel="noopener noreferrer">N123423567445 Lidas LTD</a></td><td><span style="color:green">23232</span></td></tr>`
          popupHtml += '</tbody></table>'
          this_.popUp(ev.latlng, popupHtml)
        })
      }else {
        this_.popUp(ev.latlng, popupHtml)
      }
    });
    return layer
  }

  // markerIcon = L.icon({
  //   iconUrl: 'marker-icon.png',
  //   // iconSize: [25, 50],
  //   // iconAnchor: [0, 0],
  //   iconSize: [16,25],
  //   iconAnchor: [5, 10]
  // });

  // addMarker(e){
  //   // Add marker to map at click location; add popup window
  //   console.debug(e.latlng)
  //   // L.marker(e.latlng).addTo(this.map);
  //   L.marker(e.latlng, {icon: this.markerIcon}).addTo(this.map);
  // }

  // popUp(layer, this_) {
  //   let infoHtml = 'hello';
  // }

  getCurrentBbox(){
    // let sw = this.map.options.crs.project(this.map.getBounds().getSouthWest())
    // let ne = this.map.options.crs.project(this.map.getBounds().getNorthEast())
    let sw = CRS.EPSG4326.project(this.map.getBounds().getSouthWest())
    let ne = CRS.EPSG4326.project(this.map.getBounds().getNorthEast())
    let west = this.map.getBounds().getSouthWest()
    let east = this.map.getBounds().getNorthEast()
    // let boundingBox = sw.x + "," + sw.y + "," + ne.x + "," + ne.y
    let boundingBox = west.lat + "," + west.lng + "," + east.lat + "," + east.lng

    // let bounds = [[sw.x, sw.y], [ne.x, ne.y]];
    // if (this.boundingBox){
    //   this.boundingBox.removeFrom(this.map)
    // }
    // this.boundingBox = L.rectangle(this.map.getBounds(), {color: "#ff7800", weight: 1})
    // this.map.addLayer(this.boundingBox)
    return boundingBox
  }

  pointGeoJSON(latln) {
    return {
        'type': 'Point',
        'coordinates': [latln.lng, latln.lat]
    };
  }

  // addMarkerToDrawTool() {
  //   const layer = L.marker(latlng)
  //   this.drawItems.clearLayers();
  //   // layer['layerID'] = polygon.id
  //   this.drawItems.addLayer(layer)
  // }

  // geojsonMarkerOptions = {
  //   radius: 8,
  //   fillColor: "#ff7800",
  //   color: "#000",
  //   weight: 1,
  //   opacity: 1,
  //   fillOpacity: 0.8
  // };

}
