import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tas-gov-registrations-map',
  templateUrl: './registrations-map.component.html',
  styleUrls: ['./registrations-map.component.css']
})
export class RegistrationsMapComponent implements OnInit {
  buildingId_
  buildingId
  selectedBuildingDetails
  bbox
  locationLatLng

  searchById() {
    this.buildingId = null
    setTimeout(()=>{
      this.buildingId = this.buildingId_
    }, 100)

  }

  
  constructor() {
  }

  ngOnInit() {
 
 
  }

 
 

}

