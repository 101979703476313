import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { Row } from '../form-creator/row';
import { DropdownConfigurationService } from 'src/app/services/dropdownConfiguration.service';


@Component({
  selector: 'rdf-formcreation-row-util',
  templateUrl: './row-util.component.html',
  styleUrls: ['./row-util.component.css']
})
export class RowUtilComponent implements OnInit, OnDestroy {
  @Output() onRowCreate = new EventEmitter<any>();
  @Output() onRowUpdate = new EventEmitter<any>();
  @Output() onRowCancel = new EventEmitter<any>();
  @Input() emittables: [];
  @Input() row: Row;
  @Input() year: any;
  @Input() columns: [];
  @Input() rows: [];
  @Input() stage: any;
  @Input() dataSource: any;
  @Input() formula: any;
  @Input() rowkey: any;
  columnsInRows: boolean[]
  formulaKeySubscription: any
  selectableList = false;
  editableDropdown = true;
  rowTypes = ["string", "number", "date", "checkbox", "dropdown", "multiselect", "option", "sector dropdown", "branch dropdown", "activity dropdown", "activity detail dropdown",
    "region dropdown", "department dropdown", "city dropdown", "municipality dropdown", "district dropdown", "locality dropdown", "map", "total fee"]
  rowStyles = ["input", "group", "text"]

  @Input() editingState = false;
  constructor(public sharedService: SharedService, public dropdownConfigurationService: DropdownConfigurationService) { }
  ngOnDestroy(): void {
    if (this.formulaKeySubscription) {
      this.formulaKeySubscription.unsubscribe()
    }
  }
  prevRowKey: any;
  currentRowKey: any
  checkRequired = {};
  checkFormulas = {};
  staticTexts = {};
  formulas = {};
  rowStyle = "input"
  excelMappingText = "excel mapping"
  dropdownConfigurations = []
  idNames = []
  async ngOnInit() {
    let drpConfs = await this.dropdownConfigurationService.getList('dropdown-configuration?year='+this.year).toPromise()
    this.idNames = drpConfs.map(el =>({id:el.id, name: el.name + ' CONF-DR'}))
    this.dropdownConfigurations = drpConfs
    this.rowTypes = this.rowTypes.concat(this.idNames.map(el=>el.name))

    if (this.dataSource !== undefined) {
      this.row = this.dataSource.rows.find(elem => elem.key === this.rowkey)
    } else if (this.row === undefined) {
      this.row = new Row()
    }
    this.rowStyle = this.row.style
    this.currentRowKey = this.row.key
    this.prevRowKey = this.row.key
    this.checkRowType(this.row.type)
    if (this.columns && this.columns.length > 0) {
      this.excelMappingText = "excel row"
    }
    if (this.row['values']) {
      this.row['values'].forEach(element => {
        this.checkFormulas[element['column']] = element['formula'];
        this.formulas[element['column']] = element['formula'];
        this.checkRequired[element['column']] = element['required'];
        this.staticTexts[element['column']] = element['value'];
      })
    }
    this.formulaKeySubscription = this.sharedService.formulaKeyEmmiter.subscribe(val => {
      this.addKeyToFormula(val)
    })
  }

  createRow() {
    this.row.style = this.rowStyle
    this.row.key = this.currentRowKey;
    this.onRowCreate.emit(this.row)
    this.rowFormula = undefined
  }

  updateRow(rowKey) {
    this.row.style = this.rowStyle
    this.row.key = this.currentRowKey;
    if (this.row['values'] !== undefined) {
      for (var i = 0; i < this.row['values'].length; i++) {
        if (this.formulas[this.row['values'][i].column] !== undefined) {
          if (this.row['values'][i]["type"] !== undefined)
            delete this.row['values'][i]["type"];
          this.row['values'][i]["formula"] = this.formulas[this.row['values'][i].column]
        }
        if (this.checkRequired[this.row['values'][i].column] !== undefined) {
          this.row['values'][i]['required'] = this.checkRequired[this.row['values'][i].column]
        }

        if (this.staticTexts[this.row['values'][i].column] !== undefined) {
          this.row['values'][i]['value'] = this.staticTexts[this.row['values'][i].column]
        }
      }
    }
    this.onRowUpdate.emit({ row: this.row, prevRowKey: this.prevRowKey })
  }
  cancelEditing() {
    this.onRowCancel.emit()
  }

  lastColumn = ""
  calculateColumnInRowsClasses(index) {
    return {
      btn: true,
      'selected-component': this.columnsInRows[index]
    };
  }
  selectLastFormula(col) {
    this.lastColumn = col.key
  }

  rowFormula: string
  addKeyToFormula(event) {
    this.formulas[this.lastColumn] = this.formulas[this.lastColumn] === undefined ? event : this.formulas[this.lastColumn] + " " + event
    this.rowFormula = this.rowFormula === undefined ? event : this.rowFormula + " " + event
  }
  defaultSelectItems
  checkRowType(type) {
    if (!this.defaultSelectItems && this.row.selectItems && this.row.selectItems.length > 0)
      this.defaultSelectItems = this.row.selectItems
    this.editableDropdown = true
    this.selectableList = false;
    if (['dropdown', 'multiselect', 'option'].includes(type) || type.includes(' CONF-DR')) {
      this.selectableList = true
      if (type.includes(' CONF-DR') || this.row['configDropdown']) {
        this.editableDropdown = false
        this.row['type'] = type
        let cc = this.dropdownConfigurations.find(elem=>elem.id === this.idNames.find(elem=>elem.name===type).id)
        this.row['configDropdown'] = cc.id
        this.row['selectItems'] = []
      } else {
        this.row['configDropdown'] = null
        if (this.defaultSelectItems) this.row['selectItems'] = this.defaultSelectItems
      }
    } else {
      this.row['configDropdown'] = null
      this.row['selectItems'] = null
    }
  }
  setListItems(items) {
    this.row['selectItems'] = items
  }
  setCondition(event) {
    this.row['condition'] = event
  }
  setCustomValue(event) {
    this.row['customValueEmitter'] = event
  }
}
