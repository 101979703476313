import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/entities/role';
import { AuthService } from 'src/app/services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedService } from 'src/app/services/sharedService';
import { MenuCategories } from './menuItems';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'tas-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {

  title: string = "TAS-GOV";
  lang: string;
  username: string;
  userrole: Role;
  initials: string;
  menuItems: string[][];

  constructor(
    public auth: AuthService,
    public jwtHelper: JwtHelperService,
    public sharedService: SharedService,
    public translate: TranslateService,
    public router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.initializeUserData();
  }

  initializeUserData() {
    const token = this.jwtHelper.tokenGetter();
    if (token && token !== 'undefined') {
      const decodedToken = this.jwtHelper.decodeToken(token);

      this.lang = localStorage.getItem("default_lang") || 'fr';
      this.username = decodedToken.sub;
      this.initials = decodedToken.initials;
      this.userrole = Role[decodedToken.role + ''];
      this.menuItems = MenuCategories.get(Role[this.userrole]);
    }
  }
  isSmallScreen(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 1250px)') && this.breakpointObserver.isMatched('(min-width: 420px)');
  }

  isLargeScreen(): boolean {
    return this.breakpointObserver.isMatched('(min-width: 1450px)');
  }
  setLang(lang) {
    this.sharedService.setLan(lang);
    this.lang = lang;
    this.translate.use(lang);
  }

  logout() {
    this.username = undefined;
    this.auth.logout();
    this.router.navigate(['login']);
  }

  ngOnInit() {
  }
}
