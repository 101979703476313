import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-password-restore',
  templateUrl: './password-restore.component.html',
  styleUrls: ['./password-restore.component.css']
})
export class PasswordRestoreComponent implements OnInit {

  public username: string;
  public password: string;
  public email: string;
  public error: string;
  public emailSent = false;
  notifParam: any;
  public user = {}
  expired = false;
  verificationCode: any
  passwordChanged = false;
  repeatPassword = "";
  emailForm: FormGroup;
  constructor(public authService: AuthService, private activatedRoute: ActivatedRoute, 
    private fb: FormBuilder
  ) { 
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
  
      this.verificationCode = params['vercode'];
      if (this.verificationCode)
      this.authService.getUserByVerCode(this.verificationCode).subscribe(result => {
        if (result.data && result.data==='NO_SUCH_HASH_OR_EXPIRED'){
          this.error = 'ERRORS.NO_SUCH_HASH_OR_EXPIRED';
        }else{
          this.user['id'] = result.id
          this.user['password'] = ''
        }
      })
    });
   
  }
  sendLinkToEmail(email) {
    this.emailSent = true
    this.authService.sendResetLink(email.value).subscribe(result => {

    })
  }

  setPassword(){
    this.passwordChanged = true
    this.authService.updatePassword(this.user).subscribe(res => {
   
    })
  }
}
