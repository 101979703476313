export async function getUniqueMunicipalities(thisCompanies): Promise<{ municipality: string; municipalityName: string }[]> {
    const allRecords = await thisCompanies.toArray();
    const uniqueMunicipalities = new Map();
    allRecords.forEach(({ municipality, municipalityName }) => {
      const key = `${municipality}-${municipalityName}`;
      if (!uniqueMunicipalities.has(key)) {
        uniqueMunicipalities.set(key, { id: municipality, name: municipalityName });
      }
    });
    return Array.from(uniqueMunicipalities.values());
  }