import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentReportService } from 'src/app/services/paymentReport.service';
import { FinancesDataSource } from 'src/app/services/finances.datasource';
import { AvisService } from 'src/app/services/avis.service';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectAutocompleteComponent } from '../multiselect-autocomplete/multiselect-autocomplete.component';
import { CategoryService } from 'src/app/services/category.service';
import { TaxNameService } from 'src/app/services/taxName.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  barChartLabels: Label[];
  barChartData: ChartDataSets[];
  dataSource: FinancesDataSource;
  chartDataSubscription: any
  searchMunicipality: any
  searchTaxName: any
  filterFields = ["municipality"];
  filterData = {
    municipality: null,
    taxName:null
  };
  @ViewChild('municipalitySelect', { static: false }) municipalitySelect: MultiselectAutocompleteComponent;
  @ViewChild('taxNameSelect', { static: false }) taxNameSelect: MultiselectAutocompleteComponent;
  constructor(public paymentReportService: PaymentReportService,
    public avisService: AvisService, public categoryService: CategoryService,
    public translate: TranslateService, public spinner: NgxSpinnerService) {
      this.filterFields.forEach((field) => {
        this.categoryService
          .getList(field)
          .subscribe((result) => {
            if (result) {
              this.filterData[field] = result.map((fld) => ({
                name: fld.name,
                id: fld.id,
              }));
            }
          });
      });
 
  }

  ngOnInit() {
    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
    
  }
  loadChart(type) {
    const bgColor = 'rgba(54, 162, 235, 0.9)'
    this.dataSource.loadChartData(type).subscribe(data => {
      this.barChartData = [];
      let labels = [];
      let isEven = this.isEven(data.length)
      let mid = 0
      mid = ((data.length + (!isEven ? 1 : 0)) / 2) - 1
      let dss = []
      data.reverse().forEach((element, index) => {
        labels.push(element._id.substring(5, 10));
        const dataValues = Array(data.length).fill(0);
        const labelIndex = labels.indexOf(element._id.substring(5, 10));
        dss[labelIndex] = element.totalAmount;
        if (isEven) dss.push(0)
        this.barChartData.push({
          data: dataValues,
          barPercentage: 4,
          backgroundColor: bgColor,
          hoverBackgroundColor: bgColor
        });
      });
      for (let m = 0; m < this.barChartData.length; m++) {
        this.barChartData[mid].data[m] = dss[m]
      }
      this.barChartLabels = labels;
    });
  }
  isEven(num) {
    return num % 2 === 0;
  }
  selectChange = (event: any, field) => {
    if (field === 'municipality'){
      this.searchMunicipality = event.data.map(elem => elem.id)
      if (this.searchMunicipality.length===0){
       this.searchMunicipality = null
     }
    }
    if (field === 'taxName'){
      this.searchTaxName = event.data.map(elem => elem.id)
      if (this.searchTaxName.length===0){
       this.searchTaxName = null
     }
    }
  };
  advancedSearch(val){
    this.loadChart(val)
  }
  ngOnDestroy(): void {
    this.chartDataSubscription && this.chartDataSubscription.unsubscribe()
  }
}
