import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/sharedService';
import { UserService } from 'src/app/services/user.service';
import { PaymentReportService } from 'src/app/services/paymentReport.service';
import { Label } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-rv-graph.',
  templateUrl: './rv-graph.component.html',
  styleUrls: ['./rv-graph.component.css']
})
export class RvGraphComponent implements OnInit, OnDestroy {
  @Input() currentYear
  subscriptionUpdateRevenueCode: any;
  barChartLabels: Label[]
  barChartData: ChartDataSets[]
  chartResponse
  filterObject: {};
  user: any
  currentFilter = {}
  customTexts=[]
  toolTip =  {
    callbacks: {
      label: (tooltipItem, data) => {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        let value = dataset.data[tooltipItem.index];
        if (tooltipItem.datasetIndex===1){
          const amountDataset = data.datasets[0];
          let amountValue = amountDataset.data[tooltipItem.index];
          value = Number(value) + Number(amountValue)
        }
        if (tooltipItem.datasetIndex===2){
          const amountDataset = data.datasets[0];
          const totalRevenueDataset = data.datasets[1];
          let amountValue = amountDataset.data[tooltipItem.index];
          let totalRevenueValue = Number(totalRevenueDataset.data[tooltipItem.index])+Number(amountValue);
          value = Number(value) + Math.max(Number(totalRevenueValue),Number(amountValue))
        }
        return `${dataset.label}: ${value.toLocaleString('fr-FR')}`;
      }
    }
  }
  singleMunicipality
  municipalityChangeSubscription
  subscriptionLanguage
  constructor(public dialog: MatDialog, public sharedService: SharedService,
     private userService: UserService, public paymentService: PaymentReportService,
     private translate: TranslateService
  ) {
  }
  ngOnDestroy(): void {
    if (this.municipalityChangeSubscription) this.municipalityChangeSubscription.unsubscribe()
    if (this.subscriptionLanguage) this.subscriptionLanguage.unsubscribe()
  }
  ngOnInit() {
    this.subscriptionLanguage = this.sharedService.getLan$().subscribe(lan => {
      setTimeout(async ()=>{
        await this.populateChart();
      }, 0)
    })
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      if (localStorage.getItem('municipality')) {
        if (localStorage.getItem('municipality') == "-1") {
          delete this.currentFilter['municipality']
        } else {
          this.currentFilter['municipality'] = [localStorage.getItem('municipality')]
        }
        this.setupBarchartDataAndLabels()
      } else {
        this.barChartLabels = null
        this.barChartData = null
        delete this.currentFilter['municipality']
      }
     
    })
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
      if (this.user) {
        if (this.user.role !== 'ADMIN' && this.user.municipalities && this.user.municipalities.length === 1) {
          this.currentFilter = {}
          this.currentFilter['from'] = new Date(this.currentYear * 1, 0, 1)
          this.currentFilter['to'] = new Date(this.currentYear * 1, 11, 31)
          this.currentFilter['from'].setHours(0, 0, 0, 0)
          this.currentFilter['to'].setHours(23, 59, 59, 999)
          this.currentFilter['year'] = this.currentYear + ''
          this.currentFilter['municipality'] = [this.user.municipalities[0].id]
          this.singleMunicipality = this.user.municipalities[0].name
          this.setupBarchartDataAndLabels()
        }
      }
    })

  }
  getForecast(elem){
    let forecast = elem.forecast || 0
    let amount = elem.amount || 0
    let totalRevenueAmount = elem.totalRevenueAmount || 0
      return forecast - (Math.max(totalRevenueAmount, amount))
  }

  getTotalRevenueAmount(elem){
    let amount = elem.amount || 0
    let totalRevenueAmount = elem.totalRevenueAmount || 0
      return totalRevenueAmount - amount
  }


  setupBarchartDataAndLabels(){
    if (!this.currentFilter['municipality'] && !localStorage.getItem('municipality'))
    {
      this.barChartData = null
      this.barChartLabels = null
      return
    }
    this.currentFilter['from'] = new Date(this.currentYear * 1, 0, 1)
    this.currentFilter['to'] = new Date(this.currentYear * 1, 11, 31)
    this.currentFilter['from'].setHours(0, 0, 0, 0)
    this.currentFilter['to'].setHours(23, 59, 59, 999)
    this.currentFilter['year'] = this.currentYear + ''
    if (!this.currentFilter['municipality'])
    this.currentFilter['municipality'] = [localStorage.getItem('municipality')]
    this.paymentService.getPaymentsByRevenue(this.stringifyWithUTC(this.currentFilter)).subscribe(async res => {
      res.sort((a, b) => {
        if (a.code.toLowerCase() < b.code.toLowerCase()) {
          return -1;
        }
        if (a.code.toLowerCase() > b.code.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.customTexts = res.map(elem => {
        let forecast = elem.forecast || 0
        let totalRevenueAmount = elem.totalRevenueAmount || 0
        let amount = elem.amount || 0
        let percOf = amount > totalRevenueAmount ? amount : totalRevenueAmount
        let percent = forecast!=0? Math.round(percOf/(forecast/100)) + '%' : 'N/A'
        return percent
      })
      this.chartResponse = res
      await this.populateChart();
    })
  }
  private async populateChart() {
    this.barChartLabels = this.chartResponse.map(elem => elem.code)
    this.barChartData = null
    const dashboardTranslations = await this.translate.get('DASHBOARD').toPromise();
    this.barChartData = [
      {
        label: dashboardTranslations['GRAPH_COMPARISON_LABEL_AMOUNT'],
        data: this.chartResponse.map(elem => elem.amount || 0),
        backgroundColor: '#009f60',
        minBarLength: 1,
        stack: 'group1'
      },
      {
        label: dashboardTranslations['GRAPH_COMPARISON_LABEL_TOTAL_REVENUE_AMOUNT'],
        data: this.chartResponse.map(elem => this.getTotalRevenueAmount(elem)),
        backgroundColor: '#5db5f1',
        minBarLength: 1,
        stack: 'group1'
      },
      {
        label: dashboardTranslations['GRAPH_COMPARISON_LABEL_FORECAST'],
        data: this.chartResponse.map(elem => this.getForecast(elem)),
        backgroundColor: (ctx) => {
          const pattern = ctx.chart.ctx.createPattern(this.createPattern(), 'repeat');
          return pattern;
        },
        borderColor: '#42A5F5',
        borderWidth: 1,
        minBarLength: 1,
        stack: 'group1'
      }
    ];
  }

  createPattern() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 20;
    canvas.height = 20;
    ctx.strokeStyle = 'rgba(66, 165, 245, 0.3)';
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(0, 20);
    ctx.lineTo(20, 0);
    ctx.stroke();
    ctx.moveTo(-10, 20);
    ctx.lineTo(10, 0);
    ctx.stroke();
    ctx.moveTo(10, 20);
    ctx.lineTo(30, 0);
    ctx.stroke();
    return canvas;
  }
  externalFunction() {
    this.setupBarchartDataAndLabels()
  }
  ngAfterViewInit() {
    if (localStorage.getItem('municipality')) {
      if (localStorage.getItem('municipality') == "-1") {
        delete this.currentFilter['municipality']
      } else {
        this.setupBarchartDataAndLabels()
      }
    } else {
      delete this.currentFilter['municipality']
    }

  }

  advancedSearch(val) {
    this.currentFilter = val
    this.currentFilter['from'] = new Date(this.currentYear * 1, 0, 1)
    this.currentFilter['to'] = new Date(this.currentYear * 1, 11, 31)
    this.currentFilter['from'].setHours(0, 0, 0, 0)
    this.currentFilter['to'].setHours(23, 59, 59, 999)
    this.currentFilter['year'] = this.currentYear + ''

    if (!this.currentFilter['municipality'] && !localStorage.getItem('municipality'))
      delete this.currentFilter['municipality'] 
    else
      this.currentFilter['municipality'] = this.currentFilter['municipality'] || [localStorage.getItem('municipality')]
    
    this.setupBarchartDataAndLabels()
  }


  stringifyWithUTC(obj) {
    return JSON.stringify(obj, (key, value) => {
      if (obj && obj[key] instanceof Date) {
        const utcDateString = new Date(obj[key].getTime() - (obj[key].getTimezoneOffset() * 60000)).toISOString();
        return utcDateString
      }
      return value;
    });
  }

}
