import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'tas-gov-registrations-map',
  templateUrl: './registrations-map.component.html',
  styleUrls: ['./registrations-map.component.css']
})
export class RegistrationsMapComponent implements OnInit {
  municipalityChangeSubscription: any
  buildingId_
  buildingId
  selectedBuildingDetails
  bbox
  locationLatLng

  searchById() {
    this.buildingId = null
    setTimeout(()=>{
      this.buildingId = this.buildingId_
    }, 100)

  }

  
  constructor(public sharedService: SharedService) {
  }

  ngOnInit() {
    this.municipalityChangeSubscription = this.sharedService.changeMunicipalityEmitter.subscribe(val => {
      this.bbox = val && val.bbox || undefined
      if (this.bbox!==undefined){
        localStorage.setItem('municipalityBbox',this.bbox)
      }
    })
  }

  setBBox(val){
    if (localStorage.getItem('municipalityBbox'))
    this.bbox = localStorage.getItem('municipalityBbox')
  }
}

