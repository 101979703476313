import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-conditionals',
  templateUrl: './conditionals.component.html',
  styleUrls: ['./conditionals.component.css']
})
export class ConditionalsComponent implements OnInit {
  @Input()
  selectables
  selectItems = []
  selectedValue
  @Input()
  row
  @Output() onSelectedValue = new EventEmitter<any>()
  constructor() { }

  ngOnInit() {
    this.selectables = this.selectables.filter(elem=> ['checkbox','option','dropdown'].includes(elem.type))
    if (this.row.condition){
      this.selectedValue = {name: this.row.condition.name, key:this.row.condition.key, condition:this.row.condition.condition}
      if (this.selectables.find(elem=>elem.key === this.row.condition.key))
      this.selectItems = this.selectables.find(elem=>elem.key === this.row.condition.key).selectItems
    }
  }
  changeSelection(ev){
    this.selectedValue = {name: ev.value.name, key:ev.value.key, condition:[]}
    this.selectItems = ev.value.selectItems
    if (!this.selectItems){
      this.selectedValue.condition = true
      this.onSelectedValue.emit(this.selectedValue)
    }
    
  }
  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.key === value.key;
    return false;
  }
  changeValue(event){
    if (event.checked){
      this.selectedValue.condition.push(event.source.value.key)
    }else{
      this.selectedValue.condition = this.selectedValue.condition.filter(elem=>elem!== event.source.value.key)
    }
    this.onSelectedValue.emit(this.selectedValue)
  }
}
