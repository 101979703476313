import { Component, OnInit, Input } from '@angular/core';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { ProfileChart } from '../ProfileChart';
@Component({
  selector: 'app-multi-axis-chart',
  templateUrl: './multi-axis-chart.component.html',
  styleUrls: ['./multi-axis-chart.component.css']
})
export class MultiAxisChartComponent extends ProfileChart implements OnInit {

  @Input() title;
  @Input() footer;
  @Input() description;
  @Input() dashboardConfigs;
  @Input() index;
  @Input() data;
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    fill: false
  };
  constructor(public taxpayerService: TaxPayerService) {
    super(taxpayerService);
    this.barChartType = 'line';
  }

  ngOnInit() {
    this.barChartData = [];
    super.loadData(this.data);
  }
 
 
}
