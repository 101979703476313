export class Row {

    name = "";
    key = "";
    type = "";
    style = "";
    default = true;
    required = false;
    hasAttachment = false;
    global = false;
    avis = false;
    bold = false;
    selectItems = [];
    configDropdown = null;
    mapping = "";
    condition;
}
