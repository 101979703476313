import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-grouped-horizontal-bar-chart-native',
  templateUrl: './grouped-horizontal-bar-chart-native.component.html',
  styleUrls: ['./grouped-horizontal-bar-chart-native.component.css']
})
export class GroupedHorizontalBarChartNativeComponent implements OnInit, OnDestroy {
  @Input() customTooltip
  public barChartOptions: ChartOptions = {
    responsive: true,
    hover: {
      mode: null
    },
    layout: {
      padding: {
        right: 100
      }
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return value.toLocaleString();
          }
        }
      }],
      xAxes: [{
         gridLines: {
          lineWidth: 1,
        },
        ticks: { min: 0,
                beginAtZero: true,
          callback: (value) => {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          }
        }
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
    }
  };
  @Input() barChartLabels: Label[]
  public barChartType: ChartType = 'horizontalBar'
  @Input() barChartLegend = true
  public barChartPlugins = []
  @Input() barChartData: ChartDataSets[]
  @Input() title = ''
  @Input() yearFilter = false
  @Input() yearsFrom = false
  @Input() editable = true
  @Input() downloadable = false
  @Output() onSelectedValue = new EventEmitter<any>()
  emitValue = {
    cdi: '0',
    year: (new Date()).getFullYear(),
    dsf: '0'
  }
  @Input() customTexts: string[] = [];
  @Input() customLabels: string[] = [];

  constructor() { 

    this.addCustomTextPlugin();
  }

  ngOnDestroy() {
    
    this.customTexts = null
    this.customLabels = null
  }
  addCustomTextPlugin() {
    Chart.plugins.register({
      afterDatasetsDraw: (chart) => {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        ctx.save();

        const meta = chart.getDatasetMeta(0);
        if (meta.type!=='horizontalBar'){
          return;
        } 
 
        meta.data.forEach((bar, index) => {
          const positionY = bar._model.y;

          const positionX = chartArea.right;

          ctx.font = 'normal 12px Arial';
          ctx.fillStyle = '#4A4A4A';
          ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
          ctx.shadowBlur = 2;
          if (this.customTexts && this.customTexts[index])
          ctx.fillText(this.customTexts[index], positionX + 8, positionY + 5);
          let mLength = meta.data.length;
          let factor = this.calculateFactor(mLength);
          if (this.customLabels){
            let length = 3
            this.customLabels.forEach(elem => {
              ctx.fillText(elem, 65, positionY + length);
              length += factor/meta.data.length;
            })
          }
          
        });
        ctx.restore();
      },
    });
  }
  calculateFactor(mLength) {
    let baseFactor = 200;
    if (mLength > 10) {
        let range = Math.floor((mLength - 1) / 10);
        baseFactor = 200 + (range * 100);
    }

    return baseFactor;
}
  ngOnInit() {
    
    if (this.customTooltip){
      this.barChartOptions['tooltips'] = this.customTooltip
    }

    if (!this.editable) {
      this.barChartOptions['legend'] = {
        onClick: (e) => {
          e.stopPropagation()
        }
      }
    }
  }

  selectonChanged(value) {
    this.emitValue.cdi = value
    this.onSelectedValue.emit(this.emitValue)
  }

  yearSelectonChanged(value) {
    this.emitValue.year = value
    this.onSelectedValue.emit(this.emitValue)
  }

  @ViewChild('chart', { static: false }) chart: ElementRef;
  printChart() {
    var canvasDataUrl = this.chart.nativeElement.toDataURL()
      .replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
    var link = document.createElement('a');
    link.setAttribute('href', canvasDataUrl);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', this.title + ".png");
    if (document.createEvent) {
      var evtObj = document.createEvent('MouseEvents');
      evtObj.initEvent('click', true, true);
      link.dispatchEvent(evtObj);
    } else if (link.click) {
      link.click();
    }
  }

  getDynamicHeight(): string {
    const baseHeight = 400;
    const increment = 100;
    const threshold = 5;
    const perGroup = 5;

    if (this.barChartLabels.length <= threshold) {
      return `${baseHeight}px`;
    }

    const extraGroups = Math.ceil((this.barChartLabels.length - threshold) / perGroup);
    const newHeight = baseHeight + (extraGroups * increment);
    
    return `${newHeight}px`;
  }
}
