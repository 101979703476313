import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Wallet } from 'src/app/entities/wallet';
import { AvisService } from 'src/app/services/avis.service';
import { CategoryService } from 'src/app/services/category.service';
import { SharedService } from 'src/app/services/sharedService';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { UserService } from 'src/app/services/user.service';
import { DialogCommunicator } from '../dialog-communicator';

@Component({
  selector: 'gov-fill-wallet-dialog',
  templateUrl: './fill-wallet-dialog.component.html',
  styleUrls: ['./fill-wallet-dialog.component.css']
})
export class FillWalletDialogComponent extends DialogCommunicator implements OnInit {

  text: string
  emitter: EventEmitter
  fillWallet: any
  currentUser: any
  taxNames: any[]

  constructor(
    public dialogRef: MatDialogRef<FillWalletDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public taxPayerService: TaxPayerService,
    public categoryService: CategoryService, public avisService: AvisService,
    public userService: UserService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.setConfirmationDialog(this.confirmationDialog)
  }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      this.fillWallet = new Wallet()
      this.fillWallet.date = new Date()
      this.fillWallet['class'] = 'WALLET'
      this.setEntity(this.fillWallet);
      this.currentUser = user
    })
  }

  convertDate = (dateStr) => {
    if (isNaN(Date.parse(dateStr))) {
      return ''
    }
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  selectChangeTaxNames = (event: any) => {
    this.fillWallet['taxName'] = event.data[0];
  };

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option === value;
    return false;
  }
}
