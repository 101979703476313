import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stat-details',
  templateUrl: './stat-details.component.html',
  styleUrls: ['./stat-details.component.css']
})
export class StatDetailsComponent implements OnInit {
  decName: String;
  decKey: String;
  decYear: String;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      if (params['dec_key']){
        this.decKey = params['dec_key']
        if (params['dec_name']){
          this.decName = params['dec_name']
        }
      }
      if (params['dec_year']){
        this.decYear = params['dec_year']
      }
    });
  }

}
