import { Component, OnInit, Inject } from "@angular/core";
import { SharedService } from "src/app/services/sharedService";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { EventEmitter } from "events";
import { DialogData } from "src/app/entities/dialogData";
import { UserStatusName, replaceValues } from "src/app/entities/util";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/entities/user";
import { UserStatus } from "src/app/entities/states/userStatus";
import { DialogCommunicator } from "../dialog-communicator";
import { DropdownService } from "src/app/services/dropdown.service";
import { InformationDialogComponent } from "../information-dialog/information-dialog.component";

@Component({
  selector: "gov-user-dialog",
  templateUrl: "./user-dialog.component.html",
  styleUrls: ["./user-dialog.component.css"],
})
export class UserDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  user: any;
  statusNames = UserStatusName;
  userStatus = UserStatus;
  branches = [];
  usernames = [];
  userroles = [
    "ADMIN",
    "MAYOR_TREASURER",
    "CENTRAL_READER",
    "FINANCIAL_MANAGER_ADMINISTRATOR",
    "CENTRAL_EDITOR",
    "SECRETARY_ASSISTANT",
  ];
  repeatPassword = "";
  municipalities: any[];
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    public userService: UserService,
    public confirmationDialog: MatDialog,
    public dropdownService: DropdownService
  ) {
    super(dialogRef, sharedService);
    this.setConfirmationDialog(this.confirmationDialog);
  }

  ngOnInit() {
    this.user = new User();
    if (this.data) {
      this.userService.getResource("user", this.data["id"]).subscribe((val) => {
        this.user = val;
        this.user["class"] = "USER";
        this.setEntity(this.user);
      });
    } else {
      this.user.status = UserStatus.ACTIVE;
      this.setEntity(this.user);
    }
    this.dropdownService.getList("municipality").subscribe((municipalities) => {
      this.municipalities = municipalities;
    });
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value) return option === value;
    return false;
  };
  selectChange = (event: any) => {
    this.user["municipalities"] = event.data;
  };

  needForMunicipalityByRole(role) {
    return !["ADMIN", "CENTRAL_READER", "CENTRAL_EDITOR"].includes(role);
  }

  saveUser(id) {
    this.userService.getUserByEmail(this.user.email).subscribe((res) => {
      if (!res) {
        this.onUpdateClick(id ? "SAVE" : "UPDATE", "UPDATE_USER");
      } else {
        if (!id || (id && res.id !== id)) {
          this.dialog.open(InformationDialogComponent, {
            width: "500px",
            disableClose: true,
            data: { text: "DIALOG.EMAIL_ALREADY_EXISTS" },
          });
        } else {
          this.onUpdateClick(id ? "SAVE" : "UPDATE", "UPDATE_USER");
        }
      }
    });
  }
}
