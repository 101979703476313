import { TaxNameDexie } from "../interfaces/taxNameDexie";

export async function getTaxNamesByMunicipalityAndType(munId: string, catId: string, thisTaxNames): Promise<TaxNameDexie[]> {
    return thisTaxNames
        .toArray()
        .then(records =>
            records.filter(record =>
                record.municipality.some(m => m.id === munId) &&
                record.category.some(c => c.id === catId)
            )
        );
}
