import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { switchMap } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessType } from 'src/app/entities/processType';
import { ProcessTypeService } from 'src/app/services/proces-type.service';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { ProcessService } from 'src/app/services/process.service';
export interface DialogData {
  name: string;
}
@Component({
  selector: 'app-declarations-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit {
  
  currentProcessId: number;
  // stages coming from the process structure
  stages = [];
  processType: ProcessType;
  missingStages = []
 
  fileName: string;
  fileNamePDF: string;
  excelType: any
 


  constructor(public processTypeService: ProcessTypeService, public reportService: ReportService, 
    private router: Router, private route: ActivatedRoute,
    private processService: ProcessService,
    private spinner: NgxSpinnerService,  private toastr: ToastrService) {
      
  }

   
  ngOnInit() {
    this.processType = new ProcessType();
    this.processType['stages']=[]
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      if (params['id']){
        this.processTypeService.getProcessType(params['id']).subscribe(prType => {
          this.processType = prType
        })
      }
      
    });
    
  
  }

  populatePages(processType) {
    this.processType = processType;
    this.currentProcessId = this.processType.id;
    this.reportService.getPages(processType['key'])
      .subscribe(
        data => {
          this.stages = data.stages;
          var stgs = this.stages.filter(elem => elem.required === true).map(a => a.key);
          var x = this.processType['stages'].map(a => a.key);

        },
        err => {
          console.log(err);
        }
      );
  }
 

  list() {
    this.router.navigate(['declarations']);
  }

  openInEditor(stage) {
    this.router.navigate(['/edit_form/'+this.processType.year+"/"+this.processType.key+"/"+ stage.key], {queryParams: {name:stage.name, type:stage.type, onlyView:true}});
  }
 

  downloadStageData(id){
    this.spinner.show();
    this.processService.getExcelReport(this.processType.year, this.processType.key, id.key).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });

  }
}

 