import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProcessDataSource } from 'src/app/services/process.datasource';
import { UserService } from 'src/app/services/user.service';
import { YearService } from 'src/app/services/year.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  displayedColumns: string[] = ['link', 'username', 'companyName', 'name', 'status', 'started_on', 'due_date'];
  
  displayedColumnsInternal: string[] = ['link','name','municipality','status','year','started_on','month']
  currentYear = new Date().getFullYear().toString()
  currentNIU = '';
  displayedYears = []
  dataSource: ProcessDataSource;

  @ViewChild('input', { static: false }) input: ElementRef;

  user: any;
  name: string;
  currentUser
  constructor(public userService: UserService, public translate: TranslateService,
    public yearService: YearService, public dialog: MatDialog) {
  } 
  ngOnInit() {
   this.userService.getCurrentUser().subscribe(user =>{
    this.currentUser = user
  })
  }
}
