import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ProcessService } from '../../services/process.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { YearService } from 'src/app/services/year.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessDataSource } from 'src/app/services/process.datasource';
import { merge } from 'rxjs';
import { environment } from "../../../environments/environment";
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { UserService } from 'src/app/services/user.service';

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  displayedColumns: string[] = ['link', 'username', 'companyName', 'name', 'status', 'started_on', 'due_date'];
  currentYear = new Date().getFullYear().toString()
  currentNIU = '';
  displayedYears = []
  dataSource: ProcessDataSource;
  objectFilter
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;

  user: any;
  name: string;
  constructor(private processService: ProcessService, public userService: UserService,
    private spinner: NgxSpinnerService, public translate: TranslateService,
    private router: Router, public yearService: YearService, public dialog: MatDialog) {
  }

  cellClicked(element) {
  }
  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user

      this.dataSource = new ProcessDataSource(this.processService)
      this.yearService.getList("year").subscribe(val => {
        val.forEach(element => {
          this.displayedYears.push(element.year)
        });
        if (val.length > 0) {
          setTimeout(() => {
            this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
            merge(this.sort.sortChange, this.paginator.page)
              .pipe(
                tap(() => this.loadProcessPage())
              )
              .subscribe();
          }, 1000)
        }
      });
    })
  }
  advancedSearch(filter) {
    this.spinner.show()
    this.objectFilter = filter
    this.loadProcessPage()
  }
  loadProcessPage() {
    this.dataSource.loadProcesses(null, '', this.sort.direction, this.sort.active, this.paginator.pageIndex, this.paginator.pageSize, this.objectFilter, this.spinner);
  }
  getGovinLink(id,username) {
    return environment.govinLink + '/process/'+id+'?token_from_tas=' + localStorage.getItem(environment.accessToken) + '&username=' + username
  }
  canGoToGovin() {
    if (this.user) {
      return !['MAYOR_TREASURER', 'CENTRAL_READER'].includes(this.user.role)
    }
    return false
  }

  goToStatDetails(row) {
    this.router.navigate(['stat_details'], { queryParams: { dec_key: row.key, dec_name: row.name, dec_year: this.currentYear } });
  }
  downloadStatistics() {
    let extraData = {
      statuses: {
        submitted: this.translate.instant("DECLARATIONS.SUBMITTED"),
        inProgress: this.translate.instant("DECLARATIONS.INPROGRESS")
      },
      headers: [
        { header: this.translate.instant("HOME.NAME"), key: 'name' },
        { header: this.translate.instant("HOME.STATUS"), key: 'status' },
        { header: this.translate.instant("HOME.DUE_DATE"), key: 'due_date' },
        { header: this.translate.instant("HOME.STARTED_ON"), key: 'started_on' },
        { header: this.translate.instant("PROCESS.LAST_MODIFIED"), key: 'last_modified' }
      ]
    }
    this.spinner.show();
    this.processService.downloadList(this.objectFilter, extraData).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }
}




