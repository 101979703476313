import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-dropdown',
  templateUrl: './dynamic-dropdown.component.html',
  styleUrls: ['./dynamic-dropdown.component.css']
})
export class DynamicDropdownComponent implements OnInit {
  @Input() dropdownElements = []
  @Input() maxItems
  @Input() editableDropdown
  dropdownElementsClone = []
  fieldname: string
  fieldvalue: any
  fieldKey: string
  multiselect: boolean
  sortDir = 0
  editingField
  
  @Output() itemsModifiedEmitter: EventEmitter<any> = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }
  addField(){
    if (this.fieldname && this.fieldname.length>0){
      var keyIncrement = 1
      var fieldKey = "key_"+ keyIncrement
      if (this.dropdownElements)
      while (this.dropdownElements.find(elem => elem.key === fieldKey) !== undefined){
        keyIncrement++
        fieldKey = "key_"+ keyIncrement
      }else{
        this.dropdownElements = []
      }
      this.dropdownElements.push({name:this.fieldname, value:this.fieldvalue, key:fieldKey})
      this.dropdownElementsClone.push({name:this.fieldname, value:this.fieldvalue, key:fieldKey})
      this.fieldname = ""
      this.fieldvalue = null
      this.fieldKey = null
      this.multiselect = false
      this.itemsModifiedEmitter.emit(this.dropdownElements)
    }
    
  }
 
  sort(direction){
    this.sortDir = direction
      this.dropdownElements.sort((a,b) => {
        if (this.sortDir===1){
          return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
        }
        if (this.sortDir===-1){
          return (b.name > a.name) ? 1 : ((a.name > b.name) ? -1 : 0)
        }
        if (this.sortDir===0){
          this.dropdownElements = this.dropdownElementsClone.filter(elem => elem.key !== undefined)
        }
      }
      )
      this.itemsModifiedEmitter.emit(this.dropdownElements)
  }
  removeField(key){
    this.dropdownElements = this.dropdownElements.filter(elem => elem.key !== key)
    this.dropdownElementsClone = this.dropdownElements.filter(elem => elem.key !== key)
    this.itemsModifiedEmitter.emit(this.dropdownElements)
  }
  editField(key){
    let elem = this.dropdownElements.find(elem => elem.key === key)
    this.editingField = true
    this.fieldname = elem.name
    this.fieldvalue = elem.value
    this.fieldKey = elem.key
    //this.dropdownElementsClone = this.dropdownElements.filter(elem => elem.key !== key)
    //this.itemsModifiedEmitter.emit(this.dropdownElements)
  }
  cancelEdit(){
    this.editingField = false
    this.fieldname = ""
    this.fieldvalue = null
    this.fieldKey = ""
    this.multiselect = false
  }
  updateField(){
    let elem = this.dropdownElements.find(elem => elem.key === this.fieldKey)
    elem.value = this.fieldvalue
    elem.name = this.fieldname
    this.itemsModifiedEmitter.emit(this.dropdownElements)
    this.cancelEdit()
  }
}
