import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rdf-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.css']
})
export class OptionComponent implements OnInit {
  @Input() field
  constructor() { }

  ngOnInit() {
  }

}
