import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { SharedService } from './sharedService';
import { Optional } from '@angular/core';


export class TasDataSource implements DataSource<any> {

    private processSubject = new BehaviorSubject<any[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public size = 0;

    private data = []

    private totalAmount = 0;

    constructor(private service: any) {

    }

    loadData(resourceUrl: string,
        filter: any,
        sortDirection: string,
        sortColumn: string,
        pageIndex: number,
        pageSize: number, filterObject: any, spinner = null) {
        this.loadingSubject.next(true);
        this.service.getTableData(resourceUrl, filter, sortDirection, sortColumn,
            pageIndex, pageSize, filterObject).pipe(
                catchError(() => of([])),
                finalize(() => { this.loadingSubject.next(false) 
                    spinner && spinner.hide()      
                })
            )
            .subscribe(result => {
                this.data = result['records']
                this.processSubject.next(result['records'])
                this.size = result['total'];
                this.totalAmount = result['totalAmount']
            });

    }

    getData(){
        return this.data;
    }
    getTotalAmount() {
        return this.totalAmount;
    }
    emptyData() {
        this.processSubject.next(null)
        this.size = 0;
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.processSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.processSubject.complete();
        this.loadingSubject.complete();
    }

}